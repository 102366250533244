import {Types} from "./action";
import _ from "lodash";
// import {NotificationManager} from 'react-notifications';

// import cleanDeep from 'clean-deep';


const defaultState = {
  booking: {}
};

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case Types.BOOKING_CALENDAR_DATE_LOCATION: {

      let newState = {...state};

      // to be used for subscriptions
      newState.booking.calendarDate = action.date;
      newState.booking.calendarLocation = action.location;

      return newState;
    }
    case Types.BOOKING_GET_ITEM: {

      let newState = {...state};

      newState.booking.item = action.payload.data.getBooking;

      return newState;
    }
    case Types.BOOKING_CLEAR_ITEM: {

      let newState = {...state};

      newState.booking.item = {};

      if (newState.customer.newModal){
        newState.customer.newModal = false;
      }

      return newState;
    }
    case Types.BOOKING_GET_LIST: {

      let newState = {...state};

      const existingList = newState.booking.list || []

      const merged = _(existingList) // start sequence
        .keyBy('id') // create a dictionary of the 1st array
        .merge(_.keyBy(action.payload.data.listBookings.items, 'id')) // create a dictionary of the 2nd array, and merge it to the 1st
        .values() // turn the combined dictionary to array
        .value(); // get the value (array) out of the sequence


      // TODO add sorting facility
      newState.booking.list = _.sortBy(merged,['givenName','familyName']) 


      newState.booking.listNextToken = action.payload.data.listBookings.nextToken;
      newState.booking.listStartedAt = action.payload.data.listBookings.startedAt;
      console.log('updatedBookingList',newState.booking.list)

      return newState;
    }
    case Types.BOOKING_BY_TEAM_MEMBER: {

      let newState = {...state};

      // this is meant to clear not append to existing
      const existingList = [] // newState.booking.byTeamMember || []

      const merged = _(existingList) // start sequence
        .keyBy('id') // create a dictionary of the 1st array
        .merge(_.keyBy(action.payload.data.bookingByTeamMemberByDate.items, 'id')) // create a dictionary of the 2nd array, and merge it to the 1st
        .values() // turn the combined dictionary to array
        .value(); // get the value (array) out of the sequence


      // TODO add sorting facility
      newState.booking.byTeamMember = merged 


      newState.booking.byTeamMemberNextToken = action.payload.data.bookingByTeamMemberByDate.nextToken;
      newState.booking.byTeamMemberStartedAt = action.payload.data.bookingByTeamMemberByDate.startedAt;
      console.log('updatedBookingList',newState.booking.list)

      return newState;
    }
    case Types.BOOKING_UNAVAILABILITY: {

      let newState = {...state};

      newState.booking.unavailability = action.payload.data.unavailablityByBusinessByDate.items

      return newState;
    }
    case Types.BOOKING_AVAILABILITY_EXCEPTION: {

      let newState = {...state};

      newState.booking.availabilityExceptions = action.payload.data.availabilityExceptionByBusinessByDate.items

      return newState;
    }
    case Types.BOOKING_BY_LOCATION: {

      let newState = {...state};

      const existingList = [] // newState.booking.byLocation || []

      const merged = _(existingList) // start sequence
        .keyBy('id') // create a dictionary of the 1st array
        .merge(_.keyBy(action.payload.data.bookingByLocationByDate.items, 'id')) // create a dictionary of the 2nd array, and merge it to the 1st
        .values() // turn the combined dictionary to array
        .value(); // get the value (array) out of the sequence


      // TODO add sorting facility
      newState.booking.byLocation = merged


      newState.booking.byLocationNextToken = action.payload.data.bookingByLocationByDate.nextToken;
      newState.booking.byLocationStartedAt = action.payload.data.bookingByLocationByDate.startedAt;
      console.log('updatedBookingList',newState.booking.byLocation)

      return newState;
    }
    case Types.BOOKING_BY_CUSTOMER: {

      let newState = {...state};

      const existingList = [] // newState.booking.byCustomer || []

      const merged = _(existingList) // start sequence
        .keyBy('id') // create a dictionary of the 1st array
        .merge(_.keyBy(action.payload.data.bookingByCustomerByDate.items, 'id')) // create a dictionary of the 2nd array, and merge it to the 1st
        .values() // turn the combined dictionary to array
        .value(); // get the value (array) out of the sequence


      // TODO add sorting facility
      newState.booking.byCustomer = merged


      newState.booking.byCustomerNextToken = action.payload.data.bookingByCustomerByDate.nextToken;
      newState.booking.byCustomerStartedAt = action.payload.data.bookingByCustomerByDate.startedAt;
      console.log('updatedBookingList',newState.booking.byCustomer)

      return newState;
    }
    case Types.BOOKING_UPDATE_ITEM: {

      console.log('insideUpdateItem')

      let newState = {...state};

      const updatedItem = action.payload.data.updateBooking || action.payload.data.onUpdateBooking;
      console.log('updatedItem',updatedItem,action.payload)
      newState.booking.item = updatedItem;

      if (newState.booking.list){
        const itemIndex = newState.booking.list.findIndex(item => item.id == updatedItem.id)
        newState.booking.list[itemIndex] = updatedItem;
        newState.booking.list = _.sortBy(newState.booking.list,['givenName','familyName'])
      }

      if (newState.booking.byCustomer){
        const itemIndex = newState.booking.byCustomer.findIndex(item => item.id == updatedItem.id)
        newState.booking.byCustomer[itemIndex] = updatedItem;
        newState.booking.byCustomer = _.sortBy(newState.booking.byCustomer,['date','startTime'])
      }

      if (newState.booking.byLocation){
        const itemIndex = newState.booking.byLocation.findIndex(item => item.id == updatedItem.id)
        newState.booking.byLocation[itemIndex] = updatedItem;
        newState.booking.byLocation = _.sortBy(newState.booking.byLocation,['date','startTime'])
      }

      if (newState.booking.byTeamMember){
        const itemIndex = newState.booking.byTeamMember.findIndex(item => item.id == updatedItem.id)
        newState.booking.byTeamMember[itemIndex] = updatedItem;
        newState.booking.byTeamMember = _.sortBy(newState.booking.byTeamMember,['date','startTime'])
      }

      console.log('newBookingUpdated',newState.booking.item)

      // close modal if open
      if (document.getElementsByClassName('MuiPopover-root').length == 0){
        document.elementFromPoint(0, 0).click();
      }

      return newState;
    }
    case Types.BOOKING_CREATE_ITEM: {

      let newState = {...state};

      const createdItem = action.payload.data.createBooking || action.payload.data.onCreateBooking;

      const exists = newState.booking.list.find(item => item.id == createdItem.id)

      if (exists){
        return state;
      }


      newState.booking.item = createdItem;
      if (newState.booking.list){
        newState.booking.list.push(createdItem);
        newState.booking.list = _.sortBy(newState.booking.list,['givenName','familyName'])
      }

      if (newState.booking.byCustomer){
        newState.booking.byCustomer.push(createdItem);
        newState.booking.byCustomer = _.sortBy(newState.booking.byCustomer,['date','startTime'])
      }

      if (newState.booking.byLocation){
        newState.booking.byLocation.push(createdItem);
        newState.booking.byLocation = _.sortBy(newState.booking.byLocation,['date','startTime'])
      }

      if (newState.booking.byTeamMember){
        newState.booking.byTeamMember.push(createdItem);
        newState.booking.byTeamMember = _.sortBy(newState.booking.byTeamMember,['date','startTime'])
      }

      console.log('newBookingUpdated',newState.booking.item)

      // close modal if open
      document.elementFromPoint(0, 0).click();

      return newState;
    }
    case Types.BOOKING_EMAIL_MODAL: {

      let newState = {...state};

      newState.booking.bookingChangedEmailModal = action.status

      return newState;
    }
    case Types.BOOKING_SHOW_BLOCK_MODAL: {

      let newState = {...state};

      newState.booking.blockModal = action.teamMemberId

      return newState;
    }
    default:
      return state;
  }
};

export default reducer;
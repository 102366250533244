const easter = (Y) => {
    var C = Math.floor(Y/100);
    var N = Y - 19*Math.floor(Y/19);
    var K = Math.floor((C - 17)/25);
    var I = C - Math.floor(C/4) - Math.floor((C - K)/3) + 19*N + 15;
    I = I - 30*Math.floor((I/30));
    I = I - Math.floor(I/28)*(1 - Math.floor(I/28)*Math.floor(29/(I + 1))*Math.floor((21 - N)/11));
    var J = Y + Math.floor(Y/4) + I + 2 - C + Math.floor(C/4);
    J = J - 7*Math.floor(J/7);
    var L = I - J;
    var M = 3 + Math.floor((L + 40)/44);
    var D = L + 28 - 31*Math.floor(M/4);

    return Y + '-' + padout(M) + '-' + padout(D);
}

const  padout = (number) => { return (number < 10) ? '0' + number : number; }

// public holidays for Malta will need to make these somewhat agnostic or via an API?
const publicHolidays = (year) => {

    const holidays = [
        year + "-01-01",
        year + "-02-10",
        year + "-03-19",
        year + "-03-31",
        year + "-05-01",
        year + "-06-07",
        year + "-06-29",
        year + "-08-15",
        year + "-09-08",
        year + "-09-21",
        year + "-12-08",
        year + "-12-13",
        year + "-12-25",
    ]

    const easterDate = easter(parseInt(year));

    holidays.push(easterDate)

    const easterFridayDate = new Date(easterDate);
    easterFridayDate.setDate(easterFridayDate.getDate()-2);
    const easterFriday = easterFridayDate.toISOString().substring(0,10)

    holidays.push(easterFriday);

    return holidays;
}

module.exports = {publicHolidays};
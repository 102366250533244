
import businessReducer from './business/reducer'
import customerReducer from './customer/reducer'
import serviceReducer from './service/reducer'
import productReducer from './product/reducer'
import teamMemberReducer from './teamMember/reducer'
import locationReducer from './location/reducer'
import mobileLocationReducer from './mobileLocation/reducer'
import bookingReducer from './booking/reducer'
import notificationReducer from './notifications/reducer'
import schedulerReducer from './scheduler/reducer'
import changelogReducer from './changelog/reducer'
import tenantsReducer from './tenants/reducer'
import dashboardReducer from './dashboard/reducer'
import adReducer from './ads/reducer'
import paymentMachineReducer from './paymentMachines/reducer'
import deleteModalReducer from './deleteModal/reducer'

// TODO set businessID based on info from login
const defaultState = {
  businessID: false,
  // businessID: "e0d51e18-0448-4b1f-b2af-bf8adf7174b7",
  business: {},
  delete: {},
  customer: {
    newModal: false
  },
  service: {},
  product: {},
  location: {},
  mobileLocation: {},
  booking: {},
  teamMember: {},
  ad: {},
  paymentMachine: {},
  dashboard: {},
  scheduler: {
    filter: {
      value: "Booked,Confirmed,Present,Served,BeingServed,FinishedEarly,Queueing,CheckedIn,NotAnswering,Paid,ReadyForPayment",
      label: "Show Active",
    }
  },
  notifications: [],
  changelog: {},
};


const reducer = (state = defaultState, action) => {

  console.log('state',defaultState)
  console.log('action',action)
  
  // run each reducer based on the action
  if (action && action.type.indexOf('TENANTS') == 0){
    return tenantsReducer(state,action)
  }
  if (action && action.type.indexOf('CUSTOMER') == 0){
    return customerReducer(state,action)
  }
  if (action && action.type.indexOf('BUSINESS') == 0){
    return businessReducer(state,action)
  }
  if (action && action.type.indexOf('SERVICE') == 0){
    return serviceReducer(state,action)
  }
  if (action && action.type.indexOf('PRODUCT') == 0){
    return productReducer(state,action)
  }
  if (action && action.type.indexOf('TEAMMEMBER') == 0){
    return teamMemberReducer(state,action)
  }
  if (action && action.type.indexOf('LOCATION') == 0){
    return locationReducer(state,action)
  }
  if (action && action.type.indexOf('MOBILELOCATION') == 0){
    return mobileLocationReducer(state,action)
  }
  if (action && action.type.indexOf('BOOKING') == 0){
    return bookingReducer(state,action)
  }
  if (action && action.type.indexOf('SCHEDULE') == 0){
    return schedulerReducer(state,action)
  }
  if (action && action.type.indexOf('NOTIFICATION') == 0){
    return notificationReducer(state,action)
  }
  if (action && action.type.indexOf('CHANGELOG') == 0){
    return changelogReducer(state,action)
  }
  if (action && action.type.indexOf('AD') == 0){
    return adReducer(state,action)
  }
  if (action && action.type.indexOf('PAYMENT_MACHINE') == 0){
    return paymentMachineReducer(state,action)
  }
  if (action && action.type.indexOf('DELETE') == 0){
    return deleteModalReducer(state,action)
  }
  if (action && action.type.indexOf('DASHBOARD') == 0){
    return dashboardReducer(state,action)
  }
  
  return state
};

export default reducer;
import React, { useState, useEffect } from "react";
import { useForm } from 'react-hook-form'
import { useParams, useHistory} from "react-router";

import {connect} from 'react-redux';

import {Actions as ACTIONS} from 'store/customer/action';
import PageHeading from 'components/PageHeading';
import { v4 as uuidv4 } from 'uuid';
import { format } from 'date-fns'
import cleanDeep from 'clean-deep';

const CustomerBookings = ({
    services,
    teamMembers,
    locations,
    customer
  }) => {

  const bookings = customer.bookings && customer.bookings.items

  console.log('bookings,bookings',bookings,teamMembers)
  console.log('number of bookings', bookings.length)
  return(
    <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
      <div class="flex flex-col">
        <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
          <div class="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
            <table class="min-w-full">
              <thead>
                <tr>
                  <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Date & Time
                  </th>
                  <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Location
                  </th>
                  <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Team Member
                  </th>
                  <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Service(s)
                  </th>
                  {/*<th class="px-6 py-3 border-b border-gray-200 bg-gray-50"></th>*/}
                </tr>
              </thead>
              <tbody class="bg-white">
                { bookings && bookings.map( (booking, index) => {

                  console.log('bookingDetails',booking,services,booking.services && booking.services.map(i => services.find(item => item.id == i.service)?.name))

                  const teamMember = booking.teamMemberID && teamMembers && teamMembers.find(item => item.id == booking.teamMemberID)
                  return (
                    <tr>
                      <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">
                        {format(new Date(booking.date),'dd/MM/yyyy')} {booking.startTime}
                      </td>
                      <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
                        {booking.locationID && locations && locations.find(item => item.id == booking.locationID)?.name}
                      </td>
                      <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
                        {teamMember && teamMember.givenName} {teamMember && teamMember.familyName}
                      </td>
                      <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
                        {booking.services && services && booking.services.map(i => services.find(item => item.id == i.service)?.name).join(', ')}
                      </td>
                      {/*<td class="px-6 py-4 whitespace-no-wrap text-right border-b border-gray-200 text-sm leading-5 font-medium">
                        <Link to={`/customer/${customer.id}`}  class="text-indigo-600 hover:text-indigo-900 focus:outline-none focus:underline">Edit</Link>
                      </td>*/}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    customer: state.customer.item,
    services: state.service.list,
    teamMembers: state.teamMember.list,
    locations: state.location.list,
  }
}

const mapDispatchToProps = dispatch => ({
  // getCustomer: (id) => dispatch(ACTIONS.getItem(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerBookings) 
import React from "react";
import cleanDeep from 'clean-deep';

import {
          getTeamMember,
          unavailablityByTeamMemberByDate,
          availabilityExceptionByTeamMemberByDate,
          bookingByTeamMemberByDate,
       } from 'graphql/queries'

import {
  createTeamMember,
  deleteTeamMember,
  updateTeamMember,
  createUnavailablity,
  updateUnavailablity,
  deleteUnavailablity,
  createAvailabilityException,
  updateAvailabilityException,
  deleteAvailabilityException,
  createTeamMemberService as createTeamMemberServiceQuery,
  updateTeamMemberService as updateTeamMemberServiceQuery,
} from 'graphql/mutations';
       
import {API} from '@aws-amplify/api'
import {Storage} from '@aws-amplify/storage'
import {Auth} from '@aws-amplify/auth'
import awsMobile from 'aws-exports';
import AWS from 'aws-sdk';
import {Actions as NOTIFICATIONACTIONS} from 'store/notifications/action';
import { v4 as uuidv4 } from 'uuid';

import parsePhoneNumber from 'libphonenumber-js'
import parsePhoneNumberFromString from 'libphonenumber-js'

const listTeamMembers = /* GraphQL */ `
  query ListTeamMembers(
    $filter: ModelTeamMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeamMembers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        businessID
        username
        queueGap
        givenName
        familyName
        phone
        email
        acceptsBookings
        estimatedBreakTimes
        locationID
        offDays
        offDayExceptions
        seatNo
        status
        role
        startDate
        endDate
        photo {
          bucket
          key
        }
        openingHours {
          day
          opensAt
          closesAt
        }
        break {
          day
          opensAt
          closesAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        location {
          id
          businessID
          name
          addressID
          phone
          opensOnPublicHolidays
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        services {
          items {
            id
            businessID
            serviceID
            teamMemberID
            duration
            price
            maxDaily
            enabled
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        unavailablies {
          nextToken
          startedAt
        }
        bookings {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;

// types of action
const Types = {
  TEAMMEMBER_GET_ITEM: "TEAMMEMBER_GET_ITEM",
  TEAMMEMBER_UPDATE_ITEM: "TEAMMEMBER_UPDATE_ITEM",
  TEAMMEMBER_UPDATING_ITEM: "TEAMMEMBER_UPDATING_ITEM",
  TEAMMEMBER_CREATE_ITEM: "TEAMMEMBER_CREATE_ITEM",
  TEAMMEMBER_DELETE_ITEM: "TEAMMEMBER_DELETE_ITEM",
  TEAMMEMBER_CLEAR_ITEM: "TEAMMEMBER_CLEAR_ITEM",
  TEAMMEMBER_LOADING: "TEAMMEMBER_LOADING",
  TEAMMEMBER_GET_LIST: "TEAMMEMBER_GET_LIST",
  TEAMMEMBER_UPDATE_TEAMMEMBER_SERVICE: "TEAMMEMBER_UPDATE_TEAMMEMBER_SERVICE",
  TEAMMEMBER_CREATE_TEAMMEMBER_SERVICE: "TEAMMEMBER_CREATE_TEAMMEMBER_SERVICE",
  TEAMMEMBER_UPDATE_PHOTO: "TEAMMEMBER_UPDATE_PHOTO",
  TEAMMEMBER_GET_UNAVAILABILITY: "TEAMMEMBER_GET_UNAVAILABILITY",
  TEAMMEMBER_CREATE_UNAVAILABILITY: "TEAMMEMBER_CREATE_UNAVAILABILITY",
  TEAMMEMBER_DELETE_UNAVAILABILITY: "TEAMMEMBER_DELETE_UNAVAILABILITY",
  TEAMMEMBER_GET_AVAILABILITY_EXCEPTION: "TEAMMEMBER_GET_AVAILABILITY_EXCEPTION",
  TEAMMEMBER_CREATE_AVAILABILITY_EXCEPTION: "TEAMMEMBER_CREATE_AVAILABILITY_EXCEPTION",
  TEAMMEMBER_DELETE_AVAILABILITY_EXCEPTION: "TEAMMEMBER_DELETE_AVAILABILITY_EXCEPTION",
  TEAMMEMBER_CUSTOM_AVAILABILITY_EXCEPTION: "TEAMMEMBER_CUSTOM_AVAILABILITY_EXCEPTION",
};

const createCustomAvailability = (modalStatus, dateString) => ({
  type: Types.TEAMMEMBER_CUSTOM_AVAILABILITY_EXCEPTION,
  modalStatus, dateString
})

const dispatchLoading =  loading => ({
  type: Types.TEAMMEMBER_LOADING,
  loading
});

const clearItem = response => ({
  type: Types.TEAMMEMBER_CLEAR_ITEM
});

const dispatchGetItem = response => ({
  type: Types.TEAMMEMBER_GET_ITEM,
  payload: response
});

const getItem = (id) => {
  console.log('getItem1',getItem)
  return (dispatch, getState) => {
    dispatch(dispatchLoading(true))

    const variables = {id}
    const request = API.graphql({query: getTeamMember, variables, authMode: "AMAZON_COGNITO_USER_POOLS"})
    console.log('getItem2',getItem)
    request.then((response) => {
        dispatch(dispatchGetItem(response))
        dispatch(dispatchLoading(false))
    });
    
  }
}

const dispatchUpdateItem = response => ({
  type: Types.TEAMMEMBER_UPDATE_ITEM,
  payload: response
});

const dispatchUpdatingItem = () => ({
  type: Types.TEAMMEMBER_UPDATING_ITEM,
});

const updateItem = (item) => {
  return (dispatch, getState) => {
    dispatch(dispatchUpdatingItem())

    let cognitoFunction = '/updateUser'

    if (!item.username){
      // item.username = item.email //uuidv4()
      cognitoFunction = '/createUser'
    }

    if (item.phone){
      let phone = item.phone.startsWith("00") ? "+" + item.phone.substr(2) : item.phone;

      phone = parsePhoneNumberFromString(phone)

      if (!phone){
        phone = parsePhoneNumber(item.phone,"MT")
      }
      item.phone = phone.number
    }

    const state = getState()

    const photo = state.teamMember.item.photo
    item.photo = photo

    const businessID = getState().businessID
    const variables = {input: {...item, businessID}}
    const request = API.graphql({query: updateTeamMember, variables, authMode: "AMAZON_COGNITO_USER_POOLS"})

    request.then(async (response) => {
        dispatch(dispatchUpdateItem(response))

        const jwtToken = (await Auth.currentSession()).getAccessToken();
        console.log('jwtToken',jwtToken)

        const body = {
          username: item.username || item.email,
          email: item.email,
          phone_number: item.phone,
          family_name: item.familyName,
          given_name: item.givenName,
        }

        const myInit = {
            body: cleanDeep(body), // replace this with attributes you need
            headers: { 
              Authorization: `Bearer ${jwtToken.getJwtToken()}`,
            },
        };

        // lets create the user in cognito
        API
          .post('AdminQueries', cognitoFunction, myInit)
          .then(response => {
            // Add your code here
            const username = response && response.id

            if (!variables.input.username && username){
              variables.input.username = username
              const request = API.graphql({query: updateTeamMember, variables, authMode: "AMAZON_COGNITO_USER_POOLS"})
              request.then(async (response) => {
                // updated username in gql
              })
            }
          })
          .catch(error => {
            console.log(error.response);
          });

        //TODO Plan notification logic/errors
        dispatch(NOTIFICATIONACTIONS.addNotification({
          title: <React.Fragment><strong>{response.data.updateTeamMember.givenName}</strong> has been updated</React.Fragment>,
          message: response.data.updateTeamMember.status == "Active" ? `Customers can book this team member` : `This team member is disabled. It cannot be booked`,
          icon: `success`,
        },
          3000
        ))
    });
    
  }
}

const dispatchCreateItem = response => ({
  type: Types.TEAMMEMBER_CREATE_ITEM,
  payload: response
});

const createItem = (item) => {
  return (dispatch, getState) => {

    console.log('businessID',getState('businessID'))

    const businessID = getState().businessID
    const variables = {input: {...item, businessID}}
    const request = API.graphql({query: createTeamMember, variables, authMode: "AMAZON_COGNITO_USER_POOLS"})

    request.then(async (response) => {

      console.log('responseErrors??',response)

      if (response.errors){
        //TODO Plan notification logic/errors
        dispatch(NOTIFICATIONACTIONS.addNotification({
          title: <React.Fragment>Error Creating Team Member</React.Fragment>,
          message: response.errors[0].message,
          icon: `error`,
        },3000))
      } else {


        dispatch(dispatchCreateItem(response))


        const body = {
          username: item.username || item.email,
          email: item.email,
          phone_number: item.phone,
          family_name: item.familyName,
          given_name: item.givenName,
        }

        const myInit = {
            body: cleanDeep(body), // replace this with attributes you need
            headers: { 
              Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
            },
        };

        // lets create the user in cognito
        API
          .post('AdminQueries', '/createUser', myInit)
          .then(response => {
            
            const username = response && response.id

            if (!variables.input.username && username){
              variables.input.username = username
              const request = API.graphql({query: updateTeamMember, variables, authMode: "AMAZON_COGNITO_USER_POOLS"})
              request.then(async (response) => {
                // updated username in gql
              })
            }
          })
          .catch(error => {
            console.log(error.response);
          });


        //TODO Plan notification logic/errors
        dispatch(NOTIFICATIONACTIONS.addNotification({
          title: <React.Fragment><strong>{response.data.createTeamMember.givenName}</strong> has been created</React.Fragment>,
          message: response.data.createTeamMember.status == "Active" ? `This team member is raring and ready to go.` : `This team member is disabled and cannot be booked.`,
          icon: `success`,
        },3000))
      }
    })
      .catch(function(response){

        console.log('err',response)

         dispatch(NOTIFICATIONACTIONS.addNotification({
          title: <React.Fragment>Error Creating Team Member</React.Fragment>,
          message: response.errors[0].message,
          icon: `error`,
        },3000))
      });
    
  }
}

const dispatchDeleteItem = response => ({
  type: Types.TEAMMEMBER_DELETE_ITEM,
  payload: response
});

const deleteItem = (id,_version,history) => {
  return (dispatch, getState) => {

    console.log('businessID',getState('businessID'))

    const businessID = getState().businessID
    const variables = {input: {id, _version }}
    const request = API.graphql({query: deleteTeamMember, variables, authMode: "AMAZON_COGNITO_USER_POOLS"})

    request.then((response) => {
      console.log('responseTEST',response.data.deleteProduct)

        // go to main page as it's been deleted
        history.push(`/team`) 
        dispatch(dispatchDeleteItem(id))

        //TODO Plan notification logic/errors
        dispatch(NOTIFICATIONACTIONS.addNotification({
          title: <React.Fragment><strong>{response.data.deleteTeamMember.name}</strong> has been deleted</React.Fragment>,
          message: `This team member is no longer available.`,
          icon: `success`,
        },2000))
    });
  }
}


const resetPassword = (username) => {
  return async (dispatch, getState) => {

    console.log('businessID',getState('businessID'))


    const body = {
      username,
    }

    const myInit = {
        body: cleanDeep(body), // replace this with attributes you need
        headers: { 
          Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
        },
    };

    // lets create the user in cognito
    API
      .post('AdminQueries', '/resetPassword', myInit)
      .then(response => {
        
        console.log('resetPassword',response)
            
        //TODO Plan notification logic/errors
        dispatch(NOTIFICATIONACTIONS.addNotification({
          title: <React.Fragment>Password has been reset</React.Fragment>,
          message: "An email has been sent with a new temporary password.",
          icon: `success`,
        },3000))

      })
      .catch(error => {
        console.log(error.response);
      });

  }
}


const setPassword = (username,password) => {
  return async (dispatch, getState) => {

    console.log('businessID',getState('businessID'))


    const body = {
      username,
      password
    }

    const myInit = {
        body: cleanDeep(body), // replace this with attributes you need
        headers: { 
          Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
        },
    };

    // lets create the user in cognito
    API
      .post('AdminQueries', '/setPassword', myInit)
      .then(response => {
        
        console.log('setPassword',response)
            
        //TODO Plan notification logic/errors
        dispatch(NOTIFICATIONACTIONS.addNotification({
          title: <React.Fragment>Password has been saved</React.Fragment>,
          message: `Kindly advise the user of their temporary password: ${password}.`,
          icon: `success`,
        },3000))

      })
      .catch(error => {
        console.log(error.response);
      });

  }
}

const dispatchUpdateTeamMemberService = response => ({
  type: Types.TEAMMEMBER_UPDATE_TEAMMEMBER_SERVICE,
  payload: response
});

const updateTeamMemberService = (item) => {
  return (dispatch, getState) => {

    const variables = {input: item}
    const request = API.graphql({query: updateTeamMemberServiceQuery, variables, authMode: "AMAZON_COGNITO_USER_POOLS"})

    const currentState = getState()

    request.then((response) => {
        dispatch(dispatchUpdateTeamMemberService(response))

        const service = currentState.service.list.find(service => service.id == response.data.updateTeamMemberService.serviceID);
        const teamMember = currentState.teamMember.item;

        //TODO Plan notification logic/errors
        dispatch(NOTIFICATIONACTIONS.addNotification({
          title: <React.Fragment><strong>{service.name}</strong> has been updated</React.Fragment>,
          message: response.data.updateTeamMemberService.enabled  ? `${teamMember.givenName} is now offering a ${service.name}` : `${teamMember.givenName} no longer provides a ${service.name}`,
          icon: `success`,
        },
          3000
        ))
    });
    
  }
}

const dispatchCreateTeamMemberService = response => ({
  type: Types.TEAMMEMBER_CREATE_TEAMMEMBER_SERVICE,
  payload: response
});

const createTeamMemberService = (item) => {
  return (dispatch, getState) => {

    const currentState = getState()

    const businessID = currentState.businessID
    const variables = {input: {...item, businessID}}
    const request = API.graphql({query: createTeamMemberServiceQuery, variables, authMode: "AMAZON_COGNITO_USER_POOLS"})

    request.then((response) => {
        dispatch(dispatchCreateTeamMemberService(response))

        const service = currentState.service.list.find(service => service.id == response.data.createTeamMemberService.serviceID);
        const teamMember = currentState.teamMember.item;

        //TODO Plan notification logic/errors
        dispatch(NOTIFICATIONACTIONS.addNotification({
          title: <React.Fragment><strong>{service.name}</strong> has been saved</React.Fragment>,
          message: response.data.createTeamMemberService.enabled  ? `${teamMember.givenName} is now offering a ${service.name}` : `${teamMember.givenName} no longer provides a ${service.name}`,
          icon: `success`,
        },3000))
    });
    
  }
}

const updateList = response => ({
  type: Types.TEAMMEMBER_GET_LIST,
  payload: response
});

const getList = (limit, nextToken) => {
  return (dispatch, getState) => {

    const variables = {limit, nextToken}
    const request = API.graphql({query: listTeamMembers, variables, authMode: "AMAZON_COGNITO_USER_POOLS"})

    request.then((response) => {
        dispatch(updateList(response))
    });
    
  }
}

const updatePhoto = photo => ({
  type: Types.TEAMMEMBER_UPDATE_PHOTO,
  photo
});

const handleUpload = (file, preFileName) => {
  return (dispatch, getState) => {

    const state = getState()

    const businessID = state.businessID

    console.log(file,'show me the file!')

    const {
      aws_user_files_s3_bucket_region: region,
      aws_user_files_s3_bucket: bucket
    } = awsMobile


    console.log(bucket,'this is the bucket!')

    const { name: fileName, type: mimeType } = file;

    const filePath = `teamMember/${state.teamMember.item.id}/${fileName}`  

    const key = preFileName ? preFileName : filePath

    const fileForUpload = {
      bucket,
      key,
      region,
      type: mimeType
    };

    dispatch(NOTIFICATIONACTIONS.addNotification({
      title: <React.Fragment><strong>Image</strong> is currently uploading. Please wait...</React.Fragment>,
      // message: response.data.updateLocation.status == "Active" ? `This location is raring and ready to go.` : `This location is disabled and cannot be booked.`,
      icon: `success`,
    },3000))


    const request = Storage.put(key, file, {
      level: 'public',
      contentType: mimeType,
      progressCallback: (e) => {
          console.log(e,'this is a callback! from storage')
      }
    });


    request.then(() => {

      dispatch(updatePhoto({
        key,
        bucket
      }))


      dispatch(NOTIFICATIONACTIONS.addNotification({
        title: <React.Fragment><strong>Photo</strong> has been uploaded</React.Fragment>,
        // message: response.data.updateLocation.status == "Active" ? `This location is raring and ready to go.` : `This location is disabled and cannot be booked.`,
        icon: `success`,
      },3000))

    });
  }
}



const dispatchCreateUnavailability = response => ({
  type: Types.TEAMMEMBER_CREATE_UNAVAILABILITY,
  payload: response
});

const createUnavailability = (date, timeIn, timeOut,breakTime,allowExtraBreak) => {
  return (dispatch, getState) => {

    console.log('businessID',getState('businessID'))

    const state = getState();

    const businessID = state.businessID
    const teamMemberID = state.teamMember.item.id


    const bookingVariables = {
      teamMemberID,
      date: {
        eq: date
      },
      limit: 1000,
    }

    const bookingRequest = API.graphql({query: bookingByTeamMemberByDate, variables: bookingVariables, authMode: "AMAZON_COGNITO_USER_POOLS"})

    bookingRequest.then((response) => {

      // check if we can proceed 
      const filtered = response.data.bookingByTeamMemberByDate && response.data.bookingByTeamMemberByDate.items && 
              response.data.bookingByTeamMemberByDate.items
                .filter( item => !item._deleted && item.status != "Cancelled");

      if (filtered && filtered.length > 0){

        dispatch(NOTIFICATIONACTIONS.addNotification({
          title: <React.Fragment><strong>Error</strong> user has booking on this day</React.Fragment>,
          message: `Please cancel or move any bookings before you apply leave.`,
          icon: `warning`,
        },3000))

      } else {

          const variables = {input: {
            date,
            businessID,
            teamMemberID,
            reason: "Off",
          }}
          if(timeIn && timeOut) {
            variables.input.from = timeIn
            variables.input.to = timeOut
            variables.input.breakTime = breakTime
            variables.input.allowExtraBreak = allowExtraBreak
          }

          const request = API.graphql({query: createUnavailablity, variables, authMode: "AMAZON_COGNITO_USER_POOLS"})


          request.then(async (response) => {
              dispatch(dispatchCreateUnavailability(response))

              // just refresh all unavailability (instead of updating data in array...)
              dispatch(getUnavailability())

              //TODO Plan notification logic/errors
              dispatch(
                NOTIFICATIONACTIONS.addNotification({
                title: <React.Fragment><strong>Off Day</strong> has been created</React.Fragment>,
                // message: response.data.createTeamMember.status == "Active" ? `This team member is raring and ready to go.` : `This team member is disabled and cannot be booked.`,
                icon: `success`,
              },3000)
              )
          });
        }

    });


    
  }
}

const updateUnavailability = ({id,reason,_version}) => {
  return (dispatch, getState) => {

    const variables = {input: {
      id,
      reason,
      _version,
    }}
    const request = API.graphql({query: updateUnavailablity, variables, authMode: "AMAZON_COGNITO_USER_POOLS"})


    request.then(async (response) => {
        dispatch(dispatchCreateUnavailability(response))

        // just refresh all unavailability (instead of updating data in array...)
        dispatch(getUnavailability())

        //TODO Plan notification logic/errors
        dispatch(NOTIFICATIONACTIONS.addNotification({
          title: <React.Fragment><strong>{reason}</strong> has been created</React.Fragment>,
          // message: response.data.createTeamMember.status == "Active" ? `This team member is raring and ready to go.` : `This team member is disabled and cannot be booked.`,
          icon: `success`,
        },3000))
    });


    
  }
}

const dispatchDeleteUnavailability = response => ({
  type: Types.TEAMMEMBER_DELETE_UNAVAILABILITY,
  payload: response
});

const deleteUnavailability = (id) => {
  return (dispatch, getState) => {

    console.log('businessID',getState('businessID'))

    const state = getState();

    const unavailabilities = state.teamMember.unavailabilities

    const unaviability = unavailabilities.find(item => id == item.id )

    const variables = {input: {
      id,
      _version: unaviability._version,
    }}

    const request = API.graphql({query: deleteUnavailablity, variables, authMode: "AMAZON_COGNITO_USER_POOLS"})

    request.then(async (response) => {
        dispatch(dispatchDeleteUnavailability(response))

        // just refresh all unavailability (instead of updating data in array...)
        dispatch(getUnavailability())

        //TODO Plan notification logic/errors
        dispatch(NOTIFICATIONACTIONS.addNotification({
          title: <React.Fragment><strong>{unaviability.reason}</strong> has been deleted</React.Fragment>,
          // message: response.data.createTeamMember.status == "Active" ? `This team member is raring and ready to go.` : `This team member is disabled and cannot be booked.`,
          icon: `success`,
        },3000))
    });
    
  }
}


const updateUnavailabilityList = response => ({
  type: Types.TEAMMEMBER_GET_UNAVAILABILITY,
  payload: response
});

const getUnavailability = (id) => {
  return (dispatch, getState) => {

    const state = getState();

    const teamMemberID = id || state.teamMember.item.id

    const variables = {
      teamMemberID,
      sortDirection: "DESC",
      limit: 100,
      // nextToken
    }
    const request = API.graphql({query: unavailablityByTeamMemberByDate, variables, authMode: "AMAZON_COGNITO_USER_POOLS"})

    request.then((response) => {
        dispatch(updateUnavailabilityList(response))
    });
    
  }
}



const dispatchCreateAvailabilityException = response => ({
  type: Types.TEAMMEMBER_CREATE_AVAILABILITY_EXCEPTION,
  payload: response
});

const createAvailabilityExceptionItem = (date, timeIn, timeOut, breakTime, allowExtraBreak) => {
  return (dispatch, getState) => {

    console.log('businessID',getState('businessID'))

    const state = getState();

    const businessID = state.businessID
    const teamMemberID = state.teamMember.item.id


    const variables = {input: {
      date,
      businessID,
      teamMemberID,
    }}

    if(timeIn && timeOut) {
      variables.input.from = timeIn
      variables.input.to = timeOut
      variables.input.breakTime = breakTime
      variables.input.allowExtraBreak = allowExtraBreak
    }

    const request = API.graphql({query: createAvailabilityException, variables, authMode: "AMAZON_COGNITO_USER_POOLS"})


    request.then(async (response) => {
        dispatch(dispatchCreateAvailabilityException(response))

        // just refresh all unavailability (instead of updating data in array...)
        dispatch(getAvailabilityExceptions())

        //TODO Plan notification logic/errors
        dispatch(NOTIFICATIONACTIONS.addNotification({
          title: <React.Fragment><strong>Availability Exception</strong> has been created</React.Fragment>,
          // message: response.data.createTeamMember.status == "Active" ? `This team member is raring and ready to go.` : `This team member is disabled and cannot be booked.`,
          icon: `success`,
        },3000))
    });
  }
}

const updateAvailabilityExceptionItem = (id, timeIn, timeOut, breakTime, allowExtraBreak) => {
  return (dispatch, getState) => {

    console.log('businessID',getState('businessID'))

    const state = getState();

    const businessID = state.businessID
    const teamMemberID = state.teamMember.item.id


    const availabilityExceptions = state.teamMember.availabilityExceptions

    const unaviability = availabilityExceptions.find(item => id == item.id )

    const variables = {input: {
      id,
      date: unaviability.date,
      from: timeIn,
      to: timeOut,
      businessID,
      teamMemberID,
      _version: unaviability._version,
    }}

    const bookingRequest = API.graphql({query: updateAvailabilityException, variables: variables, authMode: "AMAZON_COGNITO_USER_POOLS"})

    bookingRequest.then((response) => {

      // check if we can proceed 
      const filtered = response.data.bookingByTeamMemberByDate && response.data.bookingByTeamMemberByDate.items && 
              response.data.bookingByTeamMemberByDate.items
                .filter( item => !item._deleted && item.status != "Cancelled");

      if (filtered && filtered.length > 0){

        dispatch(NOTIFICATIONACTIONS.addNotification({
          title: <React.Fragment><strong>Error</strong> user has booking on this day</React.Fragment>,
          message: `Please cancel or move any bookings before you cancel exception.`,
          icon: `warning`,
        },3000))

      } else {

        const variables = {input: {
          id,
          _version: unaviability._version,
        }}

        const request = API.graphql({query: updateAvailabilityException, variables, authMode: "AMAZON_COGNITO_USER_POOLS"})

        request.then(async (response) => {
            dispatch(dispatchCreateAvailabilityException(response))

            // just refresh all unavailability (instead of updating data in array...)
            dispatch(getAvailabilityExceptions())

            //TODO Plan notification logic/errors
            dispatch(NOTIFICATIONACTIONS.addNotification({
              title: <React.Fragment><strong>Availability Exception</strong> has been updated</React.Fragment>,
              // message: response.data.createTeamMember.status == "Active" ? `This team member is raring and ready to go.` : `This team member is disabled and cannot be booked.`,
              icon: `success`,
            },3000))
        });
      }

    });
    
  }
}

const dispatchDeleteAvailabilityException = response => ({
  type: Types.TEAMMEMBER_DELETE_AVAILABILITY_EXCEPTION,
  payload: response
});

const deleteAvailabilityExceptionItem = (id) => {
  return (dispatch, getState) => {

    console.log('businessID',getState('businessID'))

    const state = getState();

    const businessID = state.businessID
    const teamMemberID = state.teamMember.item.id


    const availabilityExceptions = state.teamMember.availabilityExceptions

    const unaviability = availabilityExceptions.find(item => id == item.id )

    const bookingVariables = {
      teamMemberID,
      date: {
        eq: unaviability.date
      },
      limit: 1000,
    }

    const bookingRequest = API.graphql({query: bookingByTeamMemberByDate, variables: bookingVariables, authMode: "AMAZON_COGNITO_USER_POOLS"})

    bookingRequest.then((response) => {

      // check if we can proceed 
      const filtered = response.data.bookingByTeamMemberByDate && response.data.bookingByTeamMemberByDate.items && 
              response.data.bookingByTeamMemberByDate.items
                .filter( item => !item._deleted && item.status != "Cancelled");

      if (filtered && filtered.length > 0){

        dispatch(NOTIFICATIONACTIONS.addNotification({
          title: <React.Fragment><strong>Error</strong> user has booking on this day</React.Fragment>,
          message: `Please cancel or move any bookings before you cancel exception.`,
          icon: `warning`,
        },3000))

      } else {

        const variables = {input: {
          id,
          _version: unaviability._version,
        }}

        const request = API.graphql({query: deleteAvailabilityException, variables, authMode: "AMAZON_COGNITO_USER_POOLS"})

        request.then(async (response) => {
            dispatch(dispatchDeleteAvailabilityException(response))

            // just refresh all unavailability (instead of updating data in array...)
            dispatch(getAvailabilityExceptions())

            //TODO Plan notification logic/errors
            dispatch(NOTIFICATIONACTIONS.addNotification({
              title: <React.Fragment><strong>Availability Exception</strong> has been deleted</React.Fragment>,
              // message: response.data.createTeamMember.status == "Active" ? `This team member is raring and ready to go.` : `This team member is disabled and cannot be booked.`,
              icon: `success`,
            },3000))
        });
      }

    });
    
  }
}


const updateAvailabilityExceptionList = response => ({
  type: Types.TEAMMEMBER_GET_AVAILABILITY_EXCEPTION,
  payload: response
});

const getAvailabilityExceptions = (id) => {
  return (dispatch, getState) => {

    const state = getState();

    const teamMemberID = id || state.teamMember.item.id

    const variables = {
      teamMemberID,
      sortDirection: "DESC",
      limit: 100,
      // nextToken
    }
    const request = API.graphql({query: availabilityExceptionByTeamMemberByDate, variables, authMode: "AMAZON_COGNITO_USER_POOLS"})

    request.then((response) => {
        dispatch(updateAvailabilityExceptionList(response))
    });
    
  }
}


const Actions = {
  getItem,
  createItem,
  deleteItem,
  updateItem,
  getList,
  clearItem,
  updateTeamMemberService,
  createTeamMemberService,
  handleUpload,
  createUnavailability,
  getUnavailability,
  deleteUnavailability,
  createAvailabilityException: createAvailabilityExceptionItem,
  deleteAvailabilityException: deleteAvailabilityExceptionItem,
  updateAvailabilityException: updateAvailabilityExceptionItem,
  getAvailabilityExceptions,
  resetPassword,
  setPassword,
  updateUnavailability,
  createCustomAvailability,
}

// exports = {
//   Types,
//   Actions
// }

export {Types, Actions}

// exports.Types = Types
// exports.Actions = Actions
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAddress = /* GraphQL */ `
  query GetAddress($id: ID!) {
    getAddress(id: $id) {
      id
      businessID
      addressLine1
      addressLine2
      addressLine3
      addressLine4
      city
      region
      postCode
      country
      attentionTo
      lat
      lng
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listAddresss = /* GraphQL */ `
  query ListAddresss(
    $filter: ModelAddressFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAddresss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        businessID
        addressLine1
        addressLine2
        addressLine3
        addressLine4
        city
        region
        postCode
        country
        attentionTo
        lat
        lng
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAddresses = /* GraphQL */ `
  query SyncAddresses(
    $filter: ModelAddressFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAddresses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        businessID
        addressLine1
        addressLine2
        addressLine3
        addressLine4
        city
        region
        postCode
        country
        attentionTo
        lat
        lng
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getAddressConnection = /* GraphQL */ `
  query GetAddressConnection($id: ID!) {
    getAddressConnection(id: $id) {
      id
      businessID
      customerID
      address {
        id
        businessID
        addressLine1
        addressLine2
        addressLine3
        addressLine4
        city
        region
        postCode
        country
        attentionTo
        lat
        lng
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      addressType
      current
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listAddressConnections = /* GraphQL */ `
  query ListAddressConnections(
    $filter: ModelAddressConnectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAddressConnections(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        businessID
        customerID
        address {
          id
          businessID
          addressLine1
          addressLine2
          addressLine3
          addressLine4
          city
          region
          postCode
          country
          attentionTo
          lat
          lng
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        addressType
        current
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAddressConnections = /* GraphQL */ `
  query SyncAddressConnections(
    $filter: ModelAddressConnectionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAddressConnections(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        businessID
        customerID
        address {
          id
          businessID
          addressLine1
          addressLine2
          addressLine3
          addressLine4
          city
          region
          postCode
          country
          attentionTo
          lat
          lng
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        addressType
        current
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getBusiness = /* GraphQL */ `
  query GetBusiness($id: ID!) {
    getBusiness(id: $id) {
      id
      name
      allowBookingsDayAdvance
      allowBookingsDayAdvanceWeb
      vacationLeaveDays
      takingBookings
      allowBackDatedBookings
      logo {
        bucket
        key
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      addresses {
        items {
          id
          businessID
          customerID
          addressType
          current
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const listBusinesss = /* GraphQL */ `
  query ListBusinesss(
    $filter: ModelBusinessFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBusinesss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        allowBookingsDayAdvance
        allowBookingsDayAdvanceWeb
        vacationLeaveDays
        takingBookings
        allowBackDatedBookings
        logo {
          bucket
          key
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        addresses {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncBusinesses = /* GraphQL */ `
  query SyncBusinesses(
    $filter: ModelBusinessFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncBusinesses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        allowBookingsDayAdvance
        allowBookingsDayAdvanceWeb
        vacationLeaveDays
        takingBookings
        allowBackDatedBookings
        logo {
          bucket
          key
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        addresses {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getLocation = /* GraphQL */ `
  query GetLocation($id: ID!) {
    getLocation(id: $id) {
      id
      businessID
      name
      code
      addressID
      phone
      openingHours {
        day
        opensAt
        closesAt
      }
      acceptsBookings
      virtualQueue
      online
      maintenance
      opensOnPublicHolidays
      isMobile
      bookingFlow
      mobileWeekOffset
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      address {
        id
        businessID
        addressLine1
        addressLine2
        addressLine3
        addressLine4
        city
        region
        postCode
        country
        attentionTo
        lat
        lng
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      team {
        items {
          id
          businessID
          code
          username
          givenName
          familyName
          phone
          email
          acceptsBookings
          locationID
          offDays
          offDayExceptions
          seatNo
          queueGap
          entrance
          status
          role
          startDate
          endDate
          estimatedBreakTimes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      photos {
        items {
          id
          businessID
          description
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const listLocations = /* GraphQL */ `
  query ListLocations(
    $filter: ModelLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLocations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        businessID
        name
        code
        addressID
        phone
        openingHours {
          day
          opensAt
          closesAt
        }
        acceptsBookings
        virtualQueue
        online
        maintenance
        opensOnPublicHolidays
        isMobile
        bookingFlow
        mobileWeekOffset
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        address {
          id
          businessID
          addressLine1
          addressLine2
          addressLine3
          addressLine4
          city
          region
          postCode
          country
          attentionTo
          lat
          lng
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        team {
          nextToken
          startedAt
        }
        photos {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncLocations = /* GraphQL */ `
  query SyncLocations(
    $filter: ModelLocationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLocations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        businessID
        name
        code
        addressID
        phone
        openingHours {
          day
          opensAt
          closesAt
        }
        acceptsBookings
        virtualQueue
        online
        maintenance
        opensOnPublicHolidays
        isMobile
        bookingFlow
        mobileWeekOffset
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        address {
          id
          businessID
          addressLine1
          addressLine2
          addressLine3
          addressLine4
          city
          region
          postCode
          country
          attentionTo
          lat
          lng
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        team {
          nextToken
          startedAt
        }
        photos {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getMobileLocation = /* GraphQL */ `
  query GetMobileLocation($id: ID!) {
    getMobileLocation(id: $id) {
      id
      businessID
      locationID
      name
      addressID
      isRepeating
      comingSoon
      colour
      order
      weeksAtLocation
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      address {
        id
        businessID
        addressLine1
        addressLine2
        addressLine3
        addressLine4
        city
        region
        postCode
        country
        attentionTo
        lat
        lng
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      photos {
        items {
          id
          businessID
          description
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const listMobileLocations = /* GraphQL */ `
  query ListMobileLocations(
    $filter: ModelMobileLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMobileLocations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        businessID
        locationID
        name
        addressID
        isRepeating
        comingSoon
        colour
        order
        weeksAtLocation
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        address {
          id
          businessID
          addressLine1
          addressLine2
          addressLine3
          addressLine4
          city
          region
          postCode
          country
          attentionTo
          lat
          lng
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        photos {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncMobileLocations = /* GraphQL */ `
  query SyncMobileLocations(
    $filter: ModelMobileLocationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMobileLocations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        businessID
        locationID
        name
        addressID
        isRepeating
        comingSoon
        colour
        order
        weeksAtLocation
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        address {
          id
          businessID
          addressLine1
          addressLine2
          addressLine3
          addressLine4
          city
          region
          postCode
          country
          attentionTo
          lat
          lng
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        photos {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getLocationScheduleException = /* GraphQL */ `
  query GetLocationScheduleException($id: ID!) {
    getLocationScheduleException(id: $id) {
      id
      businessID
      locationID
      mobileLocationID
      date
      disabled
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listLocationScheduleExceptions = /* GraphQL */ `
  query ListLocationScheduleExceptions(
    $filter: ModelLocationScheduleExceptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLocationScheduleExceptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        businessID
        locationID
        mobileLocationID
        date
        disabled
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const locationScheduleExceptionByLocationByDate = /* GraphQL */ `
  query LocationScheduleExceptionByLocationByDate(
    $locationID: ID
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLocationScheduleExceptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    locationScheduleExceptionByLocationByDate(
      locationID: $locationID
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        businessID
        locationID
        mobileLocationID
        date
        disabled
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const locationScheduleExceptionByBusinessByDate = /* GraphQL */ `
  query LocationScheduleExceptionByBusinessByDate(
    $businessID: ID
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLocationScheduleExceptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    locationScheduleExceptionByBusinessByDate(
      businessID: $businessID
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        businessID
        locationID
        mobileLocationID
        date
        disabled
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncLocationScheduleExceptions = /* GraphQL */ `
  query SyncLocationScheduleExceptions(
    $filter: ModelLocationScheduleExceptionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLocationScheduleExceptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        businessID
        locationID
        mobileLocationID
        date
        disabled
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getService = /* GraphQL */ `
  query GetService($id: ID!) {
    getService(id: $id) {
      id
      businessID
      name
      code
      blockColour
      offeredIn
      order
      duration
      price
      isDoneParallel
      isSecondary
      isAddon
      enabled
      online
      photo {
        bucket
        key
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      teamMembers {
        items {
          id
          businessID
          serviceID
          teamMemberID
          duration
          price
          maxDaily
          enabled
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const listServices = /* GraphQL */ `
  query ListServices(
    $filter: ModelServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listServices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        businessID
        name
        code
        blockColour
        offeredIn
        order
        duration
        price
        isDoneParallel
        isSecondary
        isAddon
        enabled
        online
        photo {
          bucket
          key
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        teamMembers {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncServices = /* GraphQL */ `
  query SyncServices(
    $filter: ModelServiceFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncServices(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        businessID
        name
        code
        blockColour
        offeredIn
        order
        duration
        price
        isDoneParallel
        isSecondary
        isAddon
        enabled
        online
        photo {
          bucket
          key
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        teamMembers {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getProduct = /* GraphQL */ `
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      id
      businessID
      name
      code
      order
      price
      enabled
      online
      photo {
        bucket
        key
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listProducts = /* GraphQL */ `
  query ListProducts(
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        businessID
        name
        code
        order
        price
        enabled
        online
        photo {
          bucket
          key
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncProducts = /* GraphQL */ `
  query SyncProducts(
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncProducts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        businessID
        name
        code
        order
        price
        enabled
        online
        photo {
          bucket
          key
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getTeamMemberService = /* GraphQL */ `
  query GetTeamMemberService($id: ID!) {
    getTeamMemberService(id: $id) {
      id
      businessID
      serviceID
      teamMemberID
      duration
      price
      maxDaily
      enabled
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listTeamMemberServices = /* GraphQL */ `
  query ListTeamMemberServices(
    $filter: ModelTeamMemberServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeamMemberServices(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        businessID
        serviceID
        teamMemberID
        duration
        price
        maxDaily
        enabled
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncTeamMemberServices = /* GraphQL */ `
  query SyncTeamMemberServices(
    $filter: ModelTeamMemberServiceFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTeamMemberServices(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        businessID
        serviceID
        teamMemberID
        duration
        price
        maxDaily
        enabled
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getTeamMember = /* GraphQL */ `
  query GetTeamMember($id: ID!) {
    getTeamMember(id: $id) {
      id
      businessID
      code
      username
      givenName
      familyName
      phone
      email
      acceptsBookings
      locationID
      offDays
      offDayExceptions
      seatNo
      queueGap
      entrance
      status
      role
      startDate
      endDate
      photo {
        bucket
        key
      }
      openingHours {
        day
        opensAt
        closesAt
      }
      break {
        day
        opensAt
        closesAt
      }
      estimatedBreakTimes
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      location {
        id
        businessID
        name
        code
        addressID
        phone
        openingHours {
          day
          opensAt
          closesAt
        }
        acceptsBookings
        virtualQueue
        online
        maintenance
        opensOnPublicHolidays
        isMobile
        bookingFlow
        mobileWeekOffset
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        address {
          id
          businessID
          addressLine1
          addressLine2
          addressLine3
          addressLine4
          city
          region
          postCode
          country
          attentionTo
          lat
          lng
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        team {
          nextToken
          startedAt
        }
        photos {
          nextToken
          startedAt
        }
      }
      services {
        items {
          id
          businessID
          serviceID
          teamMemberID
          duration
          price
          maxDaily
          enabled
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      unavailablies {
        items {
          id
          businessID
          teamMemberID
          date
          from
          to
          reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      availabilityExceptions {
        items {
          id
          businessID
          teamMemberID
          date
          from
          to
          breakTime
          allowExtraBreak
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      bookings {
        items {
          id
          businessID
          customerID
          teamMemberID
          locationID
          note
          transactionReference
          paymentMethod
          date
          startTime
          endTime
          dateCancelled
          status
          source
          fee
          additionalFee
          tip
          confirmationReminderSent
          finalReminderSent
          reviewEmailSent
          createdAt
          updatedAt
          stripeID
          version
          transaction
          isNew
          any
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const listTeamMembers = /* GraphQL */ `
  query ListTeamMembers(
    $filter: ModelTeamMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeamMembers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        businessID
        code
        username
        givenName
        familyName
        phone
        email
        acceptsBookings
        locationID
        offDays
        offDayExceptions
        seatNo
        queueGap
        entrance
        status
        role
        startDate
        endDate
        photo {
          bucket
          key
        }
        openingHours {
          day
          opensAt
          closesAt
        }
        break {
          day
          opensAt
          closesAt
        }
        estimatedBreakTimes
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        location {
          id
          businessID
          name
          code
          addressID
          phone
          acceptsBookings
          virtualQueue
          online
          maintenance
          opensOnPublicHolidays
          isMobile
          bookingFlow
          mobileWeekOffset
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        services {
          nextToken
          startedAt
        }
        unavailablies {
          nextToken
          startedAt
        }
        availabilityExceptions {
          nextToken
          startedAt
        }
        bookings {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const teamMemberByUsername = /* GraphQL */ `
  query TeamMemberByUsername(
    $username: String
    $sortDirection: ModelSortDirection
    $filter: ModelTeamMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    teamMemberByUsername(
      username: $username
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        businessID
        code
        username
        givenName
        familyName
        phone
        email
        acceptsBookings
        locationID
        offDays
        offDayExceptions
        seatNo
        queueGap
        entrance
        status
        role
        startDate
        endDate
        photo {
          bucket
          key
        }
        openingHours {
          day
          opensAt
          closesAt
        }
        break {
          day
          opensAt
          closesAt
        }
        estimatedBreakTimes
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        location {
          id
          businessID
          name
          code
          addressID
          phone
          acceptsBookings
          virtualQueue
          online
          maintenance
          opensOnPublicHolidays
          isMobile
          bookingFlow
          mobileWeekOffset
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        services {
          nextToken
          startedAt
        }
        unavailablies {
          nextToken
          startedAt
        }
        availabilityExceptions {
          nextToken
          startedAt
        }
        bookings {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncTeamMembers = /* GraphQL */ `
  query SyncTeamMembers(
    $filter: ModelTeamMemberFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTeamMembers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        businessID
        code
        username
        givenName
        familyName
        phone
        email
        acceptsBookings
        locationID
        offDays
        offDayExceptions
        seatNo
        queueGap
        entrance
        status
        role
        startDate
        endDate
        photo {
          bucket
          key
        }
        openingHours {
          day
          opensAt
          closesAt
        }
        break {
          day
          opensAt
          closesAt
        }
        estimatedBreakTimes
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        location {
          id
          businessID
          name
          code
          addressID
          phone
          acceptsBookings
          virtualQueue
          online
          maintenance
          opensOnPublicHolidays
          isMobile
          bookingFlow
          mobileWeekOffset
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        services {
          nextToken
          startedAt
        }
        unavailablies {
          nextToken
          startedAt
        }
        availabilityExceptions {
          nextToken
          startedAt
        }
        bookings {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getUnavailablity = /* GraphQL */ `
  query GetUnavailablity($id: ID!) {
    getUnavailablity(id: $id) {
      id
      businessID
      teamMemberID
      teamMember {
        id
        businessID
        code
        username
        givenName
        familyName
        phone
        email
        acceptsBookings
        locationID
        offDays
        offDayExceptions
        seatNo
        queueGap
        entrance
        status
        role
        startDate
        endDate
        photo {
          bucket
          key
        }
        openingHours {
          day
          opensAt
          closesAt
        }
        break {
          day
          opensAt
          closesAt
        }
        estimatedBreakTimes
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        location {
          id
          businessID
          name
          code
          addressID
          phone
          acceptsBookings
          virtualQueue
          online
          maintenance
          opensOnPublicHolidays
          isMobile
          bookingFlow
          mobileWeekOffset
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        services {
          nextToken
          startedAt
        }
        unavailablies {
          nextToken
          startedAt
        }
        availabilityExceptions {
          nextToken
          startedAt
        }
        bookings {
          nextToken
          startedAt
        }
      }
      date
      from
      to
      reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listUnavailablitys = /* GraphQL */ `
  query ListUnavailablitys(
    $filter: ModelUnavailablityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUnavailablitys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        businessID
        teamMemberID
        teamMember {
          id
          businessID
          code
          username
          givenName
          familyName
          phone
          email
          acceptsBookings
          locationID
          offDays
          offDayExceptions
          seatNo
          queueGap
          entrance
          status
          role
          startDate
          endDate
          estimatedBreakTimes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        date
        from
        to
        reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const unavailablityByTeamMemberByDate = /* GraphQL */ `
  query UnavailablityByTeamMemberByDate(
    $teamMemberID: ID
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUnavailablityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    unavailablityByTeamMemberByDate(
      teamMemberID: $teamMemberID
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        businessID
        teamMemberID
        teamMember {
          id
          businessID
          code
          username
          givenName
          familyName
          phone
          email
          acceptsBookings
          locationID
          offDays
          offDayExceptions
          seatNo
          queueGap
          entrance
          status
          role
          startDate
          endDate
          estimatedBreakTimes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        date
        from
        to
        reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const unavailablityByBusinessByDate = /* GraphQL */ `
  query UnavailablityByBusinessByDate(
    $businessID: ID
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUnavailablityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    unavailablityByBusinessByDate(
      businessID: $businessID
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        businessID
        teamMemberID
        teamMember {
          id
          businessID
          code
          username
          givenName
          familyName
          phone
          email
          acceptsBookings
          locationID
          offDays
          offDayExceptions
          seatNo
          queueGap
          entrance
          status
          role
          startDate
          endDate
          estimatedBreakTimes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        date
        from
        to
        reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncUnavailablities = /* GraphQL */ `
  query SyncUnavailablities(
    $filter: ModelUnavailablityFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUnavailablities(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        businessID
        teamMemberID
        teamMember {
          id
          businessID
          code
          username
          givenName
          familyName
          phone
          email
          acceptsBookings
          locationID
          offDays
          offDayExceptions
          seatNo
          queueGap
          entrance
          status
          role
          startDate
          endDate
          estimatedBreakTimes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        date
        from
        to
        reason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getAvailabilityException = /* GraphQL */ `
  query GetAvailabilityException($id: ID!) {
    getAvailabilityException(id: $id) {
      id
      businessID
      teamMemberID
      teamMember {
        id
        businessID
        code
        username
        givenName
        familyName
        phone
        email
        acceptsBookings
        locationID
        offDays
        offDayExceptions
        seatNo
        queueGap
        entrance
        status
        role
        startDate
        endDate
        photo {
          bucket
          key
        }
        openingHours {
          day
          opensAt
          closesAt
        }
        break {
          day
          opensAt
          closesAt
        }
        estimatedBreakTimes
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        location {
          id
          businessID
          name
          code
          addressID
          phone
          acceptsBookings
          virtualQueue
          online
          maintenance
          opensOnPublicHolidays
          isMobile
          bookingFlow
          mobileWeekOffset
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        services {
          nextToken
          startedAt
        }
        unavailablies {
          nextToken
          startedAt
        }
        availabilityExceptions {
          nextToken
          startedAt
        }
        bookings {
          nextToken
          startedAt
        }
      }
      date
      from
      to
      breakTime
      allowExtraBreak
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listAvailabilityExceptions = /* GraphQL */ `
  query ListAvailabilityExceptions(
    $filter: ModelAvailabilityExceptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAvailabilityExceptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        businessID
        teamMemberID
        teamMember {
          id
          businessID
          code
          username
          givenName
          familyName
          phone
          email
          acceptsBookings
          locationID
          offDays
          offDayExceptions
          seatNo
          queueGap
          entrance
          status
          role
          startDate
          endDate
          estimatedBreakTimes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        date
        from
        to
        breakTime
        allowExtraBreak
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const availabilityExceptionByTeamMemberByDate = /* GraphQL */ `
  query AvailabilityExceptionByTeamMemberByDate(
    $teamMemberID: ID
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAvailabilityExceptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    availabilityExceptionByTeamMemberByDate(
      teamMemberID: $teamMemberID
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        businessID
        teamMemberID
        teamMember {
          id
          businessID
          code
          username
          givenName
          familyName
          phone
          email
          acceptsBookings
          locationID
          offDays
          offDayExceptions
          seatNo
          queueGap
          entrance
          status
          role
          startDate
          endDate
          estimatedBreakTimes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        date
        from
        to
        breakTime
        allowExtraBreak
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const availabilityExceptionByBusinessByDate = /* GraphQL */ `
  query AvailabilityExceptionByBusinessByDate(
    $businessID: ID
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAvailabilityExceptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    availabilityExceptionByBusinessByDate(
      businessID: $businessID
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        businessID
        teamMemberID
        teamMember {
          id
          businessID
          code
          username
          givenName
          familyName
          phone
          email
          acceptsBookings
          locationID
          offDays
          offDayExceptions
          seatNo
          queueGap
          entrance
          status
          role
          startDate
          endDate
          estimatedBreakTimes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        date
        from
        to
        breakTime
        allowExtraBreak
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAvailabilityExceptions = /* GraphQL */ `
  query SyncAvailabilityExceptions(
    $filter: ModelAvailabilityExceptionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAvailabilityExceptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        businessID
        teamMemberID
        teamMember {
          id
          businessID
          code
          username
          givenName
          familyName
          phone
          email
          acceptsBookings
          locationID
          offDays
          offDayExceptions
          seatNo
          queueGap
          entrance
          status
          role
          startDate
          endDate
          estimatedBreakTimes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        date
        from
        to
        breakTime
        allowExtraBreak
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getCustomer = /* GraphQL */ `
  query GetCustomer($id: ID!) {
    getCustomer(id: $id) {
      id
      businessID
      stripeID
      username
      givenName
      familyName
      transactionReference
      cards {
        pan
        expiry
        transactionReference
      }
      dependents {
        givenName
        familyName
      }
      dob
      vip
      phone
      email
      expoToken
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      bookings {
        items {
          id
          businessID
          customerID
          teamMemberID
          locationID
          note
          transactionReference
          paymentMethod
          date
          startTime
          endTime
          dateCancelled
          status
          source
          fee
          additionalFee
          tip
          confirmationReminderSent
          finalReminderSent
          reviewEmailSent
          createdAt
          updatedAt
          stripeID
          version
          services {
            teamMemberService
            service
          }
          products {
            productID
            quantity
          }
          transaction
          isNew
          any
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const listCustomers = /* GraphQL */ `
  query ListCustomers(
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        businessID
        stripeID
        username
        givenName
        familyName
        transactionReference
        cards {
          pan
          expiry
          transactionReference
        }
        dependents {
          givenName
          familyName
        }
        dob
        vip
        phone
        email
        expoToken
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        bookings {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const customerByBusinessByPhone = /* GraphQL */ `
  query CustomerByBusinessByPhone(
    $businessID: ID
    $phone: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    customerByBusinessByPhone(
      businessID: $businessID
      phone: $phone
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        businessID
        stripeID
        username
        givenName
        familyName
        transactionReference
        cards {
          pan
          expiry
          transactionReference
        }
        dependents {
          givenName
          familyName
        }
        dob
        vip
        phone
        email
        expoToken
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        bookings {
          nextToken
          startedAt
          items{
            id
          }
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncCustomers = /* GraphQL */ `
  query SyncCustomers(
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCustomers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        businessID
        stripeID
        username
        givenName
        familyName
        transactionReference
        cards {
          pan
          expiry
          transactionReference
        }
        dependents {
          givenName
          familyName
        }
        dob
        vip
        phone
        email
        expoToken
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        bookings {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getQueueReminder = /* GraphQL */ `
  query GetQueueReminder($id: ID!) {
    getQueueReminder(id: $id) {
      id
      businessID
      locationID
      mobileLocationID
      time
      validUntil
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listQueueReminders = /* GraphQL */ `
  query ListQueueReminders(
    $filter: ModelQueueReminderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQueueReminders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        businessID
        locationID
        mobileLocationID
        time
        validUntil
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const customerByBusinessByTime = /* GraphQL */ `
  query CustomerByBusinessByTime(
    $businessID: ID
    $time: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQueueReminderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    customerByBusinessByTime(
      businessID: $businessID
      time: $time
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        businessID
        locationID
        mobileLocationID
        time
        validUntil
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncQueueReminders = /* GraphQL */ `
  query SyncQueueReminders(
    $filter: ModelQueueReminderFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncQueueReminders(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        businessID
        locationID
        mobileLocationID
        time
        validUntil
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getBooking = /* GraphQL */ `
  query GetBooking($id: ID!) {
    getBooking(id: $id) {
      id
      businessID
      dependent {
        givenName
        familyName
      }
      customerID
      teamMember {
        id
        businessID
        code
        username
        givenName
        familyName
        phone
        email
        acceptsBookings
        locationID
        offDays
        offDayExceptions
        seatNo
        queueGap
        entrance
        status
        role
        startDate
        endDate
        photo {
          bucket
          key
        }
        openingHours {
          day
          opensAt
          closesAt
        }
        break {
          day
          opensAt
          closesAt
        }
        estimatedBreakTimes
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        location {
          id
          businessID
          name
          code
          addressID
          phone
          acceptsBookings
          virtualQueue
          online
          maintenance
          opensOnPublicHolidays
          isMobile
          bookingFlow
          mobileWeekOffset
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        services {
          nextToken
          startedAt
        }
        unavailablies {
          nextToken
          startedAt
        }
        availabilityExceptions {
          nextToken
          startedAt
        }
        bookings {
          nextToken
          startedAt
        }
      }
      teamMemberID
      locationID
      note
      transactionReference
      paymentMethod
      paymentDetails {
        method
        amount
        reference
        provider
      }
      services {
        teamMemberService
        service
      }
      products {
        productID
        quantity
      }
      date
      startTime
      endTime
      dateCancelled
      status
      source
      fee
      additionalFee
      tip
      confirmationReminderSent
      finalReminderSent
      reviewEmailSent
      createdAt
      updatedAt
      stripeID
      version
      transaction
      isNew
      any
      _version
      _deleted
      _lastChangedAt
      customer {
        id
        businessID
        stripeID
        username
        givenName
        familyName
        transactionReference
        cards {
          pan
          expiry
          transactionReference
        }
        dependents {
          givenName
          familyName
        }
        dob
        vip
        phone
        email
        expoToken
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        bookings {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const listBookings = /* GraphQL */ `
  query ListBookings(
    $filter: ModelBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBookings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        businessID
        dependent {
          givenName
          familyName
        }
        customerID
        teamMember {
          id
          businessID
          code
          username
          givenName
          familyName
          phone
          email
          acceptsBookings
          locationID
          offDays
          offDayExceptions
          seatNo
          queueGap
          entrance
          status
          role
          startDate
          endDate
          estimatedBreakTimes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        teamMemberID
        locationID
        note
        transactionReference
        paymentMethod
        paymentDetails {
          method
          amount
          reference
          provider
        }
        services {
          teamMemberService
          service
        }
        products {
          productID
          quantity
        }
        date
        startTime
        endTime
        dateCancelled
        status
        source
        fee
        additionalFee
        tip
        confirmationReminderSent
        finalReminderSent
        reviewEmailSent
        createdAt
        updatedAt
        stripeID
        version
        transaction
        isNew
        any
        _version
        _deleted
        _lastChangedAt
        customer {
          id
          businessID
          stripeID
          username
          givenName
          familyName
          transactionReference
          dob
          vip
          phone
          email
          expoToken
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const bookingByCustomerByDate = /* GraphQL */ `
  query BookingByCustomerByDate(
    $customerID: ID
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bookingByCustomerByDate(
      customerID: $customerID
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        businessID
        dependent {
          givenName
          familyName
        }
        customerID
        teamMember {
          id
          businessID
          code
          username
          givenName
          familyName
          phone
          email
          acceptsBookings
          locationID
          offDays
          offDayExceptions
          seatNo
          queueGap
          entrance
          status
          role
          startDate
          endDate
          estimatedBreakTimes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        teamMemberID
        locationID
        note
        transactionReference
        paymentMethod
        paymentDetails {
          method
          amount
          reference
          provider
        }
        services {
          teamMemberService
          service
        }
        products {
          productID
          quantity
        }
        date
        startTime
        endTime
        dateCancelled
        status
        source
        fee
        additionalFee
        tip
        confirmationReminderSent
        finalReminderSent
        reviewEmailSent
        createdAt
        updatedAt
        stripeID
        version
        transaction
        isNew
        any
        _version
        _deleted
        _lastChangedAt
        customer {
          id
          businessID
          stripeID
          username
          givenName
          familyName
          transactionReference
          dob
          vip
          phone
          email
          expoToken
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const bookingByTeamMemberByDate = /* GraphQL */ `
  query BookingByTeamMemberByDate(
    $teamMemberID: ID
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bookingByTeamMemberByDate(
      teamMemberID: $teamMemberID
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        businessID
        dependent {
          givenName
          familyName
        }
        customerID
        teamMember {
          id
          businessID
          code
          username
          givenName
          familyName
          phone
          email
          acceptsBookings
          locationID
          offDays
          offDayExceptions
          seatNo
          queueGap
          entrance
          status
          role
          startDate
          endDate
          estimatedBreakTimes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        teamMemberID
        locationID
        note
        transactionReference
        paymentMethod
        paymentDetails {
          method
          amount
          reference
          provider
        }
        services {
          teamMemberService
          service
        }
        products {
          productID
          quantity
        }
        date
        startTime
        endTime
        dateCancelled
        status
        source
        fee
        additionalFee
        tip
        confirmationReminderSent
        finalReminderSent
        reviewEmailSent
        createdAt
        updatedAt
        stripeID
        version
        transaction
        isNew
        any
        _version
        _deleted
        _lastChangedAt
        customer {
          id
          businessID
          stripeID
          username
          givenName
          familyName
          transactionReference
          dob
          vip
          phone
          email
          expoToken
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const bookingByLocationByDate = /* GraphQL */ `
  query BookingByLocationByDate(
    $locationID: ID
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bookingByLocationByDate(
      locationID: $locationID
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        businessID
        dependent {
          givenName
          familyName
        }
        customerID
        teamMember {
          id
          businessID
          code
          username
          givenName
          familyName
          phone
          email
          acceptsBookings
          locationID
          offDays
          offDayExceptions
          seatNo
          queueGap
          entrance
          status
          role
          startDate
          endDate
          estimatedBreakTimes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        teamMemberID
        locationID
        note
        transactionReference
        paymentMethod
        paymentDetails {
          method
          amount
          reference
          provider
        }
        services {
          teamMemberService
          service
        }
        products {
          productID
          quantity
        }
        date
        startTime
        endTime
        dateCancelled
        status
        source
        fee
        additionalFee
        tip
        confirmationReminderSent
        finalReminderSent
        reviewEmailSent
        createdAt
        updatedAt
        stripeID
        version
        transaction
        isNew
        any
        _version
        _deleted
        _lastChangedAt
        customer {
          id
          businessID
          stripeID
          username
          givenName
          familyName
          transactionReference
          dob
          vip
          phone
          email
          expoToken
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncBookings = /* GraphQL */ `
  query SyncBookings(
    $filter: ModelBookingFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncBookings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        businessID
        dependent {
          givenName
          familyName
        }
        customerID
        teamMember {
          id
          businessID
          code
          username
          givenName
          familyName
          phone
          email
          acceptsBookings
          locationID
          offDays
          offDayExceptions
          seatNo
          queueGap
          entrance
          status
          role
          startDate
          endDate
          estimatedBreakTimes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        teamMemberID
        locationID
        note
        transactionReference
        paymentMethod
        paymentDetails {
          method
          amount
          reference
          provider
        }
        services {
          teamMemberService
          service
        }
        products {
          productID
          quantity
        }
        date
        startTime
        endTime
        dateCancelled
        status
        source
        fee
        additionalFee
        tip
        confirmationReminderSent
        finalReminderSent
        reviewEmailSent
        createdAt
        updatedAt
        stripeID
        version
        transaction
        isNew
        any
        _version
        _deleted
        _lastChangedAt
        customer {
          id
          businessID
          stripeID
          username
          givenName
          familyName
          transactionReference
          dob
          vip
          phone
          email
          expoToken
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getQueue = /* GraphQL */ `
  query GetQueue($id: ID!) {
    getQueue(id: $id) {
      id
      businessID
      locationID
      teamMemberID
      date
      nextAvailable
      closesAt
      breaksTaken
      maxDuration
      block {
        startTime
        endTime
      }
      gaps {
        from
        to
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listQueues = /* GraphQL */ `
  query ListQueues(
    $filter: ModelQueueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQueues(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        businessID
        locationID
        teamMemberID
        date
        nextAvailable
        closesAt
        breaksTaken
        maxDuration
        block {
          startTime
          endTime
        }
        gaps {
          from
          to
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const queueByLocationByDate = /* GraphQL */ `
  query QueueByLocationByDate(
    $locationID: ID
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQueueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queueByLocationByDate(
      locationID: $locationID
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        businessID
        locationID
        teamMemberID
        date
        nextAvailable
        closesAt
        breaksTaken
        maxDuration
        block {
          startTime
          endTime
        }
        gaps {
          from
          to
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncQueues = /* GraphQL */ `
  query SyncQueues(
    $filter: ModelQueueFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncQueues(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        businessID
        locationID
        teamMemberID
        date
        nextAvailable
        closesAt
        breaksTaken
        maxDuration
        block {
          startTime
          endTime
        }
        gaps {
          from
          to
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getPhoto = /* GraphQL */ `
  query GetPhoto($id: ID!) {
    getPhoto(id: $id) {
      id
      businessID
      description
      photo {
        bucket
        key
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      location {
        id
        businessID
        name
        code
        addressID
        phone
        openingHours {
          day
          opensAt
          closesAt
        }
        acceptsBookings
        virtualQueue
        online
        maintenance
        opensOnPublicHolidays
        isMobile
        bookingFlow
        mobileWeekOffset
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        address {
          id
          businessID
          addressLine1
          addressLine2
          addressLine3
          addressLine4
          city
          region
          postCode
          country
          attentionTo
          lat
          lng
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        team {
          nextToken
          startedAt
        }
        photos {
          nextToken
          startedAt
        }
      }
      mobileLocation {
        id
        businessID
        locationID
        name
        addressID
        isRepeating
        comingSoon
        colour
        order
        weeksAtLocation
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        address {
          id
          businessID
          addressLine1
          addressLine2
          addressLine3
          addressLine4
          city
          region
          postCode
          country
          attentionTo
          lat
          lng
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        photos {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const listPhotos = /* GraphQL */ `
  query ListPhotos(
    $filter: ModelPhotoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPhotos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        businessID
        description
        photo {
          bucket
          key
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        location {
          id
          businessID
          name
          code
          addressID
          phone
          acceptsBookings
          virtualQueue
          online
          maintenance
          opensOnPublicHolidays
          isMobile
          bookingFlow
          mobileWeekOffset
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        mobileLocation {
          id
          businessID
          locationID
          name
          addressID
          isRepeating
          comingSoon
          colour
          order
          weeksAtLocation
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncPhotos = /* GraphQL */ `
  query SyncPhotos(
    $filter: ModelPhotoFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPhotos(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        businessID
        description
        photo {
          bucket
          key
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        location {
          id
          businessID
          name
          code
          addressID
          phone
          acceptsBookings
          virtualQueue
          online
          maintenance
          opensOnPublicHolidays
          isMobile
          bookingFlow
          mobileWeekOffset
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        mobileLocation {
          id
          businessID
          locationID
          name
          addressID
          isRepeating
          comingSoon
          colour
          order
          weeksAtLocation
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getChangelog = /* GraphQL */ `
  query GetChangelog($id: ID!) {
    getChangelog(id: $id) {
      id
      entityID
      businessID
      action
      diff
      teamMemberID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listChangelogs = /* GraphQL */ `
  query ListChangelogs(
    $filter: ModelChangelogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChangelogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        entityID
        businessID
        action
        diff
        teamMemberID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const changelogByEntityByDate = /* GraphQL */ `
  query ChangelogByEntityByDate(
    $entityID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelChangelogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    changelogByEntityByDate(
      entityID: $entityID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        entityID
        businessID
        action
        diff
        teamMemberID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncChangelogs = /* GraphQL */ `
  query SyncChangelogs(
    $filter: ModelChangelogFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncChangelogs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        entityID
        businessID
        action
        diff
        teamMemberID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getBookingStats = /* GraphQL */ `
  query GetBookingStats($id: ID!) {
    getBookingStats(id: $id) {
      id
      reportType
      businessID
      date
      value
      amount
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listBookingStatss = /* GraphQL */ `
  query ListBookingStatss(
    $filter: ModelBookingStatsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBookingStatss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        reportType
        businessID
        date
        value
        amount
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const bookingStatsByBusinessByReportTypeByDate = /* GraphQL */ `
  query BookingStatsByBusinessByReportTypeByDate(
    $businessID: ID
    $reportTypeDate: ModelBookingStatsByBusinessByReportTypeByDateCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBookingStatsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bookingStatsByBusinessByReportTypeByDate(
      businessID: $businessID
      reportTypeDate: $reportTypeDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        reportType
        businessID
        date
        value
        amount
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const bookingStatsByBusinessByReportTypeByValueByDate = /* GraphQL */ `
  query BookingStatsByBusinessByReportTypeByValueByDate(
    $businessID: ID
    $reportTypeValueDate: ModelBookingStatsByBusinessByReportTypeByValueByDateCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBookingStatsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bookingStatsByBusinessByReportTypeByValueByDate(
      businessID: $businessID
      reportTypeValueDate: $reportTypeValueDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        reportType
        businessID
        date
        value
        amount
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncBookingStats = /* GraphQL */ `
  query SyncBookingStats(
    $filter: ModelBookingStatsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncBookingStats(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        reportType
        businessID
        date
        value
        amount
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getAd = /* GraphQL */ `
  query GetAd($id: ID!) {
    getAd(id: $id) {
      id
      businessID
      locationID
      mobileLocationIDs
      name
      startDate
      endDate
      status
      image {
        bucket
        key
      }
      link
      duration
      showWhenQueueing
      showWhenBeingServed
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listAds = /* GraphQL */ `
  query ListAds($filter: ModelAdFilterInput, $limit: Int, $nextToken: String) {
    listAds(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        businessID
        locationID
        mobileLocationIDs
        name
        startDate
        endDate
        status
        image {
          bucket
          key
        }
        link
        duration
        showWhenQueueing
        showWhenBeingServed
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const adsByLocationByStatus = /* GraphQL */ `
  query AdsByLocationByStatus(
    $locationID: ID
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAdFilterInput
    $limit: Int
    $nextToken: String
  ) {
    adsByLocationByStatus(
      locationID: $locationID
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        businessID
        locationID
        mobileLocationIDs
        name
        startDate
        endDate
        status
        image {
          bucket
          key
        }
        link
        duration
        showWhenQueueing
        showWhenBeingServed
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAds = /* GraphQL */ `
  query SyncAds(
    $filter: ModelAdFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAds(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        businessID
        locationID
        mobileLocationIDs
        name
        startDate
        endDate
        status
        image {
          bucket
          key
        }
        link
        duration
        showWhenQueueing
        showWhenBeingServed
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getPaymentMachine = /* GraphQL */ `
  query GetPaymentMachine($id: ID!) {
    getPaymentMachine(id: $id) {
      id
      businessID
      locationID
      name
      type
      settings
      status
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listPaymentMachines = /* GraphQL */ `
  query ListPaymentMachines(
    $filter: ModelPaymentMachineFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPaymentMachines(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        businessID
        locationID
        name
        type
        settings
        status
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const paymentMachinesByLocationByStatus = /* GraphQL */ `
  query PaymentMachinesByLocationByStatus(
    $locationID: ID
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPaymentMachineFilterInput
    $limit: Int
    $nextToken: String
  ) {
    paymentMachinesByLocationByStatus(
      locationID: $locationID
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        businessID
        locationID
        name
        type
        settings
        status
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncPaymentMachines = /* GraphQL */ `
  query SyncPaymentMachines(
    $filter: ModelPaymentMachineFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPaymentMachines(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        businessID
        locationID
        name
        type
        settings
        status
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;

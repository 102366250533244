import * as React from 'react';
import * as PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'clsx';
import { HOUR_MINUTE_OPTIONS } from '@devexpress/dx-scheduler-core';
import { SMALL_LAYOUT_MEDIA_QUERY } from '../constants';
import { addCommaAndSpaceToString } from '../utils';
import cleanDeep from 'clean-deep';
import {connect} from 'react-redux';
import Color from 'color';

import {Actions as ACTIONS} from 'store/booking/action';

const styles = ({ palette, spacing }) => ({
  title: {
    fontWeight: 'bold',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  textContainer: {
    lineHeight: 1,
    whiteSpace: 'pre-wrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  middleContainer: {
    lineHeight: '0.9!important',
  },
  time: {
    display: 'inline-block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  content: {
    color: palette.common.white,
    padding: spacing(0.5, 1),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    display: 'flex',
    [`${SMALL_LAYOUT_MEDIA_QUERY}`]: {
      paddingLeft: spacing(0.5),
      paddingRight: spacing(0.5),
    },
  },
  shortContent: {
    padding: spacing(0.25, 1),
    lineHeight: 1.1,
    [`${SMALL_LAYOUT_MEDIA_QUERY}`]: {
      paddingLeft: spacing(0.5),
      paddingRight: spacing(0.5),
    },
  },
  shortContainer: {
    display: 'flex',
  },
  shortTime: {
    textOverflow: 'initial',
    flexShrink: 0,
  },
  shortTitle: {
    flexShrink: 3,
  },
  container: {
    width: '100%',
  },
  recurringContainer: {
    width: `calc(100% - ${spacing(2)}px)`,
  },
  imageContainer: {
    width: spacing(2),
    height: spacing(2),
  },
  image: {
    width: '100%',
    height: '100%',
  },
});

const VerticalAppointmentBase = ({
  classes,
  data,
  children,
  className,
  formatDate,
  recurringIconComponent: RecurringIcon,
  durationType,
  updateBooking,
  permissions,
  ...restProps
}) => {
  const repeat = !!data.rRule;
  const isShortHeight = durationType === 'short';
  const isMiddleHeight = durationType === 'middle';

  const onConfirmClick = (e) => {
    e.stopPropagation();

    const booking = data.booking
    /*locationBookings
    const booking = appointments.find(item => item.id = bookingId)
    // console.log('item toSubmitData booking',booking,appointments)

    const bookingChanges = changed[bookingId]*/

    const item = {
      id: booking.id,
      _version: booking && booking._version,
    }

    if (!data.status || data.status == 'Booked'){
      item.status = 'Confirmed'
    } else if (data.status == 'Confirmed'){
      item.status = 'Present'
    } else if (data.status == 'Present'){
      item.status = 'Served'
    }

    updateBooking(cleanDeep(item))

  }

  let bookingStatusIcon = <path d="M12 22a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"/>

  switch (data.booking.status){
   case "Confirmed":
      bookingStatusIcon = <path d="M12 22a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-2.3-8.7l1.3 1.29 3.3-3.3a1 1 0 0 1 1.4 1.42l-4 4a1 1 0 0 1-1.4 0l-2-2a1 1 0 0 1 1.4-1.42z"/>
      break;
  }

  const bookingStatus = data.status == "Booked" ? "Not Confirmed" : data.status
  const bookingSource = data.booking.source || "Staff"

  let bookingSourceIcon = <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                      <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5" />
                                    </svg>


  switch (bookingSource){
   case "Website":
      bookingSourceIcon = <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M9 17.25v1.007a3 3 0 0 1-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0 1 15 18.257V17.25m6-12V15a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 15V5.25m18 0A2.25 2.25 0 0 0 18.75 3H5.25A2.25 2.25 0 0 0 3 5.25m18 0V12a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 12V5.25" />
                          </svg>

      break;
   case "App":
      bookingSourceIcon = <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 1.5H8.25A2.25 2.25 0 0 0 6 3.75v16.5a2.25 2.25 0 0 0 2.25 2.25h7.5A2.25 2.25 0 0 0 18 20.25V3.75a2.25 2.25 0 0 0-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3" />
                          </svg>

      break;
   case "TeamMember":
      bookingSourceIcon = <svg class="heroicon-ui" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m7.848 8.25 1.536.887M7.848 8.25a3 3 0 1 1-5.196-3 3 3 0 0 1 5.196 3Zm1.536.887a2.165 2.165 0 0 1 1.083 1.839c.005.351.054.695.14 1.024M9.384 9.137l2.077 1.199M7.848 15.75l1.536-.887m-1.536.887a3 3 0 1 1-5.196 3 3 3 0 0 1 5.196-3Zm1.536-.887a2.165 2.165 0 0 0 1.083-1.838c.005-.352.054-.695.14-1.025m-1.223 2.863 2.077-1.199m0-3.328a4.323 4.323 0 0 1 2.068-1.379l5.325-1.628a4.5 4.5 0 0 1 2.48-.044l.803.215-7.794 4.5m-2.882-1.664A4.33 4.33 0 0 0 10.607 12m3.736 0 7.794 4.5-.802.215a4.5 4.5 0 0 1-2.48-.043l-5.326-1.629a4.324 4.324 0 0 1-2.068-1.379M14.343 12l-2.882 1.664" />
                          </svg>

      break;
  }

  const textColor = ["Present","Served","FinishedEarly","Paid"].includes(data.status) || new Color(data.backgroundColor).isDark() ? "white" : "black";
  const inverseTextColour = textColor == "white" ? "black" : "white";

  return (
    <div
      className={classNames({
        [classes.content]: true,
        [classes.shortContent]: isShortHeight || isMiddleHeight,
      }, className)}
      {...restProps}
      style={{
        position:"relative",
        // backgroundColor: data.backgroundColor,
        height: "100%"
      }}
    >
      {children || (
        <React.Fragment>
          <div className={classNames({
            [classes.container]: !repeat,
            [classes.recurringContainer]: repeat,
          })}
          >
            {isShortHeight && false ? (
              <div className={classes.shortContainer}>
                <div className={classNames(classes.title, classes.shortTitle)}>
                  {addCommaAndSpaceToString(data.title)}
                </div>
                <div className={classNames(classes.time, classes.shortTime)}>
                  {formatDate(data.startDate, HOUR_MINUTE_OPTIONS)}
                </div>
              </div>
            ) : (
              <React.Fragment>
                { bookingSource &&
                  <div style={{position: "absolute", top: "5px", right: "5px", color: textColor, fontSize: 14, cursor:"pointer"}}>
                    {bookingSourceIcon}
                    {data.booking.isNew == true &&
                      <span x="0" y="15" fill="currentColor" style={{fontSize: 10, fontWeight: "bold"}}>NEW</span>
                    }
                    
                  </div>
                }
                { (data.booking.any) &&
                  <div style={{lineHeight:1,position: "absolute", bottom: "5px", left: "5px", borderRadius: "50%", width: "15px", height: "15px", backgroundColor: textColor, color:inverseTextColour, fontSize: 14, textAlign: "center"}}>
                    A
                  </div>
                }
                { permissions == "Admin" && data.booking.paymentMethod &&
                  <div style={{lineHeight:1,position: "absolute", bottom: "5px", left: "5px", color: textColor, fontSize: 14, cursor:"pointer"}}>
                    {data.booking.tip && data.booking.tip > 0 ? <p>TIP €{data.booking.tip}</p> : ``}
                    {data.booking.paymentMethod.toUpperCase()}
                    {/*<svg onClick={onConfirmClick} class="h-6 w-6 text-white" fill="currentColor" viewBox="0 0 24 24" title={ data.status == "Confirmed" ? "unconfirm booking" : "Confirm Booking"}>
                      {bookingStatusIcon}
                    </svg>*/}
                  </div>
                }
                <div onClick={onConfirmClick}  style={{position: "absolute", bottom: "5px", right: "5px", color: textColor, fontSize: 14, cursor:"pointer"}} title={bookingStatus}>
                  {bookingStatus.getInitials()}
                  {/*<svg onClick={onConfirmClick} class="h-6 w-6 text-white" fill="currentColor" viewBox="0 0 24 24" title={ data.status == "Confirmed" ? "unconfirm booking" : "Confirm Booking"}>
                    {bookingStatusIcon}
                  </svg>*/}
                </div>
                <div className={classes.title} style={{fontSize:"16px",color: textColor,whiteSpace:"normal",paddingRight:"10px"}}>
                  {data.customerName}
                </div>
                { permissions !== "OwnBookings" && 
                  <div className={classes.title} style={{fontSize:"16px",color: textColor}}>
                    {data.customerPhone ? `${data.customerPhone}` : ""}
                  </div>
                }
                <div className={classes.title} style={{fontSize:"13px",color: textColor}}>
                  {data.primaryService && data.primaryService.name}
                </div>
                <div className={classes.title} style={{fontSize:"13px",color: textColor}}>
                  {data.addonServices}
                </div>
                { data.booking.note &&
                  <div className={classes.title} style={{fontSize:"13px",color: textColor}}>
                    Contains Note
                  </div>
                }
                {/*<div
                  className={classNames({
                    [classes.textContainer]: true,
                    [classes.middleContainer]: isMiddleHeight,
                  })}
                >
                  <div className={classes.time}>
                    {formatDate(data.startDate, HOUR_MINUTE_OPTIONS)}
                  </div>
                  <div className={classes.time}>
                    &nbsp;
                    -
                    &nbsp;
                  </div>
                  <div className={classes.time}>
                    {formatDate(data.endDate, HOUR_MINUTE_OPTIONS)}
                  </div>
                </div>*/}
              </React.Fragment>
            )}
          </div>
          {repeat ? (
            <div className={classes.imageContainer}>
              <RecurringIcon className={classes.image} />
            </div>
          ) : undefined}
        </React.Fragment>
      )}
    </div>
  );
};

VerticalAppointmentBase.propTypes = {
  // oneOfType is a workaround because withStyles returns react object
  recurringIconComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  formatDate: PropTypes.func.isRequired,
  durationType: PropTypes.string.isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
};

VerticalAppointmentBase.defaultProps = {
  children: undefined,
  className: undefined,
};

const VerticalAppointmentWithStyles = withStyles(styles, { name: 'VerticalAppointment' })(VerticalAppointmentBase);

const mapStateToProps = (state) => {
    return {
      business: state.business.item,
      permissions: state.permissions,
  }
}

const mapDispatchToProps = dispatch => ({
  updateBooking: (item) => dispatch(ACTIONS.updateItem(item)),
});

export const VerticalAppointment = connect(
  mapStateToProps,
  mapDispatchToProps
)(VerticalAppointmentWithStyles)


import React, { useState, useEffect } from "react";
import { useForm } from 'react-hook-form'
import { useParams, useHistory} from "react-router";

import {connect} from 'react-redux';

import {Actions as ACTIONS} from 'store/teamMember/action';
import {Actions as LOCATIONACTIONS} from 'store/location/action';
import {Actions as DELETEACTIONS} from 'store/deleteModal/action';
import PageHeading from 'components/PageHeading';
import TeamMemberServices from './TeamMemberServices';
import CalendarDay from './CalendarDay';
import cleanDeep from 'clean-deep'
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import { v4 as uuidv4 } from 'uuid';
import { format, parse, setYear, addYears, addDays, setDate, setMonth } from 'date-fns'
import Calendar from 'react-calendar-multiday'

import "react-datepicker/dist/react-datepicker.css";
import "styles/datepicker.css";
import AvailabilityExceptionModal from "./AvailabilityExceptionModal";


const ExampleCustomInput = ({ value, onClick }) => (
	<button onClick={onClick} type="button" class="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150">
		{value} {/*{currentDate && format(new Date(currentDate),'dd/MM/yyyy')}*/}
	</button>
);

const TeamMember = ({
		teamMember,
		updating,
		createTeamMember,
		deleteTeamMember,
		deleteModal,
		updateTeamMember,
		resetPassword,
		setPassword,
		getTeamMember,
		clearTeamMember,
		locations,
		listLocations,
		handleUpload,
		createUnavailability,
		getUnavailability,
		getAvailabilityExceptions,
		deleteUnavailability,
		unavailabilities,
		leaveAvailable
	}) => {

  	let history = useHistory();
	let { id } = useParams();

	// initialId is used for when we are clearing as it causes an issue.
  	const [initialId, setInitialId] = useState(teamMember && teamMember.id)
  	const [newPassword, setNewPassword] = useState(null)
  	const [newLeaveDate, setNewLeaveDate] = useState(new Date())

	const { register, setValue, handleSubmit, watch, errors, reset } = useForm()

	const handleUploadChange = async (e, name) => {
        const file = e.target.files[0];

        if (!file) {
            return;
        }

        const key = await handleUpload(file)
    }

	useEffect(() => {
		console.log('id',locations)
		if (!(locations)){
			console.log('getBusiness')
			listLocations(1000,null)
		}
	},[]);

  	const locationID = watch('locationID')
  	const workingHours = watch('workingHours')
  	const estimatedBreakTimes = watch('estimatedBreakTimes')
  	const workingHoursWithBlank = (workingHours && [...workingHours,{}]) || [{}]
  	const estimatedBreakTimesWithBlank = (estimatedBreakTimes && [...estimatedBreakTimes,""]) || [""]
  	console.log("workingHoursValue",workingHours)

  	const startDate = watch('startDate')
  	const endDate = watch('endDate')

	register({ name: 'estimatedBreakTimes', type: 'custom'}, { required: false })
	register({ name: 'workingHours', type: 'custom'}, { required: false })
	register({ name: 'startDate', type: 'custom'}, { required: false })
	register({ name: 'endDate', type: 'custom'}, { required: false })

	const onSubmit = data => {
		console.log(data)


		/*offDayExceptions: [AWSDate] #OffDays that will be worked anyway
		startDate: AWSDate
		endDate: AWSDate
		photo: S3Object*/

		const openingHours = data.workingHours && data.workingHours
			.filter(row => row.day && row.opensAt && row.closesAt)
			.map(row => ({
				day: row.day,
				opensAt: format(row.opensAt,"HH:mm"),
				closesAt: format(row.closesAt,"HH:mm")
			}))

		const estimatedBreakTimes = data.estimatedBreakTimes && data.estimatedBreakTimes
			.map(breakTime => format(breakTime,"HH:mm"))

		const item = {
			id: teamMember && teamMember.id || uuidv4(),
			_version: teamMember && teamMember._version,
			username: teamMember && teamMember.username,
			givenName: data.givenName,
			familyName: data.familyName,
			startDate: data.startDate,
			endDate: data.endDate,
			phone: data.phone,
			email: data.email,
			queueGap: data.queueGap,
			acceptsBookings: data.acceptsBookings,
			locationID: data.locationID,
			offDays: data.offDays,
			seatNo: data.seatNo,
			entrance: data.entrance,
			status: data.status,
			role: data.role,
			openingHours: openingHours,
			estimatedBreakTimes: estimatedBreakTimes,
		}
		console.log('item',item)

		if (teamMember && teamMember.id){
			const cleanedItem = cleanDeep(item);
			if (!cleanedItem.familyName && teamMember.familyName){
				cleanedItem.familyName = null
			}
			if (!cleanedItem.startDate && teamMember.startDate){
				cleanedItem.startDate = null
			}
			if (!cleanedItem.endDate && teamMember.endDate){
				cleanedItem.endDate = null
			}
			if (!cleanedItem.estimatedBreakTimes && teamMember.estimatedBreakTimes){
				cleanedItem.estimatedBreakTimes = []
			}
			updateTeamMember(cleanedItem)
		} else {
			delete(item._version);
			console.log('updatedItem',item)
			createTeamMember(cleanDeep(item))
		}
	}

	const isAddon = watch('isAddon') || watch('isAddon') == null && teamMember && teamMember.isAddon;

	useEffect(() => {
		if (!(id !== 'new' && teamMember && teamMember.id && teamMember.id == id)){
			getTeamMember(id)
			getUnavailability(id)
			getAvailabilityExceptions(id)
		} else if (id == 'new'){
			clearTeamMember()
		} else {

			const teamMemberData = {...teamMember}

			const workingHours = teamMemberData.openingHours && teamMemberData.openingHours
				.filter(row => row.day && row.opensAt && row.closesAt)
				.map(row => ({
					day: row.day,
					opensAt: parse(row.opensAt,"HH:mm",new Date()),
					closesAt: parse(row.closesAt,"HH:mm",new Date()),
					// opensAt: parse(`${format(new Date(),"yyyy-MM-dd")}T${row.opensAt}:00`),
					// closesAt: parse(`${format(new Date(),"yyyy-MM-dd")}T${row.closesAt}:00`),
				}))

				console.log('WorkingHoursInitial',workingHours)

			teamMemberData.workingHours = workingHours;

			const estimatedBreakTimes = teamMemberData.estimatedBreakTimes && teamMemberData.estimatedBreakTimes
				.map(breakTime => parse(breakTime,"HH:mm",new Date()))

			teamMemberData.estimatedBreakTimes = estimatedBreakTimes;

			reset(teamMemberData); 
		}
	},[teamMember && teamMember.id]);

	useEffect(() => {
		if (id == 'new' && teamMember && teamMember.id && teamMember.id !== initialId && teamMember._version == 1){
  			history.push(`/team/${teamMember.id}`)
		}
	},[teamMember && teamMember._version == 1 && teamMember.id]);

	if ( (!teamMember && id !== 'new' ) || (teamMember && id !== 'new' && id !== teamMember.id ) || (id === 'new' && teamMember)){

		return (
			<div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
				Loading...
			</div>
		)
	}

	const daysOfWeek = [
		{ 
			label: "Monday",
			value: "Monday",
		},
		{ 
			label: "Tuesday",
			value: "Tuesday",
		},
		{ 
			label: "Wednesday",
			value: "Wednesday",
		},
		{ 
			label: "Thursday",
			value: "Thursday",
		},
		{ 
			label: "Friday",
			value: "Friday",
		},
		{ 
			label: "Saturday",
			value: "Saturday",
		},
		{ 
			label: "Sunday",
			value: "Sunday",
		},
		{ 
			label: "Public Holiday",
			value: "PublicHoliday",
		}
	]


	const thisYear = new Date().getFullYear()
	let startLeave = startDate ? setYear(startDate,thisYear) : setDate(setMonth(new Date(),1),1)
	if (startLeave > new Date()){
		startLeave = addYears(startLeave,-1)
	}
	const endLeave = endDate ? endDate : addDays(addYears(startLeave,1),-1)


	const updateAvailability = (ob) => {
	  console.warn(ob)
	}

	/*id: ID!
	businessID: ID!
	// username: String
	acceptsBookings: Boolean
	// location: Location @connection(name: "TeamMemberLocation")
	// locationID: ID
	// services: [TeamMemberService] @connection(name: "TeamMemberServiceServiceTeamMember")
	offDayExceptions: [AWSDate] #OffDays that will be worked anyway
	startDate: AWSDate
	endDate: AWSDate
	photo: S3Object
	createdAt: AWSDateTime
	updatedAt: AWSDateTime*/
	return (

		<React.Fragment>
			<PageHeading item={teamMember} isNew={id === 'new'} label={teamMember && teamMember.givenName && `${teamMember.givenName} ${teamMember.familyName ? teamMember.familyName : ""}`}/>
			<AvailabilityExceptionModal />
			<div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
				<div className="md:grid md:grid-cols-3 md:gap-6">
					<div className="md:col-span-1">
					  <div className="px-4 sm:px-0">
						<h3 className="text-lg font-medium leading-6 text-gray-900">TeamMember Details</h3>
						<p className="mt-1 text-sm leading-5 text-gray-500">
						  This information will be displayed publicly.
						</p>


						{/*unavailablityByTeamMemberByDate*/}
						{/* Unavailability Here */}

						<div>
							{ teamMember && <Calendar
					            isMultiple={true}
					            reset={false}
					            DayComponent={<CalendarDay/>}
					            onChange={updateAvailability}/>
					        } 
						</div>
				        

						<h3 className="mt-15 mb-6 text-lg font-medium leading-6 text-gray-900">Leave</h3>
						
						<div className="px-4 py-3 bg-gray-50 text-right sm:px-6">

							<div className="grid grid-cols-6 gap-6">
								<div class="col-span-6 sm:col-span-3">
									<DatePicker
			                          dateFormat="dd/MM/yyyy" 
			                          selected={newLeaveDate}
			                          onChange={(selectedDate) => {
						                setNewLeaveDate(selectedDate);
			                          }}
			                          customInput={<ExampleCustomInput />}
				                      minDate={new Date()}
			                          className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
			                          popperPlacement="bottom-end"
			                          popperModifiers={{
			                            offset: {
			                              enabled: true,
			                              //offset: "77px, 0px"
			                            }
			                          }}
			                        />
			                    </div>
								<div class="col-span-6 sm:col-span-3">
									<span className="inline-flex rounded-md shadow-sm">
									  	<button 
									  		onClick={
									  			() => createUnavailability(format(newLeaveDate,"yyyy-MM-dd"))
									  		} 
									  		type="submit" 
									  		className="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
									  	>
											Add Leave 
									  	</button>
									</span>
								</div>
							</div>
						</div>

						<h3 className="mt-6 text-lg font-medium leading-6 text-gray-900">Leave Booked</h3>
						<div className="px-4 text-right">
					  		Leave Remaining: {unavailabilities ? leaveAvailable - unavailabilities.filter(
					  			item => {
					  				const itemDate = new Date(item.date)
					  				return (
					  					item.reason == "Leave" && 
					  					!(teamMember.offDays && teamMember.offDays.includes(format(itemDate,'EEEE'))) && 
					  					startLeave <= itemDate && itemDate <= endLeave
					  				)
					  			}
					  				//setYear(startDate,new Date().getFullYear()).getTime() > new Date(item.date).getTime()
					  			).length : leaveAvailable}
					  	</div>


						<ul>
							{unavailabilities && unavailabilities.filter(l => l.reason == "Leave").map( item => (
								<li>
									{format(new Date(item.date),"dd MMM yyyy")}
									<span 
										onClick={() => deleteUnavailability(item.id)}
										class="inline-block h-6 w-6 rounded-full overflow-hidden bg-gray-100 cursor-pointer" title="Delete Leave">
										<svg class="h-full w-full text-gray-300 hover:text-red-400" fill="currentColor" viewBox="0 0 24 24">
							                <path d="M16.24 14.83a1 1 0 0 1-1.41 1.41L12 13.41l-2.83 2.83a1 1 0 0 1-1.41-1.41L10.59 12 7.76 9.17a1 1 0 0 1 1.41-1.41L12 10.59l2.83-2.83a1 1 0 0 1 1.41 1.41L13.41 12l2.83 2.83z"/>
						                </svg>
						            </span>
								</li>
							))}
						</ul>


						<h3 className="mt-6 text-lg font-medium leading-6 text-gray-900">Sick Booked</h3>
						<div className="px-4 text-right">
					  		Sick Used: {unavailabilities ? unavailabilities.filter(
					  			item => {
					  				const itemDate = new Date(item.date)
					  				return (
					  					item.reason == "Sick" && 
					  					!(teamMember.offDays && teamMember.offDays.includes(format(itemDate,'EEEE'))) && 
					  					startLeave <= itemDate && itemDate <= endLeave
					  				)
					  			}
					  				//setYear(startDate,new Date().getFullYear()).getTime() > new Date(item.date).getTime()
					  			).length : 0}
					  	</div>


						<ul>
							{unavailabilities && unavailabilities.filter(l => l.reason == "Sick").map( item => (
								<li>
									{format(new Date(item.date),"dd MMM yyyy")}
									<span 
										onClick={() => deleteUnavailability(item.id)}
										class="inline-block h-6 w-6 rounded-full overflow-hidden bg-gray-100 cursor-pointer" title="Delete Leave">
										<svg class="h-full w-full text-gray-300 hover:text-red-400" fill="currentColor" viewBox="0 0 24 24">
							                <path d="M16.24 14.83a1 1 0 0 1-1.41 1.41L12 13.41l-2.83 2.83a1 1 0 0 1-1.41-1.41L10.59 12 7.76 9.17a1 1 0 0 1 1.41-1.41L12 10.59l2.83-2.83a1 1 0 0 1 1.41 1.41L13.41 12l2.83 2.83z"/>
						                </svg>
						            </span>
								</li>
							))}
						</ul>

					  </div>
					</div>
					<div className="mt-5 md:mt-0 md:col-span-2">
					  <form onSubmit={handleSubmit(onSubmit)}>
						<div className="shadow sm:rounded-md">
						  <div className="px-4 py-5 bg-white sm:p-6">
							<div className="grid grid-cols-6 gap-6">


								<div class="col-span-6 sm:col-span-3">
									<label for="givenName" class="block text-sm font-medium leading-5 text-gray-700">First name</label>
									<input id="givenName" name="givenName" defaultValue={teamMember && teamMember.givenName} ref={register} class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
								</div>

								<div class="col-span-6 sm:col-span-3">
									<label for="familyName" class="block text-sm font-medium leading-5 text-gray-700">Last name</label>
									<input id="familyName" name="familyName" defaultValue={teamMember && teamMember.familyName} ref={register} class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
								</div>

								<div class="col-span-6 sm:col-span-3">
									<label for="email" class="block text-sm font-medium leading-5 text-gray-700">Email</label>
									<input id="email" name="email" defaultValue={teamMember && teamMember.email} ref={register} class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
								</div>

								<div class="col-span-6 sm:col-span-3">
									<label for="phone" class="block text-sm font-medium leading-5 text-gray-700">Phone</label>
									<input id="phone" name="phone" defaultValue={teamMember && teamMember.phone} ref={register} class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
								</div>

								<div className="col-span-6 sm:col-span-3">
									<label htmlFor="locationID" className="block text-sm font-medium leading-5 text-gray-700">Location</label>
									<select id="locationID" name="locationID" ref={register} className="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
										{
											locations && locations.map(location => (
												<option value={location.id}>{location.name} ({location.address && location.address.postCode})</option>
											))
										}
										{/* TODO Add official location list */}
									</select>
								</div>

								<div class="col-span-6 sm:col-span-3">
									<label for="queueGap" class="block text-sm font-medium leading-5 text-gray-700">Minimum Queue Gap (first client)</label>
									<input id="queueGap" name="queueGap" defaultValue={teamMember && teamMember.queueGap || 5} ref={register} class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
								</div>

								<div class="col-span-6 sm:col-span-3">
									<label for="seatNo" class="block text-sm font-medium leading-5 text-gray-700">Seat</label>
									<input id="seatNo" name="seatNo" defaultValue={teamMember && teamMember.seatNo} ref={register} type="number" class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
								</div>

								<div class="col-span-6 sm:col-span-3">
									<label for="entrance" class="block text-sm font-medium leading-5 text-gray-700">Entrance</label>
									<input id="entrance" name="entrance" defaultValue={teamMember && teamMember.entrance} ref={register} class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
								</div>

								<div className="col-span-6 sm:col-span-3">
									<label htmlFor="status" className="block text-sm font-medium leading-5 text-gray-700">Status</label>
									<select id="status" name="status" defaultValue={teamMember && teamMember.status} ref={register} className="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
										<option>Active</option>
										<option>Disabled</option>
										{/* TODO Add official location list */}
									</select>
								</div>

								<div className="col-span-6 sm:col-span-3">
									<label htmlFor="role" className="block text-sm font-medium leading-5 text-gray-700">Permissions</label>
									<select id="role" name="role" defaultValue={teamMember && teamMember.role} ref={register} className="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
										<option value="Admin">Admin</option>
										<option value="AllBookings">Manager (View/Create All Bookings)</option>
										<option value="Host">Host (View/Create Today & Future Bookings)</option>
										<option value="OwnBookings">View Own Bookings</option>
										{/* TODO Add official location list */}
									</select>
								</div>

								<div className="col-span-6 sm:col-span-3">
									<label htmlFor="role" className="block text-sm font-medium leading-5 text-gray-700">Employment Date</label>
									<DatePicker
				                      selected={startDate}
				                      onChange={(selectedTime) => {
				                        setValue(`startDate`,selectedTime)
				                      }}
				                      isClearable
				                      className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
				                      dateFormat="d MMMM yyyy"
				                      />
								</div>

								<div className="col-span-6 sm:col-span-3">
									<label htmlFor="role" className="block text-sm font-medium leading-5 text-gray-700">Termination Date</label>
									<DatePicker
				                      selected={endDate}
				                      onChange={(selectedTime) => {
				                        setValue(`endDate`,selectedTime)
				                      }}
				                      isClearable
				                      className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
				                      dateFormat="d MMMM yyyy"
				                      />
								</div>

								{/* Cannot add photo before we save the team member */}
								{ teamMember && teamMember.id && 
						            <div class="col-span-6 sm:col-span-3">
						              <label for="photo" class="block text-sm leading-5 font-medium text-gray-700">
						                Photo
						              </label>

						              <div class="mt-2 flex items-center">
						                <span class="inline-block h-12 w-12 rounded-full overflow-hidden bg-gray-100">
						                	{
						                		teamMember && teamMember.photo ? 
						                			<img src={`https://${teamMember.photo.bucket}.s3-eu-west-1.amazonaws.com/public/${teamMember.photo.key}`}/>
						                			:
								                  <svg class="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
								                    <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
								                  </svg>
						                	}
						                </span>
						                <span class="ml-5 rounded-md shadow-sm">
						                  	<label htmlFor="upload-button">
								                <div type="button" class="py-2 px-3 border border-gray-300 rounded-md text-sm leading-4 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out inline-block">
								                    Change
								                </div>
								            </label>

											<input
										        type="file"
										        name="image"
										        id="upload-button"
										        style={{ display: "none" }}
										        onChange={handleUploadChange}
										      />
						                </span>
						              </div>
						            </div>
						        }
								
								<div className="col-span-6 sm:col-span-3">
									<label htmlFor="offDays" className="block text-sm font-medium leading-5 text-gray-700">Off Days</label>
									<select multiple={true} id="offDays" defaultValue={teamMember && teamMember.offDays} name="offDays" ref={register} className="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
										<option>Monday</option>
										<option>Tuesday</option>
										<option>Wednesday</option>
										<option>Thursday</option>
										<option>Friday</option>
										<option>Saturday</option>
										<option>Sunday</option>
										{/* TODO Add official location list */}
									</select>
								</div>

					            <div class="col-span-6 ">
					              	<label for="working-hours" class="block text-sm leading-5 font-medium text-gray-700">
					                	Working Hours
					              	</label>
					              	{workingHoursWithBlank.map((row,index) => {

													{/*isOptionSelected={(option) => true}*/}
					              		const selectedValue = daysOfWeek && daysOfWeek
					              			.filter(
					              				item => (row.day && row.day.includes(item.value))
					              			)
					              		return (
					              		<div key={index} className="grid grid-cols-6 gap-6 mt-2">
											<div class="col-span-4">
												<Select 
													options={daysOfWeek}
													value={selectedValue}
													isMulti
													onChange={(selected) => {
														console.log('weekday',selected);

														const weekdaysToSave = selected && selected.map(item => item.value)

														const newWorkingHours = workingHours ? [...workingHours] : []
								                      	if (newWorkingHours[index]){ 
								                      		newWorkingHours[index].day = weekdaysToSave
								                      	} else {
								                      		newWorkingHours[index] = {
								                      			day: weekdaysToSave
								                      		}
								                      	}
								                        setValue(`workingHours`,newWorkingHours)

							                        }}
													/>
											</div>
											<div class="col-span-1">
													<DatePicker

								                      selected={row.opensAt}
								                      onChange={(selectedTime) => {
								                      	const newWorkingHours = workingHours ? [...workingHours] : []
								                      	if (newWorkingHours[index]){ 
								                      		newWorkingHours[index].opensAt = selectedTime
								                      	} else {
								                      		newWorkingHours[index] = {
								                      			opensAt: selectedTime
								                      		}
								                      	}
								                        setValue(`workingHours`,newWorkingHours)
								                      }}
								                      className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
								                      showTimeSelect
								                      showTimeSelectOnly
								                      timeIntervals={15}
								                      timeCaption="Time"
								                      dateFormat="h:mm aa"
								                      />
											</div>
											<div class="col-span-1">
												
													<DatePicker

								                      selected={row.closesAt}
								                      onChange={(selectedTime) => {
								                      	const newWorkingHours = workingHours ? [...workingHours] : []
								                      	if (newWorkingHours[index]){ 
								                      		newWorkingHours[index].closesAt = selectedTime
								                      	} else {
								                      		newWorkingHours[index] = {
								                      			closesAt: selectedTime
								                      		}
								                      	}
								                        setValue(`workingHours`,newWorkingHours)
								                      }}

								                      className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
								                      showTimeSelect
								                      showTimeSelectOnly
								                      timeIntervals={15}
								                      timeCaption="Time"
								                      dateFormat="h:mm aa"
								                      />
											</div>
										</div>)
					              	})}
					            </div>

					            <div class="col-span-6 ">
					              	<label for="working-hours" class="block text-sm leading-5 font-medium text-gray-700">
					                	Break Times (Queue Only)
					              	</label>
					              	{estimatedBreakTimesWithBlank.map((item,index) => {

					              		return (
					              		<div key={index} class="relative">
											<DatePicker

						                      selected={item}
						                      onChange={(selectedTime) => {
						                      	const newEstimatedBreakTimes = estimatedBreakTimes ? [...estimatedBreakTimes] : []
						                      	if (newEstimatedBreakTimes[index]){ 
						                      		newEstimatedBreakTimes[index] = selectedTime
						                      	} else {
						                      		newEstimatedBreakTimes[index] = selectedTime
						                      	}
						                        setValue(`estimatedBreakTimes`,newEstimatedBreakTimes)
						                      }}

						                      className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
						                      showTimeSelect
						                      showTimeSelectOnly
						                      timeIntervals={15}
						                      timeCaption="Time"
						                      dateFormat="h:mm aa"
						                      />

						                      	<div className="absolute top-1 right-1 w-8 h-8 cursor-pointer"
						                      		onClick={() => {
						                      			const newEstimatedBreakTimes = estimatedBreakTimes ? [...estimatedBreakTimes] : []
						                      			newEstimatedBreakTimes.splice(index, 1);
						                      			setValue(`estimatedBreakTimes`,newEstimatedBreakTimes)
						                      		}}
						                      	>
							                      	<svg class="h-full w-full text-gray-300 hover:text-red-400" fill="currentColor" viewBox="0 0 24 24">
										                <path d="M16.24 14.83a1 1 0 0 1-1.41 1.41L12 13.41l-2.83 2.83a1 1 0 0 1-1.41-1.41L10.59 12 7.76 9.17a1 1 0 0 1 1.41-1.41L12 10.59l2.83-2.83a1 1 0 0 1 1.41 1.41L13.41 12l2.83 2.83z"/>
									                </svg>
								               	</div>
										</div>)
					              	})}
					            </div>


								<div className="col-span-6 sm:col-span-4">
								  <div className="flex items-start">
									<div className="absolute flex items-center h-5">
									  <input id="acceptsBookings" name="acceptsBookings" ref={register} defaultChecked={teamMember && teamMember.acceptsBookings} type="checkbox" className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out" />
									</div>
									<div className="pl-7 text-sm leading-5">
									  <label htmlFor="acceptsBookings" className="font-medium text-gray-700">Accepts Bookings</label>
									  <p className="text-gray-500">Does this team member take bookings?</p>
									</div>
								  </div>
								</div>


								{ teamMember && teamMember.username && 
									<>
										<div class="col-span-6 sm:col-span-3 col-start-1">
											<label for="newPassword" class="block text-sm font-medium leading-5 text-gray-700">New Password</label>
											<input id="newPassword" name="newPassword" onChange={(e)=> setNewPassword(e.target.value)} class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
										</div>
										<div className="col-span-6 sm:col-span-3">
											<span className="inline-flex rounded-md shadow-sm float-left mt-6">
											  <button onClick={(e) => {
											  	e.stopPropagation();
											  	e.preventDefault();
											  	setPassword(teamMember.username,newPassword)
											  }} className="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">
												Change Password
											  </button>
											</span>
										</div>
									</>
								}

							</div>
						  </div>
						  <div class="md:grid md:grid-cols-2 md:gap-6 bg-gray-50 ">
							  <div className="px-4 py-3 text-left sm:px-6">
							  	{ teamMember && teamMember.id /*&& futureBookings && futureBookings.length == 0*/ &&
									<span className="inline-flex rounded-md shadow-sm">
									  <button 
									  	onClick={(e) => {
									  		e.preventDefault()
									  		e.stopPropagation()
									  		deleteModal(() => {
									  			deleteTeamMember(teamMember.id,teamMember._version,history)
									  		})
									  	}} 
									  	className="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red active:bg-red-700 transition duration-150 ease-in-out">
										Delete
									  </button>
									</span>
								}
							  </div>
							  <div className="px-4 py-3 text-right sm:px-6">
								{ teamMember && teamMember.username && 
									<span className="inline-flex rounded-md shadow-sm float-left">
									  <button onClick={(e) => {
									  	e.stopPropagation();
									  	e.preventDefault();
									  	resetPassword(teamMember.username)
									  }} className="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">
										Reset Password
									  </button>
									</span> 
								}
								{!updating && 
									<span className="inline-flex rounded-md shadow-sm">
									  <button type="submit" className="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">
										Save
									  </button>
									</span>
								}
								{updating && 
									<span className="inline-flex rounded-md shadow-sm">
									  <div className="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">
										Saving
									  </div>
									</span>
								}
							  </div>
							</div>
						</div>
					  </form>
					</div>
				</div>

			
				<TeamMemberServices locationID={locationID}/>
			</div>
		</React.Fragment>
		)
}


const mapStateToProps = (state) => {
	return {
	  teamMember: state.teamMember.item,
	  updating: state.teamMember.updating,
	  unavailabilities: state.teamMember.unavailabilities,
	  leaveAvailable: state.business.item.vacationLeaveDays,
      locations: state.location.list,
      services: state.service.list,
  }
}

const mapDispatchToProps = dispatch => ({
  resetPassword: (username) => dispatch(ACTIONS.resetPassword(username)),
  setPassword: (username,password) => dispatch(ACTIONS.setPassword(username,password)),
  deleteModal: (fn) => dispatch(DELETEACTIONS.toggleDeleteModal(true,fn)),
  deleteTeamMember: (id,version,history) => dispatch(ACTIONS.deleteItem(id,version,history)),
  createTeamMember: (item) => dispatch(ACTIONS.createItem(item)),
  updateTeamMember: (item) => dispatch(ACTIONS.updateItem(item)),
  getTeamMember: (id) => dispatch(ACTIONS.getItem(id)),
  clearTeamMember: () => dispatch(ACTIONS.clearItem()),
  listLocations: (limit,nextToken) => dispatch(LOCATIONACTIONS.getList(limit,nextToken)),
  listServices: (limit,nextToken) => dispatch(ACTIONS.getList(limit,nextToken)),
  handleUpload: (file,preFileName) => dispatch(ACTIONS.handleUpload(file,preFileName)),
  createUnavailability: (date) => dispatch(ACTIONS.createUnavailability(date)),
  getUnavailability: (id) => dispatch(ACTIONS.getUnavailability(id)),
  deleteUnavailability: (id) => dispatch(ACTIONS.deleteUnavailability(id)),
  getAvailabilityExceptions: (id) => dispatch(ACTIONS.getAvailabilityExceptions(id)),
  // timesheetUpdateSaveStatus: (status) => dispatch(ACTIONS.timesheetUpdateSaveStatus(status))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TeamMember) 
import React from "react";

// types of action
const Types = {
  SCHEDULER_SET_LOCATION: "SCHEDULER_SET_LOCATION",
  SCHEDULER_SET_FILTER: "SCHEDULER_SET_FILTER",
};

const setSchedulerLocation = location => ({
  type: Types.SCHEDULER_SET_LOCATION,
  location
});

const setSchedulerFilter = filter => ({
  type: Types.SCHEDULER_SET_FILTER,
  filter
});


const Actions = {
  setSchedulerLocation,
  setSchedulerFilter,
}

export {Types, Actions}

// exports.Types = Types
// exports.Actions = Actions
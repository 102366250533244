import React, { useState, useEffect } from "react";
import { useForm } from 'react-hook-form'

import {connect} from 'react-redux';

import {Actions as ACTIONS} from 'store/business/action';
import cleanDeep from 'clean-deep'
import Toggle from 'components/formElements/Toggle'


const BusinessDetails = ({business, updateBusiness,handleUpload}) => {

	const { register, handleSubmit, watch, errors, setValue } = useForm({
		defaultValues: {...business}
	})


  	const takingBookings = watch('takingBookings')
	register({ name: 'takingBookings', type: 'custom'}, { })

  	const allowBackDatedBookings = watch('allowBackDatedBookings')
	register({ name: 'allowBackDatedBookings', type: 'custom'}, {  })


	const handleUploadChange = async (e, name) => {
        const file = e.target.files[0];

        if (!file) {
            return;
        }

        const key = await handleUpload(file)
    }

	const onSubmit = data => {
		console.log('handleSubmit',data)

		const item = {
			id: business.id,
			_version: business._version,
			name: data.name,
			logo: business.logo,
			takingBookings,
			allowBackDatedBookings,
			vacationLeaveDays: data.vacationLeaveDays,
			allowBookingsDayAdvance: data.allowBookingsDayAdvance,
			allowBookingsDayAdvanceWeb: data.allowBookingsDayAdvanceWeb,
		}
		console.log(item)

		updateBusiness(cleanDeep(item))
	}

	return (
			<div className="md:grid md:grid-cols-3 md:gap-6">
			    <div className="md:col-span-1">
			      <div className="px-4 sm:px-0">
			        <h3 className="text-lg font-medium leading-6 text-gray-900">Basic Business Details</h3>
			        <p className="mt-1 text-sm leading-5 text-gray-500">
			          This information will be displayed publicly.
			        </p>
			      </div>
			    </div>
			    <div className="mt-5 md:mt-0 md:col-span-2">
			      <form onSubmit={handleSubmit(onSubmit)}>
			        <div className="shadow sm:rounded-md sm:overflow-hidden">
			          <div className="px-4 py-5 bg-white sm:p-6">
			            <div className="grid grid-cols-6 gap-6">

							<div className="col-span-6 sm:col-span-4">
								<label htmlFor="name" className="block text-sm font-medium leading-5 text-gray-700">Business Name</label>
								<input id="name" name="name" defaultValue={business.name} ref={register} className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
							</div>

							<div className="col-span-6 sm:col-span-4">
								<label htmlFor="email" className="block text-sm font-medium leading-5 text-gray-700">Email address</label>
								<input id="email" name="email" ref={register} className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
							</div>

							{/* Cannot add photo before we save the team member */}
							{ business && business.id && 
					            <div class="col-span-6 sm:col-span-3">
					              <label for="photo" class="block text-sm leading-5 font-medium text-gray-700">
					                Logo
					              </label>

					              <div class="mt-2 flex items-center">
					                <span class="inline-block h-12 w-12 rounded-full overflow-hidden bg-gray-100">
					                	{
					                		business && business.logo ? 
					                			<img src={`https://${business.logo.bucket}.s3-eu-west-1.amazonaws.com/public/${business.logo.key}`}/>
					                			:
							                  <svg class="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
							                    <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
							                  </svg>
					                	}
					                </span>
					                <span class="ml-5 rounded-md shadow-sm">
					                  	<label htmlFor="upload-button">
							                <div type="button" class="py-2 px-3 border border-gray-300 rounded-md text-sm leading-4 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out inline-block">
							                    Change
							                </div>
							            </label>

										<input
									        type="file"
									        name="image"
									        id="upload-button"
									        style={{ display: "none" }}
									        onChange={handleUploadChange}
									      />
					                </span>
					              </div>
					            </div>
					        }

				              <div className="col-span-6 sm:col-span-4">
				                <label htmlFor="website" className="block text-sm font-medium leading-5 text-gray-700">
				                  Website
				                </label>
				                <div className="mt-1 flex rounded-md shadow-sm">
				                  <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
				                    https://
				                  </span>
				                  <input id="website" name="website" ref={register} className="form-input flex-1 block w-full rounded-none rounded-r-md transition duration-150 ease-in-out sm:text-sm sm:leading-5" placeholder="www.example.com" />
				                </div>
				              </div>


							<div className="col-span-6 sm:col-span-4">
								<label htmlFor="allowBookingsDayAdvance" className="block text-sm font-medium leading-5 text-gray-700">Days Staff can book in Advance</label>
								<input id="allowBookingsDayAdvance" name="allowBookingsDayAdvance" defaultValue={business.allowBookingsDayAdvance} type='number' ref={register} className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
							</div>

							<div className="col-span-6 sm:col-span-4">
								<label htmlFor="allowBookingsDayAdvanceWeb" className="block text-sm font-medium leading-5 text-gray-700">Days Clients can book in Advance (via website/app)</label>
								<input id="allowBookingsDayAdvanceWeb" name="allowBookingsDayAdvanceWeb" defaultValue={business.allowBookingsDayAdvanceWeb} type='number' ref={register} className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
							</div>

							<div className="col-span-6 sm:col-span-4">
								<label htmlFor="vacationLeaveDays" className="block text-sm font-medium leading-5 text-gray-700">No of Vacation Days per year</label>
								<input id="vacationLeaveDays" name="vacationLeaveDays" defaultValue={business.vacationLeaveDays} type='number' ref={register} className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
							</div>

				            {/*<div className="mt-6">
				              <label htmlFor="about" className="block text-sm leading-5 font-medium text-gray-700">
				                About
				              </label>
				              <div className="rounded-md shadow-sm">
				                <textarea id="about" rows="3" className="form-textarea mt-1 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5" placeholder="you@example.com"></textarea>
				              </div>
				              <p className="mt-2 text-sm text-gray-500">
				                Brief description for your profile. URLs are hyperlinked.
				              </p>
				            </div>*/}

							<div className="col-span-3 col-start-1">
								<label className="block text-sm font-medium leading-5 text-gray-700">Allow Back-Dated Bookings</label>
							</div>

							<div className="col-span-1">
								<Toggle onClick={() => {setValue('allowBackDatedBookings',!allowBackDatedBookings);}} active={allowBackDatedBookings} useActive={true}/>
							</div>
							
							<div className="col-span-3 col-start-1">
								<label className="block text-sm font-medium leading-5 text-gray-700">Enable Website & App Bookings</label>
							</div>

							<div className="col-span-1">
								<Toggle onClick={() => {setValue('takingBookings',!takingBookings);}} active={takingBookings} useActive={true}/>
							</div>

							
			          	</div>
			          </div>
			          <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
			            <span className="inline-flex rounded-md shadow-sm">
			              <button type="submit" className="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">
			                Save
			              </button>
			            </span>
			          </div>
			        </div>
			      </form>
			    </div>
			  </div>
		)
}


const mapStateToProps = (state) => {
    return {
      business: state.business.item,
  }
}

const mapDispatchToProps = dispatch => ({
  updateBusiness: (item) => dispatch(ACTIONS.updateItem(item)),
  getBusiness: (id) => dispatch(ACTIONS.getItem(id)),
  handleUpload: (file,preFileName) => dispatch(ACTIONS.handleUpload(file,preFileName)),
  // timesheetUpdateSaveStatus: (status) => dispatch(ACTIONS.timesheetUpdateSaveStatus(status))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BusinessDetails) 
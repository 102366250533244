import React, { useState, useEffect } from "react";
import { useForm } from 'react-hook-form'

import {connect} from 'react-redux';
import {format} from 'date-fns';

import {Actions as ACTIONS} from 'store/deleteModal/action';

const DeleteModal = ({changelog,closeModal,deleteAction,show}) => {

	if (!show){
		return <React.Fragment/>;
	}

	/*x-transition:enter="transform ease-out duration-300 transition" x-transition:enter-start="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2" x-transition:enter-end="translate-y-0 opacity-100 sm:translate-x-0" x-transition:leave="transition ease-in duration-100" x-transition:leave-start="opacity-100" x-transition:leave-end="opacity-0" */
	return (
		<div class="fixed bottom-0 inset-x-0 px-4 pb-6 sm:inset-0 sm:p-0 sm:flex sm:items-center sm:justify-center z-50">
		  {/*<!--
		  		    Background overlay, show/hide based on modal state.
		  
		  		    Entering: "ease-out duration-300"
		  		      From: "opacity-0"
		  		      To: "opacity-100"
		  		    Leaving: "ease-in duration-200"
		  		      From: "opacity-100"
		  		      To: "opacity-0"
		  		  -->*/}
		  <div class="fiLed inset-0 transition-opacity" onClick={closeModal}>
		    <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
		  </div>

		  {/*<!--
		  		    Modal panel, show/hide based on modal state.
		  
		  		    Entering: "ease-out duration-300"
		  		      From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
		  		      To: "opacity-100 translate-y-0 sm:scale-100"
		  		    Leaving: "ease-in duration-200"
		  		      From: "opacity-100 translate-y-0 sm:scale-100"
		  		      To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
		  		  -->*/}
		  <div class="bg-white rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full sm:p-6" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
		    <div>
		      <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
		        <svg class="h-6 w-6 text-green-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
		          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"/>
		        </svg>
		      </div>
		      <div class="mt-3 text-center sm:mt-5">
		        <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
		          Are you sure you want to delete?
		        </h3>
		        <div class="mt-2">
		          	<p class="text-sm leading-5 text-gray-500">
			            Once deleted you cannot recover this item.
			        </p>
		        </div>
		      </div>
		    </div>
		    <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
		      <span class="flex w-full rounded-md shadow-sm sm:col-start-2">
		        <button type="button" class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-red-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red transition ease-in-out duration-150 sm:text-sm sm:leading-5"
		        	onClick={() => {
		        		deleteAction()
		        		closeModal()
		        	}}
		        >
		          Delete Permanently
		        </button>
		      </span>
		      <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:col-start-1">
		        <button type="button" class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
		        	onClick={closeModal}
		        >
		          Cancel
		        </button>
		      </span>
		    </div>
		  </div>
		</div>
	)
}


const mapStateToProps = (state) => {
  return {
  	// customer: state.customer && state.customer.list && state.customer.list.find(({id}) => id == state.booking.item.customerID),
  	show: state.delete?.show,
  	deleteAction: state.delete?.fn,
  }
}

const mapDispatchToProps = dispatch => ({
  closeModal: () => dispatch(ACTIONS.toggleDeleteModal(false)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteModal)
import {Types} from "./action";
// import {NotificationManager} from 'react-notifications';

// import cleanDeep from 'clean-deep';


const defaultState = {
  tenants: []
};

// BUSINESS_GET_ITEM
// BUSINESS_UPDATE_ITEM
// BUSINESS_CREATE_ITEM

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case Types.TENANTS_SET: {

      let newState = {...state};

      newState.businessID = action.tenant;

      return newState;
    }
    case Types.TENANTS_UPDATE: {

      let newState = {...state};

      newState.tenants = action.tenants;
      if (!newState.businessID || !action.tenants.includes(newState.businessID) ) { 
        newState.businessID = action.tenants[0];
        newState.permissions = action.tenants[1];
      }

      return newState;
    }
    default:
      return state;
  }
};

export default reducer;
import React, { useState, useEffect, useRef } from "react";
import { TwitterPicker } from "react-color";

const ColorPicker = ({
  onChange,
  formId, // Id of the selected Form :)
  color, // text Color
  label
}) => {
  const [colorModal, setColorModal] = useState(false);
  const node = useRef();

  useEffect(() => {
    console.log(color, "color");
    if (colorModal) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [colorModal]);

  const boxColor = {
    cursor: "pointer",
    width: "15px",
    height: "15px",
    marginRight: "10px",
    background: `${color ? color : "#fafafa"}`,
    verticalAlign: "baseline",
    position: "relative"
  };
  const popover = {
    position: "absolute",
    zIndex: "1",
    top: "100%",
    left: "-15px",
    marginTop: "15px",
    // transform: "translateX(-50%)",
    // marginLeft: "7px",
    // marginTop: "2px",
    // width: "200px",
    // padding: "20px",
    // background: "white",
    // border: "solid 1px #d2d6dc"
  };
  const cover = {
    position: "fixed",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px"
  };

  const handleClickOutside = e => {
    console.log("clicking anywhere");
    if (node.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    setColorModal(false);
  };
  
  return (
    <React.Fragment>
      
          <div
            className="border"
            ref={node}
            style={boxColor}
            onClick={() => setColorModal(!colorModal)}
          >
            {colorModal &&
              <div style={popover}>
                <TwitterPicker
                  triangle="top-left"
                  color={color ? color : "#fafafa"}
                  onChangeComplete={onChange}
                  colors={[
                    '#ad0b02',
                    '#FFAABB',
                    '#8A00FF',
                    '#0000b0',
                    '#00E9FF',
                    '#00FF00',
                    '#02630c',
                    '#EDFF00',
                    '#ff9100',
                    '#5e3324',
                    '#757575',
                    '#c7974e',
                  ]}
                />
              </div>
            }
          </div>
    </React.Fragment>
  );
};

export default ColorPicker;

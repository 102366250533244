import React, { useState, useEffect } from "react";
import { useForm } from 'react-hook-form'
import _ from 'lodash';

import { connect } from 'react-redux';
import { parse, format } from 'date-fns';
import DatePicker from 'react-datepicker'
import { Actions as ACTIONS } from 'store/booking/action';
import cleanDeep from 'clean-deep';
import { API } from 'aws-amplify';

const BlockModal = ({
    closeModal,
    show,
    calendarDate,
    locationID,
    businessID,
    createBooking,
    teamMemberName,
    openingHours,

}) => {

    const [date, setDate] = useState(new Date())
    const [timeIn, setTimeIn] = useState(new Date())
    const [timeOut, setTimeOut] = useState(new Date())

    useEffect(() => {
        setDate(new Date(calendarDate))
    },[calendarDate]);

    console.log('calendarDate',calendarDate, new Date(calendarDate))
    console.log('{}',{date,timeIn,timeOut})


    if (!show) {
        return <React.Fragment />;
    }

    /*x-transition:enter="transform ease-out duration-300 transition" x-transition:enter-start="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2" x-transition:enter-end="translate-y-0 opacity-100 sm:translate-x-0" x-transition:leave="transition ease-in duration-100" x-transition:leave-start="opacity-100" x-transition:leave-end="opacity-0" */
    return (
        <div class="fixed bottom-0 inset-x-0 px-4 pb-6 sm:inset-0 sm:p-0 sm:flex sm:items-center sm:justify-center z-50">
            {/*<!--
		  		    Background overlay, show/hide based on modal state.
		  
		  		    Entering: "ease-out duration-300"
		  		      From: "opacity-0"
		  		      To: "opacity-100"
		  		    Leaving: "ease-in duration-200"
		  		      From: "opacity-100"
		  		      To: "opacity-0"
		  		  -->*/}
            <div class="fiLed inset-0 transition-opacity" onClick={closeModal}>
                <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>

            {/*<!--
		  		    Modal panel, show/hide based on modal state.
		  
		  		    Entering: "ease-out duration-300"
		  		      From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
		  		      To: "opacity-100 translate-y-0 sm:scale-100"
		  		    Leaving: "ease-in duration-200"
		  		      From: "opacity-100 translate-y-0 sm:scale-100"
		  		      To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
		  		  -->*/}
            <div class="bg-white rounded-lg px-4 pt-5 pb-4 overflow-overlay shadow-xl transform transition-all sm:max-w-lg sm:w-full sm:p-6" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                <div>
                    <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                        <svg class="h-6 w-6 text-green-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                        </svg>
                    </div>
                    <div class="mt-3 text-center sm:mt-5">
                        <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                            Block Time for {teamMemberName}
                        </h3>
                    </div>
                </div>
                <div className="grid grid-cols-6 gap-6 pt-6">
                    <div className="col-span-6 sm:col-span-6">
                        <label htmlFor="role" className="block text-sm font-medium leading-5 text-gray-700">Date</label>
                        <DatePicker
                            className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                            dateFormat="d MMMM yyyy"
                            selected={date}
                            disabled={true}
                            onChange={(e) => setDate(e)}
                        />
                    </div>
                    <div className="col-span-3 sm:col-span-3">
                        <label htmlFor="role" className="block text-sm font-medium leading-5 text-gray-700">From</label>
                        <DatePicker
                            className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                            selected={timeIn}
                            onChange={(e) => {
                                setTimeIn(e)

                                if (timeOut <= e) {
                                    setTimeOut(e)
                                }
                            }}
                        />
                    </div>
                    <div className="col-span-3 sm:col-span-3">
                        <label htmlFor="role" className="block text-sm font-medium leading-5 text-gray-700">To</label>
                        <DatePicker
                            className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                            selected={timeOut}
                            onChange={(e) => {
                                if (timeIn <= e)
                                    setTimeOut(e)

                            }}
                        />
                    </div>
                </div>
                <div class="bg-white pt-12 sm:flex sm:flex-row-reverse justify-between">

                    <button
                        type="button"
                        class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-500 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={() => {
                            
                              const serviceID = "c11c165d-4493-4af1-b6e4-cce0f875263a"
                              const customerID = "722295f6-1200-4f55-8099-16c8ed4e6de0"

                              const teamMemberID = show

                              const item = {
                                businessID,
                                customerID,
                                // createBooking,
                                teamMemberID,
                                locationID,
                                status: "Served", //start ? "Served" : "CheckedIn",
                                // source: "Block",
                                fee: 0,
                                date: format((date),"yyyy-MM-dd"),
                                startTime: format((timeIn),"HH:mm"),
                                endTime: format((timeOut),"HH:mm"),
                                services: [{
                                  service: serviceID,
                                  teamMemberService: null
                                }]
                              }

                              createBooking(cleanDeep(item))

                              if (item.date == format(new Date(),"yyyy-MM-dd")){
                                API.get('booking', `/bookings/teamMembers/${teamMemberID}/eta?force=1`)
                              }
                              closeModal(false)

                        }}
                    >
                        Block Selected Time
                    </button>
                    
                    
                    <button
                        type="button"
                        class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-500 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:w-auto sm:text-sm"
                        onClick={() => {
                            
                              const serviceID = "c11c165d-4493-4af1-b6e4-cce0f875263a"
                              const customerID = "722295f6-1200-4f55-8099-16c8ed4e6de0"

                              const teamMemberID = show

                              const item = {
                                businessID,
                                customerID,
                                // createBooking,
                                teamMemberID,
                                locationID,
                                status: "Served", //start ? "Served" : "CheckedIn",
                                // source: "Block",
                                fee: 0,
                                date: format((date),"yyyy-MM-dd"),
                                startTime: _.minBy(openingHours[format(date,'EEEE')],"opensAt")?.opensAt,
                                endTime: _.maxBy(openingHours[format(date,'EEEE')],"closesAt")?.closesAt,
                                services: [{
                                  service: serviceID,
                                  teamMemberService: null
                                }]
                              }

                              createBooking(cleanDeep(item))
                              if (item.date == format(new Date(),"yyyy-MM-dd")){
                                API.get('booking', `/bookings/teamMembers/${teamMemberID}/eta?force=1`)
                              }
                              closeModal(false)

                        }}
                    >
                        Block All Day
                    </button>
                </div>
            </div>
        </div>
    )
}


const mapStateToProps = (state) => {

    
    const teamMember = state?.teamMember?.list?.find(t => t.id == state?.booking?.blockModal)
    const openingHours = {}

    if (teamMember){
      const openingHoursSource = teamMember && teamMember.openingHours;

      openingHoursSource && openingHoursSource.map(openingHour => {
        openingHour.day && openingHour.day.map(day => {

          const openingHourRow = {
            opensAt: openingHour.opensAt,
            closesAt: openingHour.closesAt,
          }

          if (openingHours[day]){
            openingHours[day].push(openingHourRow)
          } else {
            openingHours[day] = [openingHourRow]
          }
        })
      })
    }

    console.log('openingHours',openingHours)
       
    return {
        show: state?.booking?.blockModal,
        calendarDate: state?.booking?.calendarDate,
        locationID: state?.scheduler?.location?.value,
        businessID: state?.businessID,
        teamMemberName: teamMember?.givenName,
        openingHours
    }
}

const mapDispatchToProps = dispatch => ({
    closeModal: () => dispatch(ACTIONS.showBlock(false)),
    createBooking: (item) => dispatch(ACTIONS.createItem(item)),
    updateBooking: (item) => dispatch(ACTIONS.updateItem(item)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BlockModal)
import React, { useState, useEffect } from "react";
import { useForm } from 'react-hook-form'

import {connect} from 'react-redux';

import {Actions as ACTIONS} from 'store/business/action';
import BusinessDetails from './BusinessDetails';
import BusinessBillingDetails from './BusinessBillingDetails';

const BusinessSettings = ({business, getBusiness, updateBusiness}) => {

  	// const [sidebarOpen, setSidebarOpen] = useState(false)
	useEffect(() => {
		console.log('id',business && business.id)
		if (!(business && business.id)){
			console.log('getBusiness')
			getBusiness()
		}
	},[]);

	if (!business){

		return (
			<div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
				Loading...
			</div>
		)
	}

	return (
		<div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
		  	
		  	<BusinessDetails />

			<div className="hidden sm:block">
			  <div className="py-5">
			    <div className="border-t border-gray-200"></div>
			  </div>
			</div>

			<BusinessBillingDetails />

			<div className="hidden sm:block">
			  <div className="py-5">
			    <div className="border-t border-gray-200"></div>
			  </div>
			</div>

			<div className="mt-10 sm:mt-0">
			  <div className="md:grid md:grid-cols-3 md:gap-6">
			    <div className="md:col-span-1">
			      <div className="px-4 sm:px-0">
			        <h3 className="text-lg font-medium leading-6 text-gray-900">Notifications</h3>
			        <p className="mt-1 text-sm leading-5 text-gray-500">
			          Decide which communications you'd like to receive and how.
			        </p>
			      </div>
			    </div>
			    <div className="mt-5 md:mt-0 md:col-span-2">
			      <form action="#" method="POST">
			        <div className="shadow overflow-hidden sm:rounded-md">
			          <div className="px-4 py-5 bg-white sm:p-6">
			            <fieldset>
			              <legend className="text-base leading-6 font-medium text-gray-900">By Email</legend>
			              <div className="mt-4">
			                <div className="flex items-start">
			                  <div className="absolute flex items-center h-5">
			                    <input id="bookingNotification" type="checkbox" className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out" />
			                  </div>
			                  <div className="pl-7 text-sm leading-5">
			                    <label htmlFor="bookingNotification" className="font-medium text-gray-700">Booking</label>
			                    <p className="text-gray-500">Get notified by email once someone makes a new booking .</p>
			                  </div>
			                </div>

			                <div className="mt-4">
				                <div className="flex items-start">
				                  <div className="absolute flex items-center h-5">
				                    <input id="bookingCanellationNotification" type="checkbox" className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out" />
				                  </div>
				                  <div className="pl-7 text-sm leading-5">
				                    <label htmlFor="bookingCanellationNotification" className="font-medium text-gray-700">Cancellation</label>
				                    <p className="text-gray-500">Get notified by email once someone cancels a booking.</p>
				                  </div>
				                </div>
			                </div>
			                <div className="mt-4">
			                  <div className="flex items-start">
			                    <div className="absolute flex items-center h-5">
			                      <input id="systemUpdates" type="checkbox" className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out" />
			                    </div>
			                    <div className="pl-7 text-sm leading-5">
			                      <label htmlFor="systemUpdates" className="font-medium text-gray-700">System Updates</label>
			                      <p className="text-gray-500">Get notified when there are new features and updates for the software.</p>
			                    </div>
			                  </div>
			                </div>
			              </div>
			            </fieldset>
			            {/*<fieldset className="mt-6">
			              <legend className="text-base leading-6 font-medium text-gray-900">Push Notifications</legend>
			              <p className="text-sm leading-5 text-gray-500">These are delivered via SMS to your mobile phone.</p>
			              <div className="mt-4">
			                <div className="flex items-center">
			                  <input id="push_everything" name="form-input push_notifications" type="radio" className="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out" />
			                  <label htmlFor="push_everything" className="ml-3">
			                    <span className="block text-sm leading-5 font-medium text-gray-700">Everything</span>
			                  </label>
			                </div>
			                <div className="mt-4 flex items-center">
			                  <input id="push_email" name="form-input push_notifications" type="radio" className="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out" />
			                  <label htmlFor="push_email" className="ml-3">
			                    <span className="block text-sm leading-5 font-medium text-gray-700">Same as email</span>
			                  </label>
			                </div>
			                <div className="mt-4 flex items-center">
			                  <input id="push_nothing" name="form-input push_notifications" type="radio" className="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out" />
			                  <label htmlFor="push_nothing" className="ml-3">
			                    <span className="block text-sm leading-5 font-medium text-gray-700">No push notifications</span>
			                  </label>
			                </div>
			              </div>
			            </fieldset>*/}
			          </div>
			          <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
			            <button className="py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 shadow-sm hover:bg-indigo-500 focus:outline-none focus:shadow-outline-blue focus:bg-indigo-500 active:bg-indigo-600 transition duration-150 ease-in-out">
			              Save
			            </button>
			          </div>
			        </div>
			      </form>
			    </div>
			  </div>
			</div>
		</div>
	)
}


const mapStateToProps = (state) => {
  console.log('state',state && state.business)
    return {
      business: state.business.item,
  }
}

const mapDispatchToProps = dispatch => ({
  updateBusiness: (item) => dispatch(ACTIONS.updateItem(item)),
  getBusiness: (id) => dispatch(ACTIONS.getItem(id)),
  // timesheetUpdateSaveStatus: (status) => dispatch(ACTIONS.timesheetUpdateSaveStatus(status))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BusinessSettings) 

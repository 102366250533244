import React, { useState, useEffect } from "react";
import { useForm } from 'react-hook-form'
import { useParams, useHistory} from "react-router";

import {connect} from 'react-redux';

import {Actions as ACTIONS} from 'store/teamMember/action';
import {Actions as SERVICEACTIONS} from 'store/service/action';
import { v4 as uuidv4 } from 'uuid';
import Toggle from 'components/formElements/Toggle'

const TeamMemberServiceRow = ({service, teamMemberService, teamMember, updateTeamMemberService, createTeamMemberService}) => {

  	const [id, setId] = useState(teamMemberService && teamMemberService.id || uuidv4())
  	const [enabled, setEnabled] = useState(teamMemberService && teamMemberService.enabled || false)
  	const [price, setPrice] = useState(teamMemberService && teamMemberService.price || service.price)
  	const [duration, setDuration] = useState(teamMemberService && teamMemberService.duration || service.duration)
  	const [maxDaily, setMaxDaily] = useState(teamMemberService && teamMemberService.maxDaily || null)
  	const [edited, setEdited] = useState(false)

  		console.log('enabled',enabled,teamMemberService && teamMemberService.enabled)

  	const saveChanges = () => {

  		const item = {
  			id,
  			enabled,
  			teamMemberID: teamMember && teamMember.id,
  			serviceID: service.id,
  			maxDaily: maxDaily
  		}

  		if (price && (price != service.price || teamMemberService && price != teamMemberService.price)){
  			item.price = price
  		}
  		if (duration && (duration != service.duration || teamMemberService && duration != teamMemberService.duration)){
  			item.duration = duration
  		}

  		console.log('itemToSave',item,duration)

  		if (teamMemberService){
  			item._version = teamMemberService._version
  			updateTeamMemberService(item)
  		} else {
  			createTeamMemberService(item)
  		}
  	}

	return (
		<tr>
	        <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">
	          {service.name}
	        </td>
	        <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
	          <Toggle onClick={() => {setEnabled(!enabled);setEdited(true)}} active={enabled} useActive={true}/>
	        </td>
	        <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
	          <input onChange={e => {setPrice(e.target.value);setEdited(true)}} defaultValue={price} style={{background: "#f9fafb", padding: "5px", border: "solid 1px #E5E7EA", width: "50px"}}/>
	        </td>
	        <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
	          <input onChange={e => {setDuration(e.target.value);setEdited(true)}} defaultValue={duration} style={{background: "#f9fafb", padding: "5px", border: "solid 1px #E5E7EA", width: "50px"}}/>
	        </td>
	        <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
	          <input type="number" onChange={e => {setMaxDaily(e.target.value.length > 0 ? e.target.value : null);setEdited(true)}} defaultValue={maxDaily} style={{background: "#f9fafb", padding: "5px", border: "solid 1px #E5E7EA", width: "50px"}}/>
	        </td>
	        <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
	          	{ edited &&
	          		<svg onClick={() => {saveChanges();setEdited(false)}} class="h-6 w-6 text-indigo-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
					  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 17a5 5 0 01-.916-9.916 5.002 5.002 0 019.832 0A5.002 5.002 0 0116 17m-7-5l3-3m0 0l3 3m-3-3v12"/>
					</svg>
				}
	        </td>
	      </tr>
	)
}

const TeamMemberServices = ({teamMember, services, listServices, updateTeamMemberService, createTeamMemberService, locationID}) => {

	useEffect(() => {
		if (!services){
			console.log('listServices')
			listServices(1000,null)
		}
	},[]);

	return (

		<React.Fragment>
			<div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
				<div class="flex flex-col">
				  <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
				    <div class="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
				      <table class="min-w-full">
				        <thead>
				          <tr>
				            <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
				              Name
				            </th>
				            <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
				              Enabled
				            </th>
				            <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
				              Price
				            </th>
				            <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
				              Duration
				            </th>
				            <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
				              Max Daily
				            </th>
				            <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
				            </th>
				          </tr>
				        </thead>
				        <tbody class="bg-white">
				          { teamMember && services && services
				          	.filter( service => !service.offeredIn || service.offeredIn.includes(locationID))
				          	.map( (service, index) => {

				          		const teamMemberService = teamMember && teamMember.services && teamMember.services.items.find(item => item.serviceID == service.id) || null

				          		return (
					          		<TeamMemberServiceRow service={service} teamMember={teamMember} teamMemberService={teamMemberService} key={service.id} updateTeamMemberService={updateTeamMemberService} createTeamMemberService={createTeamMemberService} />
					          	)
										}
							)}
				        </tbody>
				      </table>
				    </div>
				  </div>
				</div>
			</div>
		</React.Fragment>
		)
}


const mapStateToProps = (state) => {
	return {
	  teamMember: state.teamMember.item,
      services: state.service.list,
  }
}

const mapDispatchToProps = dispatch => ({
	listServices: (limit,nextToken) => dispatch(SERVICEACTIONS.getList(limit,nextToken)),
	updateTeamMemberService: (item) => dispatch(ACTIONS.updateTeamMemberService(item)),
	createTeamMemberService: (item) => dispatch(ACTIONS.createTeamMemberService(item)),
  // timesheetUpdateSaveStatus: (status) => dispatch(ACTIONS.timesheetUpdateSaveStatus(status))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TeamMemberServices) 
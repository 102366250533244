import React, { useState, useEffect } from "react";
import { useForm } from 'react-hook-form'

import {connect} from 'react-redux';

import {Actions as ACTIONS} from 'store/business/action';


const BusinessBillingDetails = ({}) => {

	const { register, handleSubmit, watch, errors } = useForm()
	const onSubmit = data => {
		console.log(data)
	}

	return (
			<div className="mt-10 sm:mt-0">
			  <div className="md:grid md:grid-cols-3 md:gap-6">
			    <div className="md:col-span-1">
			      <div className="px-4 sm:px-0">
			        <h3 className="text-lg font-medium leading-6 text-gray-900">Billing Information</h3>
			        <p className="mt-1 text-sm leading-5 text-gray-500">
			          Use a permanent address where you can receive mail. This address and email will be used for all invoices issued by our software.
			        </p>
			      </div>
			    </div>
			    <div className="mt-5 md:mt-0 md:col-span-2">
			      <form  onSubmit={handleSubmit(onSubmit)}>
			        <div className="shadow overflow-hidden sm:rounded-md">
			          <div className="px-4 py-5 bg-white sm:p-6">
			            <div className="grid grid-cols-6 gap-6">

			              <div className="col-span-6 sm:col-span-4">
			                <label htmlFor="email_address" className="block text-sm font-medium leading-5 text-gray-700">Email address</label>
			                <input id="email_address" name="email_address" ref={register} className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
			              </div>

			              <div className="col-span-6 sm:col-span-4">
			                <label htmlFor="country" className="block text-sm font-medium leading-5 text-gray-700">Country / Region</label>
			                <select id="country" name="country" ref={register} className="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
			                  <option>Malta</option>
			                  <option>United States</option>
			                  <option>Canada</option>
			                  <option>Mexico</option>
			              	  {/* TODO Add official country list */}
			                </select>
			              </div>

			              <div className="col-span-6 sm:col-span-4">
			                <label htmlFor="attentionTo" className="block text-sm font-medium leading-5 text-gray-700">Attention To</label>
			                <input id="attentionTo" name="attentionTo" ref={register} className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
			              </div>

			              <div className="col-span-6 sm:col-span-4">
			                <label htmlFor="street_address" className="block text-sm font-medium leading-5 text-gray-700">Street address</label>
			                {/*<input id="street_address" name="street_address" ref={register} className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />*/}
			                <textarea id="street_address" name="street_address" ref={register} rows="4" className="form-textarea mt-1 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5" placeholder="1, The Company 
The Only Building
Greatest Street Ever "></textarea>
			              </div>

			              <div className="col-span-6 grid grid-cols-6 gap-6">
				              <div className="col-span-6 sm:col-span-6 lg:col-span-2">
				                <label htmlFor="city" className="block text-sm font-medium leading-5 text-gray-700">City</label>
				                <input id="city" name="city" ref={register} className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
				              </div>

				              <div className="col-span-6 sm:col-span-3 lg:col-span-2">
				                <label htmlFor="state" className="block text-sm font-medium leading-5 text-gray-700">State / Region</label>
				                <input id="state" name="state" ref={register} className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
				              </div>

				              <div className="col-span-6 sm:col-span-3 lg:col-span-2">
				                <label htmlFor="postal_code" className="block text-sm font-medium leading-5 text-gray-700">ZIP / Postal Code</label>
				                <input id="postal_code" name="postal_code" ref={register} className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
				              </div>
				             </div>
			            </div>
			          </div>
			          <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
			            <button className="py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 shadow-sm hover:bg-indigo-500 focus:outline-none focus:shadow-outline-blue active:bg-indigo-600 transition duration-150 ease-in-out">
			              Save
			            </button>
			          </div>
			        </div>
			      </form>
			    </div>
			  </div>
			</div>
		)
}


const mapStateToProps = (state) => {
    return {
      business: state.business.item,
  }
}

const mapDispatchToProps = dispatch => ({
  updateBusiness: (item) => dispatch(ACTIONS.updateItem(item)),
  getBusiness: (id) => dispatch(ACTIONS.getItem(id)),
  // timesheetUpdateSaveStatus: (status) => dispatch(ACTIONS.timesheetUpdateSaveStatus(status))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BusinessBillingDetails) 
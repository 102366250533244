import React from "react";

// types of action
const Types = {
  DELETE_MODAL: "DELETE_MODAL",
};

const toggleDeleteModal = (show,fn) => ({
  type: Types.DELETE_MODAL,
  show,
  fn
});


const Actions = {
  toggleDeleteModal
}

export {Types, Actions}

// exports.Types = Types
// exports.Actions = Actions
import { v4 as uuidv4 } from 'uuid';

// types of action
const Types = {
  NOTIFICATION_ADD: "NOTIFICATION_ADD",
  NOTIFICATION_CLOSE: "NOTIFICATION_CLOSE",
};

const sendNotification =  (notification) => ({
  type: Types.NOTIFICATION_ADD,
  notification
});

const closeNotification =  (id) => ({
  type: Types.NOTIFICATION_CLOSE,
  id
});

const addNotification = (notification,timeout) => {
  return (dispatch, getState) => {

    if (!notification.id){
      notification.id = uuidv4();
    }

    dispatch(sendNotification(notification))

    if (timeout){
      // if there is a timeout automatically remove the notification after some time
      setTimeout(() => dispatch(closeNotification(notification.id)) ,timeout)
    }
    
  }
}

const Actions = {
  closeNotification,
  addNotification
}

export {Types, Actions}
import React from "react";

import {
          getCustomer,
          listCustomers,
          customerByBusinessByPhone,
       } from 'graphql/queries'

import {
  createCustomer,
  updateCustomer,
  deleteCustomer,
  createChangelog,
} from 'graphql/mutations';

import {
  onCreateCustomer,
  onUpdateCustomer,
} from 'graphql/subscriptions';

import { diffString, diff } from 'json-diff';

import {API, graphqlOperation} from '@aws-amplify/api'
import {Auth} from '@aws-amplify/auth'
import awsMobile from 'aws-exports';
import AWS from 'aws-sdk';
import {Actions as NOTIFICATIONACTIONS} from 'store/notifications/action';

import parsePhoneNumber from 'libphonenumber-js'
import parsePhoneNumberFromString from 'libphonenumber-js'

// const { parsePhoneNumberFromString, parsePhoneNumber } = require('libphonenumber-js')
const { format } = require('date-fns')


// types of action
const Types = {
  CUSTOMER_GET_ITEM: "CUSTOMER_GET_ITEM",
  CUSTOMER_UPDATE_ITEM: "CUSTOMER_UPDATE_ITEM",
  CUSTOMER_CREATE_ITEM: "CUSTOMER_CREATE_ITEM",
  CUSTOMER_DELETE_ITEM: "CUSTOMER_DELETE_ITEM",
  CUSTOMER_CLEAR_ITEM: "CUSTOMER_CLEAR_ITEM",
  CUSTOMER_LOADING: "CUSTOMER_LOADING",
  CUSTOMER_GET_LIST: "CUSTOMER_GET_LIST",
  CUSTOMER_GET_SEARCH: "CUSTOMER_GET_SEARCH",
  CUSTOMER_NEW_MODAL: "CUSTOMER_NEW_MODAL",
};

const newCustomerModal =  (show) => ({
  type: Types.CUSTOMER_NEW_MODAL,
  show
});

const dispatchLoading =  loading => ({
  type: Types.CUSTOMER_LOADING,
  loading
});

const clearItem = response => ({
  type: Types.CUSTOMER_CLEAR_ITEM
});

const dispatchGetItem = response => ({
  type: Types.CUSTOMER_GET_ITEM,
  payload: response
});

const getItem = (id) => {
  console.log('getItem1',getItem)
  return (dispatch, getState) => {
    dispatch(dispatchLoading(true))

    const variables = {id}
    const request = API.graphql({query: getCustomer, variables, authMode: "AMAZON_COGNITO_USER_POOLS"})
    console.log('getItem2',getItem)
    request.then((response) => {
        dispatch(dispatchGetItem(response))
        dispatch(dispatchLoading(false))
    });
    
  }
}

const dispatchUpdateItem = (response,callback) => ({
  type: Types.CUSTOMER_UPDATE_ITEM,
  payload: response,
  callback
});

const updateItem = (item,callback) => {
  return (dispatch, getState) => {

    const state = getState()

    if (item.phone){
      let phone = item.phone.startsWith("00") ? "+" + item.phone.substr(2) : item.phone;

      phone = parsePhoneNumberFromString(phone)

      if (!phone){
        phone = parsePhoneNumber(item.phone,"MT")
      }
      item.phone = phone.number
    }


    const currentCustomer = state.customer && state.customer.item
    console.log('currentCustomer',currentCustomer,item)

    const variables = {input: item}
    const request = API.graphql({query: updateCustomer, variables, authMode: "AMAZON_COGNITO_USER_POOLS"})


    request.then(async (response) => {
        dispatch(dispatchUpdateItem(response,callback))

        const difference = diff(currentCustomer,response.data.updateCustomer);

        // remove common items
          delete difference._version;
          delete difference._lastChangedAt;
          delete difference.updatedAt;
          delete difference.queue;
          delete difference.bookings;
          delete difference.reviewEmailSent;
          delete difference.finalReminderSent;
          delete difference.confirmationReminderSent;

        console.log('difference',difference)

        const differenceKeys = Object.keys(difference)

        const userInfo = await Auth.currentUserInfo();
        console.log('userInfo',userInfo)

        const currentTeamMember = state.teamMember.list.find(i => i.username == userInfo.username)

        const newChangeLog = {
          entityID: item.id,
          action: "Update",
          teamMemberID: currentTeamMember.id,
          businessID: state.businessID,
          diff: JSON.stringify(difference)
        }

        const changeLogRequest = API.graphql({query: createChangelog, variables: {input: newChangeLog}, authMode: "AMAZON_COGNITO_USER_POOLS"})

        changeLogRequest.then((response) => {
          console.log('changeLogSaved',response)
          // we don't need to do anythign after saving the changelog right now.
        })

        //TODO Plan notification logic/errors
        dispatch(NOTIFICATIONACTIONS.addNotification({
          title: <React.Fragment><strong>{response.data.updateCustomer.givenName}</strong> has been updated</React.Fragment>,
          message: response.data.updateCustomer.active ? `Customers can book this customer` : `This customer is disabled. It cannot be booked`,
          icon: `success`,
        },
          3000
        ))
    });

    
  }
}

const dispatchDeleteItem = response => ({
  type: Types.CUSTOMER_DELETE_ITEM,
  payload: response
});

const deleteItem = (id,_version,history) => {
  return (dispatch, getState) => {

    console.log('businessID',getState('businessID'))

    const businessID = getState().businessID
    const variables = {input: {id, _version }}
    const request = API.graphql({query: deleteCustomer, variables, authMode: "AMAZON_COGNITO_USER_POOLS"})

    request.then((response) => {
      console.log('responseTEST',response.data.deleteCustomer)

        // go to main page as it's been deleted
        history.push(`/customer`) 
        dispatch(dispatchDeleteItem(id))

        //TODO Plan notification logic/errors
        dispatch(NOTIFICATIONACTIONS.addNotification({
          title: <React.Fragment><strong>{response.data.deleteCustomer.name}</strong> has been deleted</React.Fragment>,
          message: `This customer is no longer available.`,
          icon: `success`,
        },2000))
    });
  }
}

const dispatchCreateItem = (response,callback) => ({
  type: Types.CUSTOMER_CREATE_ITEM,
  payload: response,
  callback
});

const createItem = (item,callback) => {
  return (dispatch, getState) => {

    console.log('parsePhoneNumberFromString',require('libphonenumber-js'))
    console.log('parsePhoneNumberFromString',parsePhoneNumberFromString)
    let phone = parsePhoneNumberFromString(item.phone)

    if (!phone){
      phone = parsePhoneNumber(item.phone,"MT")
    }
    item.phone = phone.number

    const state = getState();

    // check if this is a duplicate
    const customerExists = state.customer && state.customer.list && state.customer.list.find(i => i.phone == item.phone && i.givenName == item.givenName && i.familyName == item.familyName)
    console.log('customerExists',customerExists)

    if (customerExists){
      dispatch(NOTIFICATIONACTIONS.addNotification({
          title: <React.Fragment><strong>Duplicate Customer</strong></React.Fragment>,
          message: 'A customer with this Name, Surname and Phone number already exists',
          icon: `warning`,
        },
        3000
      ))
      callback(customerExists)
      dispatch(newCustomerModal(false))
      // stop processing at this point
      return;
    }


    const businessID = state.businessID;
    const variables = {input: {...item, businessID}}
    const request = API.graphql({query: createCustomer, variables, authMode: "AMAZON_COGNITO_USER_POOLS"})

    request.then((response) => {
        // switched off as subscription is filling this
        dispatch(dispatchCreateItem(response,callback))

        //TODO Plan notification logic/errors
        dispatch(NOTIFICATIONACTIONS.addNotification({
          title: <React.Fragment><strong>{response.data.createCustomer.givenName}</strong> has been created</React.Fragment>,
          // message: response.data.createCustomer.active ? `You team can now start to provide this customer.` : `This customer is disabled and cannot be booked.`,
          icon: `success`,
        },2000))
    });
    
  }
}

const updateSearch = response => ({
  type: Types.CUSTOMER_GET_SEARCH,
  payload: response
});

const findCustomerByPhone = (phone) => {
  return (dispatch, getState) => {

    const state = getState()

    const businessID = state.businessID
    const today = format(new Date(),"yyyy-MM-dd")

    const beginsWith = phone.substr(0,1) == "+" ? 
                              phone :
                              phone.substr(0,2) == "00" ? 
                                `+${phone.substr(2)}` :
                                `+356${phone}`

    const variables = {
      phone: {beginsWith}, 
      businessID
    }
    const request = API.graphql({query: customerByBusinessByPhone, variables, authMode: "AMAZON_COGNITO_USER_POOLS"})

    request.then((response) => {
        dispatch(updateSearch(response))
    });
    
  }
}

const updateList = response => ({
  type: Types.CUSTOMER_GET_LIST,
  payload: response
});

const getList = (limit, nextToken) => {
  return (dispatch, getState) => {

    const today = format(new Date(),"yyyy-MM-dd")

    const variables = {limit, nextToken, today}
    const request = API.graphql({query: listCustomers, variables, authMode: "AMAZON_COGNITO_USER_POOLS"})

    request.then((response) => {
        dispatch(updateList(response))
    });
    
  }
}


const subscribeToUpdates = () => {
  return (dispatch, getState) => {

    const state = getState()

    const businessID = state.businessID

    const createSubscription = API.graphql(
        graphqlOperation(onCreateCustomer,{businessID})
    ).subscribe({
        next: (data) => {

          const newEntry = data.value.data.onCreateCustomer;

          let currentCustomer = state.customer && state.customer.list && state.customer.list.find(({id}) => id == newEntry.id)
          
          if (!currentCustomer){
            dispatch(dispatchCreateItem(data.value))
          }
          console.log('onCreateCustomer',data.value)
        }
    });

    const updateSubscription = API.graphql(
        graphqlOperation(onUpdateCustomer,{businessID})
    ).subscribe({
        next: (data) => {

          const newEntry = data.value.data.onUpdateCustomer;
          let currentCustomer = state.customer && state.customer.list && state.customer.list.find(({id}) => id == newEntry.id)

          if (!currentCustomer || currentCustomer._version != newEntry._version){
            console.log('onUpdateCustomer',data.value)
            dispatch(dispatchUpdateItem(data.value))
          }
        }
    });

  }
}

const Actions = {
  getItem,
  createItem,
  updateItem,
  deleteItem,
  getList,
  clearItem,
  newCustomerModal,
  subscribeToUpdates
}

// exports = {
//   Types,
//   Actions
// }

export {Types, Actions}

// exports.Types = Types
// exports.Actions = Actions
import React, { useState, useEffect } from "react";
import { useForm } from 'react-hook-form'

import {connect} from 'react-redux';
import {format} from 'date-fns';

import {Actions as ACTIONS} from 'store/changelog/action';

import { withStyles } from '@material-ui/core/styles';


const styles = {
	spanStyle: {
	    position: "absolute",
	    left: "50%",
	    transform: "translateX(-50%)",
	    marginTop: "19px",
	    width: "300px",
	    background: "white",
	    border: "solid 1px",
	},
	tr: {
		'& span': {
	    	display: "none",
		},
		'&:hover span': {
	    	display: "block",
		},
		'& table': {
	    	width: "100%",
		},
	}
};

const ChangeLogRow = ({changelog,teamMembers,services,classes}) => {

	const teamMember = teamMembers.find(({id}) => id == changelog.teamMemberID)

	const diff = changelog.diff && JSON.parse(changelog.diff)
	const diffKeys = diff && Object.keys(diff)

	const getAttributeValue = (key,value) => {
		switch (key){
			case "teamMemberID": {
				const assignedTeamMember = teamMembers.find(({id}) => id == value)
				if (!assignedTeamMember) {
					return "Not Set";
				}
				return `${assignedTeamMember.givenName ? assignedTeamMember.givenName : ""} ${assignedTeamMember.familyName ? assignedTeamMember.familyName : ""}`
			}
			default: 
				return value;
		}
	}

	const getServices = (servicesUpdated,symbol) => {
		const servicesToWrite = servicesUpdated
				.filter(item => item[0] == symbol)
				.map(item => 
					services
						.find(({id}) => id == item[1].service)
				);
				console.log('servicesToWrite',servicesToWrite)
		const serviceLabels = servicesToWrite.map(service => service.name);
				console.log('serviceLabels',serviceLabels)
		return serviceLabels.join(',')
	}

	const getPaymentDetails = (paymentDetails) => {
		return paymentDetails && paymentDetails.map(p => `${p.method}: ${p.amount}`).join(',')
	}

  	return (

      <tr className={classes.tr}>
        <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">
          {teamMember ? `${teamMember.givenName} ${teamMember.familyName ? teamMember.familyName : ``}` : 'Client'}
        </td>
        <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
          {changelog.action}
          { diff && 
          	<span className={classes.spanStyle}>
	          	<table>
	          		<thead>
	          			<tr>
	          				<th>Field</th>
	          				<th>From</th>
	          				<th>To</th>
	          			</tr>
	          		</thead>
	          		<tbody>
	          			{
	          				diffKeys.map(key => {

	          					const oldValue = getAttributeValue(key,diff[key].__old);
	          					const newValue = getAttributeValue(key,diff[key].__new);

	          					// if (key == 'paymentDetails'){
	          					// 	return null
	          					// }

	          					return (
		          					<tr>
				          				<td>{key}</td>
				          				<td styles={{cursor:"pointer"}}>
				          					{key == 'services' && getServices(diff[key],'-')}
				          					{key == 'paymentDetails' ? getPaymentDetails(oldValue) :
				          					 	oldValue && typeof(oldValue) == 'object' ? JSON.stringify(oldValue) : oldValue}
				          				</td>
				          				<td>
				          					{key == 'services' && getServices(diff[key],'+')}
				          					{key == 'paymentDetails' ? getPaymentDetails(newValue) :
				          						newValue && typeof(newValue) == 'object' ? JSON.stringify(newValue) : newValue}
				          				</td>
				          			</tr>
	          					)
	          				})
	          			}
	          		</tbody>
	          	</table>
	          </span>
	      }
        </td>
        <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
           {format(new Date(changelog.createdAt), "d MMM yyyy hh:mm:ss")}
        </td>
      </tr>
	)
}


const mapStateToProps = (state) => {
  return {
  	teamMembers: state.teamMember.list,
  	services: state.service.list,
  	// changelog: state.changelog.items,
  	// show: state.changelog.modal,
  }
}

const mapDispatchToProps = dispatch => ({
  // toggleChangeLogModal: () => dispatch(ACTIONS.toggleChangeLogModal(false)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ChangeLogRow))
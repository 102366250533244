import React from "react";

import {
          changelogByEntityByDate,
       } from 'graphql/queries'

import {API} from '@aws-amplify/api'
// types of action
const Types = {
  CHANGELOG_GET_LIST: "CHANGELOG_GET_LIST",
  CHANGELOG_TOGGLE_MODAL: "CHANGELOG_TOGGLE_MODAL",
};

const updateList = response => ({
  type: Types.CHANGELOG_GET_LIST,
  payload: response
});

const getChangelogByEntity = (entityID) => {
  return (dispatch, getState) => {

    const variables = {
      entityID,
      limit: 1000,
      // nextToken,
      sortDirection: "DESC"
    }
    const request = API.graphql({query: changelogByEntityByDate, variables, authMode: "AMAZON_COGNITO_USER_POOLS"})

    request.then((response) => {
        dispatch(updateList(response))
    });
    
  }
}

const toggleChangeLogModal = status => ({
  type: Types.CHANGELOG_TOGGLE_MODAL,
  status
});

const Actions = {
  getChangelogByEntity,
  toggleChangeLogModal
}

// exports = {
//   Types,
//   Actions
// }

export {Types, Actions}

// exports.Types = Types
// exports.Actions = Actions
import React, { useState, useEffect } from "react";
import { useForm } from 'react-hook-form'

import {connect} from 'react-redux';

import {Actions as ACTIONS} from 'store/location/action';
import PageHeading from 'components/PageHeading';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useLocation,
  useParams
} from "react-router-dom";

const Locations = ({locations,locationsNextToken,listLocations}) => {

  	// const [sidebarOpen, setSidebarOpen] = useState(false)
  	// const [sidebarOpen, setSidebarOpen] = useState(false)

	useEffect(() => {
		console.log('id',locations)
		if (!(locations)){
			console.log('getBusiness')
			listLocations(1000,locationsNextToken)
		}
	},[]);

	if (!locations){

		return (
			<div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
				Loading...
			</div>
		)
	}

	return (
		<React.Fragment>
			<PageHeading withCreate={true}/>
			<div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
				<div class="flex flex-col">
				  <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
				    <div class="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
				      <table class="min-w-full">
				        <thead>
				          <tr>
				            <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
				              Name
				            </th>
				            <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
				              City, Country
				            </th>
				            <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
				              Status
				            </th>
				            <th class="px-6 py-3 border-b border-gray-200 bg-gray-50"></th>
				          </tr>
				        </thead>
				        <tbody class="bg-white">
				          { locations && locations.map( (location, index) => (

					          <tr>
					            <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">
					              {location.name}
					            </td>
					            <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
					              {location.address && location.address.postCode} {location.address && location.address.city} {location.address && location.address.country}
					            </td>
					            <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
					               <div class="ml-2 flex-shrink-0 flex">
						            	{
						            		location.enabled && 
								              <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
								                {location.isAddon ? "Add On" : "Primary"}
								              </span>
						            	}
						            	{
						            		!location.enabled && 
								              <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
								                Disabled
								              </span>
						            	}
						            </div>
					            </td>
					            <td class="px-6 py-4 whitespace-no-wrap text-right border-b border-gray-200 text-sm leading-5 font-medium">
					              <Link to={`/location/${location.id}`}  class="text-indigo-600 hover:text-indigo-900 focus:outline-none focus:underline">Edit</Link>
					            </td>
					          </tr>
							))}
				        </tbody>
				      </table>
				    </div>
				  </div>
				</div>
			</div>
		</React.Fragment>
		)
}


const mapStateToProps = (state) => {
    return {
      locations: state.location.list,
      locationsNextToken: state.location.listNextToken,
  }
}

const mapDispatchToProps = dispatch => ({
  listLocations: (limit,nextToken) => dispatch(ACTIONS.getList(limit,nextToken)),
  // timesheetUpdateSaveStatus: (status) => dispatch(ACTIONS.timesheetUpdateSaveStatus(status))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Locations)
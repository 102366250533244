import React, { useState, useEffect } from "react";
import { useForm } from 'react-hook-form'

import {connect} from 'react-redux';

import {Actions as ACTIONS} from 'store/booking/action';
import {Actions as SERVICEACTIONS} from 'store/service/action';
import {Actions as TEAMMEMBERACTIONS} from 'store/teamMember/action';
import {Actions as LOCATIONACTIONS} from 'store/location/action';
import {Actions as CUSTOMERACTIONS} from 'store/customer/action';
import PageHeading from 'components/PageHeading';
import BookingForm from './BookingForm';
import BlockModal from './BlockModal';
import BookingSchedule from './BookingSchedule';
import Select from 'react-select';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useLocation,
  useParams
} from "react-router-dom";



const selectDefaultStyle = {
  control: styles => ({ ...styles, backgroundColor: 'white', border: 'none' }),

	menu: (provided, state) => ({
	  ...provided,
	  zIndex: 999999,
	  // lineHeight: "14px",
	}),
};

const Bookings = ({
					bookings,
					bookingsNextToken,
					listBookings,
					services,
					servicesNextToken,
					teamMembers,
					teamMembersNextToken,
					locations,
					locationsNextToken,
					listServices,
					listTeamMembers,
					listLocations,
					listCustomers,
					customers,
					customersNextToken,
					username,
					thisUser
				}) => {

	const locationOptions = locations && locations.map(location => ({value: location.id, label: location.name}))

	return (
		<React.Fragment>
			<PageHeading withCreate={false}/>

			<BookingSchedule/>
			<BlockModal/>
		</React.Fragment>
		)
}


const mapStateToProps = (state) => {
    return {
      bookings: state.booking.list,
      bookingsNextToken: state.booking && state.booking.listNextToken,
      services: state.service.list,
      servicesNextToken: state.services && state.services.listNextToken,
      teamMembers: state.teamMember.list,
      teamMembersNextToken: state.teamMembers && state.teamMembers.listNextToken,
      locations: state.location.list,
      locationsNextToken: state.location && state.location.listNextToken,
      customers: state.customer.list,
      customersNextToken: state.customer && state.customer.listNextToken,
      username: state.userDetails.username,
      thisUser: state.teamMember.list && state.teamMember.list.filter(item => item.username == state.userDetails.username)
  }
}

const mapDispatchToProps = dispatch => ({
  listBookings: (limit,nextToken) => dispatch(ACTIONS.getList(limit,nextToken)),
  listServices: (limit,nextToken) => dispatch(SERVICEACTIONS.getList(limit,nextToken)),
  listTeamMembers: (limit,nextToken) => dispatch(TEAMMEMBERACTIONS.getList(limit,nextToken)),
  listLocations: (limit,nextToken) => dispatch(LOCATIONACTIONS.getList(limit,nextToken)),
  listCustomers: (limit,nextToken) => dispatch(CUSTOMERACTIONS.getList(limit,nextToken)),
  
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Bookings)
import React, { useState, useEffect } from "react";
import { useForm } from 'react-hook-form'
import { useParams, useHistory} from "react-router";

import {connect} from 'react-redux';

import {Actions as ACTIONS} from 'store/product/action';
import {Actions as DELETEACTIONS} from 'store/deleteModal/action';
import PageHeading from 'components/PageHeading';
import ColorPicker from 'components/formElements/ColorPicker';
import { v4 as uuidv4 } from 'uuid';
import cleanDeep from 'clean-deep'


const Product = ({
	product,
	deleteModal,
	createProduct,
	updateProduct,
	getProduct,
	deleteProduct,
	clearProduct,
	handleUpload
}) => {

  	let history = useHistory();
	let { id } = useParams();

	// initialId is used for when we are clearing as it causes an issue.
  	const [initialId, setInitialId] = useState(product && product.id)

	const { register, handleSubmit, watch, errors, setValue } = useForm()

	const handleUploadChange = async (e, name) => {
        const file = e.target.files[0];

        if (!file) {
            return;
        }

        const key = await handleUpload(file)
    }

	const onSubmit = data => {
		console.log(data)


		const item = {
			id: product && product.id || uuidv4(),
			_version: product && product._version,
			name: data.name,
			code: data.code,
			price: data.price,
			order: data.order,
			enabled: data.enabled,
			online: data.online,
		}
		console.log('item',item)

		if (product && product.id){
			updateProduct(cleanDeep(item))
		} else {
			delete(item._version);
			console.log('updatedItem',item)
			createProduct(cleanDeep(item))
		}
	}

	const blockColour = watch('blockColour') || product && product.blockColour;
	const isAddon = watch('isAddon') || watch('isAddon') == null && product && product.isAddon;
	const isSecondary = watch('isSecondary') || watch('isSecondary') == null && product && product.isSecondary;

	useEffect(() => {
		if (!(id !== 'new' && product && product.id && product.id == id)){
			getProduct(id)
		} else if (id == 'new'){
			clearProduct()
		}
	},[product && product.id]);

	useEffect(() => {
		if (id == 'new' && product && product.id && product.id !== initialId && product._version == 1){
  			history.push(`/products/${product.id}`)
		}
	},[product && product._version == 1 && product.id]);

	if ( (!product && id !== 'new' ) || (product && id !== 'new' && id !== product.id ) || (id === 'new' && product)){

		return (
			<div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
				Loading...
			</div>
		)
	}

	return (

		<React.Fragment>
			<PageHeading item={product} isNew={id === 'new'}/>
			<div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
				<div className="md:grid md:grid-cols-3 md:gap-6">
					<div className="md:col-span-1">
					  <div className="px-4 sm:px-0">
						<h3 className="text-lg font-medium leading-6 text-gray-900">Product Details</h3>
						<p className="mt-1 text-sm leading-5 text-gray-500">
						  This information will be displayed publicly.
						</p>
					  </div>
					</div>
					<div className="mt-5 md:mt-0 md:col-span-2">
					  <form onSubmit={handleSubmit(onSubmit)}>
						<div className="shadow sm:rounded-md sm:overflow-hidden">
						  <div className="px-4 py-5 bg-white sm:p-6">
							<div className="grid grid-cols-6 gap-6">

								<div className="col-span-6 sm:col-span-4">
									<label htmlFor="name" className="block text-sm font-medium leading-5 text-gray-700">Name</label>
									<input id="name" name="name" defaultValue={product && product.name} ref={register} className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
								</div>

								<div className="col-span-6 sm:col-span-4">
									<label htmlFor="code" className="block text-sm font-medium leading-5 text-gray-700">Code/SKU</label>
									<input id="code" name="code" defaultValue={product && product.code} ref={register} className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
								</div>

								  <div className="col-span-6 sm:col-span-4">
									<label htmlFor="price" className="block text-sm font-medium leading-5 text-gray-700">
									  Price
									</label>
									<div className="mt-1 flex rounded-md shadow-sm">
									  <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
										€
									  </span>
									  <input id="price" name="price" defaultValue={product && product.price} ref={register} className="form-input flex-1 block w-full rounded-none rounded-r-md transition duration-150 ease-in-out sm:text-sm sm:leading-5" placeholder="15.00" />
									</div>
								  </div>

								  <div className="col-span-6 sm:col-span-4">
									<label htmlFor="order" className="block text-sm font-medium leading-5 text-gray-700">
									  Sort Position
									</label>
									<div className="mt-1 flex rounded-md shadow-sm">
									  <input id="order" type="number" name="order" defaultValue={product && product.order} ref={register} className="form-input flex-1 block w-full rounded-none rounded-r-md transition duration-150 ease-in-out sm:text-sm sm:leading-5" placeholder="15.00" />
									</div>
								  </div>



									{/* Cannot add photo before we save the team member */}
									{ product && product.id && 
							            <div class="col-span-6 sm:col-span-3">
							              <label for="photo" class="block text-sm leading-5 font-medium text-gray-700">
							                Photo
							              </label>

							              <div class="mt-2 flex items-center">
							                <span class="inline-block h-12 w-12 rounded-full overflow-hidden bg-gray-100">
							                	{
							                		product && product.photo ? 
							                			<img src={`https://${product.photo.bucket}.s3-eu-west-1.amazonaws.com/public/${product.photo.key}`}/>
							                			:
									                  <svg class="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
									                    <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
									                  </svg>
							                	}
							                </span>
							                <span class="ml-5 rounded-md shadow-sm">
							                  	<label htmlFor="upload-button">
									                <div type="button" class="py-2 px-3 border border-gray-300 rounded-md text-sm leading-4 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out inline-block">
									                    Change
									                </div>
									            </label>

												<input
											        type="file"
											        name="image"
											        id="upload-button"
											        style={{ display: "none" }}
											        onChange={handleUploadChange}
											      />
							                </span>
							              </div>
							            </div>
							        }

									<div className="col-span-6 sm:col-span-4">
									  <div className="flex items-start">
										<div className="absolute flex items-center h-5">
										  <input id="enabled" name="enabled" ref={register} defaultChecked={product && product.enabled} type="checkbox" className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out" />
										</div>
										<div className="pl-7 text-sm leading-5">
										  <label htmlFor="enabled" className="font-medium text-gray-700">Enabled</label>
										  <p className="text-gray-500">Untick this option if you do not want to take bookings for this product.</p>
										</div>
									  </div>
									</div>

									<div className="col-span-6 sm:col-span-4">
									  <div className="flex items-start">
										<div className="absolute flex items-center h-5">
										  <input id="online" name="online" ref={register} defaultChecked={product && product.online} type="checkbox" className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out" />
										</div>
										<div className="pl-7 text-sm leading-5">
										  <label htmlFor="online" className="font-medium text-gray-700">Can be booked via Website & App</label>
										  <p className="text-gray-500">Tick this option if you would like clients to book this directly.</p>
										</div>
									  </div>
									</div>

							</div>
						  </div>
						  <div className="md:grid md:grid-cols-2 md:gap-6 bg-gray-50 ">
							  <div className="px-4 py-3 text-left sm:px-6">
								<span className="inline-flex rounded-md shadow-sm">
								  <button 
								  	onClick={(e) => {
								  		e.preventDefault()
								  		e.stopPropagation()
								  		deleteModal(() => {
								  			deleteProduct(id,product._version,history)
								  		})
								  	}} 
								  	className="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red active:bg-red-700 transition duration-150 ease-in-out">
									Delete
								  </button>
								</span>
							  </div>
							  <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
								<span className="inline-flex rounded-md shadow-sm">
								  <button type="submit" className="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">
									Save
								  </button>
								</span>
							  </div>
						  </div>
						</div>
					  </form>
					</div>
				</div>
			</div>
		</React.Fragment>
		)
}


const mapStateToProps = (state) => {
	return {
	  product: state.product.item
  }
}

const mapDispatchToProps = dispatch => ({
  deleteModal: (fn) => dispatch(DELETEACTIONS.toggleDeleteModal(true,fn)),
  deleteProduct: (id,version,history) => dispatch(ACTIONS.deleteItem(id,version,history)), 
  createProduct: (item) => dispatch(ACTIONS.createItem(item)), 
  updateProduct: (item) => dispatch(ACTIONS.updateItem(item)),
  getProduct: (id) => dispatch(ACTIONS.getItem(id)),
  clearProduct: () => dispatch(ACTIONS.clearItem()),
  handleUpload: (file,preFileName) => dispatch(ACTIONS.handleUpload(file,preFileName)),
  // timesheetUpdateSaveStatus: (status) => dispatch(ACTIONS.timesheetUpdateSaveStatus(status))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Product) 
import {Auth} from '@aws-amplify/auth'

// types of action
const Types = {
  TENANTS_UPDATE: "TENANTS_UPDATE",
};

const setTenant = tenant => ({
  type: Types.TENANTS_SET,
  tenant
});

const dispatchUpdate = tenants => ({
  type: Types.TENANTS_UPDATE,
  tenants
});

const fetchTenants = () => {
  return async (dispatch, getState) => {
    
		const {accessToken} = await Auth.currentSession();
    const userDetails = await Auth.currentUserInfo()
    console.log('accessToken.payload',accessToken.payload,userDetails)
		// get the tenant from the top of the cognito groups list
		const cognitogroups = accessToken.payload['cognito:groups'];
		const tenants = cognitogroups;
        dispatch(dispatchUpdate(tenants))
    
  }
}

const Actions = {
  fetchTenants,
  setTenant
}

export {Types, Actions}

import {Types} from "./action";
import _ from "lodash";
// import {NotificationManager} from 'react-notifications';

// import cleanDeep from 'clean-deep';


const defaultState = {
  service: {}
};

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case Types.SERVICE_GET_ITEM: {

      let newState = {...state};

      newState.service.item = action.payload.data.getService;

      return newState;
    }
    case Types.SERVICE_CLEAR_ITEM: {

      let newState = {...state};

      newState.service.item = {};

      return newState;
    }
    case Types.SERVICE_UPDATE_PHOTO: {

      let newState = {...state};

      newState.service.item = {...newState.service.item};
      newState.service.item.photo = action.photo;

      if (newState.service.list){
        const itemIndex = newState.service.list.findIndex(item => item.id == newState.service.item.id)
        newState.service.list[itemIndex] = newState.service.item;
        newState.service.list = _.sortBy(newState.service.list,['order','name'])
      }

      console.log('newserviceUpdated',newState.service.item)

      return newState;
    }
    case Types.SERVICE_GET_LIST: {

      let newState = {...state};

      const existingList = newState.service.list || []

      const newItems = action.payload.data.listServices.items.filter(item => item._deleted != true)

      const merged = _(existingList) // start sequence
        .keyBy('id') // create a dictionary of the 1st array
        .merge(_.keyBy(newItems, 'id')) // create a dictionary of the 2nd array, and merge it to the 1st
        .values() // turn the combined dictionary to array
        .value(); // get the value (array) out of the sequence


      // TODO add sorting facility
      newState.service.list = _.sortBy(merged,['order','name']) 


      newState.service.listNextToken = action.payload.data.listServices.nextToken;
      newState.service.listStartedAt = action.payload.data.listServices.startedAt;
      console.log('updatedServiceList',newState.service.list)

      return newState;
    }
    case Types.SERVICE_UPDATE_ITEM: {

      let newState = {...state};

      const updatedItem = action.payload.data.updateService;
      newState.service.item = updatedItem;

      if (newState.service.list){
        const itemIndex = newState.service.list.findIndex(item => item.id == updatedItem.id)
        newState.service.list[itemIndex] = updatedItem;
        newState.service.list = _.sortBy(newState.service.list,['order','name'])
      }

      console.log('newServiceUpdated',newState.service.item)

      return newState;
    }
    case Types.SERVICE_DELETE_ITEM: {

      let newState = {...state};

      const deletedItem = action.payload;
      // newState.service.item = deletedItem;

      if (newState.service.list){
        newState.service.list = _.reject(newState.service.list,{id: deletedItem})
      }

      console.log('serviceDeleted')

      return newState;
    }
    case Types.SERVICE_CREATE_ITEM: {

      let newState = {...state};

      const createdItem = action.payload.data.createService;
      newState.service.item = createdItem;
      if (newState.service.list){
        newState.service.list.push(createdItem);
        newState.service.list = _.sortBy(newState.service.list,['order','name'])
      }

      console.log('newServiceUpdated',newState.service.item)

      return newState;
    }
    default:
      return state;
  }
};

export default reducer;
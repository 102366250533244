import React, { useState, useEffect } from "react";
import { useForm } from 'react-hook-form'
import { useParams, useHistory} from "react-router";

import {connect} from 'react-redux';

import {Actions as ACTIONS} from 'store/customer/action';
import PageHeading from 'components/PageHeading';
import { v4 as uuidv4 } from 'uuid';
import { format } from 'date-fns'
import cleanDeep from 'clean-deep';
import CustomerBookings from 'components/customer/CustomerBookings';
import CustomerForm from 'components/customer/CustomerForm';

const Customer = ({customer,createCustomer, updateCustomer, getCustomer, clearCustomer}) => {

  	let history = useHistory();
	let { id } = useParams();

	// initialId is used for when we are clearing as it causes an issue.
  	const [initialId, setInitialId] = useState(customer && customer.id)

	const { register, handleSubmit, watch, errors } = useForm()
	const onSubmit = data => {
		console.log(data)


		/*offDayExceptions: [AWSDate] #OffDays that will be worked anyway
		startDate: AWSDate
		endDate: AWSDate
		photo: S3Object*/

		const item = {
			id: customer && customer.id || uuidv4(),
			_version: customer && customer._version,
			givenName: data.givenName,
			familyName: data.familyName,
			phone: data.phone,
			email: data.email,
		}
		console.log('item',item)

		if (customer && customer.id){
			updateCustomer(cleanDeep(item))
		} else {
			delete(item._version);
			console.log('updatedItem',item)
			createCustomer(cleanDeep(item))
		}
	}

	const isAddon = watch('isAddon') || watch('isAddon') == null && customer && customer.isAddon;

	useEffect(() => {
		if (!(id !== 'new' && customer && customer.id && customer.id == id)){
			getCustomer(id)
		} else if (id == 'new'){
			clearCustomer()
		}
	},[customer && customer.id]);

	useEffect(() => {
		if (id == 'new' && customer && customer.id && customer.id !== initialId && customer._version == 1){
  			history.push(`/customer/${customer.id}`)
		}
	},[customer && customer._version == 1 && customer.id]);

	if ( (!customer && id !== 'new' ) || (customer && id !== 'new' && id !== customer.id ) || (id === 'new' && customer)){

		return (
			<div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
				Loading...
			</div>
		)
	}

	/*id: ID!
	businessID: ID!
	// username: String
	acceptsBookings: Boolean
	// location: Location @connection(name: "CustomerLocation")
	// locationID: ID
	// services: [CustomerService] @connection(name: "CustomerServiceServiceCustomer")
	offDayExceptions: [AWSDate] #OffDays that will be worked anyway
	startDate: AWSDate
	endDate: AWSDate
	photo: S3Object
	createdAt: AWSDateTime
	updatedAt: AWSDateTime*/
	return (

		<React.Fragment>
			<PageHeading item={customer} isNew={id === 'new'} label={customer && customer.givenName && `${customer.givenName} ${customer.familyName}`}/>
			<div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
				<div className="md:grid md:grid-cols-3 md:gap-6">
					<div className="md:col-span-1">
					  <div className="px-4 sm:px-0">
						<h3 className="text-lg font-medium leading-6 text-gray-900">Customer Details</h3>
						<p className="mt-1 text-sm leading-5 text-gray-500">
						  This information will be displayed publicly.
						</p>
					  </div>
					</div>
					<CustomerForm />
				</div>
			</div>
			{ customer && <CustomerBookings /> }
		</React.Fragment>
		)
}


const mapStateToProps = (state) => {
	return {
	  customer: state.customer.item,
      services: state.service.list,
      teamMembers: state.teamMember.list,
      locations: state.location.list,
  }
}

const mapDispatchToProps = dispatch => ({
  createCustomer: (item) => dispatch(ACTIONS.createItem(item)),
  updateCustomer: (item) => dispatch(ACTIONS.updateItem(item)),
  getCustomer: (id) => dispatch(ACTIONS.getItem(id)),
  clearCustomer: () => dispatch(ACTIONS.clearItem()),

  // timesheetUpdateSaveStatus: (status) => dispatch(ACTIONS.timesheetUpdateSaveStatus(status))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Customer) 
import * as React from 'react';
import * as PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import classNames from 'clsx';
import { LAYOUT_MEDIA_QUERY } from '../constants';

const styles = ({ spacing }) => ({
  // button: {
  //   padding: spacing(0.8, 2),
  //   marginLeft: spacing(0.5),
  //   '&:first-child': {
  //     marginLeft: 0,
  //   },
  //   [`${LAYOUT_MEDIA_QUERY}`]: {
  //     fontSize: '0.75rem',
  //   },
  // },
});

const TodayButtonBase = ({
  setCurrentDate, classes, getMessage, className, ...restProps
}) => {
  const handleClick = () => {
    setCurrentDate(new Date());
  };
      {/*className={classNames(classes.button, className)}*/}
      {/*variant="outlined"*/}
  return (
    <button
      className="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150"
      onClick={handleClick}
    >
      {getMessage('today')}
    </button>
  );
};

TodayButtonBase.propTypes = {
  setCurrentDate: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  getMessage: PropTypes.func.isRequired,
};

TodayButtonBase.defaultProps = {
  className: undefined,
};

export const TodayButton = TodayButtonBase;

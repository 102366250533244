import * as React from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import AccessTime from '@material-ui/icons/AccessTime';
import Lens from '@material-ui/icons/Lens';
import { HOUR_MINUTE_OPTIONS, WEEKDAY_INTERVAL, viewBoundText } from '@devexpress/dx-scheduler-core';
import { getAppointmentColor, getResourceColor } from '../utils';
import Select from 'react-select';
import {connect} from 'react-redux';
import {Actions as ACTIONS} from 'store/booking/action';
import cleanDeep from 'clean-deep';
import {format, addMinutes} from 'date-fns';

const selectOptions = [
  {
    label: "Confirmed (C)",
    value: "Confirmed",
  },
  {
    label: "Not Confirmed (NC)",
    value: "Booked",
  },
  {
    label: "Not Answering (NA)",
    value: "NotAnswering",
  },
  {
    label: "Arrived (A)",
    value: "Present",
  },
  {
    label: "Served (S)",
    value: "Served",
  },
  {
    label: "Finished Early (FE)",
    value: "Served",
  },
  {
    label: "No Show (NS)",
    value: "NoShow",
  },
  {
    label: "Checked In [Mobile Unit] (CI)",
    value: "CheckedIn",
    queueOnly: true,
  },
  {
    label: "Being Served [Mobile Unit] (BS)",
    value: "BeingServed",
    queueOnly: true,
  },

]

const selectDefaultStyle = {
  control: styles => ({ ...styles, backgroundColor: 'white', border: 'none' })
};


const useStyles = makeStyles(({ spacing, palette, typography }) => ({
  content: {
    padding: spacing(1.5, 1),
    paddingTop: spacing(1),
    backgroundColor: palette.background.paper,
    boxSizing: 'border-box',
    ...typography.body2,
  },
  text: {
    display: 'inline-block',
  },
  title: {
    ...typography.h6,
    color: palette.text.secondary,
    fontWeight: typography.fontWeightBold,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  icon: {
    verticalAlign: 'middle',
    color: palette.action.active,
  },
  lens: {
    // color: resources => getAppointmentColor(300, getResourceColor(resources), palette.primary),
    width: spacing(4.5),
    height: spacing(4.5),
    verticalAlign: 'super',
    position: 'absolute',
    left: '50%',
    transform: 'translate(-50%,0)',
  },
  lensMini: {
    width: spacing(2.5),
    height: spacing(2.5),
  },
  textCenter: {
    textAlign: 'center',
    height: spacing(2.5),
  },
  dateAndTitle: {
    lineHeight: 1.4,
  },
  titleContainer: {
    paddingBottom: spacing(2),
  },
  contentContainer: {
    paddingBottom: spacing(1.5),
  },
  resourceContainer: {
    paddingBottom: spacing(0.25),
  },
  recurringIcon: {
    position: 'absolute',
    paddingTop: spacing(0.875),
    left: '50%',
    transform: 'translate(-50%,0)',
    color: palette.background.paper,
    width: spacing(2.625),
    height: spacing(2.625),
  },
  relativeContainer: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
}));

const TooltipContent = ({
  className,
  children,
  appointmentData,
  appointmentResources,
  formatDate,
  recurringIconComponent: RecurringIcon,
  updateBooking,
  ...restProps
}) => {
  const classes = useStyles(appointmentResources);
  const weekDays = viewBoundText(
    appointmentData.startDate, appointmentData.endDate, WEEKDAY_INTERVAL,
    appointmentData.startDate, 1, formatDate,
  );

// title: `${addonServiceLabels.join(', ')} for ${customerLabel}`,
//         customerName: customer && `${customer.givenName} ${customer.familyName}`,
//         customerPhone: customer && customer.phone,
//         customerEmail: customer && customer.email,
//         primaryService,
//         addonServices: `${addonServiceLabels.join(', ')}`,
//         members: [booking.teamMemberID],
//         // roomId: 1,
//         startDate: new Date(`${booking.date} ${booking.startTime}`),
//         endDate: new Date(`${booking.date} ${booking.endTime}`),
//         fee: booking.fee,
//         status: booking.status,
//         backgroundColor: primaryService.blockColour,

  return (
    <div
      className={classNames(classes.content, className)}
      {...restProps}
    >
      <Grid container alignItems="flex-start" className={classes.titleContainer}>
        <Grid item xs={2}>
          <div className={classes.relativeContainer}>
            <Lens className={classes.lens} style={{color:appointmentData.backgroundColor}} />
            {!!appointmentData.rRule && (
              <RecurringIcon className={classes.recurringIcon} />
            )}
          </div>
        </Grid>
        <Grid item xs={10}>
          <div>
            <div className={classNames(classes.title, classes.dateAndTitle)}>
              {/*{appointmentData.title}*/}
              {appointmentData.customerName}
              {appointmentData.customerPhone && `(${appointmentData.customerPhone})`}
            </div>
            <div className={classNames(classes.title, classes.dateAndTitle)}>
              Service: {appointmentData.primaryService ? appointmentData.primaryService.name : "No Primary Service"}
            </div>
            <div className={classNames(classes.title, classes.dateAndTitle)}>
              {appointmentData.addonServices}
            </div>
            <div className={classNames(classes.text, classes.dateAndTitle)}>
              {weekDays}
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid container alignItems="center" className={classes.contentContainer}>
        <Grid item xs={2} className={classes.textCenter}>
          <AccessTime className={classes.icon} />
        </Grid>
        <Grid item xs={10}>
          <div className={classes.text}>
            {`${formatDate(appointmentData.startDate, HOUR_MINUTE_OPTIONS)}
              - ${formatDate(appointmentData.endDate, HOUR_MINUTE_OPTIONS)}`}
          </div>
        </Grid>
      </Grid>
      <Grid container alignItems="center" className={classes.contentContainer}>
        <Grid item xs={2} className={classes.textCenter}>
          {/*<AccessTime className={classes.icon} />*/}
        </Grid>
        <Grid item xs={10}>
          
          <Select
            className="mt-1 block form-select w-full p-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
            styles={selectDefaultStyle}
            menuPlacement="top"
            value={selectOptions && selectOptions.find(item => item.value == appointmentData.status) || {value:"",label:""}}
            onChange={(selected) => {
                // change appointment status
                const booking = appointmentData.booking


                const item = {
                  id: booking.id,
                  _version: booking && booking._version,
                  status: selected.value
                }

                if (selected.label == "Finished Early (FE)"){
                  const fifteenInterval = Math.floor( new Date().getTime() / 1000 / 60 / 15)
                  const fifteenRounded = new Date(fifteenInterval * 15 * 60 * 1000)

                  const fifteenMinAfterStartTime = addMinutes(new Date(`${booking.date} ${booking.startTime}`),15)
                  const fifteenMinAfterStartTimeString = format(fifteenMinAfterStartTime,"HH:mm")

                  let endTime = format(fifteenRounded,'HH:mm')

                  if (endTime < booking.endTime){
                    item.endTime = endTime;
                    if (endTime < fifteenMinAfterStartTimeString) {
                      item.endTime = fifteenMinAfterStartTimeString;
                    }
                  }

                }

                appointmentData.status = selected.value

                updateBooking(cleanDeep(item))
              }
            }
            options={selectOptions.filter(s => !s.queueOnly || s.queueOnly == appointmentData.isQueue)}
          />
        </Grid>
      </Grid>
      <Grid container alignItems="center" className={classes.contentContainer}>
        <Grid item xs={2} className={classes.textCenter}>
          {/*<AccessTime className={classes.icon} />*/}
        </Grid>
        <Grid item xs={10}>
          {appointmentData.booking.note ? appointmentData.booking.note : ""}
        </Grid>
      </Grid>
      {appointmentResources.map(resourceItem => (
        <Grid container alignItems="center" className={classes.resourceContainer} key={`${resourceItem.fieldName}_${resourceItem.id}`}>
          <Grid item xs={2} className={classes.textCenter}>
            <div className={classes.relativeContainer}>
              <Lens
                className={classNames(classes.lens, classes.lensMini)}
                style={{ color: getAppointmentColor(300, resourceItem.color) }}
              />
            </div>
          </Grid>
          <Grid item xs={10}>
            <div className={classes.text}>
              {resourceItem.text}
            </div>
          </Grid>
        </Grid>
      ))}
      {children}
    </div>
  );
};

TooltipContent.propTypes = {
  appointmentData: PropTypes.object,
  appointmentResources: PropTypes.array,
  children: PropTypes.node,
  className: PropTypes.string,
  formatDate: PropTypes.func.isRequired,
  recurringIconComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
};

TooltipContent.defaultProps = {
  appointmentData: undefined,
  appointmentResources: [],
  className: undefined,
  children: undefined,
};

const mapStateToProps = (state) => {
  console.log('state',state && state.business)
    return {
      business: state.business.item,
  }
}

const mapDispatchToProps = dispatch => ({
  updateBooking: (item) => dispatch(ACTIONS.updateItem(item)),
});

export const Content = connect(
  mapStateToProps,
  mapDispatchToProps
)(TooltipContent)



import React from "react";

import {
          getService,
          listServices,
       } from 'graphql/queries'

import {
  createService,
  updateService,
  deleteService,
  createTeamMemberService,
  updateTeamMemberService,
} from 'graphql/mutations';

import {API} from '@aws-amplify/api'
import {Storage} from '@aws-amplify/storage'
import awsMobile from 'aws-exports';
import AWS from 'aws-sdk';
import {Actions as NOTIFICATIONACTIONS} from 'store/notifications/action';

// types of action
const Types = {
  SERVICE_GET_ITEM: "SERVICE_GET_ITEM",
  SERVICE_UPDATE_ITEM: "SERVICE_UPDATE_ITEM",
  SERVICE_CREATE_ITEM: "SERVICE_CREATE_ITEM",
  SERVICE_DELETE_ITEM: "SERVICE_DELETE_ITEM",
  SERVICE_CLEAR_ITEM: "SERVICE_CLEAR_ITEM",
  SERVICE_LOADING: "SERVICE_LOADING",
  SERVICE_GET_LIST: "SERVICE_GET_LIST",
  SERVICE_UPDATE_PHOTO: "SERVICE_UPDATE_PHOTO",
};

const dispatchLoading =  loading => ({
  type: Types.SERVICE_LOADING,
  loading
});

const clearItem = response => ({
  type: Types.SERVICE_CLEAR_ITEM
});

const dispatchGetItem = response => ({
  type: Types.SERVICE_GET_ITEM,
  payload: response
});

const getItem = (id) => {
  console.log('getItem1',getItem)
  return (dispatch, getState) => {
    dispatch(dispatchLoading(true))

    const variables = {id}
    const request = API.graphql({query: getService, variables, authMode: "AMAZON_COGNITO_USER_POOLS"})
    console.log('getItem2',getItem)
    request.then((response) => {
        dispatch(dispatchGetItem(response))
        dispatch(dispatchLoading(false))
    });
    
  }
}

const dispatchUpdateItem = response => ({
  type: Types.SERVICE_UPDATE_ITEM,
  payload: response
});

const updateItem = (item) => {
  return (dispatch, getState) => {


    const state = getState()

    const photo = state.service.item.photo
    item.photo = photo

    const variables = {input: item}
    const request = API.graphql({query: updateService, variables, authMode: "AMAZON_COGNITO_USER_POOLS"})

    request.then((response) => {
      console.log('responseTEST',response.data.updateService)
      
        dispatch(dispatchUpdateItem(response))

        //TODO Plan notification logic/errors
        dispatch(NOTIFICATIONACTIONS.addNotification({
          title: <React.Fragment><strong>{response.data.updateService.name}</strong> has been updated</React.Fragment>,
          message: response.data.updateService.enabled ? `Customers can book this service` : `This service is disabled. It cannot be booked`,
          icon: `success`,
        },
          3000
        ))
    });
    
  }
}

const dispatchDeleteItem = response => ({
  type: Types.SERVICE_DELETE_ITEM,
  payload: response
});

const deleteItem = (id,_version,history) => {
  return (dispatch, getState) => {

    console.log('businessID',getState('businessID'))

    const businessID = getState().businessID
    const variables = {input: {id, _version }}
    const request = API.graphql({query: deleteService, variables, authMode: "AMAZON_COGNITO_USER_POOLS"})

    request.then((response) => {
      console.log('responseTEST',response.data.deleteService)

        // go to main page as it's been deleted
        history.push(`/services`) 
        dispatch(dispatchDeleteItem(id))

        //TODO Plan notification logic/errors
        dispatch(NOTIFICATIONACTIONS.addNotification({
          title: <React.Fragment><strong>{response.data.deleteService.name}</strong> has been deleted</React.Fragment>,
          message: `This service is no longer available.`,
          icon: `success`,
        },2000))
    });
  }
}

const dispatchCreateItem = response => ({
  type: Types.SERVICE_CREATE_ITEM,
  payload: response
});

const createItem = (item) => {
  return (dispatch, getState) => {

    console.log('businessID',getState('businessID'))

    const businessID = getState().businessID
    const variables = {input: {...item, businessID}}
    const request = API.graphql({query: createService, variables, authMode: "AMAZON_COGNITO_USER_POOLS"})

    request.then((response) => {
      console.log('responseTEST',response.data.createService)

        dispatch(dispatchCreateItem(response))

        //TODO Plan notification logic/errors
        dispatch(NOTIFICATIONACTIONS.addNotification({
          title: <React.Fragment><strong>{response.data.createService.name}</strong> has been created</React.Fragment>,
          message: response.data.createService.enabled ? `You team can now start to provide this service.` : `This service is disabled and cannot be booked.`,
          icon: `success`,
        },2000))
    });
    
  }
}

const updateList = response => ({
  type: Types.SERVICE_GET_LIST,
  payload: response
});

const getList = (limit, nextToken) => {
  return (dispatch, getState) => {

    const variables = {limit, nextToken}
    const request = API.graphql({query: listServices, variables, authMode: "AMAZON_COGNITO_USER_POOLS"})

    request.then((response) => {
        dispatch(updateList(response))
    });
    
  }
}

const updatePhoto = photo => ({
  type: Types.SERVICE_UPDATE_PHOTO,
  photo
});

const handleUpload = (file, preFileName) => {
  return (dispatch, getState) => {

    const state = getState()

    const businessID = state.businessID

    console.log(file,'show me the file!')

    const {
      aws_user_files_s3_bucket_region: region,
      aws_user_files_s3_bucket: bucket
    } = awsMobile


    console.log(bucket,'this is the bucket!')

    const { name: fileName, type: mimeType } = file;

    const filePath = `service/${state.service.item.id}/${fileName}`  

    const key = preFileName ? preFileName : filePath

    const fileForUpload = {
      bucket,
      key,
      region,
      type: mimeType
    };

    dispatch(NOTIFICATIONACTIONS.addNotification({
      title: <React.Fragment><strong>Image</strong> is currently uploading. Please wait...</React.Fragment>,
      // message: response.data.updateLocation.status == "Active" ? `This location is raring and ready to go.` : `This location is disabled and cannot be booked.`,
      icon: `success`,
    },3000))


    const request = Storage.put(key, file, {
      level: 'public',
      contentType: mimeType,
      progressCallback: (e) => {
          console.log(e,'this is a callback! from storage')
      }
    });


    request.then(() => {

      dispatch(updatePhoto({
        key,
        bucket
      }))


      dispatch(NOTIFICATIONACTIONS.addNotification({
        title: <React.Fragment><strong>Photo</strong> has been uploaded</React.Fragment>,
        // message: response.data.updateLocation.status == "Active" ? `This location is raring and ready to go.` : `This location is disabled and cannot be booked.`,
        icon: `success`,
      },3000))

    });
  }
}


const Actions = {
  getItem,
  createItem,
  updateItem,
  deleteItem,
  getList,
  clearItem,
  handleUpload
}

// exports = {
//   Types,
//   Actions
// }

export {Types, Actions}

// exports.Types = Types
// exports.Actions = Actions
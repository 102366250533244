import React, { useState, useEffect } from "react";
import * as PropTypes from 'prop-types';
import classNames from 'clsx';
import ToolbarMUI from '@material-ui/core/Toolbar';
import { withStyles } from '@material-ui/core/styles';
import Select from 'react-select';
import {connect} from 'react-redux';
import {Actions as ACTIONS} from 'store/scheduler/action';
import {Actions as BOOKINGACTIONS} from 'store/booking/action';
import {
  Plugin,
  Template,
  TemplatePlaceholder,
  TemplateConnector
} from "@devexpress/dx-react-core";
import { getMessagesFormatter } from '@devexpress/dx-core';
import { addDays, subDays, format } from 'date-fns'
import DatePicker from 'react-datepicker';
import { useHistory } from "react-router-dom";

import {API} from '@aws-amplify/api'

import {
          customerByBusinessByPhone,
       } from 'graphql/queries'

import AsyncSelect from 'react-select/async';

import "react-datepicker/dist/react-datepicker.css";
import "styles/datepicker.css";

const IndicatorsContainer = () => (
	<div style={{ display: "flex", padding:"6.5px"}}>
		<svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
		  <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"/>
		</svg>
	</div>
)
	
const selectDefaultStyle = {
	// radius 0.375rem
  control: styles => ({ 
  	...styles, 
  	backgroundColor: 'white', 
  	border: 'none',
  	padding: 0,
  	minHeight: 'auto',
  	height: '29px',
  }),
  indicatorSeparator:  (provided, state) => ({
  	display: "none",
  }),
  indicatorsContainer:  (provided, state) => ({
  	// icon: ``,
  }),
  valueContainer:  (provided, state) => ({
    // ...provided,
    padding: "0",
    display: "block",
  }),
  placeholder:  (provided, state) => ({
    // ...provided,
    padding: "0",
    display: "block",
    padding: "6.5px 13px",
    fontSize: "0.875rem",
  }),
  singleValue:  (provided, state) => ({
    // ...provided,
    padding: "6.5px 13px",
    fontSize: "0.875rem",
  }),
  input:  (provided, state) => ({
    ...provided,
    lineHeight: "0",
    display: "block",
    padding: "6.5px 13px",
    fontSize: "0.875rem",
    top: 0,
    position: "absolute",
  }),
  option:  (provided, state) => ({
    ...provided,
    lineHeight: "1.25rem",
    fontSize: "0.875rem",
  }),
  menu: (provided, state) => ({
    ...provided,
    zIndex: 999999,
    // lineHeight: "14px",
  }),
};

const pluginDependencies = [
  { name: 'Toolbar' },
  { name: 'ViewState' },
];

const defaultMessages = {
  today: 'Today',
};



const BaseCustomMobileHeader = ({
	business,
	locations,
	setSchedulerLocation,
	setSchedulerFilter,
	filter,
	getBookingsByLocation,
	getUnavailability,
	getAvailabilityException,
	location,
	messages,
	customers,
	thisUser,
	permissions,
	businessID,
	...restProps
}) => {
  	let history = useHistory();

  	const [currentDate, setCurrentDateState] = useState(localStorage.getItem('calendarBookingDate') ? localStorage.getItem('calendarBookingDate') : format(new Date(),'yyyy-MM-dd'));

	const updateBookings = (currentDateOption) => { 
		// console.log('update',updateBookings)

		const variables = {
	        locationID: location.value,
	        date: {
	          eq: currentDateOption
	        },
	        limit: 1000,
	    }

		getBookingsByLocation(variables)

		const unavailabilityVariables = {
	        businessID: business.id,
	        date: {
	          eq: currentDateOption
	        },
	        limit: 1000,
	    }

		getUnavailability(unavailabilityVariables)
		getAvailabilityException(unavailabilityVariables)
	}

	let currentDateOption = currentDate



	useEffect(() => {
		if (locations && locations.length > 0 && !location){

			console.log('locationToUse User',thisUser)
	
			const locationToUse = thisUser ? locations.find(item => item.id == thisUser.locationID) : locations[0]

			const locationDeets = {value: locationToUse.id, label: locationToUse.name}
			console.log('locationToUse',locationToUse,locationDeets)

			setSchedulerLocation(locationDeets)
		}
	},[locations && locations.length]);

	useEffect(() => {
		if (location){
			updateBookings(currentDateOption)
		}
	},[location && location.value]);

	const locationOptions = locations && 
							locations
								.filter(location => (permissions !== "OwnBookings" || location.id == thisUser.locationID))
								.map(location => ({value: location.id, label: location.name}))


    const getMessage = getMessagesFormatter({ ...defaultMessages, ...messages });

    const maxDate = addDays(new Date(), business && business.allowBookingsDayAdvance || 90)


			{/*console.log('setters',setters)*/}

	const maxDateReached = new Date(currentDate).getTime() >= maxDate

      const setCurrentDate = nextDate => {

      	localStorage.setItem('calendarBookingDate',nextDate)
      	setCurrentDateState(nextDate)

      	{/*console.log('nextDate',nextDate)*/}
      	// update from DB
      	updateBookings(nextDate);

      };

      const ExampleCustomInput = ({ value, onClick }) => (
	    <button onClick={onClick} type="button" class="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150">
        	{value} {/*{currentDate && format(new Date(currentDate),'dd/MM/yyyy')}*/}
      	</button>
	  );

	  const filterOptions = [
        	{
        		value: "Booked,Confirmed,Present,Served,BeingServed,FinishedEarly,Queueing,CheckedIn,NotAnswering,Paid,ReadyForPayment",
        		label: "Show Active",
        	},
        	{
        		value: "NoShow",
        		label: "Show No Show",
        	},
          {
            label: "Not Answering",
            value: "NotAnswering",
          },
        	{
        		value: "Cancelled",
        		label: "Show Cancelled",
        	},
        	{
        		value: "Confirmed",
        		label: "Show Confirmed",
        	},
        	{
        		value: "Booked",
        		label: "Show Unconfirmed",
        	},
        	{
        		value: "Present",
        		label: "Show Arrived",
        	}
        ]

      return (
		    <div className="sm:px-12 lg:px-12" style={{width: "100%"}}>
		      {permissions !== "OwnBookings" && 
			      <div className="pt-6 grid grid-cols-12 gap-12">
			        <div className="col-span-6 col-start-7 md:col-span-3 md:col-start-10">
			          <AsyncSelect
			              className="block form-select w-full p-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
			              styles={selectDefaultStyle}
                          defaultOptions={[]}
                          loadOptions={(inputValue, callback) => {

                            console.log('loadOptions',inputValue)

                            const beginsWith = inputValue.substr(0,1) == "+" ? 
                                                      inputValue :
                                                      inputValue.substr(0,2) == "00" ? 
                                                        `+${inputValue.substr(2)}` :
                                                        `+356${inputValue}`

                            const variables = {
                              phone: {beginsWith}, 
                              businessID
                            }
                            const request = API.graphql({query: customerByBusinessByPhone, variables, authMode: "AMAZON_COGNITO_USER_POOLS"})

                            request.then( ({data}) => {
                                console.log('response',data)

                                const customers = data.customerByBusinessByPhone.items

                                const options = customers && customers.map(customer => ({
                                  value: customer.id,
                                  label: `${customer.givenName} ${customer.familyName} (${customer.phone})`
                                }))

                                callback(options);
                            });
                          }}
			              placeholder="Find client..."
			              onChange={(selectedCustomer) => {
			            	history.push("/customer/" + selectedCustomer.value);
			              }}
			              components={{IndicatorsContainer}}
			            />
			        </div>
			      </div>
			  }
		      <div className="py-6 grid grid-cols-12 gap-12">
		        <div className="col-span-6 md:col-span-3">
		          <Select
		            className="block form-select w-full p-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
		            styles={selectDefaultStyle}
		            value={location}
		            onChange={setSchedulerLocation}
		            options={locationOptions}
		            components={{IndicatorsContainer}}
		          />
		        </div>
		        <div className="col-span-6 col-start-7 md:col-span-4 md:col-start-5">
		          <span class="relative z-0 inline-flex shadow-sm">
		            <button type="button" class="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150"
		            	onClick={() => {
		            		const newDate = subDays(new Date(currentDate),1)
		                	setCurrentDate(format(newDate,'yyyy-MM-dd'));
		            	}}>
		              <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
		                <path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd"/>
		              </svg>
		            </button>
		            <button
		              className="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150"
		              onClick={() => {
		                setCurrentDate(format(new Date(),'yyyy-MM-dd'));
		              }}
		            >
		              {getMessage('today')}
		            </button>
		            
		            <DatePicker
                      dateFormat="EEE dd/MM/yyyy" 
                      selected={new Date(currentDate)}
                      onChange={(selectedDate) => {
		                setCurrentDate(format(selectedDate,'yyyy-MM-dd'));
                      }}
                      minDate={new Date("2020-04-01")}
                      customInput={<ExampleCustomInput />}
                      maxDate={maxDate}
                      className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      popperPlacement="bottom-end"
                      popperModifiers={{
                        offset: {
                          enabled: true,
                          //offset: "77px, 0px"
                        }
                      }}
                    />
		            <button disabled={maxDateReached} style={{opacity: maxDateReached ? 0.5 : 1}} type="button" class="-ml-px relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150" onClick={() => {
	                	const newDate = addDays(new Date(currentDate),1)

	                	if (newDate.getTime() <= maxDate.getTime()){
	                		// only allow next date if not going over maximum
	                		setCurrentDate(format(newDate,'yyyy-MM-dd'));
	                	} else {
	                		// alert that max date is blocked
	                	}
	            	}}>
		              <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
		                <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"/>
		              </svg>
		            </button>
		          </span>
		        </div>

		        <div className="col-span-6 col-start-7 md:col-span-3 md:col-start-10">
		          <Select
		            className="block form-select w-full p-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
		            styles={selectDefaultStyle}
		            placeholder="Show..."
		            onChange={setSchedulerFilter}
		            value={filter}
		            options={filterOptions}
		            components={{IndicatorsContainer}}
		          />
		        </div>
		      </div>
		    </div>
      );

};


const mapStateToProps = (state) => {
    return {
      businessID: state.businessID,
      business: state.business.item,
      location: state.scheduler.location,
      filter: state.scheduler.filter,
      locations: state.location.list,
      customers: state.customer.list && state.customer.list.map(item => ({ label: `${item.givenName} ${item.lastName} (${item.phone})`,value: item.id})),
      username: state.userDetails.username,
      permissions: state.permissions,
      thisUser: state.teamMember.list && state.teamMember.list.find(item => item.username == state.userDetails.username)
  }
}

const mapDispatchToProps = dispatch => ({
  setSchedulerFilter: (variables) => dispatch(ACTIONS.setSchedulerFilter(variables)),
  setSchedulerLocation: (variables) => dispatch(ACTIONS.setSchedulerLocation(variables)),
  getBookingsByLocation: (variables) => dispatch(BOOKINGACTIONS.getBookingsByLocation(variables)),
  getUnavailability: (variables) => dispatch(BOOKINGACTIONS.getUnavailability(variables)),
  getAvailabilityException: (variables) => dispatch(BOOKINGACTIONS.getAvailabilityException(variables)),
});

export const CustomMobileHeader = connect(
  mapStateToProps,
  mapDispatchToProps
)(BaseCustomMobileHeader)

import React, { useState, useEffect } from "react";
import { useForm } from 'react-hook-form'

import {connect} from 'react-redux';
import _ from 'lodash';

import {Actions as ACTIONS} from 'store/dashboard/action';
import PageHeading from 'components/PageHeading';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useLocation,
  useParams
} from "react-router-dom";

import {format, addDays} from 'date-fns';
import DatePicker from 'react-datepicker';
import Select from 'react-select';

import "react-datepicker/dist/react-datepicker.css";
import "styles/datepicker.css";
import { useHistory } from "react-router-dom";

import Chart from '../dashboard/Chart';

const paymentOptions = [
                {
                  label: "Cash",
                  value: "cash",
                },
                {
                  label: "Card",
                  value: "card",
                },
                {
                  label: "Trust",
                  value: "trust",
                },
                {
                  label: "Wamo",
                  value: "wamo",
                },
                {
                  label: "Voucher",
                  value: "voucher",
                },
                {
                  label: "Tips",
                  value: "tip",
                }
              ]

const Dashboard = ({
  getSales,
  reportData,
  teamMemberOptions,
}) => {

  const [startDate, setStartDate] = useState(addDays(new Date(),-7));
  const [endDate, setEndDate] = useState(new Date());
  const [teamMember, setTeamMember] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const onDateChange = dates => {
    console.log('dates',dates)
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
  };

  useEffect(() => {
    getSales({
      limit: 1000, 
      nextToken: null, 
      from: format(startDate,"yyyy-MM-dd"), 
      to: format(endDate,"yyyy-MM-dd"), 
      reportType: 'teamMemberCash', 
    })
    getSales({
      limit: 1000, 
      nextToken: null, 
      from: format(startDate,"yyyy-MM-dd"), 
      to: format(endDate,"yyyy-MM-dd"), 
      reportType: 'teamMemberWamo', 
    })
    getSales({
      limit: 1000, 
      nextToken: null, 
      from: format(startDate,"yyyy-MM-dd"), 
      to: format(endDate,"yyyy-MM-dd"), 
      reportType: 'teamMemberTrust', 
    })
    getSales({
      limit: 1000, 
      nextToken: null, 
      from: format(startDate,"yyyy-MM-dd"), 
      to: format(endDate,"yyyy-MM-dd"), 
      reportType: 'teamMemberCard', 
    })
    getSales({
      limit: 1000, 
      nextToken: null, 
      from: format(startDate,"yyyy-MM-dd"), 
      to: format(endDate,"yyyy-MM-dd"), 
      reportType: 'teamMemberVoucher', 
    })
    getSales({
      limit: 1000, 
      nextToken: null, 
      from: format(startDate,"yyyy-MM-dd"), 
      to: format(endDate,"yyyy-MM-dd"), 
      reportType: 'teamMemberTip', 
    })
  },[startDate, endDate])

	let history = useHistory();


  
  const ExampleCustomInput = ({ value, onClick }) => (
      <button 
        onClick={onClick} 
        type="button" 
        class="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150"
      >
        {value} {/*{currentDate && format(new Date(currentDate),'dd/MM/yyyy')}*/}
      </button>
  )

  return (
    <React.Fragment>
      <PageHeading/>
      <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">

        <span class="relative z-10 inline-flex shadow-sm" style={{lineHeight:"31px"}}>
          <label style={{marginLeft:"10px",marginRight:"5px"}}>From </label>
          <DatePicker
                dateFormat="dd/MM/yyyy" 
                customInput={<ExampleCustomInput />}
            selected={startDate}
            onChange={date => setStartDate(date)}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"             
                popperPlacement="bottom-end"
                popperModifiers={{
                    offset: {
                      enabled: true,
                      //offset: "77px, 0px"
                    }
                }}
          />
          <label style={{marginRight:"5px", marginLeft:"5px"}}> to </label> 
          <DatePicker
                dateFormat="dd/MM/yyyy" 
                customInput={<ExampleCustomInput />}
            selected={endDate}
            onChange={date => setEndDate(date)}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"popperPlacement="bottom-end"
                popperModifiers={{
                    offset: {
                      enabled: true,
                      //offset: "77px, 0px"
                    }
                }}
          />

          <label style={{marginRight:"5px", marginLeft:"5px", lineHeight: 1.2}}>Team Member</label> 
          <span style={{width: 200}}>
            <Select 
              options={teamMemberOptions}
              isMulti
              onChange={(selected) => {
                setTeamMember(selected)
              }}
            />
          </span>

          <label style={{marginRight:"5px", marginLeft:"5px", lineHeight: 1.2}}>Payment Method</label> 
          <span style={{width: 200}}>
            <Select 
              options={paymentOptions}
              isMulti
              onChange={(selected) => {
                setPaymentMethod(selected)
              }}
            />
          </span>
        </span>



                        

        <div class="flex flex-col mt-5">
            <div className="flex flex-col">
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Date
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Team Member
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Payment Method
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Amount
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {reportData
                          .filter(salesRow => !teamMember || teamMember.map(t => t.value).includes(salesRow.teamMemberID))
                          .filter(salesRow => !paymentMethod || paymentMethod.map(t => t.value).includes(salesRow.paymentMethod))
                          .map((salesRow, rowIndex) => (
                          <tr key={salesRow.id} className={rowIndex % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{salesRow.date}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{salesRow.teamMemberName}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{paymentOptions?.find?.(p => p.value == salesRow.paymentMethod).label}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{salesRow.amount/100}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
        </div>


        <div class="flex flex-col mt-5">
            <div className="flex flex-col">
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Team Member
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Total Sales
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {teamMember && teamMember
                          .map((t, rowIndex) => {

                            const filteredData = reportData
                                                  .filter(salesRow => t.value == salesRow.teamMemberID)
                                                  .filter(salesRow => !paymentMethod || paymentMethod.map(p => p.value).includes(salesRow.paymentMethod))
                            return (
                              <tr key={t.value} className={rowIndex % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{t.label}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{_.sumBy(filteredData,'amount')/100}</td>
                              </tr>
                            )
                        })}
                        {!teamMember &&
                          [1].map((t, rowIndex) => {

                            const filteredData = reportData
                                                  .filter(salesRow => !paymentMethod || paymentMethod.map(p => p.value).includes(salesRow.paymentMethod))
                            return (
                              <tr key={t.value} className={rowIndex % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">All Team</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{_.sumBy(filteredData,'amount')/100}</td>
                              </tr>
                            )
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
    </React.Fragment>
    )
}


const mapStateToProps = (state) => {

  // merge data into structured format for report


  let reportData = []

  const teamMembers = state.teamMember.list

  const addToData = (i, paymentMethod) => {
    const teamMember = teamMembers.find(t => t.id == i.value)

    const alreadyExist = reportData.find(r => r.teamMemberID == i.value && r.paymentMethod == paymentMethod && r.date == i.date)

    if (!alreadyExist){
      reportData.push({
        amount: i.amount,
        teamMemberID: i.value,
        teamMemberName: `${teamMember && teamMember.givenName}${teamMember && teamMember.lastName ? ` ${teamMember.lastName}`: ``}`,
        paymentMethod,
        date: i.date
      })
    } else {
      alreadyExist.amount += i.amount;
    }
  }

  state.dashboard && state.dashboard.teamMemberCash && state.dashboard.teamMemberCash.map(i => addToData(i,"cash"))
  state.dashboard && state.dashboard.teamMemberWamo && state.dashboard.teamMemberWamo.map(i => addToData(i,"wamo"))
  state.dashboard && state.dashboard.teamMemberTrust && state.dashboard.teamMemberTrust.map(i => addToData(i,"trust"))
  state.dashboard && state.dashboard.teamMemberCard && state.dashboard.teamMemberCard.map(i => addToData(i,"card"))
  state.dashboard && state.dashboard.teamMemberVoucher && state.dashboard.teamMemberVoucher.map(i => addToData(i,"voucher"))
  state.dashboard && state.dashboard.teamMemberTip && state.dashboard.teamMemberTip.map(i => addToData(i,"tip"))

  return {
    reportData: _.orderBy(reportData,['date','teamMemberName','paymentMethod']),
    teamMemberOptions: state.teamMember && state.teamMember.list && state.teamMember.list
    .filter(t => t.status != "Disabled")
    .map(t => ({
      value: t.id,
      label: `${t && t.givenName}${t && t.lastName ? ` ${t.lastName}`: ``}`,
    }))
  }
}

const mapDispatchToProps = dispatch => ({
  getSales: (input) => dispatch(ACTIONS.getSales(input)),
  // timesheetUpdateSaveStatus: (status) => dispatch(ACTIONS.timesheetUpdateSaveStatus(status))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard)
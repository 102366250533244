import React from "react";
import {
          getBusiness,
       } from 'graphql/queries'

import {
  createBusiness,
  updateBusiness,
} from 'graphql/mutations';
       
import {API} from '@aws-amplify/api'
import {Storage} from '@aws-amplify/storage'
import {Auth} from '@aws-amplify/auth'
import awsMobile from 'aws-exports';
import AWS from 'aws-sdk';
import {Actions as NOTIFICATIONACTIONS} from 'store/notifications/action';

// types of action
const Types = {
  BUSINESS_GET_ITEM: "BUSINESS_GET_ITEM",
  BUSINESS_UPDATE_ITEM: "BUSINESS_UPDATE_ITEM",
  BUSINESS_CREATE_ITEM: "BUSINESS_CREATE_ITEM",
  BUSINESS_LOADING: "BUSINESS_LOADING",
  BUSINESS_USER_DETAILS: "BUSINESS_USER_DETAILS",
  BUSINESS_UPDATE_LOGO: "BUSINESS_UPDATE_LOGO",
};

const dispatchLoading =  loading => ({
  type: Types.BUSINESS_LOADING,
  loading
});

const dispatchUserDetails = payload => ({
  type: Types.BUSINESS_USER_DETAILS,
  payload
});

const dispatchGetItem = response => ({
  type: Types.BUSINESS_GET_ITEM,
  payload: response
});

const getItem = (id) => {
  console.log('getItem1',getItem)
  return async (dispatch, getState) => {
    dispatch(dispatchLoading(true))

    const userInfo = await Auth.currentUserInfo();
    dispatch(dispatchUserDetails(userInfo))

    const variables = {id: "e0d51e18-0448-4b1f-b2af-bf8adf7174b7"}
    const request = API.graphql({query: getBusiness, variables, authMode: "AMAZON_COGNITO_USER_POOLS"})
    console.log('getItem2',getItem)
    request.then((response) => {
        dispatch(dispatchGetItem(response))
        dispatch(dispatchLoading(false))
    });
    
  }
}

const dispatchUpdateItem = response => ({
  type: Types.BUSINESS_UPDATE_ITEM,
  payload: response
});

const updateItem = (item) => {
  return (dispatch, getState) => {

    const variables = {input: item}
    const request = API.graphql({query: updateBusiness, variables, authMode: "AMAZON_COGNITO_USER_POOLS"})

    request.then((response) => {
        dispatch(dispatchUpdateItem(response))
    });

    dispatch(NOTIFICATIONACTIONS.addNotification({
      title: "Business Details have been saved",
      message: ``,
      icon: `success`,
    },
      3000
    ))
    
  }
}

const dispatchCreateItem = response => ({
  type: Types.BUSINESS_CREATE_ITEM,
  payload: response
});

const createItem = (item) => {
  return (dispatch, getState) => {

    const variables = {input: item}
    const request = API.graphql({query: createBusiness, variables, authMode: "AMAZON_COGNITO_USER_POOLS"})

    request.then((response) => {
        dispatch(dispatchGetItem(response))
    });

    dispatch(NOTIFICATIONACTIONS.addNotification({
      title: "Business Details have been saved",
      message: ``,
      icon: `success`,
    },
      3000
    ))
    
  }
}



const updateLogo = logo => ({
  type: Types.BUSINESS_UPDATE_LOGO,
  logo
});

const handleUpload = (file, preFileName) => {
  return (dispatch, getState) => {

    const state = getState()

    const businessID = state.businessID

    console.log(file,'show me the file!')

    const {
      aws_user_files_s3_bucket_region: region,
      aws_user_files_s3_bucket: bucket
    } = awsMobile


    console.log(bucket,'this is the bucket!')

    const { name: fileName, type: mimeType } = file;

    const filePath = `business/${businessID}/${fileName}`  

    const key = preFileName ? preFileName : filePath

    const fileForUpload = {
      bucket,
      key,
      region,
      type: mimeType
    };

    dispatch(NOTIFICATIONACTIONS.addNotification({
      title: <React.Fragment><strong>Logo</strong> is currently uploading. Please wait...</React.Fragment>,
      // message: response.data.updateLocation.status == "Active" ? `This location is raring and ready to go.` : `This location is disabled and cannot be booked.`,
      icon: `success`,
    },3000))


    const request = Storage.put(key, file, {
      level: 'public',
      contentType: mimeType,
      progressCallback: (e) => {
          console.log(e,'this is a callback! from storage')
      }
    });


    request.then(() => {

      dispatch(updateLogo({
        key,
        bucket
      }))


      dispatch(NOTIFICATIONACTIONS.addNotification({
        title: <React.Fragment><strong>Logo</strong> has been uploaded</React.Fragment>,
        // message: response.data.updateLocation.status == "Active" ? `This location is raring and ready to go.` : `This location is disabled and cannot be booked.`,
        icon: `success`,
      },3000))

    });
  }
}

const Actions = {
  getItem,
  handleUpload,
  // createItem,
  updateItem,
}

// exports = {
//   Types,
//   Actions
// }

export {Types, Actions}

// exports.Types = Types
// exports.Actions = Actions
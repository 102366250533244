import {Types} from "./action";
import _ from "lodash";
// import {NotificationManager} from 'react-notifications';

// import cleanDeep from 'clean-deep';


const defaultState = {
  location: {}
};

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case Types.LOCATION_GET_SCHEDULE_EXCEPTION: {

      let newState = {...state};

      newState.location.scheduleExceptions = action.payload.data.locationScheduleExceptionByLocationByDate.items;

      return newState;
    }
    case Types.LOCATION_GET_ITEM: {

      let newState = {...state};

      newState.location.item = action.payload.data.getLocation;

      return newState;
    }
    case Types.LOCATION_CLEAR_ITEM: {

      let newState = {...state};

      newState.location.item = {};

      return newState;
    }
    case Types.LOCATION_GET_LIST: {

      let newState = {...state};

      const existingList = newState.location.list || []

      const merged = _(existingList) // start sequence
        .keyBy('id') // create a dictionary of the 1st array
        .merge(_.keyBy(action.payload.data.listLocations.items, 'id')) // create a dictionary of the 2nd array, and merge it to the 1st
        .values() // turn the combined dictionary to array
        .value(); // get the value (array) out of the sequence


      // TODO add sorting facility
      newState.location.list = _.sortBy(merged,'name') 


      newState.location.listNextToken = action.payload.data.listLocations.nextToken;
      newState.location.listStartedAt = action.payload.data.listLocations.startedAt;
      console.log('updatedLocationList',newState.location.list)

      return newState;
    }
    case Types.LOCATION_DELETE_IMAGE: {

      let newState = {...state};

      const deletedItem = action.payload;
      const id = deletedItem.id
      
      newState.location.item.photos.items = _.reject(newState.location.item.photos.items,{id})
      
      // const

      if (newState.location.list){
        const itemIndex = newState.location.list.findIndex(item => item.id == newState.location.item.id)
        newState.location.list[itemIndex] = newState.location.item;
        newState.location.list = _.sortBy(newState.location.list,'name')
      }

      console.log('Photo deleted',newState.location.item)

      return newState;
    }
    case Types.LOCATION_UPLOAD_IMAGE: {

      let newState = {...state};

      const uploadedItem = action.payload;
      if (newState.location.item && newState.location.item.photos.items && Array.isArray(newState.location.item.photos.items)){
        newState.location.item.photos.items.push(uploadedItem);
      } else {
        newState.location.item.photos.items = [uploadedItem]
      }

      if (newState.location.list){
        const itemIndex = newState.location.list.findIndex(item => item.id == newState.location.item.id)
        newState.location.list[itemIndex] = newState.location.item;
        newState.location.list = _.sortBy(newState.location.list,'name')
      }

      console.log('new Photo Uploaded',newState.location.item)

      return newState;
    }
    case Types.LOCATION_UPDATE_ITEM: {

      let newState = {...state};

      const updatedItem = action.payload.data.updateLocation;
      newState.location.item = updatedItem;

      if (newState.location.list){
        const itemIndex = newState.location.list.findIndex(item => item.id == updatedItem.id)
        newState.location.list[itemIndex] = updatedItem;
        newState.location.list = _.sortBy(newState.location.list,'name')
      }

      console.log('newLocationUpdated',newState.location.item)

      return newState;
    }
    case Types.LOCATION_CREATE_ITEM: {

      let newState = {...state};

      const createdItem = action.payload.data.createLocation;
      newState.location.item = createdItem;
      if (newState.location.list){
        newState.location.list.push(createdItem);
        newState.location.list = _.sortBy(newState.location.list,'name')
      }

      console.log('newLocationUpdated',newState.location.item)

      return newState;
    }
    default:
      return state;
  }
};

export default reducer;
import {Types} from "./action";
import _ from "lodash";
// import {NotificationManager} from 'react-notifications';

// import cleanDeep from 'clean-deep';


const defaultState = {
  dashboard: {}
};

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case Types.DASHBOARD_GET_STATS: {

      let newState = {...state};

      // todo plan for potential pagination
      newState.dashboard[action.reportType] = action.data

      return newState;
    }
    default:
      return state;
  }
};

export default reducer;
import React, { useState, useEffect } from "react";
import { useForm } from 'react-hook-form'
import { useParams, useHistory} from "react-router";
import countryCodes from 'country-codes-list';

import _ from 'lodash';
import {connect} from 'react-redux';

import {Actions as ACTIONS} from 'store/location/action';
import PageHeading from 'components/PageHeading';
import useScript from 'hooks/useScript';
import { v4 as uuidv4 } from 'uuid';
import LocationPicker from 'react-location-picker';
const cleanDeep = require('clean-deep');

const Address = ({prefix, address, register, setValue, withAttention}) => {

	const streetAddress = address && [address.addressLine1,address.addressLine2,address.addressLine3,address.addressLine4].join('\n')

	const myCountryCodesObject = countryCodes.customList('countryNameEn', '{countryNameEn}')


	register({ name: `${prefix}.lat`, type: 'custom'}, { })
	register({ name: `${prefix}.lng`, type: 'custom'}, { })

	console.log('myCountryCodesObject',myCountryCodesObject)

	/* Default position */
	const defaultPosition = {
	  lat: address && address.lat || 0,
	  lng: address && address.lng || 0
	};

	const handleLocationChange = ({ position, address, places }) => {
		console.log('handlechange', position, address, places)
		setValue(`${prefix}.lat`,position.lat)
		setValue(`${prefix}.lng`,position.lng)
	}

	return (
		<React.Fragment>

			<input type='hidden' name={`${prefix}id`} defaultValue={address && address.id}/>

		  <div className="col-span-6 sm:col-span-4">
	        <label htmlFor="country" className="block text-sm font-medium leading-5 text-gray-700">Country / Region</label>
	        <select id="country" name={`${prefix}.country`} defaultValue={address && address.country} ref={register} className="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
	          {Object.keys(myCountryCodesObject).map(country => <option>{country}</option>)}
	      	  {/* TODO Add official country list */}
	        </select>
	      </div>

	      { withAttention && 
	      	  <div className="col-span-6 sm:col-span-4">
		        <label htmlFor="attentionTo" className="block text-sm font-medium leading-5 text-gray-700">Attention To</label>
		        <input id="attentionTo" name={`${prefix}.attentionTo`} defaultValue={address && address.attentionTo} ref={register} className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
		      </div>
		  }

	      <div className="col-span-6 sm:col-span-4">
	        <label htmlFor="streetAddress" className="block text-sm font-medium leading-5 text-gray-700">Street address</label>
	        {/*<input id="streetAddress" name={`${prefix}.streetAddress`} ref={register} className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />*/}
	        <textarea id="streetAddress" name={`${prefix}.streetAddress`} defaultValue={streetAddress} ref={register} rows="4" className="form-textarea mt-1 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5" placeholder="1, The Company 
	The Only Building
	Greatest Street Ever "></textarea>
	      </div>

	      <div className="col-span-6 grid grid-cols-6 gap-6">
	          <div className="col-span-6 sm:col-span-6 lg:col-span-2">
	            <label htmlFor="city" className="block text-sm font-medium leading-5 text-gray-700">City</label>
	            <input id="city" name={`${prefix}.city`} defaultValue={address && address.city} ref={register} className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
	          </div>

	          <div className="col-span-6 sm:col-span-3 lg:col-span-2">
	            <label htmlFor="region" className="block text-sm font-medium leading-5 text-gray-700">State / Region</label>
	            <input id="region" name={`${prefix}.region`} defaultValue={address && address.region} ref={register} className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
	          </div>

	          <div className="col-span-6 sm:col-span-3 lg:col-span-2">
	            <label htmlFor="postCode" className="block text-sm font-medium leading-5 text-gray-700">ZIP / Postal Code</label>
	            <input id="postCode" name={`${prefix}.postCode`} defaultValue={address && address.postCode} ref={register} className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
	          </div>
	      </div>

	      { 
	      	// only show once google loads
	      	window.google && 
		    <div className="col-span-6">
				<LocationPicker
					containerElement={ <div style={ {height: '100%'} } /> }
					mapElement={ <div style={ {height: '400px'} } /> }
					defaultPosition={defaultPosition}
					onChange={handleLocationChange}
				/>
			</div>
		  }
		</React.Fragment>
    )
}

const cleanAddress = (addressData,originalAddress) => {
	const address = {...addressData}

	if (!address.id){
		address.id = originalAddress && originalAddress.id || uuidv4();
	}
	if (address.streetAddress){
		const addressParts = address.streetAddress.split('\n')

		for (var i = 0; i < addressParts.length; i++) {
			if (i < 4)
				address[`addressLine${i+1}`] = addressParts[i]
		}

		delete(address.streetAddress);
	}

	if (originalAddress){
		address._version = originalAddress._version
		address._lastChangedAt = originalAddress._lastChangedAt
	} else {
		// this is a new item
		address._version = 1
		address._lastChangedAt = Math.round(new Date().getTime()/1000)
	}

	return cleanDeep(address)
}


export {
	Address,
	cleanAddress
}
import React, { useState, useEffect } from "react";
import { useForm } from 'react-hook-form'
import { useParams, useHistory} from "react-router";
import countryCodes from 'country-codes-list';

import _ from 'lodash';
import {connect} from 'react-redux';

import {Actions as ACTIONS} from 'store/mobileLocation/action';
import {Actions as LOCATIONACTIONS} from 'store/location/action';
import PageHeading from 'components/PageHeading';
import useScript from 'hooks/useScript';
import { v4 as uuidv4 } from 'uuid';
import LocationPicker from 'react-location-picker';
// import {default as ScriptjsLoader} from "react-google-maps/lib/async/ScriptjsLoader";
// 
import { Address, cleanAddress} from '../location/Address'
import ColorPicker from 'components/formElements/ColorPicker';

const cleanDeep = require('clean-deep');

const MobileLocation = ({
	location,
	mobileLocation,
	createLocation,
	updateLocation,
	getLocation,
	clearLocation,
	handleUpload,
	handleDeletePhoto
}) => {

	useScript('https://maps.googleapis.com/maps/api/js?&v=3.exp&libraries=geometry,drawing,places&key=AIzaSyC-WLi-_WTJNOsLh-85OmBrP8WhTUBoiLs');

  	let history = useHistory();
	let { id, locationID } = useParams();

	const handleUploadChange = async (e, name) => {
        const file = e.target.files[0];

        if (!file) {
            return;
        }

        const key = await handleUpload(file)
    }

	// initialId is used for when we are clearing as it causes an issue.
  	const [initialId, setInitialId] = useState(mobileLocation && mobileLocation.id)

	const { register, handleSubmit, watch, errors, setValue } = useForm()
	register('colour')
	const onSubmit = data => {
		console.log('data',data)

		const address = cleanAddress(data.address[0],mobileLocation && mobileLocation.address)

		const item = {
			id: mobileLocation && mobileLocation.id || uuidv4(),
			_version: mobileLocation && mobileLocation._version,
			name: data.name,
			colour: data.colour,
			isRepeating: data.isRepeating,
			comingSoon: data.comingSoon,
			order: data.order,
			weeksAtLocation: data.weeksAtLocation,
			locationID,
			address,
			addressID: mobileLocation && mobileLocation.addressID
		}
		console.log('item',item)

		if (mobileLocation && mobileLocation.id){
			updateLocation(cleanDeep(item))
		} else {
			delete(item._version);
			console.log('updatedItem',item)
			createLocation(cleanDeep(item))
		}
	}

	const isRepeating = watch('isRepeating') || watch('isRepeating') == null && mobileLocation && mobileLocation.isRepeating;
	const colour = watch('colour') || mobileLocation && mobileLocation.colour;

	useEffect(() => {
		if (!(id !== 'new' && mobileLocation && mobileLocation.id && mobileLocation.id == id)){
			getLocation(id)
		} else if (id == 'new'){
			clearLocation()
		}
	},[mobileLocation && mobileLocation.id]);

	useEffect(() => {
		if (id == 'new' && mobileLocation && mobileLocation.id && mobileLocation.id !== initialId && mobileLocation._version == 1){
  			history.push(`/location/${locationID}/${mobileLocation.id}`)
		}
	},[mobileLocation && mobileLocation._version == 1 && mobileLocation.id]);

	if ( (!mobileLocation && id !== 'new' ) || (mobileLocation && id !== 'new' && id !== mobileLocation.id ) || (id === 'new' && mobileLocation)){

		return (
			<div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
				Loading...
			</div>
		)
	}

	return (

		<React.Fragment>
			<PageHeading item={mobileLocation} isNew={id === 'new'}/>
			<div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
				<div className="md:grid md:grid-cols-3 md:gap-6">
					<div className="md:col-span-1">
					  <div className="px-4 sm:px-0">
						<h3 className="text-lg font-medium leading-6 text-gray-900">Mobile Location Details</h3>
						<p className="mt-1 text-sm leading-5 text-gray-500">
						  This information will be displayed publicly.
						</p>
					  </div>
					</div>
					<div className="mt-5 md:mt-0 md:col-span-2">
					  <form onSubmit={handleSubmit(onSubmit)}>
						<div className="shadow sm:rounded-md sm:overflow-hidden">
						  <div className="px-4 py-5 bg-white sm:p-6">
							<div className="grid grid-cols-6 gap-6">

								<div className="col-span-6 sm:col-span-3">
									<label htmlFor="name" className="block text-sm font-medium leading-5 text-gray-700">Name</label>
									<input id="name" name="name" defaultValue={mobileLocation && mobileLocation.name} ref={register} className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
								</div>

								<div className="col-span-6 sm:col-span-4">
									<label htmlFor="price" className="text-sm font-medium leading-5 text-gray-700">
									  Calendar Color
									</label>
									<div style={{
									 		display: "inline-block",
									 	    verticalAlign: "middle",
									 	    marginLeft: "10px",
									 	}}>
									  <ColorPicker onChange={color => { setValue('colour', color.hex)}} color={colour} />
									</div>
								</div>

								<div className="col-span-6 sm:col-span-4">
								  <div className="flex items-start">
									<div className="absolute flex items-center h-5">
									  <input id="comingSoon" name="comingSoon" ref={register} defaultChecked={mobileLocation && mobileLocation.comingSoon} type="checkbox" className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out" />
									</div>
									<div className="pl-7 text-sm leading-5">
									  <label htmlFor="comingSoon" className="font-medium text-gray-700">Coming Soon</label>
									  <p className="text-gray-500">Is this Mobile Location still not open but want to show it as coming soon?</p>
									</div>
								  </div>
								</div>

								<div className="col-span-6 sm:col-span-4">
								  <div className="flex items-start">
									<div className="absolute flex items-center h-5">
									  <input id="isRepeating" name="isRepeating" ref={register} defaultChecked={mobileLocation && mobileLocation.isRepeating} type="checkbox" className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out" />
									</div>
									<div className="pl-7 text-sm leading-5">
									  <label htmlFor="isRepeating" className="font-medium text-gray-700">Regular Location</label>
									  <p className="text-gray-500">Is this Mobile Location on a repeat schedule?</p>
									</div>
								  </div>
								</div>

								{ isRepeating && 
									[
										<div className="col-span-6 sm:col-span-3">
											<label htmlFor="order" className="block text-sm font-medium leading-5 text-gray-700">Order No</label>
											<input id="order" name="order" defaultValue={mobileLocation && mobileLocation.order} ref={register} className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
										</div>,

										<div className="col-span-6 sm:col-span-3">
											<label htmlFor="weeksAtLocation" className="block text-sm font-medium leading-5 text-gray-700">Number of Weeks At Location</label>
											<input id="weeksAtLocation" name="weeksAtLocation" defaultValue={mobileLocation && mobileLocation.weeksAtLocation} ref={register} className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
										</div>
									]
								}


								<Address address={mobileLocation && mobileLocation.address} register={register} setValue={setValue} withAttention={false} prefix="address[0]" />

							</div>
						  </div>
						  <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
							<span className="inline-flex rounded-md shadow-sm">
							  <button type="submit" className="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">
								Save
							  </button>
							</span>
						  </div>
						</div>
					  </form>
					</div>
				</div>

				<div className="md:grid md:grid-cols-3 md:gap-6">
					<div className="md:col-span-1">
					  <div className="px-4 sm:px-0">
						<h3 className="text-lg font-medium leading-6 text-gray-900">Photos</h3>
						<p className="mt-1 text-sm leading-5 text-gray-500">
						  Photos to show on app.
						</p>



						{/*maybe show other image shere*/}
						<label htmlFor="upload-button">
					        <div type="button" class="py-2 px-3 border border-gray-300 rounded-md text-sm leading-4 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out">
								Add Photo
							</div>
					    </label>
						<input
					        type="file"
					        name="image"
					        id="upload-button"
					        style={{ display: "none" }}
					        onChange={handleUploadChange}
					      />
						
					  </div>
					</div>
					<div className="mt-5 md:mt-0 md:col-span-2">
					 	{
					 		mobileLocation && mobileLocation.photos && mobileLocation.photos.items && mobileLocation.photos.items
					 			.filter(item => !item._deleted) 
					 			.map(({id,photo,description}) => {

					 			if (photo) return (
					 				<div style={{
					 					position:"relative", 
					 					margin: "10px 0",
					 					width: "100%",
					 					height: "120px",
					 					backgroundImage: `url( "https://${photo.bucket}.s3-eu-west-1.amazonaws.com/public/${photo.key}" )`,
					 					backgroundPosition: "center",
					 					backgroundSize: "cover",
					 					 }} >
					 					<button onClick={() => handleDeletePhoto(id)} style={{position:"absolute", top: "10px", right: "10px"}} class="inline-flex rounded-md p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:bg-green-100 transition ease-in-out duration-150">
								          <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
								            <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"/>
								          </svg>
								        </button>
					 				</div>
					 			)
					 		})
					 	}
					</div>
				</div>
			</div>
		</React.Fragment>
		)
}


const mapStateToProps = (state) => {
	return {
	  location: state.location.item,
	  mobileLocation: state.mobileLocation && state.mobileLocation.item
  }
}

const mapDispatchToProps = dispatch => ({
  createLocation: (item) => dispatch(ACTIONS.createItem(item)),
  updateLocation: (item) => dispatch(ACTIONS.updateItem(item)),
  
  getLocation: (id) => dispatch(ACTIONS.getItem(id)),
  clearLocation: () => dispatch(ACTIONS.clearItem()),
  handleUpload: (file, preFileName) => dispatch(ACTIONS.handleUpload(file, preFileName)),
  handleDeletePhoto: (id) => dispatch(ACTIONS.handleDeletePhoto(id)),
  // timesheetUpdateSaveStatus: (status) => dispatch(ACTIONS.timesheetUpdateSaveStatus(status))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MobileLocation) 
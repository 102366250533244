import {Types} from "./action";
import _ from "lodash";
// import {NotificationManager} from 'react-notifications';

// import cleanDeep from 'clean-deep';


const defaultState = {
  service: {}
};

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case Types.NOTIFICATION_ADD: {

      let newState = {...state};

      newState.notifications.push({...action.notification});

      newState.notifications = [...newState.notifications]

      return newState;
    }
    case Types.NOTIFICATION_CLOSE: {

      let newState = {...state};

      const indexToRemove = newState.notifications.findIndex(item => item.id == action.id)

      if (indexToRemove >= 0){
        newState.notifications.splice(indexToRemove,1);
      }
      
      newState.notifications = [...newState.notifications]

      return newState;
    }
    default:
      return state;
  }
};

export default reducer;
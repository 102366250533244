/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_mobile_analytics_app_id": "a514f7dfd61749b887b21ac6b662daae",
    "aws_mobile_analytics_app_region": "eu-west-1",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://pvir1di5o2.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        },
        {
            "name": "booking",
            "endpoint": "https://bp6j0oaukl.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://6y2uylzk3famzenf3cqzaiebqe.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "eu-west-1:82850eff-086c-42dd-b0d4-fcb6690f3a3b",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_hYKc4ZcoV",
    "aws_user_pools_web_client_id": "5037be1airgpa7abefr7meff2l",
    "oauth": {},
    "federationTarget": "COGNITO_IDENTITY_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL",
        "PHONE_NUMBER"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "bookingapp-20200505225547-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "eu-west-1",
    "aws_content_delivery_url": "https://d27oabaifkl3kx.cloudfront.net",
    "aws_user_files_s3_bucket": "bookingappimages231838-dev",
    "aws_user_files_s3_bucket_region": "eu-west-1"
};


export default awsmobile;

import React, { useState, useEffect } from "react";
import { useForm } from 'react-hook-form'

import {connect} from 'react-redux';

import {Actions as ACTIONS} from 'store/notifications/action';
import Notification from './Notification';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useLocation,
  useParams
} from "react-router-dom";

const Notifications = ({notifications}) => {

	if (notifications && notifications.length < 1){
		return <React.Fragment></React.Fragment>
	}

	return (
		<div className="fixed inset-0 pointer-events-none z-10">
			{notifications && notifications.map(notification => 
				<Notification notification={notification}/>
			)}
		</div>
		)
}


const mapStateToProps = (state) => {
    return {
      notifications: state.notifications,
  }
}

const mapDispatchToProps = dispatch => ({
  // addNotification: (notification,removeAfter) => dispatch(ACTIONS.addNotification(notification,removeAfter)),
  // closeNotification: (id) => dispatch(ACTIONS.closeNotification(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Notifications)
import React, { useState, useEffect } from "react";
import { useForm } from 'react-hook-form'
import { useParams, useHistory} from "react-router";
import countryCodes from 'country-codes-list';

import _ from 'lodash';
import {connect} from 'react-redux';

import {Actions as ACTIONS} from 'store/location/action';
import {Actions as MOBILELOCATION_ACTIONS} from 'store/mobileLocation/action';
import PageHeading from 'components/PageHeading';
import useScript from 'hooks/useScript';
import { v4 as uuidv4 } from 'uuid';
import LocationPicker from 'react-location-picker';
// import {default as ScriptjsLoader} from "react-google-maps/lib/async/ScriptjsLoader";

import { Address, cleanAddress} from './Address'
import CalendarDay from './CalendarDay';
import MobileLocationRow from './MobileLocationRow';
import Calendar from 'react-calendar-multiday'

const cleanDeep = require('clean-deep');



const Location = ({
	location,
	mobileLocations,
	selectedMobileLocation,
	createLocation,
	updateLocation,
	getLocation,
	getLocationScheduleExceptions,
	clearLocation,
	handleUpload,
	handleDeletePhoto,
	setSelectedMobileLocation
}) => {

	useScript('https://maps.googleapis.com/maps/api/js?&v=3.exp&libraries=geometry,drawing,places&key=AIzaSyC-WLi-_WTJNOsLh-85OmBrP8WhTUBoiLs');

  	let history = useHistory();

	let { id } = useParams();

	const handleUploadChange = async (e, name) => {
        const file = e.target.files[0];

        if (!file) {
            return;
        }

        const key = await handleUpload(file)
    }

	// initialId is used for when we are clearing as it causes an issue.
  	const [initialId, setInitialId] = useState(location && location.id)

	const { register, handleSubmit, watch, errors, setValue } = useForm()
	const onSubmit = data => {
		console.log('data',data)

		const address = data.address && cleanAddress(data.address[0],location && location.address)

		const item = {
			id: location && location.id || uuidv4(),
			_version: location && location._version,
			name: data.name,
			phone: data.phone,
			opensOnPublicHolidays: data.opensOnPublicHolidays,
			acceptsBookings: data.acceptsBookings,
			bookingFlow: data.threeStep ? "ThreeStep" : "TwoStep",
			virtualQueue: data.virtualQueue,
			isMobile: data.isMobile,
			mobileWeekOffset: data.mobileWeekOffset,
			online: data.online,
			maintenance: data.maintenance,
			address,
			addressID: location && location.addressID
		}
		console.log('item',item)

		if (location && location.id){
			updateLocation(cleanDeep(item))
		} else {
			delete(item._version);
			console.log('updatedItem',item)
			createLocation(cleanDeep(item))
		}
	}

	const isMobile = watch('isMobile') || watch('isMobile') == null && location && location.isMobile;

	useEffect(() => {
		if (!(id !== 'new' && location && location.id && location.id == id)){
			getLocation(id)
			getLocationScheduleExceptions(id)
		} else if (id == 'new'){
			clearLocation()
		}
	},[location && location.id]);

	useEffect(() => {
		if (id == 'new' && location && location.id && location.id !== initialId && location._version == 1){
  			history.push(`/location/${location.id}`)
		}
	},[location && location._version == 1 && location.id]);

	if ( (!location && id !== 'new' ) || (location && id !== 'new' && id !== location.id ) || (id === 'new' && location)){

		return (
			<div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
				Loading...
			</div>
		)
	}

	return (

		<React.Fragment>
			<PageHeading item={location} isNew={id === 'new'}/>
			<div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
				<div className="md:grid md:grid-cols-3 md:gap-6">
					<div className="md:col-span-1">
					  <div className="px-4 sm:px-0">
						<h3 className="text-lg font-medium leading-6 text-gray-900">Location Details</h3>
						<p className="mt-1 text-sm leading-5 text-gray-500">
						  This information will be displayed publicly.
						</p>

						<div>
							{ 
								location && location.isMobile && 
								<Calendar
						            isMultiple={true}
						            reset={false}
						            selected={[new Date()]}
						            DayComponent={<CalendarDay/>}
					            />
					        }
							{ 
								location && location.isMobile && mobileLocations &&
									<div>
										<h5>Mobile Locations</h5>
										{
											mobileLocations.map((mobileLocation) => mobileLocation && 
											<MobileLocationRow mobileLocation={mobileLocation}/>
										)}
										<div
											onClick={() => history.push(`/location/${location.id}/new`)}
										>
											
									        <svg class="inline-block flex-shrink-0 mr-5 h-5 w-5 text-gray-400" fill="none"  stroke="currentColor" viewBox="0 0 24 24">
									          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"></path>
									        </svg>

											Add Location
										</div>
									</div>
					        }
						</div>
					  </div>
					</div>
					<div className="mt-5 md:mt-0 md:col-span-2">
					  <form onSubmit={handleSubmit(onSubmit)}>
						<div className="shadow sm:rounded-md sm:overflow-hidden">
						  <div className="px-4 py-5 bg-white sm:p-6">
							<div className="grid grid-cols-6 gap-6">

								<div className="col-span-6 sm:col-span-3">
									<label htmlFor="name" className="block text-sm font-medium leading-5 text-gray-700">Name</label>
									<input id="name" name="name" defaultValue={location && location.name} ref={register} className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
								</div>

								<div className="col-span-6 sm:col-span-3">
									<label htmlFor="phone" className="block text-sm font-medium leading-5 text-gray-700">Phone</label>
									<input id="phone" name="phone" defaultValue={location && location.phone} ref={register} className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
								</div>


								<div className="col-span-6 sm:col-span-4">
								  <div className="flex items-start">
									<div className="absolute flex items-center h-5">
									  <input id="isMobile" name="isMobile" ref={register} defaultChecked={location && location.isMobile} type="checkbox" className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out" />
									</div>
									<div className="pl-7 text-sm leading-5">
									  <label htmlFor="isMobile" className="font-medium text-gray-700">Mobile Location</label>
									  <p className="text-gray-500">Does this 'location' move around?</p>
									</div>
								  </div>
								</div>

								{ 
									isMobile && 
									<div className="col-span-6 sm:col-span-3">
										<label htmlFor="mobileWeekOffset" className="block text-sm font-medium leading-5 text-gray-700">Schedule Week Offset</label>
										<input id="mobileWeekOffset" name="mobileWeekOffset" defaultValue={location && location.mobileWeekOffset} ref={register} className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
									</div>
								}

								{
									!isMobile && 
									<Address address={location && location.address} register={register} setValue={setValue} withAttention={false} prefix="address[0]" />
								}

								<div className="col-span-6 sm:col-span-4">
								  <div className="flex items-start">
									<div className="absolute flex items-center h-5">
									  <input id="acceptsBookings" name="acceptsBookings" ref={register} defaultChecked={location && location.acceptsBookings} type="checkbox" className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out" />
									</div>
									<div className="pl-7 text-sm leading-5">
									  <label htmlFor="acceptsBookings" className="font-medium text-gray-700">Accepts Bookings</label>
									  <p className="text-gray-500">Does this location accept bookings for all or some of it's services?</p>
									</div>
								  </div>
								</div>
								<div className="col-span-6 sm:col-span-4">
								  <div className="flex items-start">
									<div className="absolute flex items-center h-5">
									  <input id="threeStep" name="threeStep" ref={register} defaultChecked={location && location.bookingFlow == "ThreeStep"} type="checkbox" className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out" />
									</div>
									<div className="pl-7 text-sm leading-5">
									  <label htmlFor="threeStep" className="font-medium text-gray-700">Three Step Bookings</label>
									  <p className="text-gray-500">Use a three-step booking flow for selection of services</p>
									</div>
								  </div>
								</div>
								<div className="col-span-6 sm:col-span-4">
								  <div className="flex items-start">
									<div className="absolute flex items-center h-5">
									  <input id="virtualQueue" name="virtualQueue" ref={register} defaultChecked={location && location.virtualQueue} type="checkbox" className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out" />
									</div>
									<div className="pl-7 text-sm leading-5">
									  <label htmlFor="virtualQueue" className="font-medium text-gray-700">Virtual Queue</label>
									  <p className="text-gray-500">Does this location operate using a Virtual Queue Facility?</p>
									</div>
								  </div>
								</div>
								<div className="col-span-6 sm:col-span-4">
								  <div className="flex items-start">
									<div className="absolute flex items-center h-5">
									  <input id="online" name="online" ref={register} defaultChecked={location && location.online} type="checkbox" className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out" />
									</div>
									<div className="pl-7 text-sm leading-5">
									  <label htmlFor="online" className="font-medium text-gray-700">Online</label>
									  <p className="text-gray-500">Enable Bookings or Virtual Queue online</p>
									</div>
								  </div>
								</div>
								<div className="col-span-6 sm:col-span-4">
								  <div className="flex items-start">
									<div className="absolute flex items-center h-5">
									  <input id="maintenance" name="maintenance" ref={register} defaultChecked={location && location.maintenance} type="checkbox" className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out" />
									</div>
									<div className="pl-7 text-sm leading-5">
									  <label htmlFor="maintenance" className="font-medium text-gray-700">In Maintenance</label>
									  <p className="text-gray-500">Show that a location is Temporarily Disabled</p>
									</div>
								  </div>
								</div>
								<div className="col-span-6 sm:col-span-4">
								  <div className="flex items-start">
									<div className="absolute flex items-center h-5">
									  <input id="opensOnPublicHolidays" name="opensOnPublicHolidays" ref={register} defaultChecked={location && location.opensOnPublicHolidays} type="checkbox" className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out" />
									</div>
									<div className="pl-7 text-sm leading-5">
									  <label htmlFor="opensOnPublicHolidays" className="font-medium text-gray-700">Opens on Public Holidays</label>
									  <p className="text-gray-500">Does this location generally open on Public Holidays anyway?</p>
									</div>
								  </div>
								</div>

								{/*<div className="mt-6">
								  <label htmlFor="about" className="block text-sm leading-5 font-medium text-gray-700">
									About
								  </label>
								  <div className="rounded-md shadow-sm">
									<textarea id="about" rows="3" className="form-textarea mt-1 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5" placeholder="you@example.com"></textarea>
								  </div>
								  <p className="mt-2 text-sm text-gray-500">
									Brief description for your profile. URLs are hyperlinked.
								  </p>
								</div>*/}

							</div>
						  </div>
						  <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
							<span className="inline-flex rounded-md shadow-sm">
							  <button type="submit" className="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">
								Save
							  </button>
							</span>
						  </div>
						</div>
					  </form>
					</div>
				</div>

				<div className="md:grid md:grid-cols-3 md:gap-6">
					<div className="md:col-span-1">
					  <div className="px-4 sm:px-0">
						<h3 className="text-lg font-medium leading-6 text-gray-900">Photos</h3>
						<p className="mt-1 text-sm leading-5 text-gray-500">
						  Photos to show on app.
						</p>



						{/*maybe show other image shere*/}
						<label htmlFor="upload-button">
					        <div type="button" class="py-2 px-3 border border-gray-300 rounded-md text-sm leading-4 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out">
								Add Photo
							</div>
					    </label>
						<input
					        type="file"
					        name="image"
					        id="upload-button"
					        style={{ display: "none" }}
					        onChange={handleUploadChange}
					      />
						
					  </div>
					</div>
					<div className="mt-5 md:mt-0 md:col-span-2">
					 	{
					 		location && location.photos && location.photos.items && location.photos.items
					 			.filter(item => !item._deleted) 
					 			.map(({id,photo,description}) => {

					 			if (photo) return (
					 				<div style={{
					 					position:"relative", 
					 					margin: "10px 0",
					 					width: "100%",
					 					height: "120px",
					 					backgroundImage: `url( "https://${photo.bucket}.s3-eu-west-1.amazonaws.com/public/${photo.key}" )`,
					 					backgroundPosition: "center",
					 					backgroundSize: "cover",
					 					 }} >
					 					<button onClick={() => handleDeletePhoto(id)} style={{position:"absolute", top: "10px", right: "10px"}} class="inline-flex rounded-md p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:bg-green-100 transition ease-in-out duration-150">
								          <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
								            <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"/>
								          </svg>
								        </button>
					 				</div>
					 			)
					 		})
					 	}
					</div>
				</div>
			</div>
		</React.Fragment>
		)
}


const mapStateToProps = (state) => {

	const location = state.location.item
	const mobileLocations = location && state.mobileLocation.list && state.mobileLocation.list.filter(item => item.locationID == location.id).sort((a, b) => a.order - b.order)
	const selectedMobileLocation = location && state.mobileLocation.selected

	return {
	  location,
	  mobileLocations,
	  selectedMobileLocation,
  }
}

const mapDispatchToProps = dispatch => ({
  setSelectedMobileLocation: (item) => dispatch(MOBILELOCATION_ACTIONS.setSelectedMobileLocation(item)),

  createLocation: (item) => dispatch(ACTIONS.createItem(item)),
  updateLocation: (item) => dispatch(ACTIONS.updateItem(item)),
  
  getLocation: (id) => dispatch(ACTIONS.getItem(id)),
  getLocationScheduleExceptions: (id) => dispatch(ACTIONS.getLocationScheduleExceptions(id)),
  clearLocation: () => dispatch(ACTIONS.clearItem()),
  handleUpload: (file, preFileName) => dispatch(ACTIONS.handleUpload(file, preFileName)),
  handleDeletePhoto: (id) => dispatch(ACTIONS.handleDeletePhoto(id)),
  // timesheetUpdateSaveStatus: (status) => dispatch(ACTIONS.timesheetUpdateSaveStatus(status))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Location) 
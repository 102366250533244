import React from "react";

import {
          getPaymentMachine,
          listPaymentMachines,
       } from 'graphql/queries'

import {
  createPaymentMachine,
  updatePaymentMachine,
  deletePaymentMachine,
  createChangelog,
} from 'graphql/mutations';

import {
  onCreatePaymentMachine,
  onUpdatePaymentMachine,
} from 'graphql/subscriptions';

import { diffString, diff } from 'json-diff';

import {Auth} from '@aws-amplify/auth'
import {API, graphqlOperation} from '@aws-amplify/api'
import {Storage} from '@aws-amplify/storage'

import awsMobile from 'aws-exports';
import AWS from 'aws-sdk';
import {Actions as NOTIFICATIONACTIONS} from 'store/notifications/action';

import parsePhoneNumber from 'libphonenumber-js'
import parsePhoneNumberFromString from 'libphonenumber-js'
var { format } = require('date-fns')


// types of action
const Types = {
  PAYMENT_MACHINE_GET_ITEM: "PAYMENT_MACHINE_GET_ITEM",
  PAYMENT_MACHINE_UPDATE_ITEM: "PAYMENT_MACHINE_UPDATE_ITEM",
  PAYMENT_MACHINE_CREATE_ITEM: "PAYMENT_MACHINE_CREATE_ITEM",
  PAYMENT_MACHINE_DELETE_ITEM: "PAYMENT_MACHINE_DELETE_ITEM",
  PAYMENT_MACHINE_CLEAR_ITEM: "PAYMENT_MACHINE_CLEAR_ITEM",
  PAYMENT_MACHINE_LOADING: "PAYMENT_MACHINE_LOADING",
  PAYMENT_MACHINE_GET_LIST: "PAYMENT_MACHINE_GET_LIST",
  PAYMENT_MACHINE_GET_SEARCH: "PAYMENT_MACHINE_GET_SEARCH",
  PAYMENT_MACHINE_NEW_MODAL: "PAYMENT_MACHINE_NEW_MODAL",
  PAYMENT_MACHINE_UPDATE_IMAGE: "PAYMENT_MACHINE_UPDATE_IMAGE",
};

const newPaymentMachineModal =  (show) => ({
  type: Types.PAYMENT_MACHINE_NEW_MODAL,
  show
});

const dispatchLoading =  loading => ({
  type: Types.PAYMENT_MACHINE_LOADING,
  loading
});

const clearItem = response => ({
  type: Types.PAYMENT_MACHINE_CLEAR_ITEM
});

const dispatchGetItem = response => ({
  type: Types.PAYMENT_MACHINE_GET_ITEM,
  payload: response
});

const getItem = (id) => {
  console.log('getItem1',getItem)
  return (dispatch, getState) => {
    dispatch(dispatchLoading(true))

    const variables = {id}
    const request = API.graphql({query: getPaymentMachine, variables, authMode: "AMAZON_COGNITO_USER_POOLS"})
    console.log('getItem2',getItem)
    request.then((response) => {
        dispatch(dispatchGetItem(response))
        dispatch(dispatchLoading(false))
    });
    
  }
}

const dispatchUpdateItem = (response,callback) => ({
  type: Types.PAYMENT_MACHINE_UPDATE_ITEM,
  payload: response,
  callback
});

const updateItem = (item,callback) => {
  return (dispatch, getState) => {

    const state = getState()

    const image = state.paymentMachine.item.image
    item.image = image

    const currentPaymentMachine = state?.paymentMachine?.list?.find(({id}) => id == item.id)
    console.log('currentPaymentMachine',currentPaymentMachine,item)

    const variables = {input: item}
    const request = API.graphql({query: updatePaymentMachine, variables, authMode: "AMAZON_COGNITO_USER_POOLS"})

    request.then(async (response) => {
        dispatch(dispatchUpdateItem(response,callback))

        const difference = diff(currentPaymentMachine,response.data.updatePaymentMachine);

        // remove common items
          delete difference._version;
          delete difference._lastChangedAt;
          delete difference.updatedAt;
          delete difference.queue;
          delete difference.bookings;
          delete difference.reviewEmailSent;
          delete difference.finalReminderSent;
          delete difference.confirmationReminderSent;

        console.log('difference',difference)

        const differenceKeys = Object.keys(difference)

        const userInfo = await Auth.currentUserInfo();
        console.log('userInfo',userInfo)

        const currentTeamMember = state.teamMember.list.find(i => i.username == userInfo.username)

        const newChangeLog = {
          entityID: item.id,
          action: "Update",
          teamMemberID: currentTeamMember.id,
          businessID: state.businessID,
          diff: JSON.stringify(difference)
        }

        const changeLogRequest = API.graphql({query: createChangelog, variables: {input: newChangeLog}, authMode: "AMAZON_COGNITO_USER_POOLS"})

        changeLogRequest.then((response) => {
          console.log('changeLogSaved',response)
          // we don't need to do anythign after saving the changelog right now.
        })

        //TODO Plan notification logic/errors
        dispatch(NOTIFICATIONACTIONS.addNotification({
          title: <React.Fragment><strong>{response.data.updatePaymentMachine.name}</strong> has been updated</React.Fragment>,
          message: response.data.updatePaymentMachine.active ? `PaymentMachines can book this ad` : `This ad is disabled. It cannot be booked`,
          icon: `success`,
        },
          3000
        ))
    });

    
  }
}

const dispatchDeleteItem = response => ({
  type: Types.PAYMENT_MACHINE_DELETE_ITEM,
  payload: response
});

const deleteItem = (id,_version,history) => {
  return (dispatch, getState) => {

    console.log('businessID',getState('businessID'))

    const businessID = getState().businessID
    const variables = {input: {id, _version }}
    const request = API.graphql({query: deletePaymentMachine, variables, authMode: "AMAZON_COGNITO_USER_POOLS"})

    request.then((response) => {
      console.log('responseTEST',response.data.deletePaymentMachine)

        // go to main page as it's been deleted
        history.push(`/ad`) 
        dispatch(dispatchDeleteItem(id))

        //TODO Plan notification logic/errors
        dispatch(NOTIFICATIONACTIONS.addNotification({
          title: <React.Fragment><strong>{response.data.deletePaymentMachine.name}</strong> has been deleted</React.Fragment>,
          message: `This ad is no longer available.`,
          icon: `success`,
        },2000))
    });
  }
}

const dispatchCreateItem = (response,callback) => ({
  type: Types.PAYMENT_MACHINE_CREATE_ITEM,
  payload: response,
  callback
});

const createItem = (item,callback) => {
  return (dispatch, getState) => {


    const state = getState();

    const businessID = state.businessID;
    const variables = {input: {...item, businessID}}
    const request = API.graphql({query: createPaymentMachine, variables, authMode: "AMAZON_COGNITO_USER_POOLS"})

    request.then((response) => {
        // switched off as subscription is filling this
        dispatch(dispatchCreateItem(response,callback))

        //TODO Plan notification logic/errors
        dispatch(NOTIFICATIONACTIONS.addNotification({
          title: <React.Fragment><strong>{response.data.createPaymentMachine.name}</strong> has been created</React.Fragment>,
          // message: response.data.createPaymentMachine.active ? `You team can now start to provide this ad.` : `This ad is disabled and cannot be booked.`,
          icon: `success`,
        },2000))
    });
    
  }
}

const updateSearch = response => ({
  type: Types.PAYMENT_MACHINE_GET_SEARCH,
  payload: response
});

const updateList = response => ({
  type: Types.PAYMENT_MACHINE_GET_LIST,
  payload: response
});

const getList = (limit, nextToken) => {
  return (dispatch, getState) => {

    const today = format(new Date(),"yyyy-MM-dd")

    const variables = {limit, nextToken, today}
    const request = API.graphql({query: listPaymentMachines, variables, authMode: "AMAZON_COGNITO_USER_POOLS"})

    request.then((response) => {
        dispatch(updateList(response))
    });
    
  }
}


const subscribeToUpdates = () => {
  return (dispatch, getState) => {

    const state = getState()

    const businessID = state.businessID

    const createSubscription = API.graphql(
        graphqlOperation(onCreatePaymentMachine,{businessID})
    ).subscribe({
        next: (data) => {

          const newEntry = data.value.data.onCreatePaymentMachine;

          let currentPaymentMachine = state?.paymentMachine?.list?.find(({id}) => id == newEntry.id)
          
          if (!currentPaymentMachine){
            dispatch(dispatchCreateItem(data.value))
          }
          console.log('onCreatePaymentMachine',data.value)
        }
    });

    const updateSubscription = API.graphql(
        graphqlOperation(onUpdatePaymentMachine,{businessID})
    ).subscribe({
        next: (data) => {

          const newEntry = data.value.data.onUpdatePaymentMachine;
          let currentPaymentMachine = state?.paymentMachine?.list?.find(({id}) => id == newEntry.id)

          if (!currentPaymentMachine || currentPaymentMachine._version != newEntry._version){
            console.log('onUpdatePaymentMachine',data.value)
            dispatch(dispatchUpdateItem(data.value))
          }
        }
    });

  }
}



const updatePaymentMachineImage = image => ({
  type: Types.PAYMENT_MACHINE_UPDATE_IMAGE,
  image
});

const handleUpload = (file, preFileName) => {
  return (dispatch, getState) => {

    const state = getState()

    const businessID = state.businessID

    console.log(file,'show me the file!')

    const {
      aws_user_files_s3_bucket_region: region,
      aws_user_files_s3_bucket: bucket
    } = awsMobile

    console.log(bucket,'this is the bucket!')

    const { name: fileName, type: mimeType } = file;

    const filePath = `ads/${businessID}/${fileName}`  

    const key = preFileName ? preFileName : filePath

    const fileForUpload = {
      bucket,
      key,
      region,
      type: mimeType
    };

    dispatch(NOTIFICATIONACTIONS.addNotification({
      title: <React.Fragment><strong>Image</strong> is currently uploading. Please wait...</React.Fragment>,
      // message: response.data.updateLocation.status == "Active" ? `This location is raring and ready to go.` : `This location is disabled and cannot be booked.`,
      icon: `success`,
    },3000))


    const request = Storage.put(key, file, {
      level: 'public',
      contentType: mimeType,
      progressCallback: (e) => {
          console.log(e,'this is a callback! from storage')
      }
    });


    request.then(() => {

      dispatch(updatePaymentMachineImage({
        key,
        bucket
      }))


      dispatch(NOTIFICATIONACTIONS.addNotification({
        title: <React.Fragment><strong>Photo</strong> has been uploaded</React.Fragment>,
        // message: response.data.updateLocation.status == "Active" ? `This location is raring and ready to go.` : `This location is disabled and cannot be booked.`,
        icon: `success`,
      },3000))

    });
  }
}

const Actions = {
  getItem,
  createItem,
  updateItem,
  deleteItem,
  getList,
  clearItem,
  newPaymentMachineModal,
  subscribeToUpdates,
  handleUpload
}

// exports = {
//   Types,
//   Actions
// }

export {Types, Actions}

// exports.Types = Types
// exports.Actions = Actions
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const incrementBookingStat = /* GraphQL */ `
  mutation IncrementBookingStat($id: ID!, $amount: Int, $version: Int) {
    incrementBookingStat(id: $id, amount: $amount, version: $version) {
      id
      reportType
      businessID
      date
      value
      amount
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createAddress = /* GraphQL */ `
  mutation CreateAddress(
    $input: CreateAddressInput!
    $condition: ModelAddressConditionInput
  ) {
    createAddress(input: $input, condition: $condition) {
      id
      businessID
      addressLine1
      addressLine2
      addressLine3
      addressLine4
      city
      region
      postCode
      country
      attentionTo
      lat
      lng
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateAddress = /* GraphQL */ `
  mutation UpdateAddress(
    $input: UpdateAddressInput!
    $condition: ModelAddressConditionInput
  ) {
    updateAddress(input: $input, condition: $condition) {
      id
      businessID
      addressLine1
      addressLine2
      addressLine3
      addressLine4
      city
      region
      postCode
      country
      attentionTo
      lat
      lng
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteAddress = /* GraphQL */ `
  mutation DeleteAddress(
    $input: DeleteAddressInput!
    $condition: ModelAddressConditionInput
  ) {
    deleteAddress(input: $input, condition: $condition) {
      id
      businessID
      addressLine1
      addressLine2
      addressLine3
      addressLine4
      city
      region
      postCode
      country
      attentionTo
      lat
      lng
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createAddressConnection = /* GraphQL */ `
  mutation CreateAddressConnection(
    $input: CreateAddressConnectionInput!
    $condition: ModelAddressConnectionConditionInput
  ) {
    createAddressConnection(input: $input, condition: $condition) {
      id
      businessID
      customerID
      address {
        id
        businessID
        addressLine1
        addressLine2
        addressLine3
        addressLine4
        city
        region
        postCode
        country
        attentionTo
        lat
        lng
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      addressType
      current
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateAddressConnection = /* GraphQL */ `
  mutation UpdateAddressConnection(
    $input: UpdateAddressConnectionInput!
    $condition: ModelAddressConnectionConditionInput
  ) {
    updateAddressConnection(input: $input, condition: $condition) {
      id
      businessID
      customerID
      address {
        id
        businessID
        addressLine1
        addressLine2
        addressLine3
        addressLine4
        city
        region
        postCode
        country
        attentionTo
        lat
        lng
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      addressType
      current
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteAddressConnection = /* GraphQL */ `
  mutation DeleteAddressConnection(
    $input: DeleteAddressConnectionInput!
    $condition: ModelAddressConnectionConditionInput
  ) {
    deleteAddressConnection(input: $input, condition: $condition) {
      id
      businessID
      customerID
      address {
        id
        businessID
        addressLine1
        addressLine2
        addressLine3
        addressLine4
        city
        region
        postCode
        country
        attentionTo
        lat
        lng
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      addressType
      current
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createBusiness = /* GraphQL */ `
  mutation CreateBusiness(
    $input: CreateBusinessInput!
    $condition: ModelBusinessConditionInput
  ) {
    createBusiness(input: $input, condition: $condition) {
      id
      name
      allowBookingsDayAdvance
      allowBookingsDayAdvanceWeb
      vacationLeaveDays
      takingBookings
      allowBackDatedBookings
      logo {
        bucket
        key
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      addresses {
        items {
          id
          businessID
          customerID
          addressType
          current
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const updateBusiness = /* GraphQL */ `
  mutation UpdateBusiness(
    $input: UpdateBusinessInput!
    $condition: ModelBusinessConditionInput
  ) {
    updateBusiness(input: $input, condition: $condition) {
      id
      name
      allowBookingsDayAdvance
      allowBookingsDayAdvanceWeb
      vacationLeaveDays
      takingBookings
      allowBackDatedBookings
      logo {
        bucket
        key
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      addresses {
        items {
          id
          businessID
          customerID
          addressType
          current
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const deleteBusiness = /* GraphQL */ `
  mutation DeleteBusiness(
    $input: DeleteBusinessInput!
    $condition: ModelBusinessConditionInput
  ) {
    deleteBusiness(input: $input, condition: $condition) {
      id
      name
      allowBookingsDayAdvance
      allowBookingsDayAdvanceWeb
      vacationLeaveDays
      takingBookings
      allowBackDatedBookings
      logo {
        bucket
        key
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      addresses {
        items {
          id
          businessID
          customerID
          addressType
          current
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const createLocation = /* GraphQL */ `
  mutation CreateLocation(
    $input: CreateLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    createLocation(input: $input, condition: $condition) {
      id
      businessID
      name
      code
      addressID
      phone
      openingHours {
        day
        opensAt
        closesAt
      }
      acceptsBookings
      virtualQueue
      online
      maintenance
      opensOnPublicHolidays
      isMobile
      bookingFlow
      mobileWeekOffset
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      address {
        id
        businessID
        addressLine1
        addressLine2
        addressLine3
        addressLine4
        city
        region
        postCode
        country
        attentionTo
        lat
        lng
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      team {
        items {
          id
          businessID
          code
          username
          givenName
          familyName
          phone
          email
          acceptsBookings
          locationID
          offDays
          offDayExceptions
          seatNo
          queueGap
          entrance
          status
          role
          startDate
          endDate
          estimatedBreakTimes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      photos {
        items {
          id
          businessID
          description
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const updateLocation = /* GraphQL */ `
  mutation UpdateLocation(
    $input: UpdateLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    updateLocation(input: $input, condition: $condition) {
      id
      businessID
      name
      code
      addressID
      phone
      openingHours {
        day
        opensAt
        closesAt
      }
      acceptsBookings
      virtualQueue
      online
      maintenance
      opensOnPublicHolidays
      isMobile
      bookingFlow
      mobileWeekOffset
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      address {
        id
        businessID
        addressLine1
        addressLine2
        addressLine3
        addressLine4
        city
        region
        postCode
        country
        attentionTo
        lat
        lng
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      team {
        items {
          id
          businessID
          code
          username
          givenName
          familyName
          phone
          email
          acceptsBookings
          locationID
          offDays
          offDayExceptions
          seatNo
          queueGap
          entrance
          status
          role
          startDate
          endDate
          estimatedBreakTimes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      photos {
        items {
          id
          businessID
          description
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const deleteLocation = /* GraphQL */ `
  mutation DeleteLocation(
    $input: DeleteLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    deleteLocation(input: $input, condition: $condition) {
      id
      businessID
      name
      code
      addressID
      phone
      openingHours {
        day
        opensAt
        closesAt
      }
      acceptsBookings
      virtualQueue
      online
      maintenance
      opensOnPublicHolidays
      isMobile
      bookingFlow
      mobileWeekOffset
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      address {
        id
        businessID
        addressLine1
        addressLine2
        addressLine3
        addressLine4
        city
        region
        postCode
        country
        attentionTo
        lat
        lng
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      team {
        items {
          id
          businessID
          code
          username
          givenName
          familyName
          phone
          email
          acceptsBookings
          locationID
          offDays
          offDayExceptions
          seatNo
          queueGap
          entrance
          status
          role
          startDate
          endDate
          estimatedBreakTimes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      photos {
        items {
          id
          businessID
          description
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const createMobileLocation = /* GraphQL */ `
  mutation CreateMobileLocation(
    $input: CreateMobileLocationInput!
    $condition: ModelMobileLocationConditionInput
  ) {
    createMobileLocation(input: $input, condition: $condition) {
      id
      businessID
      locationID
      name
      addressID
      isRepeating
      comingSoon
      colour
      order
      weeksAtLocation
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      address {
        id
        businessID
        addressLine1
        addressLine2
        addressLine3
        addressLine4
        city
        region
        postCode
        country
        attentionTo
        lat
        lng
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      photos {
        items {
          id
          businessID
          description
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const updateMobileLocation = /* GraphQL */ `
  mutation UpdateMobileLocation(
    $input: UpdateMobileLocationInput!
    $condition: ModelMobileLocationConditionInput
  ) {
    updateMobileLocation(input: $input, condition: $condition) {
      id
      businessID
      locationID
      name
      addressID
      isRepeating
      comingSoon
      colour
      order
      weeksAtLocation
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      address {
        id
        businessID
        addressLine1
        addressLine2
        addressLine3
        addressLine4
        city
        region
        postCode
        country
        attentionTo
        lat
        lng
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      photos {
        items {
          id
          businessID
          description
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const deleteMobileLocation = /* GraphQL */ `
  mutation DeleteMobileLocation(
    $input: DeleteMobileLocationInput!
    $condition: ModelMobileLocationConditionInput
  ) {
    deleteMobileLocation(input: $input, condition: $condition) {
      id
      businessID
      locationID
      name
      addressID
      isRepeating
      comingSoon
      colour
      order
      weeksAtLocation
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      address {
        id
        businessID
        addressLine1
        addressLine2
        addressLine3
        addressLine4
        city
        region
        postCode
        country
        attentionTo
        lat
        lng
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      photos {
        items {
          id
          businessID
          description
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const createLocationScheduleException = /* GraphQL */ `
  mutation CreateLocationScheduleException(
    $input: CreateLocationScheduleExceptionInput!
    $condition: ModelLocationScheduleExceptionConditionInput
  ) {
    createLocationScheduleException(input: $input, condition: $condition) {
      id
      businessID
      locationID
      mobileLocationID
      date
      disabled
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateLocationScheduleException = /* GraphQL */ `
  mutation UpdateLocationScheduleException(
    $input: UpdateLocationScheduleExceptionInput!
    $condition: ModelLocationScheduleExceptionConditionInput
  ) {
    updateLocationScheduleException(input: $input, condition: $condition) {
      id
      businessID
      locationID
      mobileLocationID
      date
      disabled
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteLocationScheduleException = /* GraphQL */ `
  mutation DeleteLocationScheduleException(
    $input: DeleteLocationScheduleExceptionInput!
    $condition: ModelLocationScheduleExceptionConditionInput
  ) {
    deleteLocationScheduleException(input: $input, condition: $condition) {
      id
      businessID
      locationID
      mobileLocationID
      date
      disabled
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createService = /* GraphQL */ `
  mutation CreateService(
    $input: CreateServiceInput!
    $condition: ModelServiceConditionInput
  ) {
    createService(input: $input, condition: $condition) {
      id
      businessID
      name
      code
      blockColour
      offeredIn
      order
      duration
      price
      isDoneParallel
      isSecondary
      isAddon
      enabled
      online
      photo {
        bucket
        key
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      teamMembers {
        items {
          id
          businessID
          serviceID
          teamMemberID
          duration
          price
          maxDaily
          enabled
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const updateService = /* GraphQL */ `
  mutation UpdateService(
    $input: UpdateServiceInput!
    $condition: ModelServiceConditionInput
  ) {
    updateService(input: $input, condition: $condition) {
      id
      businessID
      name
      code
      blockColour
      offeredIn
      order
      duration
      price
      isDoneParallel
      isSecondary
      isAddon
      enabled
      online
      photo {
        bucket
        key
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      teamMembers {
        items {
          id
          businessID
          serviceID
          teamMemberID
          duration
          price
          maxDaily
          enabled
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const deleteService = /* GraphQL */ `
  mutation DeleteService(
    $input: DeleteServiceInput!
    $condition: ModelServiceConditionInput
  ) {
    deleteService(input: $input, condition: $condition) {
      id
      businessID
      name
      code
      blockColour
      offeredIn
      order
      duration
      price
      isDoneParallel
      isSecondary
      isAddon
      enabled
      online
      photo {
        bucket
        key
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      teamMembers {
        items {
          id
          businessID
          serviceID
          teamMemberID
          duration
          price
          maxDaily
          enabled
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const createProduct = /* GraphQL */ `
  mutation CreateProduct(
    $input: CreateProductInput!
    $condition: ModelProductConditionInput
  ) {
    createProduct(input: $input, condition: $condition) {
      id
      businessID
      name
      code
      order
      price
      enabled
      online
      photo {
        bucket
        key
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateProduct = /* GraphQL */ `
  mutation UpdateProduct(
    $input: UpdateProductInput!
    $condition: ModelProductConditionInput
  ) {
    updateProduct(input: $input, condition: $condition) {
      id
      businessID
      name
      code
      order
      price
      enabled
      online
      photo {
        bucket
        key
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteProduct = /* GraphQL */ `
  mutation DeleteProduct(
    $input: DeleteProductInput!
    $condition: ModelProductConditionInput
  ) {
    deleteProduct(input: $input, condition: $condition) {
      id
      businessID
      name
      code
      order
      price
      enabled
      online
      photo {
        bucket
        key
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createTeamMemberService = /* GraphQL */ `
  mutation CreateTeamMemberService(
    $input: CreateTeamMemberServiceInput!
    $condition: ModelTeamMemberServiceConditionInput
  ) {
    createTeamMemberService(input: $input, condition: $condition) {
      id
      businessID
      serviceID
      teamMemberID
      duration
      price
      maxDaily
      enabled
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateTeamMemberService = /* GraphQL */ `
  mutation UpdateTeamMemberService(
    $input: UpdateTeamMemberServiceInput!
    $condition: ModelTeamMemberServiceConditionInput
  ) {
    updateTeamMemberService(input: $input, condition: $condition) {
      id
      businessID
      serviceID
      teamMemberID
      duration
      price
      maxDaily
      enabled
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteTeamMemberService = /* GraphQL */ `
  mutation DeleteTeamMemberService(
    $input: DeleteTeamMemberServiceInput!
    $condition: ModelTeamMemberServiceConditionInput
  ) {
    deleteTeamMemberService(input: $input, condition: $condition) {
      id
      businessID
      serviceID
      teamMemberID
      duration
      price
      maxDaily
      enabled
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createTeamMember = /* GraphQL */ `
  mutation CreateTeamMember(
    $input: CreateTeamMemberInput!
    $condition: ModelTeamMemberConditionInput
  ) {
    createTeamMember(input: $input, condition: $condition) {
      id
      businessID
      code
      username
      givenName
      familyName
      phone
      email
      acceptsBookings
      locationID
      offDays
      offDayExceptions
      seatNo
      queueGap
      entrance
      status
      role
      startDate
      endDate
      photo {
        bucket
        key
      }
      openingHours {
        day
        opensAt
        closesAt
      }
      break {
        day
        opensAt
        closesAt
      }
      estimatedBreakTimes
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      location {
        id
        businessID
        name
        code
        addressID
        phone
        openingHours {
          day
          opensAt
          closesAt
        }
        acceptsBookings
        virtualQueue
        online
        maintenance
        opensOnPublicHolidays
        isMobile
        bookingFlow
        mobileWeekOffset
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        address {
          id
          businessID
          addressLine1
          addressLine2
          addressLine3
          addressLine4
          city
          region
          postCode
          country
          attentionTo
          lat
          lng
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        team {
          nextToken
          startedAt
        }
        photos {
          nextToken
          startedAt
        }
      }
      services {
        items {
          id
          businessID
          serviceID
          teamMemberID
          duration
          price
          maxDaily
          enabled
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      unavailablies {
        items {
          id
          businessID
          teamMemberID
          date
          from
          to
          reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      availabilityExceptions {
        items {
          id
          businessID
          teamMemberID
          date
          from
          to
          breakTime
          allowExtraBreak
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      bookings {
        items {
          id
          businessID
          customerID
          teamMemberID
          locationID
          note
          transactionReference
          paymentMethod
          date
          startTime
          endTime
          dateCancelled
          status
          source
          fee
          additionalFee
          tip
          confirmationReminderSent
          finalReminderSent
          reviewEmailSent
          createdAt
          updatedAt
          stripeID
          version
          transaction
          isNew
          any
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const updateTeamMember = /* GraphQL */ `
  mutation UpdateTeamMember(
    $input: UpdateTeamMemberInput!
    $condition: ModelTeamMemberConditionInput
  ) {
    updateTeamMember(input: $input, condition: $condition) {
      id
      businessID
      code
      username
      givenName
      familyName
      phone
      email
      acceptsBookings
      locationID
      offDays
      offDayExceptions
      seatNo
      queueGap
      entrance
      status
      role
      startDate
      endDate
      photo {
        bucket
        key
      }
      openingHours {
        day
        opensAt
        closesAt
      }
      break {
        day
        opensAt
        closesAt
      }
      estimatedBreakTimes
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      location {
        id
        businessID
        name
        code
        addressID
        phone
        openingHours {
          day
          opensAt
          closesAt
        }
        acceptsBookings
        virtualQueue
        online
        maintenance
        opensOnPublicHolidays
        isMobile
        bookingFlow
        mobileWeekOffset
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        address {
          id
          businessID
          addressLine1
          addressLine2
          addressLine3
          addressLine4
          city
          region
          postCode
          country
          attentionTo
          lat
          lng
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        team {
          nextToken
          startedAt
        }
        photos {
          nextToken
          startedAt
        }
      }
      services {
        items {
          id
          businessID
          serviceID
          teamMemberID
          duration
          price
          maxDaily
          enabled
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      unavailablies {
        items {
          id
          businessID
          teamMemberID
          date
          from
          to
          reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      availabilityExceptions {
        items {
          id
          businessID
          teamMemberID
          date
          from
          to
          breakTime
          allowExtraBreak
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      bookings {
        items {
          id
          businessID
          customerID
          teamMemberID
          locationID
          note
          transactionReference
          paymentMethod
          date
          startTime
          endTime
          dateCancelled
          status
          source
          fee
          additionalFee
          tip
          confirmationReminderSent
          finalReminderSent
          reviewEmailSent
          createdAt
          updatedAt
          stripeID
          version
          transaction
          isNew
          any
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const deleteTeamMember = /* GraphQL */ `
  mutation DeleteTeamMember(
    $input: DeleteTeamMemberInput!
    $condition: ModelTeamMemberConditionInput
  ) {
    deleteTeamMember(input: $input, condition: $condition) {
      id
      businessID
      code
      username
      givenName
      familyName
      phone
      email
      acceptsBookings
      locationID
      offDays
      offDayExceptions
      seatNo
      queueGap
      entrance
      status
      role
      startDate
      endDate
      photo {
        bucket
        key
      }
      openingHours {
        day
        opensAt
        closesAt
      }
      break {
        day
        opensAt
        closesAt
      }
      estimatedBreakTimes
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      location {
        id
        businessID
        name
        code
        addressID
        phone
        openingHours {
          day
          opensAt
          closesAt
        }
        acceptsBookings
        virtualQueue
        online
        maintenance
        opensOnPublicHolidays
        isMobile
        bookingFlow
        mobileWeekOffset
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        address {
          id
          businessID
          addressLine1
          addressLine2
          addressLine3
          addressLine4
          city
          region
          postCode
          country
          attentionTo
          lat
          lng
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        team {
          nextToken
          startedAt
        }
        photos {
          nextToken
          startedAt
        }
      }
      services {
        items {
          id
          businessID
          serviceID
          teamMemberID
          duration
          price
          maxDaily
          enabled
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      unavailablies {
        items {
          id
          businessID
          teamMemberID
          date
          from
          to
          reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      availabilityExceptions {
        items {
          id
          businessID
          teamMemberID
          date
          from
          to
          breakTime
          allowExtraBreak
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      bookings {
        items {
          id
          businessID
          customerID
          teamMemberID
          locationID
          note
          transactionReference
          paymentMethod
          date
          startTime
          endTime
          dateCancelled
          status
          source
          fee
          additionalFee
          tip
          confirmationReminderSent
          finalReminderSent
          reviewEmailSent
          createdAt
          updatedAt
          stripeID
          version
          transaction
          isNew
          any
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const createUnavailablity = /* GraphQL */ `
  mutation CreateUnavailablity(
    $input: CreateUnavailablityInput!
    $condition: ModelUnavailablityConditionInput
  ) {
    createUnavailablity(input: $input, condition: $condition) {
      id
      businessID
      teamMemberID
      teamMember {
        id
        businessID
        code
        username
        givenName
        familyName
        phone
        email
        acceptsBookings
        locationID
        offDays
        offDayExceptions
        seatNo
        queueGap
        entrance
        status
        role
        startDate
        endDate
        photo {
          bucket
          key
        }
        openingHours {
          day
          opensAt
          closesAt
        }
        break {
          day
          opensAt
          closesAt
        }
        estimatedBreakTimes
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        location {
          id
          businessID
          name
          code
          addressID
          phone
          acceptsBookings
          virtualQueue
          online
          maintenance
          opensOnPublicHolidays
          isMobile
          bookingFlow
          mobileWeekOffset
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        services {
          nextToken
          startedAt
        }
        unavailablies {
          nextToken
          startedAt
        }
        availabilityExceptions {
          nextToken
          startedAt
        }
        bookings {
          nextToken
          startedAt
        }
      }
      date
      from
      to
      reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateUnavailablity = /* GraphQL */ `
  mutation UpdateUnavailablity(
    $input: UpdateUnavailablityInput!
    $condition: ModelUnavailablityConditionInput
  ) {
    updateUnavailablity(input: $input, condition: $condition) {
      id
      businessID
      teamMemberID
      teamMember {
        id
        businessID
        code
        username
        givenName
        familyName
        phone
        email
        acceptsBookings
        locationID
        offDays
        offDayExceptions
        seatNo
        queueGap
        entrance
        status
        role
        startDate
        endDate
        photo {
          bucket
          key
        }
        openingHours {
          day
          opensAt
          closesAt
        }
        break {
          day
          opensAt
          closesAt
        }
        estimatedBreakTimes
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        location {
          id
          businessID
          name
          code
          addressID
          phone
          acceptsBookings
          virtualQueue
          online
          maintenance
          opensOnPublicHolidays
          isMobile
          bookingFlow
          mobileWeekOffset
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        services {
          nextToken
          startedAt
        }
        unavailablies {
          nextToken
          startedAt
        }
        availabilityExceptions {
          nextToken
          startedAt
        }
        bookings {
          nextToken
          startedAt
        }
      }
      date
      from
      to
      reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteUnavailablity = /* GraphQL */ `
  mutation DeleteUnavailablity(
    $input: DeleteUnavailablityInput!
    $condition: ModelUnavailablityConditionInput
  ) {
    deleteUnavailablity(input: $input, condition: $condition) {
      id
      businessID
      teamMemberID
      teamMember {
        id
        businessID
        code
        username
        givenName
        familyName
        phone
        email
        acceptsBookings
        locationID
        offDays
        offDayExceptions
        seatNo
        queueGap
        entrance
        status
        role
        startDate
        endDate
        photo {
          bucket
          key
        }
        openingHours {
          day
          opensAt
          closesAt
        }
        break {
          day
          opensAt
          closesAt
        }
        estimatedBreakTimes
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        location {
          id
          businessID
          name
          code
          addressID
          phone
          acceptsBookings
          virtualQueue
          online
          maintenance
          opensOnPublicHolidays
          isMobile
          bookingFlow
          mobileWeekOffset
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        services {
          nextToken
          startedAt
        }
        unavailablies {
          nextToken
          startedAt
        }
        availabilityExceptions {
          nextToken
          startedAt
        }
        bookings {
          nextToken
          startedAt
        }
      }
      date
      from
      to
      reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createAvailabilityException = /* GraphQL */ `
  mutation CreateAvailabilityException(
    $input: CreateAvailabilityExceptionInput!
    $condition: ModelAvailabilityExceptionConditionInput
  ) {
    createAvailabilityException(input: $input, condition: $condition) {
      id
      businessID
      teamMemberID
      teamMember {
        id
        businessID
        code
        username
        givenName
        familyName
        phone
        email
        acceptsBookings
        locationID
        offDays
        offDayExceptions
        seatNo
        queueGap
        entrance
        status
        role
        startDate
        endDate
        photo {
          bucket
          key
        }
        openingHours {
          day
          opensAt
          closesAt
        }
        break {
          day
          opensAt
          closesAt
        }
        estimatedBreakTimes
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        location {
          id
          businessID
          name
          code
          addressID
          phone
          acceptsBookings
          virtualQueue
          online
          maintenance
          opensOnPublicHolidays
          isMobile
          bookingFlow
          mobileWeekOffset
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        services {
          nextToken
          startedAt
        }
        unavailablies {
          nextToken
          startedAt
        }
        availabilityExceptions {
          nextToken
          startedAt
        }
        bookings {
          nextToken
          startedAt
        }
      }
      date
      from
      to
      breakTime
      allowExtraBreak
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateAvailabilityException = /* GraphQL */ `
  mutation UpdateAvailabilityException(
    $input: UpdateAvailabilityExceptionInput!
    $condition: ModelAvailabilityExceptionConditionInput
  ) {
    updateAvailabilityException(input: $input, condition: $condition) {
      id
      businessID
      teamMemberID
      teamMember {
        id
        businessID
        code
        username
        givenName
        familyName
        phone
        email
        acceptsBookings
        locationID
        offDays
        offDayExceptions
        seatNo
        queueGap
        entrance
        status
        role
        startDate
        endDate
        photo {
          bucket
          key
        }
        openingHours {
          day
          opensAt
          closesAt
        }
        break {
          day
          opensAt
          closesAt
        }
        estimatedBreakTimes
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        location {
          id
          businessID
          name
          code
          addressID
          phone
          acceptsBookings
          virtualQueue
          online
          maintenance
          opensOnPublicHolidays
          isMobile
          bookingFlow
          mobileWeekOffset
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        services {
          nextToken
          startedAt
        }
        unavailablies {
          nextToken
          startedAt
        }
        availabilityExceptions {
          nextToken
          startedAt
        }
        bookings {
          nextToken
          startedAt
        }
      }
      date
      from
      to
      breakTime
      allowExtraBreak
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteAvailabilityException = /* GraphQL */ `
  mutation DeleteAvailabilityException(
    $input: DeleteAvailabilityExceptionInput!
    $condition: ModelAvailabilityExceptionConditionInput
  ) {
    deleteAvailabilityException(input: $input, condition: $condition) {
      id
      businessID
      teamMemberID
      teamMember {
        id
        businessID
        code
        username
        givenName
        familyName
        phone
        email
        acceptsBookings
        locationID
        offDays
        offDayExceptions
        seatNo
        queueGap
        entrance
        status
        role
        startDate
        endDate
        photo {
          bucket
          key
        }
        openingHours {
          day
          opensAt
          closesAt
        }
        break {
          day
          opensAt
          closesAt
        }
        estimatedBreakTimes
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        location {
          id
          businessID
          name
          code
          addressID
          phone
          acceptsBookings
          virtualQueue
          online
          maintenance
          opensOnPublicHolidays
          isMobile
          bookingFlow
          mobileWeekOffset
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        services {
          nextToken
          startedAt
        }
        unavailablies {
          nextToken
          startedAt
        }
        availabilityExceptions {
          nextToken
          startedAt
        }
        bookings {
          nextToken
          startedAt
        }
      }
      date
      from
      to
      breakTime
      allowExtraBreak
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createCustomer = /* GraphQL */ `
  mutation CreateCustomer(
    $input: CreateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    createCustomer(input: $input, condition: $condition) {
      id
      businessID
      stripeID
      username
      givenName
      familyName
      transactionReference
      cards {
        pan
        expiry
        transactionReference
      }
      dependents {
        givenName
        familyName
      }
      dob
      vip
      phone
      email
      expoToken
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      bookings {
        items {
          id
          businessID
          customerID
          teamMemberID
          locationID
          note
          transactionReference
          paymentMethod
          date
          startTime
          endTime
          dateCancelled
          status
          source
          fee
          additionalFee
          tip
          confirmationReminderSent
          finalReminderSent
          reviewEmailSent
          createdAt
          updatedAt
          stripeID
          version
          transaction
          isNew
          any
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const updateCustomer = /* GraphQL */ `
  mutation UpdateCustomer(
    $input: UpdateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    updateCustomer(input: $input, condition: $condition) {
      id
      businessID
      stripeID
      username
      givenName
      familyName
      transactionReference
      cards {
        pan
        expiry
        transactionReference
      }
      dependents {
        givenName
        familyName
      }
      dob
      vip
      phone
      email
      expoToken
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      bookings {
        items {
          id
          businessID
          customerID
          teamMemberID
          locationID
          note
          transactionReference
          paymentMethod
          date
          startTime
          endTime
          dateCancelled
          status
          source
          fee
          additionalFee
          tip
          confirmationReminderSent
          finalReminderSent
          reviewEmailSent
          createdAt
          updatedAt
          stripeID
          version
          transaction
          isNew
          any
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const deleteCustomer = /* GraphQL */ `
  mutation DeleteCustomer(
    $input: DeleteCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    deleteCustomer(input: $input, condition: $condition) {
      id
      businessID
      stripeID
      username
      givenName
      familyName
      transactionReference
      cards {
        pan
        expiry
        transactionReference
      }
      dependents {
        givenName
        familyName
      }
      dob
      vip
      phone
      email
      expoToken
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      bookings {
        items {
          id
          businessID
          customerID
          teamMemberID
          locationID
          note
          transactionReference
          paymentMethod
          date
          startTime
          endTime
          dateCancelled
          status
          source
          fee
          additionalFee
          tip
          confirmationReminderSent
          finalReminderSent
          reviewEmailSent
          createdAt
          updatedAt
          stripeID
          version
          transaction
          isNew
          any
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const createQueueReminder = /* GraphQL */ `
  mutation CreateQueueReminder(
    $input: CreateQueueReminderInput!
    $condition: ModelQueueReminderConditionInput
  ) {
    createQueueReminder(input: $input, condition: $condition) {
      id
      businessID
      locationID
      mobileLocationID
      time
      validUntil
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateQueueReminder = /* GraphQL */ `
  mutation UpdateQueueReminder(
    $input: UpdateQueueReminderInput!
    $condition: ModelQueueReminderConditionInput
  ) {
    updateQueueReminder(input: $input, condition: $condition) {
      id
      businessID
      locationID
      mobileLocationID
      time
      validUntil
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteQueueReminder = /* GraphQL */ `
  mutation DeleteQueueReminder(
    $input: DeleteQueueReminderInput!
    $condition: ModelQueueReminderConditionInput
  ) {
    deleteQueueReminder(input: $input, condition: $condition) {
      id
      businessID
      locationID
      mobileLocationID
      time
      validUntil
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createBooking = /* GraphQL */ `
  mutation CreateBooking(
    $input: CreateBookingInput!
    $condition: ModelBookingConditionInput
  ) {
    createBooking(input: $input, condition: $condition) {
      id
      businessID
      dependent {
        givenName
        familyName
      }
      customerID
      teamMember {
        id
        businessID
        code
        username
        givenName
        familyName
        phone
        email
        acceptsBookings
        locationID
        offDays
        offDayExceptions
        seatNo
        queueGap
        entrance
        status
        role
        startDate
        endDate
        photo {
          bucket
          key
        }
        openingHours {
          day
          opensAt
          closesAt
        }
        break {
          day
          opensAt
          closesAt
        }
        estimatedBreakTimes
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        location {
          id
          businessID
          name
          code
          addressID
          phone
          acceptsBookings
          virtualQueue
          online
          maintenance
          opensOnPublicHolidays
          isMobile
          bookingFlow
          mobileWeekOffset
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        services {
          nextToken
          startedAt
        }
        unavailablies {
          nextToken
          startedAt
        }
        availabilityExceptions {
          nextToken
          startedAt
        }
        bookings {
          nextToken
          startedAt
        }
      }
      teamMemberID
      locationID
      note
      transactionReference
      paymentMethod
      paymentDetails {
        method
        amount
        reference
        provider
      }
      services {
        teamMemberService
        service
      }
      products {
        productID
        quantity
      }
      date
      startTime
      endTime
      dateCancelled
      status
      source
      fee
      additionalFee
      tip
      confirmationReminderSent
      finalReminderSent
      reviewEmailSent
      createdAt
      updatedAt
      stripeID
      version
      transaction
      isNew
      any
      _version
      _deleted
      _lastChangedAt
      customer {
        id
        businessID
        stripeID
        username
        givenName
        familyName
        transactionReference
        cards {
          pan
          expiry
          transactionReference
        }
        dependents {
          givenName
          familyName
        }
        dob
        vip
        phone
        email
        expoToken
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        bookings {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const updateBooking = /* GraphQL */ `
  mutation UpdateBooking(
    $input: UpdateBookingInput!
    $condition: ModelBookingConditionInput
  ) {
    updateBooking(input: $input, condition: $condition) {
      id
      businessID
      dependent {
        givenName
        familyName
      }
      customerID
      teamMember {
        id
        businessID
        code
        username
        givenName
        familyName
        phone
        email
        acceptsBookings
        locationID
        offDays
        offDayExceptions
        seatNo
        queueGap
        entrance
        status
        role
        startDate
        endDate
        photo {
          bucket
          key
        }
        openingHours {
          day
          opensAt
          closesAt
        }
        break {
          day
          opensAt
          closesAt
        }
        estimatedBreakTimes
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        location {
          id
          businessID
          name
          code
          addressID
          phone
          acceptsBookings
          virtualQueue
          online
          maintenance
          opensOnPublicHolidays
          isMobile
          bookingFlow
          mobileWeekOffset
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        services {
          nextToken
          startedAt
        }
        unavailablies {
          nextToken
          startedAt
        }
        availabilityExceptions {
          nextToken
          startedAt
        }
        bookings {
          nextToken
          startedAt
        }
      }
      teamMemberID
      locationID
      note
      transactionReference
      paymentMethod
      paymentDetails {
        method
        amount
        reference
        provider
      }
      services {
        teamMemberService
        service
      }
      products {
        productID
        quantity
      }
      date
      startTime
      endTime
      dateCancelled
      status
      source
      fee
      additionalFee
      tip
      confirmationReminderSent
      finalReminderSent
      reviewEmailSent
      createdAt
      updatedAt
      stripeID
      version
      transaction
      isNew
      any
      _version
      _deleted
      _lastChangedAt
      customer {
        id
        businessID
        stripeID
        username
        givenName
        familyName
        transactionReference
        cards {
          pan
          expiry
          transactionReference
        }
        dependents {
          givenName
          familyName
        }
        dob
        vip
        phone
        email
        expoToken
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        bookings {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const deleteBooking = /* GraphQL */ `
  mutation DeleteBooking(
    $input: DeleteBookingInput!
    $condition: ModelBookingConditionInput
  ) {
    deleteBooking(input: $input, condition: $condition) {
      id
      businessID
      dependent {
        givenName
        familyName
      }
      customerID
      teamMember {
        id
        businessID
        code
        username
        givenName
        familyName
        phone
        email
        acceptsBookings
        locationID
        offDays
        offDayExceptions
        seatNo
        queueGap
        entrance
        status
        role
        startDate
        endDate
        photo {
          bucket
          key
        }
        openingHours {
          day
          opensAt
          closesAt
        }
        break {
          day
          opensAt
          closesAt
        }
        estimatedBreakTimes
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        location {
          id
          businessID
          name
          code
          addressID
          phone
          acceptsBookings
          virtualQueue
          online
          maintenance
          opensOnPublicHolidays
          isMobile
          bookingFlow
          mobileWeekOffset
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        services {
          nextToken
          startedAt
        }
        unavailablies {
          nextToken
          startedAt
        }
        availabilityExceptions {
          nextToken
          startedAt
        }
        bookings {
          nextToken
          startedAt
        }
      }
      teamMemberID
      locationID
      note
      transactionReference
      paymentMethod
      paymentDetails {
        method
        amount
        reference
        provider
      }
      services {
        teamMemberService
        service
      }
      products {
        productID
        quantity
      }
      date
      startTime
      endTime
      dateCancelled
      status
      source
      fee
      additionalFee
      tip
      confirmationReminderSent
      finalReminderSent
      reviewEmailSent
      createdAt
      updatedAt
      stripeID
      version
      transaction
      isNew
      any
      _version
      _deleted
      _lastChangedAt
      customer {
        id
        businessID
        stripeID
        username
        givenName
        familyName
        transactionReference
        cards {
          pan
          expiry
          transactionReference
        }
        dependents {
          givenName
          familyName
        }
        dob
        vip
        phone
        email
        expoToken
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        bookings {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const createQueue = /* GraphQL */ `
  mutation CreateQueue(
    $input: CreateQueueInput!
    $condition: ModelQueueConditionInput
  ) {
    createQueue(input: $input, condition: $condition) {
      id
      businessID
      locationID
      teamMemberID
      date
      nextAvailable
      closesAt
      breaksTaken
      maxDuration
      block {
        startTime
        endTime
      }
      gaps {
        from
        to
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateQueue = /* GraphQL */ `
  mutation UpdateQueue(
    $input: UpdateQueueInput!
    $condition: ModelQueueConditionInput
  ) {
    updateQueue(input: $input, condition: $condition) {
      id
      businessID
      locationID
      teamMemberID
      date
      nextAvailable
      closesAt
      breaksTaken
      maxDuration
      block {
        startTime
        endTime
      }
      gaps {
        from
        to
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteQueue = /* GraphQL */ `
  mutation DeleteQueue(
    $input: DeleteQueueInput!
    $condition: ModelQueueConditionInput
  ) {
    deleteQueue(input: $input, condition: $condition) {
      id
      businessID
      locationID
      teamMemberID
      date
      nextAvailable
      closesAt
      breaksTaken
      maxDuration
      block {
        startTime
        endTime
      }
      gaps {
        from
        to
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createPhoto = /* GraphQL */ `
  mutation CreatePhoto(
    $input: CreatePhotoInput!
    $condition: ModelPhotoConditionInput
  ) {
    createPhoto(input: $input, condition: $condition) {
      id
      businessID
      description
      photo {
        bucket
        key
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      location {
        id
        businessID
        name
        code
        addressID
        phone
        openingHours {
          day
          opensAt
          closesAt
        }
        acceptsBookings
        virtualQueue
        online
        maintenance
        opensOnPublicHolidays
        isMobile
        bookingFlow
        mobileWeekOffset
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        address {
          id
          businessID
          addressLine1
          addressLine2
          addressLine3
          addressLine4
          city
          region
          postCode
          country
          attentionTo
          lat
          lng
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        team {
          nextToken
          startedAt
        }
        photos {
          nextToken
          startedAt
        }
      }
      mobileLocation {
        id
        businessID
        locationID
        name
        addressID
        isRepeating
        comingSoon
        colour
        order
        weeksAtLocation
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        address {
          id
          businessID
          addressLine1
          addressLine2
          addressLine3
          addressLine4
          city
          region
          postCode
          country
          attentionTo
          lat
          lng
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        photos {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const updatePhoto = /* GraphQL */ `
  mutation UpdatePhoto(
    $input: UpdatePhotoInput!
    $condition: ModelPhotoConditionInput
  ) {
    updatePhoto(input: $input, condition: $condition) {
      id
      businessID
      description
      photo {
        bucket
        key
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      location {
        id
        businessID
        name
        code
        addressID
        phone
        openingHours {
          day
          opensAt
          closesAt
        }
        acceptsBookings
        virtualQueue
        online
        maintenance
        opensOnPublicHolidays
        isMobile
        bookingFlow
        mobileWeekOffset
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        address {
          id
          businessID
          addressLine1
          addressLine2
          addressLine3
          addressLine4
          city
          region
          postCode
          country
          attentionTo
          lat
          lng
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        team {
          nextToken
          startedAt
        }
        photos {
          nextToken
          startedAt
        }
      }
      mobileLocation {
        id
        businessID
        locationID
        name
        addressID
        isRepeating
        comingSoon
        colour
        order
        weeksAtLocation
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        address {
          id
          businessID
          addressLine1
          addressLine2
          addressLine3
          addressLine4
          city
          region
          postCode
          country
          attentionTo
          lat
          lng
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        photos {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const deletePhoto = /* GraphQL */ `
  mutation DeletePhoto(
    $input: DeletePhotoInput!
    $condition: ModelPhotoConditionInput
  ) {
    deletePhoto(input: $input, condition: $condition) {
      id
      businessID
      description
      photo {
        bucket
        key
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      location {
        id
        businessID
        name
        code
        addressID
        phone
        openingHours {
          day
          opensAt
          closesAt
        }
        acceptsBookings
        virtualQueue
        online
        maintenance
        opensOnPublicHolidays
        isMobile
        bookingFlow
        mobileWeekOffset
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        address {
          id
          businessID
          addressLine1
          addressLine2
          addressLine3
          addressLine4
          city
          region
          postCode
          country
          attentionTo
          lat
          lng
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        team {
          nextToken
          startedAt
        }
        photos {
          nextToken
          startedAt
        }
      }
      mobileLocation {
        id
        businessID
        locationID
        name
        addressID
        isRepeating
        comingSoon
        colour
        order
        weeksAtLocation
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        address {
          id
          businessID
          addressLine1
          addressLine2
          addressLine3
          addressLine4
          city
          region
          postCode
          country
          attentionTo
          lat
          lng
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        photos {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const createChangelog = /* GraphQL */ `
  mutation CreateChangelog(
    $input: CreateChangelogInput!
    $condition: ModelChangelogConditionInput
  ) {
    createChangelog(input: $input, condition: $condition) {
      id
      entityID
      businessID
      action
      diff
      teamMemberID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateChangelog = /* GraphQL */ `
  mutation UpdateChangelog(
    $input: UpdateChangelogInput!
    $condition: ModelChangelogConditionInput
  ) {
    updateChangelog(input: $input, condition: $condition) {
      id
      entityID
      businessID
      action
      diff
      teamMemberID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteChangelog = /* GraphQL */ `
  mutation DeleteChangelog(
    $input: DeleteChangelogInput!
    $condition: ModelChangelogConditionInput
  ) {
    deleteChangelog(input: $input, condition: $condition) {
      id
      entityID
      businessID
      action
      diff
      teamMemberID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createBookingStats = /* GraphQL */ `
  mutation CreateBookingStats(
    $input: CreateBookingStatsInput!
    $condition: ModelBookingStatsConditionInput
  ) {
    createBookingStats(input: $input, condition: $condition) {
      id
      reportType
      businessID
      date
      value
      amount
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateBookingStats = /* GraphQL */ `
  mutation UpdateBookingStats(
    $input: UpdateBookingStatsInput!
    $condition: ModelBookingStatsConditionInput
  ) {
    updateBookingStats(input: $input, condition: $condition) {
      id
      reportType
      businessID
      date
      value
      amount
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteBookingStats = /* GraphQL */ `
  mutation DeleteBookingStats(
    $input: DeleteBookingStatsInput!
    $condition: ModelBookingStatsConditionInput
  ) {
    deleteBookingStats(input: $input, condition: $condition) {
      id
      reportType
      businessID
      date
      value
      amount
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createAd = /* GraphQL */ `
  mutation CreateAd($input: CreateAdInput!, $condition: ModelAdConditionInput) {
    createAd(input: $input, condition: $condition) {
      id
      businessID
      locationID
      mobileLocationIDs
      name
      startDate
      endDate
      status
      image {
        bucket
        key
      }
      link
      duration
      showWhenQueueing
      showWhenBeingServed
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateAd = /* GraphQL */ `
  mutation UpdateAd($input: UpdateAdInput!, $condition: ModelAdConditionInput) {
    updateAd(input: $input, condition: $condition) {
      id
      businessID
      locationID
      mobileLocationIDs
      name
      startDate
      endDate
      status
      image {
        bucket
        key
      }
      link
      duration
      showWhenQueueing
      showWhenBeingServed
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteAd = /* GraphQL */ `
  mutation DeleteAd($input: DeleteAdInput!, $condition: ModelAdConditionInput) {
    deleteAd(input: $input, condition: $condition) {
      id
      businessID
      locationID
      mobileLocationIDs
      name
      startDate
      endDate
      status
      image {
        bucket
        key
      }
      link
      duration
      showWhenQueueing
      showWhenBeingServed
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createPaymentMachine = /* GraphQL */ `
  mutation CreatePaymentMachine(
    $input: CreatePaymentMachineInput!
    $condition: ModelPaymentMachineConditionInput
  ) {
    createPaymentMachine(input: $input, condition: $condition) {
      id
      businessID
      locationID
      name
      type
      settings
      status
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updatePaymentMachine = /* GraphQL */ `
  mutation UpdatePaymentMachine(
    $input: UpdatePaymentMachineInput!
    $condition: ModelPaymentMachineConditionInput
  ) {
    updatePaymentMachine(input: $input, condition: $condition) {
      id
      businessID
      locationID
      name
      type
      settings
      status
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deletePaymentMachine = /* GraphQL */ `
  mutation DeletePaymentMachine(
    $input: DeletePaymentMachineInput!
    $condition: ModelPaymentMachineConditionInput
  ) {
    deletePaymentMachine(input: $input, condition: $condition) {
      id
      businessID
      locationID
      name
      type
      settings
      status
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;

import React, { useState, useEffect } from "react";
import { useForm } from 'react-hook-form'
import { useParams, useHistory} from "react-router";

import {connect} from 'react-redux';

import {Actions as ACTIONS} from 'store/service/action';
import {Actions as DELETEACTIONS} from 'store/deleteModal/action';
import PageHeading from 'components/PageHeading';
import ColorPicker from 'components/formElements/ColorPicker';
import { v4 as uuidv4 } from 'uuid';
import cleanDeep from 'clean-deep'
import Select from 'react-select';

const Service = ({
	service,
	deleteModal,
	createService,
	updateService,
	getService,
	deleteService,
	clearService,
	handleUpload,
	locationOptions,
}) => {

  	let history = useHistory();
	let { id } = useParams();

	// initialId is used for when we are clearing as it causes an issue.
  	const [initialId, setInitialId] = useState(service && service.id)

	const { register, handleSubmit, watch, errors, setValue } = useForm()

	const handleUploadChange = async (e, name) => {
        const file = e.target.files[0];

        if (!file) {
            return;
        }

        const key = await handleUpload(file)
    }

	// background colour is a custom field and needs to be registered
	register("blockColour")
	register("offeredIn")

	const onSubmit = data => {
		console.log(data)


		const item = {
			id: service && service.id || uuidv4(),
			_version: service && service._version,
			name: data.name,
			code: data.code,
			duration: data.duration,
			price: data.price,
			order: data.order,
			blockColour: data.blockColour,
			enabled: data.enabled,
			online: data.online,
			isAddon: data.isAddon,
			isSecondary: data.isSecondary,
			offeredIn: data.offeredIn && data.offeredIn.map(d => d.value),
			isDoneParallel: typeof(data.isDoneParallel) == 'undefined' ? false : data.isDoneParallel,
		}
		console.log('item',item)

		if (service && service.id){

			const updatedItem = cleanDeep(item)
			if (!updatedItem.offeredIn){
				updatedItem.offeredIn = null
			}
			updateService(updatedItem)
		} else {
			delete(item._version);
			console.log('updatedItem',item)
			createService(cleanDeep(item))
		}
	}

	const blockColour = watch('blockColour') || service && service.blockColour;
	const isAddon = watch('isAddon') || watch('isAddon') == null && service && service.isAddon;
	const isSecondary = watch('isSecondary') || watch('isSecondary') == null && service && service.isSecondary;
	const offeredIn = watch('offeredIn') || watch('offeredIn') == null && service && service.offeredIn && service.offeredIn.map(o => locationOptions.find(l => l.value == o));

	useEffect(() => {
		if (!(id !== 'new' && service && service.id && service.id == id)){
			getService(id)
		} else if (id == 'new'){
			clearService()
		}
	},[service && service.id]);

	useEffect(() => {
		if (id == 'new' && service && service.id && service.id !== initialId && service._version == 1){
  			history.push(`/services/${service.id}`)
		}
	},[service && service._version == 1 && service.id]);

	if ( (!service && id !== 'new' ) || (service && id !== 'new' && id !== service.id ) || (id === 'new' && service)){

		return (
			<div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
				Loading...
			</div>
		)
	}

	return (

		<React.Fragment>
			<PageHeading item={service} isNew={id === 'new'}/>
			<div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
				<div className="md:grid md:grid-cols-3 md:gap-6">
					<div className="md:col-span-1">
					  <div className="px-4 sm:px-0">
						<h3 className="text-lg font-medium leading-6 text-gray-900">Service Details</h3>
						<p className="mt-1 text-sm leading-5 text-gray-500">
						  This information will be displayed publicly.
						</p>
					  </div>
					</div>
					<div className="mt-5 md:mt-0 md:col-span-2">
					  <form onSubmit={handleSubmit(onSubmit)}>
						<div className="shadow sm:rounded-md sm:overflow-hidden">
						  <div className="px-4 py-5 bg-white sm:p-6">
							<div className="grid grid-cols-6 gap-6">

								<div className="col-span-6 sm:col-span-4">
									<label htmlFor="name" className="block text-sm font-medium leading-5 text-gray-700">Name</label>
									<input id="name" name="name" defaultValue={service && service.name} ref={register} className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
								</div>

								<div className="col-span-6 sm:col-span-4">
									<label htmlFor="code" className="block text-sm font-medium leading-5 text-gray-700">Code/SKU</label>
									<input id="code" name="code" defaultValue={service && service.code} ref={register} className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
								</div>

								  <div className="col-span-6 sm:col-span-4">
									<label htmlFor="price" className="block text-sm font-medium leading-5 text-gray-700">
									  Duration
									</label>

									<div className="mt-1 flex rounded-md shadow-sm">
									  <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
										<svg class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
										  <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z" clip-rule="evenodd"/>
										</svg>
									  </span>
									  <input id="price" name="duration" defaultValue={service && service.duration} ref={register} className="form-input flex-1 block w-full rounded-none transition duration-150 ease-in-out sm:text-sm sm:leading-5" placeholder="30" />

									  <span className="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
										minutes
									  </span>
									</div>
								  </div>

								  <div className="col-span-6 sm:col-span-4">
									<label htmlFor="price" className="block text-sm font-medium leading-5 text-gray-700">
									  price
									</label>
									<div className="mt-1 flex rounded-md shadow-sm">
									  <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
										€
									  </span>
									  <input id="price" name="price" defaultValue={service && service.price} ref={register} className="form-input flex-1 block w-full rounded-none rounded-r-md transition duration-150 ease-in-out sm:text-sm sm:leading-5" placeholder="15.00" />
									</div>
								  </div>

								  <div className="col-span-6 sm:col-span-4">
									<label htmlFor="order" className="block text-sm font-medium leading-5 text-gray-700">
									  Sort Position
									</label>
									<div className="mt-1 flex rounded-md shadow-sm">
									  <input id="order" type="number" name="order" defaultValue={service && service.order} ref={register} className="form-input flex-1 block w-full rounded-none rounded-r-md transition duration-150 ease-in-out sm:text-sm sm:leading-5" placeholder="15.00" />
									</div>
								  </div>

								  <div className="col-span-6 sm:col-span-4">
										<label htmlFor="order" className="block text-sm font-medium leading-5 text-gray-700">
										  Locations Offered
										</label>
										<div className="mt-1 rounded-md shadow-sm">
										  
												<Select 
													options={locationOptions}
													value={offeredIn}
													isMulti
													onChange={(selected) => {
														setValue(`offeredIn`,selected)
						              }}
												/>
										</div>
								  </div>



									{/* Cannot add photo before we save the team member */}
									{ service && service.id && 
							            <div class="col-span-6 sm:col-span-3">
							              <label for="photo" class="block text-sm leading-5 font-medium text-gray-700">
							                Photo
							              </label>

							              <div class="mt-2 flex items-center">
							                <span class="inline-block h-12 w-12 rounded-full overflow-hidden bg-gray-100">
							                	{
							                		service && service.photo ? 
							                			<img src={`https://${service.photo.bucket}.s3-eu-west-1.amazonaws.com/public/${service.photo.key}`}/>
							                			:
									                  <svg class="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
									                    <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
									                  </svg>
							                	}
							                </span>
							                <span class="ml-5 rounded-md shadow-sm">
							                  	<label htmlFor="upload-button">
									                <div type="button" class="py-2 px-3 border border-gray-300 rounded-md text-sm leading-4 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out inline-block">
									                    Change
									                </div>
									            </label>

												<input
											        type="file"
											        name="image"
											        id="upload-button"
											        style={{ display: "none" }}
											        onChange={handleUploadChange}
											      />
							                </span>
							              </div>
							            </div>
							        }

									{ !isAddon && 

										  <div className="col-span-6 sm:col-span-4">
											<label htmlFor="price" className="text-sm font-medium leading-5 text-gray-700">
											  Background Color
											</label>
											<div style={{
											 		display: "inline-block",
											 	    verticalAlign: "middle",
											 	    marginLeft: "10px",
											 	}}>
											  <ColorPicker onChange={color => { setValue('blockColour', color.hex)}} color={blockColour} />
											</div>
										  </div>
									}

									<div className="col-span-6 sm:col-span-4">
									  <div className="flex items-start">
										<div className="absolute flex items-center h-5">
										  <input id="enabled" name="enabled" ref={register} defaultChecked={service && service.enabled} type="checkbox" className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out" />
										</div>
										<div className="pl-7 text-sm leading-5">
										  <label htmlFor="enabled" className="font-medium text-gray-700">Enabled</label>
										  <p className="text-gray-500">Untick this option if you do not want to take bookings for this service.</p>
										</div>
									  </div>
									</div>

									<div className="col-span-6 sm:col-span-4">
									  <div className="flex items-start">
										<div className="absolute flex items-center h-5">
										  <input id="online" name="online" ref={register} defaultChecked={service && service.online} type="checkbox" className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out" />
										</div>
										<div className="pl-7 text-sm leading-5">
										  <label htmlFor="online" className="font-medium text-gray-700">Can be booked via Website & App</label>
										  <p className="text-gray-500">Tick this option if you would like clients to book this directly.</p>
										</div>
									  </div>
									</div>

									<div className="col-span-6 sm:col-span-4">
									  <div className="flex items-start">
										<div className="absolute flex items-center h-5">
										  <input id="isSecondary" name="isSecondary" ref={register} defaultChecked={service && service.isSecondary} type="checkbox" className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out" />
										</div>
										<div className="pl-7 text-sm leading-5">
										  <label htmlFor="isSecondary" className="font-medium text-gray-700">Secondary Service</label>
										  <p className="text-gray-500">This service is a Secondary Service to be purchased/booked with a primary service.</p>
										</div>
									  </div>
									</div>

									<div className="col-span-6 sm:col-span-4">
									  <div className="flex items-start">
										<div className="absolute flex items-center h-5">
										  <input id="isAddon" name="isAddon" ref={register} defaultChecked={service && service.isAddon} type="checkbox" className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out" />
										</div>
										<div className="pl-7 text-sm leading-5">
										  <label htmlFor="isAddon" className="font-medium text-gray-700">Add-on</label>
										  <p className="text-gray-500">This service is an Add-on to be purchased/booked with a primary service.</p>
										</div>
									  </div>
									</div>

									{ isAddon && 
										<div className="col-span-6 sm:col-span-4">
										  <div className="flex items-start">
											<div className="absolute flex items-center h-5">
											  <input id="isDoneParallel" name="isDoneParallel" ref={register} defaultChecked={service && service.isDoneParallel} type="checkbox" className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out" />
											</div>
											<div className="pl-7 text-sm leading-5">
											  <label htmlFor="isDoneParallel" className="font-medium text-gray-700">Takes No Additional Time</label>
											  <p className="text-gray-500">This service can be provided at the same time of the main service or whilst the client is waiting.</p>
											</div>
										  </div>
										</div>
									}

								{/*<div className="mt-6">
								  <label htmlFor="about" className="block text-sm leading-5 font-medium text-gray-700">
									About
								  </label>
								  <div className="rounded-md shadow-sm">
									<textarea id="about" rows="3" className="form-textarea mt-1 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5" placeholder="you@example.com"></textarea>
								  </div>
								  <p className="mt-2 text-sm text-gray-500">
									Brief description for your profile. URLs are hyperlinked.
								  </p>
								</div>*/}

							</div>
						  </div>
						  <div className="md:grid md:grid-cols-2 md:gap-6 bg-gray-50 ">
							  <div className="px-4 py-3 text-left sm:px-6">
								<span className="inline-flex rounded-md shadow-sm">
								  <button 
								  	onClick={(e) => {
								  		e.preventDefault()
								  		e.stopPropagation()

								  		deleteModal(() => {
							  				deleteService(id,service._version,history)
								  		})
								  	}} 
								  	className="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red active:bg-red-700 transition duration-150 ease-in-out">
									Delete
								  </button>
								</span>
							  </div>
							  <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
								<span className="inline-flex rounded-md shadow-sm">
								  <button type="submit" className="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">
									Save
								  </button>
								</span>
							  </div>
						  </div>
						</div>
					  </form>
					</div>
				</div>
			</div>
		</React.Fragment>
		)
}


const mapStateToProps = (state) => {
	return {
	  service: state.service.item,
    locationOptions: state.location && state.location.list && state.location.list.map(l => ({
														value: l.id,
														label: l.name
													})) || []
  }
}

const mapDispatchToProps = dispatch => ({
  deleteModal: (fn) => dispatch(DELETEACTIONS.toggleDeleteModal(true,fn)),
  deleteService: (id,version,history) => dispatch(ACTIONS.deleteItem(id,version,history)), 
  createService: (item) => dispatch(ACTIONS.createItem(item)), 
  updateService: (item) => dispatch(ACTIONS.updateItem(item)),
  getService: (id) => dispatch(ACTIONS.getItem(id)),
  clearService: () => dispatch(ACTIONS.clearItem()),
  handleUpload: (file,preFileName) => dispatch(ACTIONS.handleUpload(file,preFileName)),
  // timesheetUpdateSaveStatus: (status) => dispatch(ACTIONS.timesheetUpdateSaveStatus(status))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Service) 
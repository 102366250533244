import React, { useState, useEffect } from "react";
import { useForm } from 'react-hook-form'
import { useParams, useHistory} from "react-router";

import {connect} from 'react-redux';

import {Actions as ACTIONS} from 'store/ads/action';
import {Actions as LOCATIONACTIONS} from 'store/location/action';
import PageHeading from 'components/PageHeading';
import { v4 as uuidv4 } from 'uuid';
import cleanDeep from 'clean-deep'
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import { format, parse, setYear, addYears, addDays, setDate, setMonth } from 'date-fns'
import Calendar from 'react-calendar-multiday'

import "react-datepicker/dist/react-datepicker.css";
import "styles/datepicker.css";


const ExampleCustomInput = ({ value, onClick }) => (
	<button onClick={onClick} type="button" class="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150">
		{value} {/*{currentDate && format(new Date(currentDate),'dd/MM/yyyy')}*/}
	</button>
);

const Ad = ({
		ad,
		createAd,
		updateAd,
		getAd,
		locations,
		listLocations,
		handleUpload,
		mobileLocations,
	}) => {

  	let history = useHistory();
	let { id } = useParams();

	// initialId is used for when we are clearing as it causes an issue.
  	const [initialId, setInitialId] = useState(ad && ad.id)

	const { register, setValue, handleSubmit, watch, errors, reset } = useForm()

	const handleUploadChange = async (e, name) => {
        const file = e.target.files[0];

        if (!file) {
            return;
        }

        const key = await handleUpload(file)
    }

	useEffect(() => {
		console.log('id',locations)
		if (!(locations)){
			console.log('getLocations')
			listLocations(1000,null)
		}
	},[]);

  	const startDate = watch('startDate')
  	const endDate = watch('endDate')
	register({ name: 'startDate', type: 'custom'}, { required: false })
	register({ name: 'endDate', type: 'custom'}, { required: false })

	const onSubmit = data => {
		console.log(data)

		const item = {
			id: ad && ad.id || uuidv4(),
			_version: ad && ad._version,
			name: data.name,
			duration: data.duration,
			startDate: format(data.startDate,"yyyy-MM-dd"),
			endDate: format(data.endDate,"yyyy-MM-dd"),
			locationID: data.locationID,
			mobileLocationIDs: data.mobileLocationIDs,
			showWhenQueueing: data.showWhenQueueing,
			showWhenBeingServed: data.showWhenBeingServed,
			status: data.status,
			link: data.link,
		}
		console.log('item',item)

		if (ad && ad.id){
			const cleanedItem = cleanDeep(item);
			if (!cleanedItem.familyName && ad.familyName){
				cleanedItem.familyName = null
			}
			if (!cleanedItem.startDate && ad.startDate){
				cleanedItem.startDate = null
			}
			if (!cleanedItem.endDate && ad.endDate){
				cleanedItem.endDate = null
			}
			updateAd(cleanedItem)
		} else {
			delete(item._version);
			console.log('updatedItem',item)
			createAd(cleanDeep(item))
		}
	}

	useEffect(() => {
		if (!(id !== 'new' && ad && ad.id && ad.id == id)){
			getAd(id)
		} else if (id == 'new'){
			// clearAd()
		} else {
			console.log('setAd',ad)

			reset({
				...ad,
				startDate: new Date(ad.startDate),
				endDate: new Date(ad.endDate),
			}); 
		}
	},[ad && ad.id]);

	useEffect(() => {
		if (id == 'new' && ad && ad.id && ad.id !== initialId && ad._version == 1){
  			history.push(`/ad/${ad.id}`)
		}
	},[ad && ad._version == 1 && ad.id]);

	if ( (!ad && id !== 'new' ) || (ad && id !== 'new' && id !== ad.id ) || (id === 'new' && ad)){

		return (
			<div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
				Loading...
			</div>
		)
	}

	return (

		<React.Fragment>
			<PageHeading item={ad} isNew={id === 'new'} label={ad && ad.name}/>
			<div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
				<div className="md:grid md:grid-cols-3 md:gap-6">
					<div className="md:col-span-1">
					  <div className="px-4 sm:px-0">
						<h3 className="text-lg font-medium leading-6 text-gray-900">Ad Details</h3>
						<p className="mt-1 text-sm leading-5 text-gray-500">
						  This information will be displayed publicly.
						</p>


		                <span class="inline-block overflow-hidden bg-gray-100 mt-5">
		                	{
		                		ad && ad.image &&
		                			<img src={`https://${ad.image.bucket}.s3-eu-west-1.amazonaws.com/public/${ad.image.key}`}/>
		                	}
		                </span>

					  </div>
					</div>
					<div className="mt-5 md:mt-0 md:col-span-2">
					  <form onSubmit={handleSubmit(onSubmit)}>
						<div className="shadow sm:rounded-md sm:overflow-hidden">
						  <div className="px-4 py-5 bg-white sm:p-6">
							<div className="grid grid-cols-6 gap-6">


								<div class="col-span-6 sm:col-span-3">
									<label for="name" class="block text-sm font-medium leading-5 text-gray-700">Name</label>
									<input id="name" name="name" defaultValue={ad && ad.name} ref={register} class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
								</div>

								<div class="col-span-6 sm:col-span-3">
									<label for="link" class="block text-sm font-medium leading-5 text-gray-700">Link</label>
									<input id="link" name="link" defaultValue={ad && ad.link} ref={register} class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
								</div>

								<div className="col-span-6 sm:col-span-3">
									<label htmlFor="locationID" className="block text-sm font-medium leading-5 text-gray-700">Location</label>
									<select id="locationID" name="locationID" ref={register} className="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
										{
											locations && locations.map(location => (
												<option value={location.id}>{location.name} ({location.address && location.address.postCode})</option>
											))
										}
										{/* TODO Add official location list */}
									</select>
								</div>

								<div className="col-span-6 sm:col-span-3">
									<label htmlFor="mobileLocationIDs" className="block text-sm font-medium leading-5 text-gray-700">Mobile Locations</label>
									<select multiple={true} id="mobileLocationIDs" name="mobileLocationIDs" ref={register} className="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
										{
											mobileLocations && mobileLocations.map(location => (
												<option value={location.id}>{location.name} ({location.address && location.address.postCode})</option>
											))
										}
										{/* TODO Add official location list */}
									</select>
								</div>

								<div className="col-span-6 sm:col-span-3">
									<label htmlFor="status" className="block text-sm font-medium leading-5 text-gray-700">Status</label>
									<select id="status" name="status" defaultValue={ad && ad.status} ref={register} className="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
										<option>Active</option>
										<option>Paused</option>
										<option>Complete</option>
									</select>
								</div>

								<div class="col-span-6 sm:col-span-3">
									<label for="duration" class="block text-sm font-medium leading-5 text-gray-700">Duration</label>
									<input id="duration" name="duration" defaultValue={ad && ad.duration} ref={register} class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
								</div>

								<div className="col-span-6 sm:col-span-3">
									<label htmlFor="role" className="block text-sm font-medium leading-5 text-gray-700">Show From</label>
									<DatePicker
				                      selected={startDate}
				                      onChange={(selectedTime) => {
				                        setValue(`startDate`,selectedTime)
				                      }}
				                      isClearable
				                      className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
				                      dateFormat="d MMMM yyyy"
				                      />
								</div>

								<div className="col-span-6 sm:col-span-3">
									<label htmlFor="role" className="block text-sm font-medium leading-5 text-gray-700">Show Until</label>
									<DatePicker
				                      selected={endDate}
				                      onChange={(selectedTime) => {
				                        setValue(`endDate`,selectedTime)
				                      }}
				                      isClearable
				                      className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
				                      dateFormat="d MMMM yyyy"
				                      />
								</div>

								{/* Cannot add image before we save the ad */}
								{ ad && ad.id && 
						            <div class="col-span-6 sm:col-span-3">
						              <label for="image" class="block text-sm leading-5 font-medium text-gray-700">
						                Image
						              </label>

						              <div class="mt-2 flex items-center">
						                <span class="ml-5 rounded-md shadow-sm">
						                  	<label htmlFor="upload-button">
								                <div type="button" class="py-2 px-3 border border-gray-300 rounded-md text-sm leading-4 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out inline-block">
								                    Change
								                </div>
								            </label>

											<input
										        type="file"
										        name="image"
										        id="upload-button"
										        style={{ display: "none" }}
										        onChange={handleUploadChange}
										      />
						                </span>
						              </div>
						            </div>
						        }

								<div className="col-span-6 sm:col-span-4">
								  <div className="flex items-start">
									<div className="absolute flex items-center h-5">
									  <input id="acceptsBookings" name="showWhenQueueing" ref={register} defaultChecked={ad && ad.showWhenQueueing} type="checkbox" className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out" />
									</div>
									<div className="pl-7 text-sm leading-5">
									  <label htmlFor="showWhenQueueing" className="font-medium text-gray-700">Show When Queueing</label>
									  <p className="text-gray-500">Show this Ad when customer is waiting in Queue</p>
									</div>
								  </div>
								</div>


								<div className="col-span-6 sm:col-span-4">
								  <div className="flex items-start">
									<div className="absolute flex items-center h-5">
									  <input id="acceptsBookings" name="showWhenBeingServed" ref={register} defaultChecked={ad && ad.showWhenBeingServed} type="checkbox" className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out" />
									</div>
									<div className="pl-7 text-sm leading-5">
									  <label htmlFor="showWhenBeingServed" className="font-medium text-gray-700">Show When Being Served</label>
									  <p className="text-gray-500">Show this ad while customer is being served?</p>
									</div>
								  </div>
								</div>

							</div>
						  </div>
						  <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
							<span className="inline-flex rounded-md shadow-sm">
							  <button type="submit" className="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">
								Save
							  </button>
							</span>
						  </div>
						</div>
					  </form>
					</div>
				</div>
			</div>
		</React.Fragment>
		)
}

const mapStateToProps = (state) => {
	return {
	  ad: state.ad.item,
      locations: state.location.list,
      mobileLocations: state.mobileLocation.list,
  }
}

const mapDispatchToProps = dispatch => ({
  createAd: (item) => dispatch(ACTIONS.createItem(item)),
  updateAd: (item) => dispatch(ACTIONS.updateItem(item)),
  getAd: (id) => dispatch(ACTIONS.getItem(id)),
  listLocations: (limit,nextToken) => dispatch(LOCATIONACTIONS.getList(limit,nextToken)),
  handleUpload: (file,preFileName) => dispatch(ACTIONS.handleUpload(file,preFileName)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Ad) 
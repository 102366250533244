import React, { useState, useEffect } from "react";
import Paper from '@material-ui/core/Paper';
import {
  ViewState, GroupingState, IntegratedGrouping, IntegratedEditing, EditingState,
} from '@devexpress/dx-react-scheduler';
import {
  Scheduler,
  Resources,
  Appointments,
  AppointmentTooltip,
  GroupingPanel,
  DayView,
  DragDropProvider,
  AppointmentForm,
  WeekView,
  CurrentTimeIndicator,
  ViewSwitcher,
  DateNavigator,
  TodayButton,
  Toolbar,
// } from '@devexpress/dx-react-scheduler-material-ui';
} from '..//schedulerTheme';
import { CustomHeader } from './scheduler/CustomHeader';
import { CustomMobileHeader } from './scheduler/CustomMobileHeader';
import {
  teal, indigo, yellow
} from '@material-ui/core/colors';
import BookingForm from 'components/booking/BookingForm';
import PaymentForm from 'components/booking/PaymentForm';
import {connect} from 'react-redux';

import {Actions as ACTIONS} from 'store/booking/action';
import {Actions as NOTIFICATIONACTIONS} from 'store/notifications/action';
import { format, parse, addMinutes } from 'date-fns'
import cleanDeep from 'clean-deep';
import _ from 'lodash';
import Color from 'color';

import Select from 'react-select';

import { publicHolidays } from '../../lib/publicHolidays';

import {
  // BrowserView,
  // MobileView,
  // isBrowser,
  // isMobile,
  isIOS,
  isSafari,
} from "react-device-detect";
import { it } from "date-fns/locale";

const grouping = [{
        resourceName: 'members',
      }]

const CommandLayout = ({onCommitButtonClick}) => {
    // console.log('rendering CommandLayout')
    return (
      <div></div>
    )
  }

const selectDefaultStyle = {
  control: styles => ({ ...styles, backgroundColor: 'white', border: 'none' }),

  menu: (provided, state) => ({
    ...provided,
    zIndex: 999999,
    // lineHeight: "14px",
  }),
};

// const MyToolbar = (props) => {
//   console.log('ToolbarProps',props)
//   return (
//           <div>{props.children}</div>
//     )
// }

const getNumberWithOrdinal = (n) => {
  var s = ["th", "st", "nd", "rd"],
      v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
}

const BookingSchedule = ({
  business,
  location,
  filter,
  locations,
  virtualQueue,
  locationBookings,
  teamMembers,
  services,
  customers,
  updateBooking,
  unavailability,
  availabilityExceptions,
  addNotification,
  permissions,
  canEdit,
  me,
  username
}) => {

  const [showQueuePaymentModal, setShowQueuePaymentModal] = useState(false)

  let startDayHour = null
  let endDayHour = null

  const teamMembersInLocation = location && teamMembers && teamMembers.filter(
      teamMember => teamMember.locationID == location.value && teamMember.status == "Active" && !teamMember._deleted//  // && teamMember.acceptsBookings
                      && (permissions !== "OwnBookings" || teamMember.username == username)
                      && teamMember.role == "OwnBookings"
    ).map(teamMember => {

      // set location opening and ending hours
      teamMember.openingHours && teamMember.openingHours.map(openingHour => {

        const openingTime = parseInt(openingHour.opensAt.split(':')[0])
        const closeTime = parseInt(openingHour.closesAt.split(':')[0])

        console.log('openingTime',openingTime, openingTime === 0)

        if (!startDayHour || openingTime === 0 || openingTime < startDayHour){
          startDayHour = openingTime;
        }
        if (!endDayHour || closeTime >= endDayHour){
          endDayHour = closeTime + 1;
        }

      })

      return ({
        ...teamMember,
        text: `${teamMember.givenName} ${teamMember.familyName ? teamMember.familyName : ""}`
      })
    }) || []


        console.log('openingTime-startDayHour',startDayHour)

  teamMembersInLocation.length == 0 && teamMembersInLocation.push([{id: 0, text: "loading"}])

  const resources =  [{
      fieldName: 'members',
      title: 'Members',
      instances: teamMembersInLocation,
      allowMultiple: true,
    }];

  const timeNow = format(new Date(),"HH:mm")
  const today = format(new Date(),"yyyy-MM-dd")

  const availabilityExeptions = availabilityExceptions && availabilityExceptions.find(item => !item._deleted)
  // console.log('availabilityExeptions',availabilityExeptions); 

  availabilityExceptions && availabilityExceptions.map(a => {

    if (a.from && a.to){

      const openingTime = parseInt(a.from.split(':')[0])
      const closeTime = parseInt(a.to.split(':')[0])

      if (!startDayHour || openingTime === 0 || openingTime < startDayHour){
        startDayHour = openingTime;
      }
      if (!endDayHour || closeTime >= endDayHour){
        endDayHour = closeTime + 1;
      }
    }
  })

  let breakNo = {}


    const appointments = locationBookings && locationBookings
      .filter(booking => filter.value.split(',').includes(booking.status) )
      .map(booking => {

        let primaryService = null
        let secondaryColour = null

        const addonServiceLabels = [] 

        booking.services && booking.services.map(
            bookingService => {
              const service =  services.find(
                service => {
                  return service.id == bookingService.service 
                }
              )

              if (!service){
                return;
              }

              if (service && service.isAddon || primaryService){
                addonServiceLabels.push(service.name)

                if (service.isSecondary){
                  secondaryColour = service.blockColour
                }
              } else {
                primaryService = service;
              }
            }
          )

        // console.log('primaryService',primaryService)


        const customer = booking.customer 

        const customerLabel = customer && `${booking.dependant ? `${booking.dependant.givenName} ${booking.dependant.familyName}` : `${customer.givenName} ${customer.familyName}`} (${customer.phone})`

        // console.log('addonServiceLabels',addonServiceLabels)
        const startTime = new Date(`${booking.date} ${booking.startTime}`)
        const fifteenMinAfterStartTime = addMinutes(startTime,15)
        let endTime = new Date(`${booking.date} ${booking.endTime}`)

        // do not allow calendar blocks to take less than 15mins
        // if (fifteenMinAfterStartTime > endTime){
        //   endTime = fifteenMinAfterStartTime
        // }

        let customerPhone = customer && customer.phone

        if (customer && customer.id == "eea3b93a-eaae-439f-89ec-d7318fbf27b1"){
          
          if (!breakNo[booking.teamMemberID]){
            breakNo[booking.teamMemberID] = 1;
          } else {
            breakNo[booking.teamMemberID]++
          }
          // customerPhone = `${getNumberWithOrdinal(breakNo[booking.teamMemberID])} Break`
          customerPhone = `Break`
        }

        if (customer && customer.id == "722295f6-1200-4f55-8099-16c8ed4e6de0"){
          customerPhone = `BLOCK`
        }


        return ({
          id: booking.id,
          booking,
          title: `${addonServiceLabels.join(', ')} for ${customerLabel}`,
          customerName: customer && `${customer.givenName ? customer.givenName : ''} ${customer.familyName ? customer.familyName : ''}`,
          customerPhone,
          customerEmail: customer && customer.email,
          dependant: customer && customer.dependant,
          primaryService,
          addonServices: `${addonServiceLabels.join(', ')}`,
          members: [booking.teamMemberID],
          isQueue: virtualQueue,
          // roomId: 1,
          startDate: startTime,
          endDate: endTime,
          fee: booking.fee,
          status: booking.status == "Present" ? 
                    "Arrived" 
                    : booking.status ,
          backgroundColor: secondaryColour || (primaryService && primaryService.blockColour),
        })
      }
    ) || []

  const commitChanges = ({ added, changed, deleted }) => {
    // console.log('added',added,'changed',changed,'deleted',deleted)

      if (changed){

        Object.keys(changed).map(bookingId => {

          const booking = appointments.find(item => item.id == bookingId)
          console.log('item toSubmitData booking',booking,bookingId,[...booking.booking.services])

          const bookingChanges = changed[bookingId]
          const bookingServices = booking.booking.services
          let doNotSave = false

          if (booking.booking.teamMemberID != bookingChanges.members[0]){

            const teamMember = teamMembers.find(teamMember => teamMember.id == bookingChanges.members[0])
            const teamMemberServices = teamMember && teamMember.services && teamMember.services.items && teamMember.services.items

            console.log('teamMember',teamMember)

            bookingServices.map((service,index) => {
              const teamMemberService = teamMemberServices.find(s => s.serviceID == service.service)

              console.log('teamMemberService TEST', teamMemberService, teamMemberServices, service.service)

              if (teamMemberService){
                bookingServices[index].teamMemberService = teamMemberService.id
              } else {
                const serviceDetails = services.find(s => s.id == service.service)

                // send a notification 
                addNotification({
                  title: <React.Fragment><strong>Warning</strong> {teamMember.givenName} does not offer {serviceDetails.name}</React.Fragment>,
                  message: `Cannot save your change as this team member does not offer the same services as the original booking.`,
                  icon: `warning`,
                },5000)

                doNotSave = true;
              }

              // TODO check if employee has services (if not send warning message and bock saving)
              // else just ensure the services are linked correctly

              /*if (teamMemberService.maxDaily){

                console.log('bookingDate serv',teamMemberBookings[0].services,teamMemberService.serviceID)
                const alreadyExist = teamMemberBookings.filter(item => item.date == format(bookingDate,"yyyy-MM-dd") && (item.services.filter(s => s.service == teamMemberService.serviceID)) )
                
                if ( teamMemberService.maxDaily >= alreadyExist.length){
                  maxedOut.push({teamMemberService,service});
                }
                console.log('maxedOut',maxedOut)
              }*/

            })
          }

          const teamMemberBookings = locationBookings.filter(item => item.teamMemberID == bookingChanges.members[0])


          
          const minutes = parseFloat(format(bookingChanges.startDate,"mm"))
          const targetMinutes = Math.floor(minutes/60*4)/4*60
          const minuteDifference = targetMinutes - minutes

          const bookingStartTime = addMinutes(bookingChanges.startDate,minuteDifference)
          const bookingEndTime = addMinutes(bookingChanges.endDate,minuteDifference)

          console.log('current booking', bookingId)
          // check if has another booking
          const isBooked = teamMemberBookings.find( 
            item => {
              console.log('checking with', item.id)
              return item.status !== "Cancelled" && item.status !== "NoShow" && item.id !== bookingId && (
                      (item.startTime >= format(bookingStartTime,"HH:mm") && item.startTime < format(bookingEndTime,"HH:mm")) ||  
                      (item.endTime <= format(bookingEndTime,"HH:mm") && item.endTime > format(bookingStartTime,"HH:mm"))
                    )
              }
          )
          if (isBooked){
            doNotSave = true

             addNotification({
              title: <React.Fragment><strong>Warning</strong> there is an overlapping booking.</React.Fragment>,
              message: `You cannot have two bookings at the same time.`,
              icon: `warning`,
            },5000)
          }

          // check if day off or not a working day

          if (doNotSave){
            return
          }

          // is booking in past?
          const isInPast = bookingStartTime.getTime() < Date.now()

          if (isInPast && !business.allowBackDatedBookings){

            // send a notification 
            addNotification({
              title: <React.Fragment><strong>Warning</strong> you cannot change this booking time.</React.Fragment>,
              message: `You are not allowed to move bookings in the past.`,
              icon: `warning`,
            },5000)

          } else {

            // TODO if barber changed validate new barber does services

            // console.log('minuteDifference',minutes,targetMinutes,minuteDifference)

            const item = {
              id: bookingId,
              _version: booking && booking.booking._version,
              services: bookingServices,
              teamMemberID: bookingChanges.members[0],
              date: format(bookingChanges.startDate,"yyyy-MM-dd"),
              startTime: format(bookingStartTime,"HH:mm"),
              endTime: format(bookingEndTime,"HH:mm")
            }
            // console.log('item toSubmitData',item)

            updateBooking(cleanDeep(item))
          }

        })
      }

      if (deleted){

        if (!Array.isArray(deleted)){

          // console.log('deletedBookingID zz12',deleted)
          const booking = appointments.find(item => item.id == deleted)
          // console.log('item toSubmitData booking zz12',booking,appointments)

          const item = {
            id: deleted,
            _version: booking && booking.booking._version,
            status: "Cancelled"
          }
          updateBooking(cleanDeep(item))

        } else {
          // console.log('deleted zz12',deleted)
          // set booking to cancelled not a permanent delete
          Object.keys(deleted).map(bookingId => {

            // console.log('deletedBookingID zz12',bookingId)
            const booking = appointments.find(item => item.id == bookingId)
            // console.log('item toSubmitData booking zz12',booking,appointments)

            const item = {
              id: bookingId,
              _version: booking && booking.booking._version,
              status: "Cancelled"
            }
            // console.log('item toSubmitData',item)

            updateBooking(cleanDeep(item))

          })
        }

      }

    /*this.setState((state) => {
      let { data } = state;
      if (added) {
        const startingAddedId = data.length > 0 ? data[data.length - 1].id + 1 : 0;
        data = [...data, { id: startingAddedId, ...added }];
      }
      if (changed) {
        data = data.map(appointment => (
          changed[appointment.id] ? { ...appointment, ...changed[appointment.id] } : appointment));
      }
      if (deleted !== undefined) {
        data = data.filter(appointment => appointment.id !== deleted);
      }
      return { data };
    });*/
  }

  const saveBooking = () => {
    console.log('saveBooking');
  }

  if (!(locations && teamMembers)){
    return <div>Loading</div>
  }


  const TimeTableCell = ({onDoubleClick, ...restProps}) => { 

    const groupingInfo = restProps.groupingInfo
    const teamMember = teamMembers.find(item => item.id == groupingInfo[0].id)

    const publicHolidaysForYear = publicHolidays(format(restProps.startDate,'yyyy'))
    const isPublicHoliday = publicHolidaysForYear.includes(format(restProps.startDate,'yyyy-MM-dd'))

    // console.log('publicHolidaysForYear',publicHolidaysForYear);

    const weekDay = isPublicHoliday ? "PublicHoliday" : format(restProps.startDate,'EEEE');

    // console.log('weekDay',weekDay);

    // unavailability should only be showing today so check if this team member is unavailable
    const isException = teamMember && availabilityExceptions && availabilityExceptions.find(item => !item._deleted && item.teamMemberID == (teamMember && teamMember.id))
    const isUnavailable = teamMember && unavailability && unavailability.find(item => !item._deleted && item.teamMemberID == (teamMember && teamMember.id))

    // console.log('TimeTableCellProps',teamMember && teamMember.offDays,weekDay)
    const isOffDay = teamMember && teamMember.offDays && teamMember.offDays.find(offDay => offDay == weekDay)

    let isWorking = false

    if (teamMember && teamMember.openingHours){
      // there are specific hours set lets check if this time is in the zone
      
      let timeSet = false
      teamMember.openingHours && teamMember.openingHours.map(openingHour => {

        // console.log('isWorking openingHour.day',openingHour.day,weekDay)

        const containsDay = openingHour.day && openingHour.day.includes(weekDay)

        if (!containsDay) return;

        timeSet = true
        const openingTime = parse(isException?.from ?? openingHour.opensAt,"HH:mm",restProps.startDate)
        const closeTime = parse(isException?.to ?? openingHour.closesAt,"HH:mm",restProps.startDate)

        // console.log(
        //   'isException?',
        //   isException,
        // )
        if (restProps.startDate >= openingTime && restProps.startDate < closeTime){
          isWorking = true
        }

        if (
            teamMember.estimatedBreakTimes && teamMember.estimatedBreakTimes.includes(format(restProps.startDate,"HH:mm")) || // original 15min
            teamMember.estimatedBreakTimes && teamMember.estimatedBreakTimes.includes(format(addMinutes(restProps.startDate,-15),"HH:mm")) || // next 15min (as break is 30min)
            isException?.breakTime == (format(restProps.startDate,"HH:mm")) || // original 15min for break exception
            isException?.breakTime == (format(addMinutes(restProps.startDate,-15),"HH:mm")) // next 15min (as break is 30min)
          ){
          isWorking = false
        }

      })

      if (!timeSet && isException){
        const openingTime = parse(isException?.from,"HH:mm",restProps.startDate)
        const closeTime = parse(isException?.to,"HH:mm",restProps.startDate)

        // console.log(
        //   'isException?',
        //   isException,
        // )
        if (restProps.startDate >= openingTime && restProps.startDate < closeTime){
          isWorking = true
        }

        if (
            isException?.breakTime == (format(restProps.startDate,"HH:mm")) || // original 15min for break exception
            isException?.breakTime == (format(addMinutes(restProps.startDate,-15),"HH:mm")) // next 15min (as break is 30min)
          ){
          isWorking = false
        }

      }

    }

    // console.log('TimeTableCellProps',teamMember && teamMember.offDays , isOffDay)

    if ((isOffDay || isUnavailable || isPublicHoliday) && !isException){
      isWorking = false
      // console.log('TimeTableCellProps',restProps,teamMember,weekDay)
    }
    
    let isInPast = false;
    if (restProps.startDate < new Date()){
      isInPast = true;
    }

    return(
      <WeekView.TimeTableCell
        {...restProps}
        isShaded={(isWorking) ? restProps.isShaded: isUnavailable?.reason == "Sick" ? 6 : isUnavailable?.reason == "Leave" ? 5 : isPublicHoliday ? 4 : true}
        onDoubleClick={(isWorking && (!isInPast || business.allowBackDatedBookings) ) ? onDoubleClick : undefined}
      />
    )
  };

  const preSavedDate = localStorage.getItem('calendarBookingDate')
  const defaultCurrentDate = preSavedDate ? preSavedDate : format(new Date(),"yyyy-MM-dd")
  // console.log('defaultCurrentDate',defaultCurrentDate,preSavedDate)
/*
  const TimeTableCell = React.useCallback(React.memo(({ onDoubleClick, ...restProps }) => (
    <WeekView.TimeTableCell
      {...restPropsc
      onDoubleClick={allowAdding ? onDoubleClick : undefined}
    />
  )), [allowAdding]);
*/
// 
  if (isIOS || isSafari){

    const myAppointments = appointments.filter(app => app.members[0] == me.id);

    // doesn't render the normal scheduler so let's just list appointments
    return (
      <div>

        { showQueuePaymentModal && 
          <div 
            style={{
              position:"fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor:"rgba(0,0,0,0.7)",
              zIndex:1
            }}
            onClick={() => setShowQueuePaymentModal(null)}
          >
            <div
              style={{
                maxWidth: "400px",
                margin: "auto",
                marginTop: "10%",
              }}
              onClick={e => e.stopPropagation()}
              >
              <PaymentForm appointmentData={showQueuePaymentModal}/>
            </div>
          </div>
        }
        {/*mobile header needs to be different*/}
        <div className="bg-gray-100 sticky" style={{
          top:0,
        }}>
          <CustomMobileHeader/>
        </div>
        <div className="sm:px-12 lg:px-12">

          {_.sortBy(myAppointments,["booking.startTime"]).map(data => {


            let bookingStatusIcon = <path d="M12 22a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"/>

            switch (data.booking.status){
             case "Confirmed":
                bookingStatusIcon = <path d="M12 22a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-2.3-8.7l1.3 1.29 3.3-3.3a1 1 0 0 1 1.4 1.42l-4 4a1 1 0 0 1-1.4 0l-2-2a1 1 0 0 1 1.4-1.42z"/>
                break;
            }

            const bookingStatus = data.booking.status == "Booked" ? "Not Confirmed" : data.booking.status
            const bookingSource = data.booking.source || "Staff"

            let bookingSourceIcon = <path class="heroicon-ui" d="M17 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V6c0-1.1.9-2 2-2h2V3a1 1 0 1 1 2 0v1h6V3a1 1 0 0 1 2 0v1zm-2 2H9v1a1 1 0 1 1-2 0V6H5v4h14V6h-2v1a1 1 0 0 1-2 0V6zm4 6H5v8h14v-8z"/>

            switch (bookingSource){
             case "Website":
                bookingSourceIcon = <path class="heroicon-ui" d="M13 17h-2v2h2v-2zm2 0v2h2a1 1 0 0 1 0 2H7a1 1 0 0 1 0-2h2v-2H4a2 2 0 0 1-2-2V5c0-1.1.9-2 2-2h16a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-5zM4 5v10h16V5H4z"/>
                break;
             case "App":
                bookingSourceIcon = <path class="heroicon-ui" d="M8 2h8a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2V4c0-1.1.9-2 2-2zm0 2v16h8V4H8zm4 14a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
                break;
            }

            console.log('dataStart',data.startDate,data)

            const titleClasses = ""

            const timeNow = format(new Date(), "HH:mm")
            const isToday = data.booking.date == format(new Date(), "yyyy-MM-dd")
            const isEarlierThanNow = isToday && data.booking.endTime < timeNow
            const isNow = isToday && !isEarlierThanNow && data.booking.startTime <= timeNow && data.booking.endTime >= timeNow

            const backgroundColor = isEarlierThanNow ? new Color(data.backgroundColor || "black").desaturate(.9).hex() : data.backgroundColor || "black";

            return (
              <div style={{
                padding: isNow ? "15px" : "20px",
                width: "100%",
                margin: "auto",
                marginBottom: "5px",
                position: "relative",
                color: new Color(backgroundColor).isDark() ? "white" : "black",
                backgroundColor,
                border: isNow ? "solid 10px green" : 'none',

                }}
                onClick={() => {
                  if (data.status == "Arrived"){
                    setShowQueuePaymentModal(data)
                  }
                }}
              > 

                { bookingSource &&
                    <div style={{position: "absolute", top: "5px", right: "5px", color: 'white', fontSize: 14, cursor:"pointer"}}>
                      <svg class="h-6 w-6 text-white" fill="currentColor" viewBox="0 0 24 24" title={ bookingSource }>
                        {bookingSourceIcon}
                      </svg>
                    </div>
                  }
                  <div style={{position: "absolute", bottom: "5px", right: "5px", color: 'white', fontSize: 14, cursor:"pointer"}}>
                    {bookingStatus.toUpperCase()}
                  </div>

                  { data.booking.startTime && 
                    <div>
                      From {data.booking.startTime} until {data.booking.endTime}
                      { permissions !== "OwnBookings"  &&
                        <span style={{marginLeft: "10px"}}>
                          By {_.find(teamMembersInLocation,{id: data.booking.teamMemberID}).text}
                        </span>
                      }
                    </div>
                  }

                  <div className={titleClasses} style={{fontSize:"16px",whiteSpace:"normal",paddingRight:"10px"}}>
                    {data.customerName}
                  </div>
                  { permissions !== "OwnBookings" && 
                    <div className={titleClasses} style={{fontSize:"16px"}}>
                      {data.customerPhone ? `${data.customerPhone}` : ""}
                    </div>
                  }
                  <div className={titleClasses} style={{fontSize:"13px"}}>
                    {data.primaryService && data.primaryService.name}
                  </div>
                  <div className={titleClasses} style={{fontSize:"13px"}}>
                    {data.addonServices}
                  </div>
              </div>
            )

          })}
        </div>
      </div>
    )

  }

  return (
    <Paper>
      <Scheduler
        data={appointments}
      >
        <ViewState
          defaultCurrentDate={ defaultCurrentDate }
        />

        { (permissions !== "OwnBookings" && canEdit) && 
          <EditingState
            onCommitChanges={commitChanges}
          />
        }
        <GroupingState
          grouping={grouping}
        />

        <DayView
          startDayHour={startDayHour === 0 ? 0 : startDayHour || 9}
          endDayHour={endDayHour || 19}
          intervalCount={1}
          height={300}
          cellDuration={15}
          timeTableCellComponent={TimeTableCell}
        />
        <WeekView
          startDayHour={startDayHour || 9}
          endDayHour={endDayHour || 19}
          intervalCount={1}
          timeTableCellComponent={TimeTableCell}
        />

        <Appointments />
        <Resources
          data={resources}
          mainResourceName="members"
        />

        <IntegratedGrouping />

        { (permissions !== "OwnBookings" && canEdit) &&
          <IntegratedEditing />
        }

        { (permissions !== "OwnBookings" && canEdit) &&
          <AppointmentTooltip showOpenButton showDeleteButton />
        }

        { (permissions !== "OwnBookings" && canEdit) && 
          <AppointmentForm basicLayoutComponent={BookingForm} commandLayoutComponent={CommandLayout}/>
        }  
        {/* { (permissions !== "OwnBookings" && canEdit) && virtualQueue &&
          <AppointmentForm basicLayoutComponent={BookingForm} commandLayoutComponent={CommandLayout}/>
        }
        { (permissions !== "OwnBookings" && canEdit) && !virtualQueue &&
          <AppointmentForm basicLayoutComponent={BookingForm} commandLayoutComponent={CommandLayout}/>
        } */}
        {/* permissions == "OwnBookings" &&
          <AppointmentForm basicLayoutComponent={PaymentForm} commandLayoutComponent={CommandLayout}/>
        */}
        {/*<AppointmentForm/>*/}
        <GroupingPanel />

        { (permissions !== "OwnBookings" && canEdit) &&
          <DragDropProvider 
            allowDrag={() => true}
            allowResize={() => false}
          />
        }


        <Toolbar/>
        <CustomHeader/>
        <CurrentTimeIndicator/>
        {/*<Toolbar/>*/}
        {/*<ViewSwitcher />*/}
        {/*<TodayButton className="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150"/>*/}
        {/*<DateNavigator />*/}

      </Scheduler>
    </Paper>
  );
}

const mapStateToProps = (state) => {

    const currentLocation = state.scheduler && state.scheduler.location && state.location && state.location.list.find(l => l.id == state.scheduler.location.value)

    console.log('currentLocation.acceptsBookings',currentLocation && currentLocation.acceptsBookings)

    return {
      business: state.business.item,
      canEdit: (currentLocation && currentLocation.acceptsBookings) || ['Admin','AllBookings', 'Host' ].includes(state.permissions),
      virtualQueue: currentLocation && currentLocation.virtualQueue,
      location: state.scheduler.location,
      filter: state.scheduler.filter,
      services: state.service.list,
      customers: state.customer.list,
      teamMembers: state.teamMember.list,
      locations: state.location.list,
      locationBookings: state.booking.byLocation,
      unavailability: state.booking.unavailability,
      availabilityExceptions: state.booking.availabilityExceptions,
      permissions: state.permissions,
      username: state.userDetails.username,
      me : state.teamMember.list && state.teamMember.list.find(teamMember => teamMember.username == state.userDetails.username)
  }
}

const mapDispatchToProps = dispatch => ({
  getBookingsByLocation: (variables) => dispatch(ACTIONS.getBookingsByLocation(variables)),
  updateBooking: (item) => dispatch(ACTIONS.updateItem(item)),
  addNotification: (notification,removeAfter) => dispatch(NOTIFICATIONACTIONS.addNotification(notification,removeAfter)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BookingSchedule)
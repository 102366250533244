import {Types} from "./action";
import _ from "lodash";

// import {NotificationManager} from 'react-notifications';

// import cleanDeep from 'clean-deep';


const defaultState = {
  business: {}
};

// BUSINESS_GET_ITEM
// BUSINESS_UPDATE_ITEM
// BUSINESS_CREATE_ITEM

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case Types.BUSINESS_USER_DETAILS: {

      let newState = {...state};

      newState.userDetails = action.payload;

      return newState;
    }
    case Types.BUSINESS_GET_ITEM: {

      let newState = {...state};

      newState.business.item = action.payload.data.getBusiness;

      if (newState.permissions == "OwnBookings"){
        // barbers/users who can only see their own stuff have no access to support
        window.Beacon('destroy')
      } else {
        window.Beacon('identify', {
          name: `${state.userDetails.attributes.given_name} ${state.userDetails.attributes.family_name}`,
          email: state.userDetails.attributes.email,
          company: newState.business.item.name,
          jobTitle: newState.permissions,
        })
      }

      return newState;
    }
    case Types.BUSINESS_UPDATE_ITEM: {

      let newState = {...state};

      newState.business.item = action.payload.data.updateBusiness;
      console.log('newBusinessUpdated',newState.business.item)

      return newState;
    }
    case Types.BUSINESS_UPDATE_LOGO: {

      let newState = {...state};

      newState.business.item = {...newState.business.item};
      newState.business.item.logo = action.logo;


      return newState;
    }
    default:
      return state;
  }
};

export default reducer;
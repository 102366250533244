import React, { useState, useEffect } from "react";
import { useForm } from 'react-hook-form'
import _ from 'lodash';

import { connect } from 'react-redux';
import { parse, format } from 'date-fns';
import DatePicker from 'react-datepicker'
import { Actions as ACTIONS } from 'store/teamMember/action';

const AvailabilityExceptionModal = ({
    closeModal,
    show,
    date,
    openingHours,
    createAvailabilityException,
    availabilityExceptionsByDate,
    deleteAvailabilityException,
    availabilityExceptions,
    updateAvailabilityException,
    isLeave,
    leave,
    deleteUnavailability
}) => {

    const [exceptionDate, setExceptionDate] = useState()
    const [timeIn, setTimeIn] = useState()
    const [timeOut, setTimeOut] = useState()
    const [breakTime, setBreakTime] = useState()
    const [allowExtraBreak, setAllowExtraBreak] = useState(false)

    useEffect(() => {
        setExceptionDate(date ? new Date(date) : new Date())
        setTimeIn(openingHours?.opensAt ? parse(openingHours?.opensAt, 'HH:mm', new Date()) : new Date())
        setTimeOut(openingHours?.closesAt ? parse(openingHours?.closesAt, 'HH:mm', new Date()) : new Date())
    }, [date, openingHours])

    useEffect(() => {
        const selectedException = exceptionDate && availabilityExceptions?.find((e) => e.date == format(exceptionDate, 'yyyy-MM-dd'))

        if(selectedException?.from && selectedException?.to){
            setTimeIn(parse(selectedException.from, 'HH:mm', new Date()))
            setTimeOut(parse(selectedException.to, 'HH:mm', new Date()))
            setBreakTime(selectedException.breakTime ? parse(selectedException.breakTime, 'HH:mm', new Date()) : null)
            setAllowExtraBreak(selectedException.allowExtraBreak ?? false)
        }
    }, [exceptionDate])

    if (!show) {
        return <React.Fragment />;
    }

    /*x-transition:enter="transform ease-out duration-300 transition" x-transition:enter-start="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2" x-transition:enter-end="translate-y-0 opacity-100 sm:translate-x-0" x-transition:leave="transition ease-in duration-100" x-transition:leave-start="opacity-100" x-transition:leave-end="opacity-0" */
    return (
        <div class="fixed bottom-0 inset-x-0 px-4 pb-6 sm:inset-0 sm:p-0 sm:flex sm:items-center sm:justify-center z-50">
            {/*<!--
		  		    Background overlay, show/hide based on modal state.
		  
		  		    Entering: "ease-out duration-300"
		  		      From: "opacity-0"
		  		      To: "opacity-100"
		  		    Leaving: "ease-in duration-200"
		  		      From: "opacity-100"
		  		      To: "opacity-0"
		  		  -->*/}
            <div class="fiLed inset-0 transition-opacity" onClick={closeModal}>
                <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>

            {/*<!--
		  		    Modal panel, show/hide based on modal state.
		  
		  		    Entering: "ease-out duration-300"
		  		      From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
		  		      To: "opacity-100 translate-y-0 sm:scale-100"
		  		    Leaving: "ease-in duration-200"
		  		      From: "opacity-100 translate-y-0 sm:scale-100"
		  		      To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
		  		  -->*/}
            <div class="bg-white rounded-lg px-4 pt-5 pb-4 overflow-overlay shadow-xl transform transition-all sm:max-w-lg sm:w-full sm:p-6" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                <div>
                    <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                        <svg class="h-6 w-6 text-green-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                        </svg>
                    </div>
                    <div class="mt-3 text-center sm:mt-5">
                        <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                            Edit Availability Exception
                        </h3>
                    </div>
                </div>
                <div className="grid grid-cols-6 gap-6 pt-6">
                    <div className="col-span-6 sm:col-span-6">
                        <label htmlFor="role" className="block text-sm font-medium leading-5 text-gray-700">Date</label>
                        <DatePicker
                            className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                            dateFormat="d MMMM yyyy"
                            selected={exceptionDate}
                            disabled={true}
                            onChange={(e) => setExceptionDate(e)}
                        />
                    </div>
                    <div className="col-span-3 sm:col-span-3">
                        <label htmlFor="role" className="block text-sm font-medium leading-5 text-gray-700">Time In</label>
                        <DatePicker
                            className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                            selected={timeIn}
                            onChange={(e) => {
                                setTimeIn(e)

                                if (timeOut <= e) {
                                    setTimeOut(e)
                                }
                            }}
                        />
                    </div>
                    <div className="col-span-3 sm:col-span-3">
                        <label htmlFor="role" className="block text-sm font-medium leading-5 text-gray-700">Time Out</label>
                        <DatePicker
                            className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                            selected={timeOut}
                            onChange={(e) => {
                                if (timeIn <= e)
                                    setTimeOut(e)

                            }}
                        />
                    </div>
                    <div className="col-span-3 sm:col-span-3">
                        <label htmlFor="role" className="block text-sm font-medium leading-5 text-gray-700">Break Time</label>
                        <DatePicker
                            className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                            selected={breakTime}
                            isClearable={true}
                            onChange={(e) => {
                                if (!e || (timeIn <= e && timeOut >= e))
                                    setBreakTime(e)

                            }}
                        />
                    </div>
                    <div className="col-span-3 sm:col-span-3">
                      <div className="flex items-start">
                        <div className="absolute flex items-center h-5">
                          <input id="allowExtraBreak" name="allowExtraBreak" checked={allowExtraBreak} onChange={() => setAllowExtraBreak(!allowExtraBreak)} type="checkbox" className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out" />
                        </div>
                        <div className="pl-7 text-sm leading-5">
                          <label htmlFor="allowExtraBreak" className="font-medium text-gray-700">Extra Break</label>
                          <p className="text-gray-500">Can they add an extra break?</p>
                        </div>
                      </div>
                    </div>
                </div>
                <div class="bg-white pt-12 sm:flex sm:flex-row-reverse">
                    {!availabilityExceptionsByDate?.includes(format(exceptionDate, 'yyyy-MM-dd')) &&
                        <button
                            type="button"
                            class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-500 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
                            onClick={() => {
                                if(isLeave){
                                    deleteUnavailability(leave)
                                }
                                createAvailabilityException(format(exceptionDate, 'yyyy-MM-dd'), format(timeIn, 'HH:mm'), format(timeOut, 'HH:mm'),breakTime && format(breakTime, 'HH:mm'), allowExtraBreak)
                            }}
                        >
                            Save
                        </button>
                        
                    }
                    {availabilityExceptionsByDate?.includes(format(exceptionDate, 'yyyy-MM-dd')) && (format(exceptionDate, 'yyyy-MM-dd') >= format(new Date(), 'yyyy-MM-dd')) &&
                        <>
                            <button
                                type="button"
                                class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-500 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
                                onClick={() => {
                                    updateAvailabilityException(availabilityExceptions?.find((e) => e.date == format(exceptionDate, 'yyyy-MM-dd')).id, format(timeIn, 'HH:mm'), format(timeOut, 'HH:mm'),breakTime && format(breakTime, 'HH:mm'), allowExtraBreak)
                                }}
                            >
                                Update
                            </button>
                            <button
                                type="button"
                                class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-500 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                                onClick={() => {
                                    deleteAvailabilityException(availabilityExceptions?.find((e) => e.date == format(exceptionDate, 'yyyy-MM-dd')).id)
                                }}
                            >
                                Delete
                            </button>
                        </>
                    }
                    <button
                        type="button"
                        onClick={closeModal}
                        class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    )
}


const mapStateToProps = (state) => {

    const dateString = state?.teamMember?.customExceptionModal?.date
	const unavailabilities = state.teamMember.unavailabilities && state.teamMember.unavailabilities
	const unavailabilitiesByDate = unavailabilities?.map?.(({ date }) => date)
	const isLeave = unavailabilitiesByDate && unavailabilitiesByDate.includes(dateString);

	const leave = isLeave && _.find(unavailabilities, { date: dateString })?.id
        
    return {
        show: state?.teamMember?.customExceptionModal?.status,
        date: state?.teamMember?.customExceptionModal?.date,
        leave,
        isLeave,
        openingHours: state?.teamMember?.item?.openingHours?.[state?.teamMember?.item?.openingHours.length - 1],
        availabilityExceptionsByDate: state?.teamMember?.availabilityExceptions?.map(({ date }) => date),
        availabilityExceptions: state?.teamMember?.availabilityExceptions,
    }
}

const mapDispatchToProps = dispatch => ({
    closeModal: () => dispatch(ACTIONS.createCustomAvailability(false, undefined)),
    createAvailabilityException: (date, timeIn, timeOut, breakTime, allowExtraBreak) => dispatch(ACTIONS.createAvailabilityException(date, timeIn, timeOut, breakTime, allowExtraBreak)),
    deleteAvailabilityException: (id) => dispatch(ACTIONS.deleteAvailabilityException(id)),
    updateAvailabilityException: (id, timeIn, timeOut, breakTime, allowExtraBreak) => dispatch(ACTIONS.updateAvailabilityException(id, timeIn, timeOut, breakTime, allowExtraBreak)),
    deleteUnavailability: (id) => dispatch(ACTIONS.deleteUnavailability(id)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AvailabilityExceptionModal)
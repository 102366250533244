import React, { useState, useEffect } from "react";
import Paper from '@material-ui/core/Paper';
import {
  ViewState, GroupingState, IntegratedGrouping, IntegratedEditing, EditingState,
} from '@devexpress/dx-react-scheduler';
import {
  Scheduler,
  Resources,
  Appointments,
  AppointmentTooltip,
  GroupingPanel,
  DayView,
  DragDropProvider,
  AppointmentForm,
} from '@devexpress/dx-react-scheduler-material-ui';
import {
  teal, indigo, yellow
} from '@material-ui/core/colors';
import Select from 'react-select';
import AsyncCreatableSelect from 'react-select/async-creatable';
import chroma from 'chroma-js';
import { v4 as uuidv4 } from 'uuid';
import cleanDeep from 'clean-deep';

import { useForm } from 'react-hook-form'

import CustomerForm from 'components/customer/CustomerForm';

import {Actions as ACTIONS} from 'store/booking/action';
import {Actions as CUSTOMERACTIONS} from 'store/customer/action';
import {Actions as NOTIFICATIONACTIONS} from 'store/notifications/action';
import {connect} from 'react-redux';
import DatePicker from 'react-datepicker';

import "react-datepicker/dist/react-datepicker.css";
import "styles/datepicker.css";
import { format, formatDistance, differenceInMinutes, formatRelative, subDays, setHours, setMinutes, setSeconds, getMinutes, getDate, getDay } from 'date-fns'
import { zonedTimeToUtc } from 'date-fns-tz'
import _ from "lodash";
import {API} from '@aws-amplify/api'

import {
          customerByBusinessByPhone,
       } from 'graphql/queries'

const timeSlotInterval = 15;


const PaymentForm = ({readOnly, appointmentData, appointmentResources, resources, onFieldChange, getMessage, locale, fullSize,
                      
                      booking,
                      bookings,
                      services,
                      products,
                      teamMembers,
                      locations,
                      customers,
                      teamMemberBookings,
                      getBookingsByTeamMember,
                      clearBooking,
                      createBooking,
                      addNotification,
                      updateBooking,
                      getBooking,
                      location,
                      newCustomerModal,
                      setNewCustomerModal,
                      businessID,
                      ...restProps
                    }) => {

  const { register, setValue, handleSubmit, watch, errors } = useForm()

  const [paymentMethodModal, setPaymentMethodModal] = useState(false)

  const extraCharges = watch('extraCharges');

  useEffect(() => {
    
    if (appointmentData && appointmentData.id && !(booking && booking.id == appointmentData.id)){
      
      getBooking(appointmentData.id)
    } else if (appointmentData && !appointmentData.id){
      
      setValue('serviceIDs',null)
      setValue('products',null)
      setValue('extraCharges',null)
      setValue('paymentMethod',null)
      clearBooking();
    }
  },[appointmentData && appointmentData.id]);

  useEffect(() => {
    // new booking loaded so we should set the values
    if (booking && booking.id && appointmentData && booking.id == appointmentData.id){
      // initialise with values from booking
      setValue('products',booking.products)
      setValue('serviceIDs',booking.services && booking.services.map(service => service.teamMemberService))
      setValue('extraCharges',booking.extraCharges)
      setValue('paymentMethod',null)
    }
  },[booking && booking.id]);

  const currentPrice = watch('fee');
  const currentProductPrice = watch('additionalFee');
  const selectedServices = watch('serviceIDs');
  const selectedProducts = watch('products');
  const paymentMethod = watch('paymentMethod');

  console.log('selectedProducts',selectedProducts)

  const teamMember = booking && booking.teamMemberID && teamMembers.find(teamMember => teamMember.id == booking.teamMemberID)
  const teamMemberServices = teamMember && teamMember.services && teamMember.services.items && teamMember.services.items
                              .filter(teamMemberService => (selectedServices && selectedServices.includes(teamMemberService.id)))


  const serviceOptions = teamMember && teamMember.services && teamMember.services.items && _.sortBy(teamMember.services.items 
                          .filter(teamMemberService => (teamMemberService.enabled))
                          .map(teamMemberService => {
                            const service = services.find(service => service.id == teamMemberService.serviceID)
                            return ({
                                value: teamMemberService.id,
                                label: service && service.name,
                                backgroundColor: service && service.blockColour,
                                order: service && service.order,
                                color: service && !service.isAddon ? "#252f3f" : "#5850ec"
                              })
                          }),['order','label'])

  const productOptions =  products && _.sortBy(products
                          .map(product => ({
                              value: product.id,
                              label: product && product.name,
                              order: product && product.order,
                            })
                          ),['order','label'])

  register({ name: 'fee', type: 'custom'}, { required: true })
  register({ name: 'additionalFee', type: 'custom'}, { required: false })
  register({ name: 'products', type: 'custom'}, { required: true })
  register({ name: 'serviceIDs', type: 'custom'}, { required: true })
  register({ name: 'paymentMethod', type: 'custom'}, { required: false })


  let maxedOut = []
  let serviceDuration = 0
  let price = 0
  teamMemberServices && teamMemberServices.map(teamMemberService => {
    const service = services.find(service => service.id == teamMemberService.serviceID)

    if (teamMemberService && teamMemberService.maxDaily){

      // console.log('bookingDate serv',teamMemberBookings[0].services,teamMemberService.serviceID)
      const alreadyExist = teamMemberBookings && teamMemberBookings
        .filter(
          item => item.status != "Cancelled" 
                  && item.status != "NoShow" 
                  && (!booking || item.id != booking.id)
                  && (item.services.find(s => s.service == teamMemberService.serviceID)) )

      if ( alreadyExist && teamMemberService.maxDaily <= alreadyExist.length){
        maxedOut.push({teamMemberService,service});
      }
      console.log('maxedOut',maxedOut,alreadyExist,teamMemberService.maxDaily)
    }


    if (teamMemberService && teamMemberService.duration){
      if (!service.isDoneParallel){
        serviceDuration += teamMemberService.duration;
      }
      price += teamMemberService.price;
    } else {
      if (service && !service.isDoneParallel){
        serviceDuration += service.duration;
      }
      price += service?.price ?? 0;
    }
  })

  let productPrice = 0
  selectedProducts && selectedProducts.map(productId => {
    const product = products.find(({id})=> id == productId)

    if (product.price){
      productPrice += product.price      
    }
  })


  if(currentPrice != price){
    setValue('fee',price)
  } 

  if (productPrice != currentProductPrice){
    setValue('additionalFee',productPrice)
  }

  let includeTimes = []; 

  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const onSubmit = (data) => {
    console.log('submitBooking',data)


    // const bookingEndTimeParts = data.time && data.time.split(':')
    // const endTime = new Date(data.time.getTime());
    const endTime = setMinutes(data.time,getMinutes(data.time) + serviceDuration);

    const formattedServices = selectedServices.map(teamMemberServiceId => ({
      teamMemberService: teamMemberServiceId,
      service: teamMemberServices && teamMemberServices.find(item => item.id == teamMemberServiceId).serviceID
    }))

    const item = {
      id: booking && booking.id || uuidv4(),
      _version: booking && booking._version,
      fee: data.fee,
      additionalFee: data.additionalFee,
      services: formattedServices,
      // productPrice: price,
      products: data.products,

      status: "ReadyForPayment" //data.status || "Booked",
    }
    console.log('item toSubmitData',item)

    updateBooking(cleanDeep(item))
    
    // if (booking && booking.id){
    //   
    // } else {
    //   delete(item._version);
    //   createBooking(cleanDeep(item))
    // }
  }

  const onSubmitCash = handleSubmit(data => {
  data.paymentMethod = 'cash'
    onSubmit(data)
  })
  const onSubmitCard = handleSubmit(data => {
    data.paymentMethod = 'card'
    onSubmit(data)
  })


const defaultColor = "#111"

const selectDefaultStyle = {
  control: styles => ({ ...styles, backgroundColor: 'white', border: 'none' })
};


const dot = (color = '#ccc') => ({
  alignItems: 'center',
  display: 'flex',

  ':before': {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: 'block',
    marginRight: 8,
    height: 10,
    width: 10,
  },
});

const colourStyles = {
  control: styles => ({ ...styles, backgroundColor: 'white', border: 'none' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = chroma(data.color || defaultColor);
    return {
      ...styles,
      ...dot(data.backgroundColor),
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? data.color || defaultColor
        : isFocused
        ? color.alpha(0.1).css()
        : null,
      color: isDisabled
        ? '#ccc'
        : isSelected
        ? chroma.contrast(color, 'white') > 2
          ? 'white'
          : 'black'
        : data.color || defaultColor,
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled && (isSelected ? data.color || defaultColor : color.alpha(0.3).css()),
      },
    };
  },
  input: styles => ({ ...styles, ...dot() }),
  placeholder: styles => ({ ...styles, ...dot() }),
  singleValue: (styles, { data }) => ({ ...styles, ...dot(data.backgroundColor) }),
  multiValue: (styles, { data }) => {
    const color = chroma(data.color || defaultColor);
    return {
      ...styles,
      backgroundColor: color.alpha(0.1).css(),
      ...dot(data.backgroundColor),
      ':before': {
        backgroundColor: data.backgroundColor,
        borderRadius: 10,
        content: '" "',
        display: 'block',
        marginRight: 8,
        marginRight: 8,
        height: 10,
        width: 10,
      },
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: data.color || defaultColor,
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color || defaultColor,
    ':hover': {
      backgroundColor: data.color || defaultColor,
      color: 'white',
    },
  }),
};

  const isQueue = locations.find(item => item.id == location.value).virtualQueue
  // console.log('zBooking',booking,isQueue,restProps)
  if (!(isQueue && booking  && booking.id && booking.status == "Present")){
    return null;
  }


  return (
      <div className="" style={{
          "display": "block",
          "minWidth": "100%",
          "minHeight": "100%",
      }}>
          <div className="mt-5 md:mt-0 md:col-span-2">

            <form onSubmit={handleSubmit(onSubmit)}>
            { !paymentMethod && 
              <div className="shadow sm:rounded-md sm:overflow-visible" style={{
                  maxHeight: "90vh"
                }}>
                <div className="px-4 py-5 bg-white sm:p-6">
                  <div className="grid grid-cols-6 gap-6">

                    <div className="col-span-6">
                      <label htmlFor="serviceIDs" className="block text-sm font-medium leading-5 text-gray-700">Service(s)</label>

                      <Select
                        className="mt-1 block form-select w-full p-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                        styles={colourStyles}
                        value={serviceOptions && serviceOptions.filter(item => selectedServices && selectedServices.includes(item.value))}
                        onChange={(selected) => {
                            if (Array.isArray(selected)){
                              setValue('serviceIDs',selected.map(item => item.value))
                            } else {

                              setValue('serviceIDs',[])
                            }
                        }}
                        options={serviceOptions}
                        isMulti={true}
                      />
                      { maxedOut && maxedOut.length > 0 && `The following Services have reached their daily limit ${maxedOut.map(s => s.service.name).join(',')}`}
                    </div>

                    { 
                      <div className="col-span-6" style={{marginBottom: 20}}>
                        Services Price: <strong>€{price}</strong>
                      </div>
                    }

                    <div className="col-span-6">
                      <label htmlFor="products" className="block text-sm font-medium leading-5 text-gray-700">Product(s)</label>

                      <Select
                        className="mt-1 block form-select w-full p-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                        styles={colourStyles}
                        value={productOptions && productOptions.filter(item => selectedProducts && selectedProducts.includes(item.value))}
                        onChange={(selected) => {
                            if (Array.isArray(selected)){
                              setValue('products',selected.map(item => item.value))
                            } else {

                              setValue('products',[])
                            }
                        }}
                        options={productOptions}
                        isMulti={true}
                      />
                    </div>

                    { 
                      <div className="col-span-6" style={{marginBottom: 20}}>
                        Products Price: <strong>€{productPrice}</strong>
                      </div>
                    }

                  </div>

                  { 
                    <div className="col-span-6" style={{marginTop: 20, fontSize:"1.5em"}}>
                      Final Price: <strong>€{parseFloat(price) + parseFloat(!productPrice || isNaN(productPrice) ? 0 : productPrice)}</strong>
                    </div>
                  }

                </div>
                <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                  <button 
                      type="submit"
                      className="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
                      style={maxedOut.length > 0 ? { opacity: .3, cursor: "not-allowed"} : {}}
                      disabled={maxedOut.length > 0}
                    >
                    Request Payment
                  </button>

                  {/*<span style={{float:"left"}} className="inline-flex rounded-md shadow-sm">
                    <button 
                      type="submit" 
                      name="action" value="cash"
                      className="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
                      style={maxedOut.length > 0 ? { opacity: .3, cursor: "not-allowed"} : {}}
                      disabled={maxedOut.length > 0}
                      onClick={() => setValue('paymentMethod',"Cash")}
                    >
                    Pay Cash
                    </button>
                  </span>
                  <span className="inline-flex rounded-md shadow-sm">
                    <button 
                      type="submit" 
                      name="action" value="card"
                      className="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
                      style={maxedOut.length > 0 ? { opacity: .3, cursor: "not-allowed"} : {}}
                      disabled={maxedOut.length > 0}
                      onClick={() => setValue('paymentMethod',"Card")}
                    >
                    Pay By Card
                    </button>
                  </span>*/}
                </div>
              </div>
            }
            { paymentMethod && 
              <div className="shadow sm:rounded-md sm:overflow-visible" style={{
                  maxHeight: "90vh"
                }}>
                <div className="px-4 py-5 bg-white sm:p-6">

                  <div className="col-span-6" style={{marginTop: 20, fontSize:"1.5em"}}>
                    Confirm that <strong>€{parseFloat(price) + parseFloat(!extraCharges || isNaN(extraCharges) ? 0 : extraCharges)}</strong> are to be paid in {paymentMethod}
                  </div>

                </div>
                <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                  <span style={{float:"left"}} className="inline-flex rounded-md shadow-sm">
                    <button 
                      type="submit" 
                      name="action" value="cash"
                      className="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red active:bg-red-700 transition duration-150 ease-in-out"
                      style={maxedOut.length > 0 ? { opacity: .3, cursor: "not-allowed"} : {}}
                      disabled={maxedOut.length > 0}
                      onClick={() => setValue('paymentMethod',null)}
                    >
                      No
                    </button>
                  </span>
                  <span className="inline-flex rounded-md shadow-sm">
                    <button 
                      type="submit" 
                      name="action" value="card"
                      className="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-green-600 hover:bg-green-500 focus:outline-none focus:border-green-700 focus:shadow-outline-green active:bg-green-700 transition duration-150 ease-in-out"
                      style={maxedOut.length > 0 ? { opacity: .3, cursor: "not-allowed"} : {}}
                      disabled={maxedOut.length > 0}
                    >
                    Yes Pay By {paymentMethod}
                    </button>
                  </span>
                </div>
              </div>
            }
            </form>
          </div>

      </div>
    )
}

const mapStateToProps = (state) => {
    return {
      businessID: state.businessID,
      booking: state.booking.item,
      bookings: state.booking.list,
      customers: state.customer.list,
      newCustomerModal: state.customer.newModal,
      services: state.service.list,
      products: state.product.list,
      teamMembers: state.teamMember.list,
      location: state.scheduler.location,
      locations: state.location.list,
      teamMemberBookings: state.booking.byTeamMember,

  }
}

const mapDispatchToProps = dispatch => ({
  getBooking: (id) => dispatch(ACTIONS.getItem(id)),
  clearBooking: () => dispatch(ACTIONS.clearItem()),
  setNewCustomerModal: (show) => dispatch(CUSTOMERACTIONS.newCustomerModal(show)),
  addNotification: (message,hideAfter) => dispatch(NOTIFICATIONACTIONS.addNotification(message,hideAfter)),
  createBooking: (item) => dispatch(ACTIONS.createItem(item)),
  updateBooking: (item) => dispatch(ACTIONS.updateItem(item)),
  getBookingsByTeamMember: (variables) => dispatch(ACTIONS.getBookingsByTeamMember(variables)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentForm)

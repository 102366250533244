/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateBooking = /* GraphQL */ `
  subscription OnCreateBooking($businessID: ID) {
    onCreateBooking(businessID: $businessID) {
      id
      businessID
      dependent {
        givenName
        familyName
      }
      customerID
      teamMember {
        id
        businessID
        code
        username
        givenName
        familyName
        phone
        email
        acceptsBookings
        locationID
        offDays
        offDayExceptions
        seatNo
        queueGap
        entrance
        status
        role
        startDate
        endDate
        photo {
          bucket
          key
        }
        openingHours {
          day
          opensAt
          closesAt
        }
        break {
          day
          opensAt
          closesAt
        }
        estimatedBreakTimes
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        location {
          id
          businessID
          name
          code
          addressID
          phone
          acceptsBookings
          virtualQueue
          online
          maintenance
          opensOnPublicHolidays
          isMobile
          bookingFlow
          mobileWeekOffset
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        services {
          nextToken
          startedAt
        }
        unavailablies {
          nextToken
          startedAt
        }
        availabilityExceptions {
          nextToken
          startedAt
        }
        bookings {
          nextToken
          startedAt
        }
      }
      teamMemberID
      locationID
      note
      transactionReference
      paymentMethod
      paymentDetails {
        method
        amount
        reference
        provider
      }
      services {
        teamMemberService
        service
      }
      products {
        productID
        quantity
      }
      date
      startTime
      endTime
      dateCancelled
      status
      source
      fee
      additionalFee
      tip
      confirmationReminderSent
      finalReminderSent
      reviewEmailSent
      createdAt
      updatedAt
      stripeID
      version
      transaction
      isNew
      any
      _version
      _deleted
      _lastChangedAt
      customer {
        id
        businessID
        stripeID
        username
        givenName
        familyName
        transactionReference
        cards {
          pan
          expiry
          transactionReference
        }
        dependents {
          givenName
          familyName
        }
        dob
        vip
        phone
        email
        expoToken
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        bookings {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const onUpdateBooking = /* GraphQL */ `
  subscription OnUpdateBooking($businessID: ID) {
    onUpdateBooking(businessID: $businessID) {
      id
      businessID
      dependent {
        givenName
        familyName
      }
      customerID
      teamMember {
        id
        businessID
        code
        username
        givenName
        familyName
        phone
        email
        acceptsBookings
        locationID
        offDays
        offDayExceptions
        seatNo
        queueGap
        entrance
        status
        role
        startDate
        endDate
        photo {
          bucket
          key
        }
        openingHours {
          day
          opensAt
          closesAt
        }
        break {
          day
          opensAt
          closesAt
        }
        estimatedBreakTimes
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        location {
          id
          businessID
          name
          code
          addressID
          phone
          acceptsBookings
          virtualQueue
          online
          maintenance
          opensOnPublicHolidays
          isMobile
          bookingFlow
          mobileWeekOffset
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        services {
          nextToken
          startedAt
        }
        unavailablies {
          nextToken
          startedAt
        }
        availabilityExceptions {
          nextToken
          startedAt
        }
        bookings {
          nextToken
          startedAt
        }
      }
      teamMemberID
      locationID
      note
      transactionReference
      paymentMethod
      paymentDetails {
        method
        amount
        reference
        provider
      }
      services {
        teamMemberService
        service
      }
      products {
        productID
        quantity
      }
      date
      startTime
      endTime
      dateCancelled
      status
      source
      fee
      additionalFee
      tip
      confirmationReminderSent
      finalReminderSent
      reviewEmailSent
      createdAt
      updatedAt
      stripeID
      version
      transaction
      isNew
      any
      _version
      _deleted
      _lastChangedAt
      customer {
        id
        businessID
        stripeID
        username
        givenName
        familyName
        transactionReference
        cards {
          pan
          expiry
          transactionReference
        }
        dependents {
          givenName
          familyName
        }
        dob
        vip
        phone
        email
        expoToken
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        bookings {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const onCreateCustomer = /* GraphQL */ `
  subscription OnCreateCustomer($businessID: ID) {
    onCreateCustomer(businessID: $businessID) {
      id
      businessID
      stripeID
      username
      givenName
      familyName
      transactionReference
      cards {
        pan
        expiry
        transactionReference
      }
      dependents {
        givenName
        familyName
      }
      dob
      vip
      phone
      email
      expoToken
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      bookings {
        items {
          id
          businessID
          customerID
          teamMemberID
          locationID
          note
          transactionReference
          paymentMethod
          date
          startTime
          endTime
          dateCancelled
          status
          source
          fee
          additionalFee
          tip
          confirmationReminderSent
          finalReminderSent
          reviewEmailSent
          createdAt
          updatedAt
          stripeID
          version
          transaction
          isNew
          any
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const onUpdateCustomer = /* GraphQL */ `
  subscription OnUpdateCustomer($businessID: ID) {
    onUpdateCustomer(businessID: $businessID) {
      id
      businessID
      stripeID
      username
      givenName
      familyName
      transactionReference
      cards {
        pan
        expiry
        transactionReference
      }
      dependents {
        givenName
        familyName
      }
      dob
      vip
      phone
      email
      expoToken
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      bookings {
        items {
          id
          businessID
          customerID
          teamMemberID
          locationID
          note
          transactionReference
          paymentMethod
          date
          startTime
          endTime
          dateCancelled
          status
          source
          fee
          additionalFee
          tip
          confirmationReminderSent
          finalReminderSent
          reviewEmailSent
          createdAt
          updatedAt
          stripeID
          version
          transaction
          isNew
          any
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const onCreateQueue = /* GraphQL */ `
  subscription OnCreateQueue($businessID: ID) {
    onCreateQueue(businessID: $businessID) {
      id
      businessID
      locationID
      teamMemberID
      date
      nextAvailable
      closesAt
      breaksTaken
      maxDuration
      block {
        startTime
        endTime
      }
      gaps {
        from
        to
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateQueue = /* GraphQL */ `
  subscription OnUpdateQueue($businessID: ID) {
    onUpdateQueue(businessID: $businessID) {
      id
      businessID
      locationID
      teamMemberID
      date
      nextAvailable
      closesAt
      breaksTaken
      maxDuration
      block {
        startTime
        endTime
      }
      gaps {
        from
        to
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateAddress = /* GraphQL */ `
  subscription OnCreateAddress {
    onCreateAddress {
      id
      businessID
      addressLine1
      addressLine2
      addressLine3
      addressLine4
      city
      region
      postCode
      country
      attentionTo
      lat
      lng
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateAddress = /* GraphQL */ `
  subscription OnUpdateAddress {
    onUpdateAddress {
      id
      businessID
      addressLine1
      addressLine2
      addressLine3
      addressLine4
      city
      region
      postCode
      country
      attentionTo
      lat
      lng
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteAddress = /* GraphQL */ `
  subscription OnDeleteAddress {
    onDeleteAddress {
      id
      businessID
      addressLine1
      addressLine2
      addressLine3
      addressLine4
      city
      region
      postCode
      country
      attentionTo
      lat
      lng
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateAddressConnection = /* GraphQL */ `
  subscription OnCreateAddressConnection {
    onCreateAddressConnection {
      id
      businessID
      customerID
      address {
        id
        businessID
        addressLine1
        addressLine2
        addressLine3
        addressLine4
        city
        region
        postCode
        country
        attentionTo
        lat
        lng
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      addressType
      current
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateAddressConnection = /* GraphQL */ `
  subscription OnUpdateAddressConnection {
    onUpdateAddressConnection {
      id
      businessID
      customerID
      address {
        id
        businessID
        addressLine1
        addressLine2
        addressLine3
        addressLine4
        city
        region
        postCode
        country
        attentionTo
        lat
        lng
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      addressType
      current
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteAddressConnection = /* GraphQL */ `
  subscription OnDeleteAddressConnection {
    onDeleteAddressConnection {
      id
      businessID
      customerID
      address {
        id
        businessID
        addressLine1
        addressLine2
        addressLine3
        addressLine4
        city
        region
        postCode
        country
        attentionTo
        lat
        lng
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      addressType
      current
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateBusiness = /* GraphQL */ `
  subscription OnCreateBusiness {
    onCreateBusiness {
      id
      name
      allowBookingsDayAdvance
      allowBookingsDayAdvanceWeb
      vacationLeaveDays
      takingBookings
      allowBackDatedBookings
      logo {
        bucket
        key
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      addresses {
        items {
          id
          businessID
          customerID
          addressType
          current
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const onUpdateBusiness = /* GraphQL */ `
  subscription OnUpdateBusiness {
    onUpdateBusiness {
      id
      name
      allowBookingsDayAdvance
      allowBookingsDayAdvanceWeb
      vacationLeaveDays
      takingBookings
      allowBackDatedBookings
      logo {
        bucket
        key
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      addresses {
        items {
          id
          businessID
          customerID
          addressType
          current
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const onDeleteBusiness = /* GraphQL */ `
  subscription OnDeleteBusiness {
    onDeleteBusiness {
      id
      name
      allowBookingsDayAdvance
      allowBookingsDayAdvanceWeb
      vacationLeaveDays
      takingBookings
      allowBackDatedBookings
      logo {
        bucket
        key
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      addresses {
        items {
          id
          businessID
          customerID
          addressType
          current
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const onCreateLocation = /* GraphQL */ `
  subscription OnCreateLocation {
    onCreateLocation {
      id
      businessID
      name
      code
      addressID
      phone
      openingHours {
        day
        opensAt
        closesAt
      }
      acceptsBookings
      virtualQueue
      online
      maintenance
      opensOnPublicHolidays
      isMobile
      bookingFlow
      mobileWeekOffset
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      address {
        id
        businessID
        addressLine1
        addressLine2
        addressLine3
        addressLine4
        city
        region
        postCode
        country
        attentionTo
        lat
        lng
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      team {
        items {
          id
          businessID
          code
          username
          givenName
          familyName
          phone
          email
          acceptsBookings
          locationID
          offDays
          offDayExceptions
          seatNo
          queueGap
          entrance
          status
          role
          startDate
          endDate
          estimatedBreakTimes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      photos {
        items {
          id
          businessID
          description
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const onUpdateLocation = /* GraphQL */ `
  subscription OnUpdateLocation {
    onUpdateLocation {
      id
      businessID
      name
      code
      addressID
      phone
      openingHours {
        day
        opensAt
        closesAt
      }
      acceptsBookings
      virtualQueue
      online
      maintenance
      opensOnPublicHolidays
      isMobile
      bookingFlow
      mobileWeekOffset
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      address {
        id
        businessID
        addressLine1
        addressLine2
        addressLine3
        addressLine4
        city
        region
        postCode
        country
        attentionTo
        lat
        lng
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      team {
        items {
          id
          businessID
          code
          username
          givenName
          familyName
          phone
          email
          acceptsBookings
          locationID
          offDays
          offDayExceptions
          seatNo
          queueGap
          entrance
          status
          role
          startDate
          endDate
          estimatedBreakTimes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      photos {
        items {
          id
          businessID
          description
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const onDeleteLocation = /* GraphQL */ `
  subscription OnDeleteLocation {
    onDeleteLocation {
      id
      businessID
      name
      code
      addressID
      phone
      openingHours {
        day
        opensAt
        closesAt
      }
      acceptsBookings
      virtualQueue
      online
      maintenance
      opensOnPublicHolidays
      isMobile
      bookingFlow
      mobileWeekOffset
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      address {
        id
        businessID
        addressLine1
        addressLine2
        addressLine3
        addressLine4
        city
        region
        postCode
        country
        attentionTo
        lat
        lng
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      team {
        items {
          id
          businessID
          code
          username
          givenName
          familyName
          phone
          email
          acceptsBookings
          locationID
          offDays
          offDayExceptions
          seatNo
          queueGap
          entrance
          status
          role
          startDate
          endDate
          estimatedBreakTimes
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      photos {
        items {
          id
          businessID
          description
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const onCreateMobileLocation = /* GraphQL */ `
  subscription OnCreateMobileLocation {
    onCreateMobileLocation {
      id
      businessID
      locationID
      name
      addressID
      isRepeating
      comingSoon
      colour
      order
      weeksAtLocation
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      address {
        id
        businessID
        addressLine1
        addressLine2
        addressLine3
        addressLine4
        city
        region
        postCode
        country
        attentionTo
        lat
        lng
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      photos {
        items {
          id
          businessID
          description
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const onUpdateMobileLocation = /* GraphQL */ `
  subscription OnUpdateMobileLocation {
    onUpdateMobileLocation {
      id
      businessID
      locationID
      name
      addressID
      isRepeating
      comingSoon
      colour
      order
      weeksAtLocation
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      address {
        id
        businessID
        addressLine1
        addressLine2
        addressLine3
        addressLine4
        city
        region
        postCode
        country
        attentionTo
        lat
        lng
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      photos {
        items {
          id
          businessID
          description
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const onDeleteMobileLocation = /* GraphQL */ `
  subscription OnDeleteMobileLocation {
    onDeleteMobileLocation {
      id
      businessID
      locationID
      name
      addressID
      isRepeating
      comingSoon
      colour
      order
      weeksAtLocation
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      address {
        id
        businessID
        addressLine1
        addressLine2
        addressLine3
        addressLine4
        city
        region
        postCode
        country
        attentionTo
        lat
        lng
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      photos {
        items {
          id
          businessID
          description
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const onCreateLocationScheduleException = /* GraphQL */ `
  subscription OnCreateLocationScheduleException {
    onCreateLocationScheduleException {
      id
      businessID
      locationID
      mobileLocationID
      date
      disabled
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateLocationScheduleException = /* GraphQL */ `
  subscription OnUpdateLocationScheduleException {
    onUpdateLocationScheduleException {
      id
      businessID
      locationID
      mobileLocationID
      date
      disabled
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteLocationScheduleException = /* GraphQL */ `
  subscription OnDeleteLocationScheduleException {
    onDeleteLocationScheduleException {
      id
      businessID
      locationID
      mobileLocationID
      date
      disabled
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateService = /* GraphQL */ `
  subscription OnCreateService {
    onCreateService {
      id
      businessID
      name
      code
      blockColour
      offeredIn
      order
      duration
      price
      isDoneParallel
      isSecondary
      isAddon
      enabled
      online
      photo {
        bucket
        key
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      teamMembers {
        items {
          id
          businessID
          serviceID
          teamMemberID
          duration
          price
          maxDaily
          enabled
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const onUpdateService = /* GraphQL */ `
  subscription OnUpdateService {
    onUpdateService {
      id
      businessID
      name
      code
      blockColour
      offeredIn
      order
      duration
      price
      isDoneParallel
      isSecondary
      isAddon
      enabled
      online
      photo {
        bucket
        key
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      teamMembers {
        items {
          id
          businessID
          serviceID
          teamMemberID
          duration
          price
          maxDaily
          enabled
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const onDeleteService = /* GraphQL */ `
  subscription OnDeleteService {
    onDeleteService {
      id
      businessID
      name
      code
      blockColour
      offeredIn
      order
      duration
      price
      isDoneParallel
      isSecondary
      isAddon
      enabled
      online
      photo {
        bucket
        key
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      teamMembers {
        items {
          id
          businessID
          serviceID
          teamMemberID
          duration
          price
          maxDaily
          enabled
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const onCreateProduct = /* GraphQL */ `
  subscription OnCreateProduct {
    onCreateProduct {
      id
      businessID
      name
      code
      order
      price
      enabled
      online
      photo {
        bucket
        key
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateProduct = /* GraphQL */ `
  subscription OnUpdateProduct {
    onUpdateProduct {
      id
      businessID
      name
      code
      order
      price
      enabled
      online
      photo {
        bucket
        key
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteProduct = /* GraphQL */ `
  subscription OnDeleteProduct {
    onDeleteProduct {
      id
      businessID
      name
      code
      order
      price
      enabled
      online
      photo {
        bucket
        key
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateTeamMemberService = /* GraphQL */ `
  subscription OnCreateTeamMemberService {
    onCreateTeamMemberService {
      id
      businessID
      serviceID
      teamMemberID
      duration
      price
      maxDaily
      enabled
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateTeamMemberService = /* GraphQL */ `
  subscription OnUpdateTeamMemberService {
    onUpdateTeamMemberService {
      id
      businessID
      serviceID
      teamMemberID
      duration
      price
      maxDaily
      enabled
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteTeamMemberService = /* GraphQL */ `
  subscription OnDeleteTeamMemberService {
    onDeleteTeamMemberService {
      id
      businessID
      serviceID
      teamMemberID
      duration
      price
      maxDaily
      enabled
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateTeamMember = /* GraphQL */ `
  subscription OnCreateTeamMember {
    onCreateTeamMember {
      id
      businessID
      code
      username
      givenName
      familyName
      phone
      email
      acceptsBookings
      locationID
      offDays
      offDayExceptions
      seatNo
      queueGap
      entrance
      status
      role
      startDate
      endDate
      photo {
        bucket
        key
      }
      openingHours {
        day
        opensAt
        closesAt
      }
      break {
        day
        opensAt
        closesAt
      }
      estimatedBreakTimes
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      location {
        id
        businessID
        name
        code
        addressID
        phone
        openingHours {
          day
          opensAt
          closesAt
        }
        acceptsBookings
        virtualQueue
        online
        maintenance
        opensOnPublicHolidays
        isMobile
        bookingFlow
        mobileWeekOffset
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        address {
          id
          businessID
          addressLine1
          addressLine2
          addressLine3
          addressLine4
          city
          region
          postCode
          country
          attentionTo
          lat
          lng
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        team {
          nextToken
          startedAt
        }
        photos {
          nextToken
          startedAt
        }
      }
      services {
        items {
          id
          businessID
          serviceID
          teamMemberID
          duration
          price
          maxDaily
          enabled
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      unavailablies {
        items {
          id
          businessID
          teamMemberID
          date
          from
          to
          reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      availabilityExceptions {
        items {
          id
          businessID
          teamMemberID
          date
          from
          to
          breakTime
          allowExtraBreak
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      bookings {
        items {
          id
          businessID
          customerID
          teamMemberID
          locationID
          note
          transactionReference
          paymentMethod
          date
          startTime
          endTime
          dateCancelled
          status
          source
          fee
          additionalFee
          tip
          confirmationReminderSent
          finalReminderSent
          reviewEmailSent
          createdAt
          updatedAt
          stripeID
          version
          transaction
          isNew
          any
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const onUpdateTeamMember = /* GraphQL */ `
  subscription OnUpdateTeamMember {
    onUpdateTeamMember {
      id
      businessID
      code
      username
      givenName
      familyName
      phone
      email
      acceptsBookings
      locationID
      offDays
      offDayExceptions
      seatNo
      queueGap
      entrance
      status
      role
      startDate
      endDate
      photo {
        bucket
        key
      }
      openingHours {
        day
        opensAt
        closesAt
      }
      break {
        day
        opensAt
        closesAt
      }
      estimatedBreakTimes
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      location {
        id
        businessID
        name
        code
        addressID
        phone
        openingHours {
          day
          opensAt
          closesAt
        }
        acceptsBookings
        virtualQueue
        online
        maintenance
        opensOnPublicHolidays
        isMobile
        bookingFlow
        mobileWeekOffset
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        address {
          id
          businessID
          addressLine1
          addressLine2
          addressLine3
          addressLine4
          city
          region
          postCode
          country
          attentionTo
          lat
          lng
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        team {
          nextToken
          startedAt
        }
        photos {
          nextToken
          startedAt
        }
      }
      services {
        items {
          id
          businessID
          serviceID
          teamMemberID
          duration
          price
          maxDaily
          enabled
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      unavailablies {
        items {
          id
          businessID
          teamMemberID
          date
          from
          to
          reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      availabilityExceptions {
        items {
          id
          businessID
          teamMemberID
          date
          from
          to
          breakTime
          allowExtraBreak
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      bookings {
        items {
          id
          businessID
          customerID
          teamMemberID
          locationID
          note
          transactionReference
          paymentMethod
          date
          startTime
          endTime
          dateCancelled
          status
          source
          fee
          additionalFee
          tip
          confirmationReminderSent
          finalReminderSent
          reviewEmailSent
          createdAt
          updatedAt
          stripeID
          version
          transaction
          isNew
          any
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const onDeleteTeamMember = /* GraphQL */ `
  subscription OnDeleteTeamMember {
    onDeleteTeamMember {
      id
      businessID
      code
      username
      givenName
      familyName
      phone
      email
      acceptsBookings
      locationID
      offDays
      offDayExceptions
      seatNo
      queueGap
      entrance
      status
      role
      startDate
      endDate
      photo {
        bucket
        key
      }
      openingHours {
        day
        opensAt
        closesAt
      }
      break {
        day
        opensAt
        closesAt
      }
      estimatedBreakTimes
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      location {
        id
        businessID
        name
        code
        addressID
        phone
        openingHours {
          day
          opensAt
          closesAt
        }
        acceptsBookings
        virtualQueue
        online
        maintenance
        opensOnPublicHolidays
        isMobile
        bookingFlow
        mobileWeekOffset
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        address {
          id
          businessID
          addressLine1
          addressLine2
          addressLine3
          addressLine4
          city
          region
          postCode
          country
          attentionTo
          lat
          lng
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        team {
          nextToken
          startedAt
        }
        photos {
          nextToken
          startedAt
        }
      }
      services {
        items {
          id
          businessID
          serviceID
          teamMemberID
          duration
          price
          maxDaily
          enabled
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      unavailablies {
        items {
          id
          businessID
          teamMemberID
          date
          from
          to
          reason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      availabilityExceptions {
        items {
          id
          businessID
          teamMemberID
          date
          from
          to
          breakTime
          allowExtraBreak
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      bookings {
        items {
          id
          businessID
          customerID
          teamMemberID
          locationID
          note
          transactionReference
          paymentMethod
          date
          startTime
          endTime
          dateCancelled
          status
          source
          fee
          additionalFee
          tip
          confirmationReminderSent
          finalReminderSent
          reviewEmailSent
          createdAt
          updatedAt
          stripeID
          version
          transaction
          isNew
          any
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const onCreateUnavailablity = /* GraphQL */ `
  subscription OnCreateUnavailablity {
    onCreateUnavailablity {
      id
      businessID
      teamMemberID
      teamMember {
        id
        businessID
        code
        username
        givenName
        familyName
        phone
        email
        acceptsBookings
        locationID
        offDays
        offDayExceptions
        seatNo
        queueGap
        entrance
        status
        role
        startDate
        endDate
        photo {
          bucket
          key
        }
        openingHours {
          day
          opensAt
          closesAt
        }
        break {
          day
          opensAt
          closesAt
        }
        estimatedBreakTimes
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        location {
          id
          businessID
          name
          code
          addressID
          phone
          acceptsBookings
          virtualQueue
          online
          maintenance
          opensOnPublicHolidays
          isMobile
          bookingFlow
          mobileWeekOffset
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        services {
          nextToken
          startedAt
        }
        unavailablies {
          nextToken
          startedAt
        }
        availabilityExceptions {
          nextToken
          startedAt
        }
        bookings {
          nextToken
          startedAt
        }
      }
      date
      from
      to
      reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateUnavailablity = /* GraphQL */ `
  subscription OnUpdateUnavailablity {
    onUpdateUnavailablity {
      id
      businessID
      teamMemberID
      teamMember {
        id
        businessID
        code
        username
        givenName
        familyName
        phone
        email
        acceptsBookings
        locationID
        offDays
        offDayExceptions
        seatNo
        queueGap
        entrance
        status
        role
        startDate
        endDate
        photo {
          bucket
          key
        }
        openingHours {
          day
          opensAt
          closesAt
        }
        break {
          day
          opensAt
          closesAt
        }
        estimatedBreakTimes
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        location {
          id
          businessID
          name
          code
          addressID
          phone
          acceptsBookings
          virtualQueue
          online
          maintenance
          opensOnPublicHolidays
          isMobile
          bookingFlow
          mobileWeekOffset
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        services {
          nextToken
          startedAt
        }
        unavailablies {
          nextToken
          startedAt
        }
        availabilityExceptions {
          nextToken
          startedAt
        }
        bookings {
          nextToken
          startedAt
        }
      }
      date
      from
      to
      reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteUnavailablity = /* GraphQL */ `
  subscription OnDeleteUnavailablity {
    onDeleteUnavailablity {
      id
      businessID
      teamMemberID
      teamMember {
        id
        businessID
        code
        username
        givenName
        familyName
        phone
        email
        acceptsBookings
        locationID
        offDays
        offDayExceptions
        seatNo
        queueGap
        entrance
        status
        role
        startDate
        endDate
        photo {
          bucket
          key
        }
        openingHours {
          day
          opensAt
          closesAt
        }
        break {
          day
          opensAt
          closesAt
        }
        estimatedBreakTimes
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        location {
          id
          businessID
          name
          code
          addressID
          phone
          acceptsBookings
          virtualQueue
          online
          maintenance
          opensOnPublicHolidays
          isMobile
          bookingFlow
          mobileWeekOffset
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        services {
          nextToken
          startedAt
        }
        unavailablies {
          nextToken
          startedAt
        }
        availabilityExceptions {
          nextToken
          startedAt
        }
        bookings {
          nextToken
          startedAt
        }
      }
      date
      from
      to
      reason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateAvailabilityException = /* GraphQL */ `
  subscription OnCreateAvailabilityException {
    onCreateAvailabilityException {
      id
      businessID
      teamMemberID
      teamMember {
        id
        businessID
        code
        username
        givenName
        familyName
        phone
        email
        acceptsBookings
        locationID
        offDays
        offDayExceptions
        seatNo
        queueGap
        entrance
        status
        role
        startDate
        endDate
        photo {
          bucket
          key
        }
        openingHours {
          day
          opensAt
          closesAt
        }
        break {
          day
          opensAt
          closesAt
        }
        estimatedBreakTimes
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        location {
          id
          businessID
          name
          code
          addressID
          phone
          acceptsBookings
          virtualQueue
          online
          maintenance
          opensOnPublicHolidays
          isMobile
          bookingFlow
          mobileWeekOffset
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        services {
          nextToken
          startedAt
        }
        unavailablies {
          nextToken
          startedAt
        }
        availabilityExceptions {
          nextToken
          startedAt
        }
        bookings {
          nextToken
          startedAt
        }
      }
      date
      from
      to
      breakTime
      allowExtraBreak
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateAvailabilityException = /* GraphQL */ `
  subscription OnUpdateAvailabilityException {
    onUpdateAvailabilityException {
      id
      businessID
      teamMemberID
      teamMember {
        id
        businessID
        code
        username
        givenName
        familyName
        phone
        email
        acceptsBookings
        locationID
        offDays
        offDayExceptions
        seatNo
        queueGap
        entrance
        status
        role
        startDate
        endDate
        photo {
          bucket
          key
        }
        openingHours {
          day
          opensAt
          closesAt
        }
        break {
          day
          opensAt
          closesAt
        }
        estimatedBreakTimes
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        location {
          id
          businessID
          name
          code
          addressID
          phone
          acceptsBookings
          virtualQueue
          online
          maintenance
          opensOnPublicHolidays
          isMobile
          bookingFlow
          mobileWeekOffset
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        services {
          nextToken
          startedAt
        }
        unavailablies {
          nextToken
          startedAt
        }
        availabilityExceptions {
          nextToken
          startedAt
        }
        bookings {
          nextToken
          startedAt
        }
      }
      date
      from
      to
      breakTime
      allowExtraBreak
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteAvailabilityException = /* GraphQL */ `
  subscription OnDeleteAvailabilityException {
    onDeleteAvailabilityException {
      id
      businessID
      teamMemberID
      teamMember {
        id
        businessID
        code
        username
        givenName
        familyName
        phone
        email
        acceptsBookings
        locationID
        offDays
        offDayExceptions
        seatNo
        queueGap
        entrance
        status
        role
        startDate
        endDate
        photo {
          bucket
          key
        }
        openingHours {
          day
          opensAt
          closesAt
        }
        break {
          day
          opensAt
          closesAt
        }
        estimatedBreakTimes
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        location {
          id
          businessID
          name
          code
          addressID
          phone
          acceptsBookings
          virtualQueue
          online
          maintenance
          opensOnPublicHolidays
          isMobile
          bookingFlow
          mobileWeekOffset
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        services {
          nextToken
          startedAt
        }
        unavailablies {
          nextToken
          startedAt
        }
        availabilityExceptions {
          nextToken
          startedAt
        }
        bookings {
          nextToken
          startedAt
        }
      }
      date
      from
      to
      breakTime
      allowExtraBreak
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreatePhoto = /* GraphQL */ `
  subscription OnCreatePhoto {
    onCreatePhoto {
      id
      businessID
      description
      photo {
        bucket
        key
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      location {
        id
        businessID
        name
        code
        addressID
        phone
        openingHours {
          day
          opensAt
          closesAt
        }
        acceptsBookings
        virtualQueue
        online
        maintenance
        opensOnPublicHolidays
        isMobile
        bookingFlow
        mobileWeekOffset
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        address {
          id
          businessID
          addressLine1
          addressLine2
          addressLine3
          addressLine4
          city
          region
          postCode
          country
          attentionTo
          lat
          lng
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        team {
          nextToken
          startedAt
        }
        photos {
          nextToken
          startedAt
        }
      }
      mobileLocation {
        id
        businessID
        locationID
        name
        addressID
        isRepeating
        comingSoon
        colour
        order
        weeksAtLocation
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        address {
          id
          businessID
          addressLine1
          addressLine2
          addressLine3
          addressLine4
          city
          region
          postCode
          country
          attentionTo
          lat
          lng
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        photos {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const onUpdatePhoto = /* GraphQL */ `
  subscription OnUpdatePhoto {
    onUpdatePhoto {
      id
      businessID
      description
      photo {
        bucket
        key
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      location {
        id
        businessID
        name
        code
        addressID
        phone
        openingHours {
          day
          opensAt
          closesAt
        }
        acceptsBookings
        virtualQueue
        online
        maintenance
        opensOnPublicHolidays
        isMobile
        bookingFlow
        mobileWeekOffset
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        address {
          id
          businessID
          addressLine1
          addressLine2
          addressLine3
          addressLine4
          city
          region
          postCode
          country
          attentionTo
          lat
          lng
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        team {
          nextToken
          startedAt
        }
        photos {
          nextToken
          startedAt
        }
      }
      mobileLocation {
        id
        businessID
        locationID
        name
        addressID
        isRepeating
        comingSoon
        colour
        order
        weeksAtLocation
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        address {
          id
          businessID
          addressLine1
          addressLine2
          addressLine3
          addressLine4
          city
          region
          postCode
          country
          attentionTo
          lat
          lng
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        photos {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const onDeletePhoto = /* GraphQL */ `
  subscription OnDeletePhoto {
    onDeletePhoto {
      id
      businessID
      description
      photo {
        bucket
        key
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      location {
        id
        businessID
        name
        code
        addressID
        phone
        openingHours {
          day
          opensAt
          closesAt
        }
        acceptsBookings
        virtualQueue
        online
        maintenance
        opensOnPublicHolidays
        isMobile
        bookingFlow
        mobileWeekOffset
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        address {
          id
          businessID
          addressLine1
          addressLine2
          addressLine3
          addressLine4
          city
          region
          postCode
          country
          attentionTo
          lat
          lng
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        team {
          nextToken
          startedAt
        }
        photos {
          nextToken
          startedAt
        }
      }
      mobileLocation {
        id
        businessID
        locationID
        name
        addressID
        isRepeating
        comingSoon
        colour
        order
        weeksAtLocation
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        address {
          id
          businessID
          addressLine1
          addressLine2
          addressLine3
          addressLine4
          city
          region
          postCode
          country
          attentionTo
          lat
          lng
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        photos {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const onCreateChangelog = /* GraphQL */ `
  subscription OnCreateChangelog {
    onCreateChangelog {
      id
      entityID
      businessID
      action
      diff
      teamMemberID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateChangelog = /* GraphQL */ `
  subscription OnUpdateChangelog {
    onUpdateChangelog {
      id
      entityID
      businessID
      action
      diff
      teamMemberID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteChangelog = /* GraphQL */ `
  subscription OnDeleteChangelog {
    onDeleteChangelog {
      id
      entityID
      businessID
      action
      diff
      teamMemberID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateBookingStats = /* GraphQL */ `
  subscription OnCreateBookingStats {
    onCreateBookingStats {
      id
      reportType
      businessID
      date
      value
      amount
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateBookingStats = /* GraphQL */ `
  subscription OnUpdateBookingStats {
    onUpdateBookingStats {
      id
      reportType
      businessID
      date
      value
      amount
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteBookingStats = /* GraphQL */ `
  subscription OnDeleteBookingStats {
    onDeleteBookingStats {
      id
      reportType
      businessID
      date
      value
      amount
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onCreateAd = /* GraphQL */ `
  subscription OnCreateAd {
    onCreateAd {
      id
      businessID
      locationID
      mobileLocationIDs
      name
      startDate
      endDate
      status
      image {
        bucket
        key
      }
      link
      duration
      showWhenQueueing
      showWhenBeingServed
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateAd = /* GraphQL */ `
  subscription OnUpdateAd {
    onUpdateAd {
      id
      businessID
      locationID
      mobileLocationIDs
      name
      startDate
      endDate
      status
      image {
        bucket
        key
      }
      link
      duration
      showWhenQueueing
      showWhenBeingServed
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteAd = /* GraphQL */ `
  subscription OnDeleteAd {
    onDeleteAd {
      id
      businessID
      locationID
      mobileLocationIDs
      name
      startDate
      endDate
      status
      image {
        bucket
        key
      }
      link
      duration
      showWhenQueueing
      showWhenBeingServed
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onCreatePaymentMachine = /* GraphQL */ `
  subscription OnCreatePaymentMachine {
    onCreatePaymentMachine {
      id
      businessID
      locationID
      name
      type
      settings
      status
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onUpdatePaymentMachine = /* GraphQL */ `
  subscription OnUpdatePaymentMachine {
    onUpdatePaymentMachine {
      id
      businessID
      locationID
      name
      type
      settings
      status
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onDeletePaymentMachine = /* GraphQL */ `
  subscription OnDeletePaymentMachine {
    onDeletePaymentMachine {
      id
      businessID
      locationID
      name
      type
      settings
      status
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;

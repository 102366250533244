// import * as React from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import {Actions as CHANGELOGACTIONS} from 'store/changelog/action';
import React, { useState, useEffect } from "react";
import { format } from "date-fns";
import {connect} from 'react-redux';

const styles = ({ spacing, palette }) => ({
  head: {
    position: 'relative',
    paddingLeft: spacing(1),
    paddingRight: spacing(0.5),
    paddingTop: spacing(0.25),
    minHeight: spacing(1.5),
  },
  line: {
    backgroundColor: palette.action.disabledBackground,
    height: spacing(3.5),
    marginLeft: spacing(1),
    marginRight: spacing(1),
    marginTop: spacing(1.25),
    width: '1px',
  },
  flexContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
  },
});

const HeaderBase = ({
  appointmentData,
  commandButtonComponent: CommandButton,
  showOpenButton,
  showCloseButton,
  showDeleteButton,
  commandButtonIds,
  onOpenButtonClick,
  onDeleteButtonClick,
  onHide,
  classes,
  className,
  children,
  changelog,
  teamMembers,
  toggleChangeLogModal,
  getChangelogByEntity,
  ...restProps
}) => {

  useEffect(() => {
    console.log('Update changelog',appointmentData,appointmentData.id)
    if (appointmentData.id){
      getChangelogByEntity(appointmentData.id)
    }
  },[appointmentData && appointmentData.id]);

  const handleOpenButtonClick = () => {
    onHide();
    onOpenButtonClick();
  };

  const lastChangeLog = changelog && changelog[0]
  const lastModifiedBy = lastChangeLog && teamMembers.find(({id}) => id == lastChangeLog.teamMemberID)
  const lastModifiedDate = lastChangeLog && format(new Date(lastChangeLog.createdAt),"d MMM yyyy hh:mm")

  console.log("lastModifiedBy",lastModifiedBy)
  console.log("createdAt",lastChangeLog && lastChangeLog.createdAt,lastModifiedDate)

  return (
    <div
      className={classNames(classes.head, classes.flexContainer, className)}
      {...restProps}
    >
      { lastChangeLog && 
        <div 
          style={{
            width: "100%",
            cursor: "pointer",
          }}
          onClick={ () => {
            toggleChangeLogModal()
            onHide()
          }}
        >
          Last modified by <strong>{lastModifiedBy ? `${lastModifiedBy.givenName} ${lastModifiedBy.familyName}` : "Client" }</strong> 
          <br/> at {lastModifiedDate}
        </div>
      }
      {showOpenButton && (
        <CommandButton id={commandButtonIds.open} onExecute={handleOpenButtonClick} />
      )}
      {showDeleteButton
        && <CommandButton id={commandButtonIds.delete} onExecute={onDeleteButtonClick} />}
      {children}
      {showCloseButton && (
        <div className={classes.flexContainer}>
          <div className={classes.line} />
          <CommandButton id={commandButtonIds.close} onExecute={onHide} />
        </div>
      )}
    </div>
  );
};

HeaderBase.propTypes = {
  classes: PropTypes.object.isRequired,
  appointmentData: PropTypes.object,
  children: PropTypes.node,
  className: PropTypes.string,
  commandButtonComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  showOpenButton: PropTypes.bool.isRequired,
  showCloseButton: PropTypes.bool.isRequired,
  showDeleteButton: PropTypes.bool.isRequired,
  commandButtonIds: PropTypes.object.isRequired,
  onOpenButtonClick: PropTypes.func,
  onDeleteButtonClick: PropTypes.func,
  onHide: PropTypes.func,
};

HeaderBase.defaultProps = {
  appointmentData: undefined,
  className: undefined,
  children: undefined,
  onOpenButtonClick: () => undefined,
  onDeleteButtonClick: () => undefined,
  onHide: () => undefined,
};

const mapStateToProps = (state) => {
  console.log('state',state && state.business)
    return {
      changelog: state.changelog && state.changelog.items,
      teamMembers: state.teamMember.list,
  }
}

const mapDispatchToProps = dispatch => ({
  getChangelogByEntity: (item) => dispatch(CHANGELOGACTIONS.getChangelogByEntity(item)),
  toggleChangeLogModal: () => dispatch(CHANGELOGACTIONS.toggleChangeLogModal(true)),
});

const HeaderWithStyles = withStyles(styles, { name: 'Header' })(HeaderBase);

export const Header = connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderWithStyles)



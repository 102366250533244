import * as React from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { getAppointmentColor, getResourceColor } from '../utils';
import { darken, fade, lighten } from '@material-ui/core/styles/colorManipulator';
import Color from 'color';


export const Appointment = ({
  className,
  children,
  data,
  onClick: handleClick,
  draggable,
  isShaded,
  resources,
  ...restProps
}) => {

  // console.log('data.backgroundColor',data)

  const backgroundColor = data.backgroundColor || "#111"

  const useStyles = makeStyles(({ palette, typography, spacing }) => ({
    appointment: {
      userSelect: 'none',
      position: 'absolute',
      height: '100%',
      width: '100%',
      overflow: 'hidden',
      boxSizing: 'border-box',
      // fix no space between appointments bug in Firefox (https://bugzilla.mozilla.org/show_bug.cgi?id=989340)
      borderRight: '2px solid transparent',
      borderBottom: '1px solid transparent',
      backgroundClip: 'padding-box',
      borderRadius: spacing(0.5),
      backgroundColor: backgroundColor,
      ...typography.caption,
      '&:hover': {
        backgroundColor: lighten(backgroundColor,.1),
      },
      '&:focus': {
        backgroundColor: lighten(backgroundColor,.2),
        outline: 0,
      },
    },
    clickableAppointment: {
      cursor: 'pointer',
    },
    confirmedAppointment: {
      borderLeft: "solid 10px green",
      backgroundColor: darken(backgroundColor,.3),
      '&:hover': {
        backgroundColor: darken(backgroundColor,.1),
      },
      '&:focus': {
        backgroundColor: darken(backgroundColor,.2),
        outline: 0,
      },
    },
    noShowAppointment: {
      borderLeft: "solid 10px red",
    },
    shadedAppointment: {
      // backgroundColor: new Color(backgroundColor).desaturate(.9).hex(),
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      '&:hover': {
        // backgroundColor: new Color(backgroundColor).desaturate(.7).hex(),
        backgroundColor: "rgba(0, 0, 0, 0.7)",
      },
    },
  }));

  const onClick = handleClick
    ? {
      onClick: ({ target }) => {
        handleClick({ target, data });
      },
    }
    : null;
  const classes = useStyles(resources);
  const clickable = onClick || restProps.onDoubleClick || draggable;
  return (
    <div
      className={classNames({
        [classes.appointment]: true,
        [classes.clickableAppointment]: clickable,
        [classes.noShowAppointment]: data.backgroundColor != "#000000" && !["Present","Served","FinishedEarly","Paid"].includes(data.status) && data.startDate && data.startDate.getTime() <= new Date().getTime(),
        [classes.shadedAppointment]: data.backgroundColor != "#000000" && ["Present","Served","FinishedEarly","Paid"].includes(data.status),
        [classes.confirmedAppointment]: data.backgroundColor != "#000000" && ["Confirmed"].includes(data.status),
      }, className)}
      {...onClick}
      {...restProps}
    >
      {children}
    </div>
  );
};

Appointment.propTypes = {
  children: PropTypes.node.isRequired,
  resources: PropTypes.array,
  className: PropTypes.string,
  data: PropTypes.object,
  onClick: PropTypes.func,
  draggable: PropTypes.bool,
  isShaded: PropTypes.bool,
};

Appointment.defaultProps = {
  resources: [],
  onClick: undefined,
  className: undefined,
  data: {},
  draggable: false,
  isShaded: false,
};

import React, { useState, useEffect } from "react";
import { useForm } from 'react-hook-form'

import {connect} from 'react-redux';

import {Actions as ACTIONS} from 'store/dashboard/action';
import PageHeading from 'components/PageHeading';


import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from "recharts";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useLocation,
  useParams
} from "react-router-dom";
import { useHistory } from "react-router-dom";


import {format, addDays} from 'date-fns';
import DatePicker from 'react-datepicker';

import "react-datepicker/dist/react-datepicker.css";
import "styles/datepicker.css";

import Select from 'react-select';

const colours = [
	        '#ad0b02',
	        '#FFAABB',
	        '#8A00FF',
	        '#0000b0',
	        '#00E9FF',
	        '#00FF00',
	        '#02630c',
	        '#EDFF00',
	        '#ff9100',
	        '#5e3324',
	        '#757575',
	        '#c7974e',
        ];

const IndicatorsContainer = () => (
	<div style={{ display: "flex", padding:"6.5px"}}>
		<svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
		  <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"/>
		</svg>
	</div>
)
	
const selectDefaultStyle = {
	// radius 0.375rem
  control: styles => ({ 
  	...styles, 
  	backgroundColor: 'white', 
  	border: 'none',
  	padding: 0,
  	minHeight: 'auto',
  	height: '29px',
  }),
  indicatorSeparator:  (provided, state) => ({
  	display: "none",
  }),
  indicatorsContainer:  (provided, state) => ({
  	// icon: ``,
  }),
  valueContainer:  (provided, state) => ({
    // ...provided,
    padding: "0",
    display: "block",
  }),
  placeholder:  (provided, state) => ({
    // ...provided,
    padding: "0",
    display: "block",
    padding: "6.5px 13px",
    fontSize: "0.875rem",
  }),
  singleValue:  (provided, state) => ({
    // ...provided,
    padding: "6.5px 13px",
    fontSize: "0.875rem",
  }),
  input:  (provided, state) => ({
    ...provided,
    lineHeight: "0",
    display: "block",
    padding: "6.5px 13px",
    fontSize: "0.875rem",
    top: 0,
    position: "absolute",
  }),
  option:  (provided, state) => ({
    ...provided,
    lineHeight: "1.25rem",
    fontSize: "0.875rem",
  }),
  menu: (provided, state) => ({
    ...provided,
    zIndex: 999999,
    // lineHeight: "14px",
  }),
};

const Chart = ({getStats,reportType,chartTitle,data,keys}) => {

	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState(addDays(new Date(),7));
	const onDateChange = dates => {
		console.log('dates',dates)
	    const [start, end] = dates;
	    setStartDate(start);
	    setEndDate(end);
	};

	useEffect(() => {
		getStats({
			limit: 1000, 
			nextToken: null, 
			from: format(startDate,"yyyy-MM-dd"), 
			to: format(endDate,"yyyy-MM-dd"), 
			reportType: reportType, 
		})
	},[startDate, endDate])

  	let history = useHistory();
	// if (!customers){

	// 	return (
	// 		<div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
	// 			Loading...
	// 		</div>
	// 	)
	// }

	
	const ExampleCustomInput = ({ value, onClick }) => (
	    <button 
	    	onClick={onClick} 
	    	type="button" 
	    	class="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150"
	    >
	    	{value} {/*{currentDate && format(new Date(currentDate),'dd/MM/yyyy')}*/}
	  	</button>
	)

	return (
		<React.Fragment>
			<div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">

				<span class="relative z-10 inline-flex shadow-sm" style={{lineHeight:"31px"}}>
					<strong>{chartTitle}</strong>
					<label style={{marginLeft:"10px",marginRight:"5px"}}>From </label>
				      <DatePicker
		                dateFormat="dd/MM/yyyy" 
		                customInput={<ExampleCustomInput />}
				        selected={startDate}
				        onChange={date => setStartDate(date)}
				        selectsStart
				        startDate={startDate}
				        endDate={endDate}
				        className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"			        
		                popperPlacement="bottom-end"
		                popperModifiers={{
		                    offset: {
		                      enabled: true,
		                      //offset: "77px, 0px"
		                    }
		                }}
				      />
				      <label style={{marginRight:"5px", marginLeft:"5px"}}> to </label> 
				      <DatePicker
		                dateFormat="dd/MM/yyyy" 
		                customInput={<ExampleCustomInput />}
				        selected={endDate}
				        onChange={date => setEndDate(date)}
				        selectsEnd
				        startDate={startDate}
				        endDate={endDate}
				        minDate={startDate}
				        className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"popperPlacement="bottom-end"
		                popperModifiers={{
		                    offset: {
		                      enabled: true,
		                      //offset: "77px, 0px"
		                    }
		                }}
				      />
			    </span>

				<div class="flex flex-col">
				    <LineChart
				      width={600}
				      height={300}
				      data={data}
				      margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
				    >
				      <XAxis dataKey="name" />
				      <YAxis />
				      <CartesianGrid strokeDasharray="3 3" />
				      <Tooltip />
				      <Legend />
				      {keys && keys.map((key,index) => 
				      	<Line key={key} type="monotone" dataKey={key} stroke={colours[index]} />
				      )}
				    </LineChart>
				</div>
			</div>
		</React.Fragment>
		)
}


const mapStateToProps = (state,{reportType,...ownProps}) => {

	const sourceData = state.dashboard[reportType];
	const teamMembers = state.teamMember.list;
	const teamMemberNamesByID = {}

	teamMembers && teamMembers.map( teamMember => {
	 	teamMemberNamesByID[teamMember.id] = teamMember.givenName
	 })

	const locations = state.location.list;
	const locationNamesByID = {}

	locations && locations.map( location => {
	 	locationNamesByID[location.id] = location.name
	 })
	
	const dataToReturn = {}

	const keysUsed = {}

	if (sourceData && teamMembers){
		const datesInTable = Object.keys(sourceData);


		const startDate = datesInTable[0];
		const endDate = datesInTable[datesInTable.length -1];

		let currentDate = startDate;

		while ( currentDate <= endDate){

			dataToReturn[currentDate] = {}

			if (sourceData[currentDate]){
				sourceData[currentDate].map(item => {

					// if is team member user their name 
					// else use the raw value as key

					let key = reportType == "teamMember" ? 
						teamMemberNamesByID[item.value]
						: reportType == "location" ? 
						locationNamesByID[item.value]
						: item.value

					keysUsed[key] = true;

					dataToReturn[currentDate][key] = item.amount;

				})
			}


			// increase date
			currentDate = format(addDays(new Date(currentDate),1),"yyyy-MM-dd")

		}

		console.log('dataToReturn',dataToReturn)

	}

	const dataArray = dataToReturn && Object.keys(dataToReturn).map(date => 
		({
			...dataToReturn[date],
			name: date
		})
	)

    return {
      // teamMembers: teamMembers,
      data: dataArray || [],
      keys: Object.keys(keysUsed)
  	}
}

const mapDispatchToProps = dispatch => ({
  getStats: (input) => dispatch(ACTIONS.getStats(input)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Chart)
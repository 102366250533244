import React, { useState, useEffect } from "react";
import Paper from '@material-ui/core/Paper';
import {
  ViewState, GroupingState, IntegratedGrouping, IntegratedEditing, EditingState,
} from '@devexpress/dx-react-scheduler';
import {
  Scheduler,
  Resources,
  Appointments,
  AppointmentTooltip,
  GroupingPanel,
  DayView,
  DragDropProvider,
  AppointmentForm,
} from '@devexpress/dx-react-scheduler-material-ui';
import {
  teal, indigo, yellow
} from '@material-ui/core/colors';
import Select from 'react-select';
import AsyncCreatableSelect from 'react-select/async-creatable';
import chroma from 'chroma-js';
import { v4 as uuidv4 } from 'uuid';
import cleanDeep from 'clean-deep';

import { useForm } from 'react-hook-form'

import CustomerForm from 'components/customer/CustomerForm';

import {Actions as ACTIONS} from 'store/booking/action';
import {Actions as CUSTOMERACTIONS} from 'store/customer/action';
import {Actions as NOTIFICATIONACTIONS} from 'store/notifications/action';
import axios from 'axios';
import {connect} from 'react-redux';
import DatePicker from 'react-datepicker';
import { publicHolidays } from '../../lib/publicHolidays';

import "react-datepicker/dist/react-datepicker.css";
import "styles/datepicker.css";
import { format, formatDistance, differenceInMinutes, formatRelative, subDays, setHours, setMinutes, addMinutes, setSeconds, getMinutes, getDate, getDay, formatDistanceStrict } from 'date-fns'
import { zonedTimeToUtc } from 'date-fns-tz'
import _ from "lodash";
import {API} from '@aws-amplify/api'

import {
          customerByBusinessByPhone,
       } from 'graphql/queries'
import CalendarDay from "components/teamMember/CalendarDay";

const timeSlotInterval = 15;

const BookingForm = ({readOnly, appointmentData, appointmentResources, resources, onFieldChange, getMessage, locale, fullSize,
                      
                      booking,
                      bookings,
                      services,
                      teamMembers,
                      locations,
                      customers,
                      teamMemberBookings,
                      getBookingsByTeamMember,
                      clearBooking,
                      createBooking,
                      addNotification,
                      updateBooking,
                      getBooking,
                      location,
                      newCustomerModal,
                      setNewCustomerModal,
                      businessID,
                      availabilityExceptions,
                      ...restProps
                    }) => {

  const { register, setValue, handleSubmit, watch, errors } = useForm()
  const [customerSearch, setCustomerSearch] = useState(new Date())
  const [customerOption, setCustomerOption] = useState({value:"",label:""})

  const teamMemberID = watch('teamMemberID');
  // on change of TeamMember get their future bookings

  useEffect(() => {
    if (teamMemberID){
      getBookingsByTeamMember({
        teamMemberID,
        date: {
          ge: format(new Date(),'yyyy-MM-dd')
        },
        limit: 1000,
      })
    }
  },[teamMemberID]);

  useEffect(() => {
    
    if (appointmentData && appointmentData.id && !(booking && booking.id == appointmentData.id)){
      
      getBooking(appointmentData.id)
    } else if (appointmentData && !appointmentData.id){
      
      // this is a new appointment
      setCustomerOption(null)
      setValue('customerID',null)
      setValue('serviceIDs',null)
      setValue('locationID',location.value)
      setValue('teamMemberID',appointmentData.members[0])
      setValue('date',appointmentData.startDate)
      setValue('time',appointmentData.startDate)
      setValue('note',null)
      clearBooking();
    }
  },[appointmentData && appointmentData.id]);

  useEffect(() => {
    // new booking loaded so we should set the values
    if (booking && booking.id && appointmentData && booking.id == appointmentData.id){
      // initialise with values from booking
      

      const customer = booking.customer;
      setCustomerOption({label: `${customer.givenName} ${customer.familyName} (${customer.phone})` , value: booking.customerID})
      setValue('customerID',booking.customerID)
      setValue('locationID',booking.locationID)
      
      setValue('teamMemberID',booking.teamMemberID)
      setValue('serviceIDs',booking.services && booking.services.map(service => service.teamMemberService))
      setValue('date',new Date(booking.date))
      setValue('note',booking.note)
      const bookingStartTimeParts = booking && booking.startTime && booking.startTime.split(':')
      const bookingStartTime = bookingStartTimeParts && setHours(setMinutes(setSeconds(new Date(), 0), bookingStartTimeParts[1]), bookingStartTimeParts[0])
      bookingStartTime && setValue('time',bookingStartTime)

    }
  },[booking && booking.id]);

  const customerID = watch('customerID');
  const locationID = watch('locationID');
  const selectedServices = watch('serviceIDs');
  const bookingDate = watch('date');
  const bookingTime = watch('time');

  const isVirtualQueue = locations.find(l => l.id == locationID)?.virtualQueue;
  const restAPI = "https://bp6j0oaukl.execute-api.eu-west-1.amazonaws.com/dev/bookings/";

  const [eta, setEta] = useState();

  useEffect(() => {

    if (isVirtualQueue && teamMemberID){

      axios({
        method: `get`,
        url: `/teamMembers/${teamMemberID}/eta`,
        baseURL: restAPI,
      }).then(response => {
        const eta = response.data.eta;
        const etaDate = new Date(`${format(new Date(),'yyyy-MM-dd')} ${eta}`);
        const totalMinutes = differenceInMinutes(etaDate, new Date())
        let hours = Math.floor(totalMinutes/60)
        let minutes = totalMinutes%60
        if (hours == 0 && minutes <= 5 || hours < 0){
          hours = 0;
          minutes = 5;
        }
        const formattedETA = `${(hours > 0 ? `${hours} hrs ` : ``)}${minutes} min`
        setEta(formattedETA)
        console.log("eta", eta);
        console.log("responsetest", response);
        
      })
    }
  },[teamMemberID, isVirtualQueue]);

  const teamMember = teamMemberID && teamMembers.find(teamMember => teamMember.id == teamMemberID)
  const teamMemberServices = teamMember && teamMember.services && teamMember.services.items && teamMember.services.items
                              .filter(teamMemberService => (selectedServices && selectedServices.includes(teamMemberService.id)))

  console.log('teamMember',teamMember)

  const isWorkingDay = date => {
    const day = getDay(date);

    const dayOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ]

    if (teamMember && teamMember.offDays.includes(dayOfWeek[day])){
      // this is a normal off Day
      
      if (teamMember.offDayExceptions && teamMember.offDayExceptions.includes(format(bookingDate,"yyyy-MM-dd"))){
        // this is an exeption we are working today
        return true;
      } else {
        return false;
      }

    }

    return true
  };

  const customerOptions = customers && customers.map(customer => ({
    value: customer.id,
    label: `${customer.givenName} ${customer.familyName} (${customer.phone})`,
    existing : customer.bookings.items.length > 0
  }))
  register({ name: 'customerID', type: 'custom'}, { required: true })

  const teamMemberOptions = teamMembers && locationID && teamMembers
                          .filter(teamMember => (
                              !teamMember._deleted && 
                              teamMember.status != "Disabled" && 
                              teamMember.locationID == locationID && 
                              (teamMember.acceptsBookings || teamMember.role == "OwnBookings")
                          ))
                          .map(teamMember => (
                            {
                              value: teamMember.id,
                              label: `${teamMember.givenName} ${teamMember.familyName ? teamMember.familyName: ""}`
                            }
                          ))
  register({ name: 'teamMemberID', type: 'custom'}, { required: true })


  const locationOptions = locations && locations.map(location => (
                            {
                              value: location.id,
                              label: `${location.name}  (${location.address && location.address.postCode})`
                            }
                          ))
  register({ name: 'locationID', type: 'custom'}, { required: true })

  const serviceOptions = teamMember && teamMember.services && teamMember.services.items && _.sortBy(teamMember.services.items 
                          .filter(teamMemberService => (teamMemberService.enabled))
                          .map(teamMemberService => {
                            const service = services.find(service => service.id == teamMemberService.serviceID)
                            return ({
                                value: teamMemberService.id,
                                label: service && service.name,
                                backgroundColor: service && service.blockColour,
                                order: service && service.order,
                                color: service && !service.isAddon ? "#252f3f" : "#5850ec"
                              })
                          }),['order','label'])
  register({ name: 'serviceIDs', type: 'custom'}, { required: true })


  register({ name: 'date', type: 'custom'}, { required: true })
  register({ name: 'time', type: 'custom'}, { required: true })


  // calculate service duration

  let maxedOut = []
  let serviceDuration = 0
  let price = 0
  teamMemberServices && teamMemberServices.map(teamMemberService => {
    const service = services.find(service => service.id == teamMemberService.serviceID)

    if (teamMemberService && teamMemberService.maxDaily){

      // console.log('bookingDate serv',teamMemberBookings[0].services,teamMemberService.serviceID)
      const alreadyExist = teamMemberBookings && teamMemberBookings
        .filter(
          item => item.status != "Cancelled" 
                  && item.status != "NoShow" 
                  && (!booking || item.id != booking.id)
                  && item.date == format(bookingDate,"yyyy-MM-dd") 
                  && (item.services.find(s => s.service == teamMemberService.serviceID)) )

      if ( alreadyExist && teamMemberService.maxDaily <= alreadyExist.length){
        maxedOut.push({teamMemberService,service});
      }
      console.log('maxedOut',maxedOut,alreadyExist,teamMemberService.maxDaily)
    }


    if (teamMemberService && teamMemberService.duration){
      if (!service.isDoneParallel){
        serviceDuration += teamMemberService.duration;
      }
      price += teamMemberService.price;
    } else {
      if (service && !service.isDoneParallel){
        serviceDuration += service.duration;
      }
      price += service?.price ?? 0;
    }
  })


  let includeTimes = []; 

  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;


  const openingHours = {}

  const openingHoursSource = teamMember && teamMember.openingHours;

  openingHoursSource && openingHoursSource.map(openingHour => {
    openingHour.day && openingHour.day.map(day => {

      const openingHourRow = {
        opensAt: openingHour.opensAt,
        closesAt: openingHour.closesAt,
      }

      if (openingHours[day]){
        openingHours[day].push(openingHourRow)
      } else {
        openingHours[day] = [openingHourRow]
      }
    })
  })


  if (bookingDate && teamMemberBookings){
    // TODO filter only bookings for selected date
    const bookingsOnDate = _.sortBy(teamMemberBookings.filter(teamMemberBooking => {
      return (
        teamMemberBooking.date == format(bookingDate,'yyyy-MM-dd') 
        && !(teamMemberBooking && booking && teamMemberBooking.id == booking.id)
      )
    }),['startTime','endTime']).filter(booking => booking.status != 'Cancelled' && booking.status != 'NoShow')


    const publicHolidaysForYear = publicHolidays(format(bookingDate,'yyyy'))
    const isPublicHoliday = publicHolidaysForYear.includes(format(bookingDate,'yyyy-MM-dd'))

    const weekday = isPublicHoliday ? "PublicHoliday" : format(bookingDate,'EEEE')

    openingHours && openingHours[weekday].map(availability => {


      // console.log('teamMemberOpeninghours',availability)

      // TODO replace with actual opening hours for day - may be multiple entries if there's a mid day close
      let openTime = setHours(setMinutes(setSeconds(bookingDate, 0), availability.opensAt.split(':')[1]), availability.opensAt.split(':')[0]); //setHours(setMinutes(bookingDate, 0), 9)
      let closingHours = setHours(setMinutes(setSeconds(bookingDate, 0), availability.closesAt.split(':')[1]), availability.closesAt.split(':')[0]); //setHours(setMinutes(bookingDate, 0), 19)
      let availabilityBreakTime = null

      const availabilityExeption = availabilityExceptions && availabilityExceptions.find(item => !item._deleted && teamMemberID == item.teamMemberID && item.date == format(bookingDate,"yyyy-MM-dd"))
      // console.log('availabilityExeptions',availabilityExeptions);

      if (availabilityExeption?.from && availabilityExeption?.to){
        openTime = setHours(setMinutes(setSeconds(bookingDate, 0), availabilityExeption.from.split(':')[1]), availabilityExeption.from.split(':')[0]);
        closingHours = setHours(setMinutes(setSeconds(bookingDate, 0), availabilityExeption.to.split(':')[1]), availabilityExeption.to.split(':')[0]);
        availabilityBreakTime = availabilityExeption.breakTime && setHours(setMinutes(setSeconds(bookingDate, 0), availabilityExeption.breakTime.split(':')[1]), availabilityExeption.breakTime.split(':')[0]);
      }


      let startTime = openTime //new Date(`${format(bookingDate,'yyyy-MM-dd')} ${format(openingHours,"HH:mm")}`);
      // console.log('includeTimes startTime',startTime)

      // console.log('get teamMember Bookings bookingDate',bookingsOnDate);

      for (var i = 0; i <= bookingsOnDate.length; i++) {
        const booking = bookingsOnDate[i]
        const bookingStartTimeParts = booking && booking.startTime.split(':')
        const bookingStartTime = booking ? setHours(setMinutes(setSeconds(bookingDate, 0), bookingStartTimeParts[1]), bookingStartTimeParts[0]) : closingHours

        const minuteDifference = differenceInMinutes(bookingStartTime,startTime);
        // console.log('minDiff',minuteDifference,startTime,bookingStartTime)

        const extraMinutes = minuteDifference + 1 - serviceDuration

        if (extraMinutes >= 0){
          // console.log('fits in here',minuteDifference,format(startTime,"HH:mm"),format(bookingStartTime,"HH:mm"))

          const timeSlots = Math.floor(extraMinutes / timeSlotInterval) + 1;
          // console.log('timeSlots',extraMinutes,timeSlotInterval,timeSlots)
          // const roundingFactor = 60 / timeSlotInterval

          for (var timeSlotNo = timeSlots - 1; timeSlotNo >= 0; timeSlotNo--) {


            const slotTime = new Date(startTime.getTime()) //),Intl.DateTimeFormat().resolvedOptions().timeZone);

            const startMinutes = Math.ceil(getMinutes(startTime) / timeSlotInterval)*timeSlotInterval

            // console.log('startMinutes',startMinutes)

            slotTime.setMinutes(
                startMinutes
                + (timeSlotInterval * timeSlotNo)
              );

            if (availabilityBreakTime && (format(availabilityBreakTime,"HH:mm") == format(slotTime,"HH:mm") || format(addMinutes(availabilityBreakTime,15),"HH:mm") == format(slotTime,"HH:mm") )){

              console.log('availabilityBreakTime',availabilityBreakTime,slotTime)
            } else {
              // console.log('timeSlotNo',format(slotTime,"HH:mm"),timeSlotNo,format(startTime,"HH:mm"))
              includeTimes.push(slotTime);
            }

          }

        } else {
          // console.log('no in here',minuteDifference,extraMinutes,booking,bookingStartTime)
        }

        if (booking){
          const bookingEndTimeParts = booking.endTime.split(':')
          const bookingEndTime = setHours(setMinutes(bookingDate, bookingEndTimeParts[1]), bookingEndTimeParts[0])
          startTime = bookingEndTime;
        }

      }
    })
  }


  // console.log('includeTimes',includeTimes)

  if (bookingTime && includeTimes && bookingDate && teamMemberBookings){
    const inAllowedValues = includeTimes.find(time => {

      // console.log('timeToFind',time,format(time,"HH:mm"),bookingTime,format(bookingTime,"HH:mm"))

      return format(time,"HH:mm") == format(bookingTime,"HH:mm")
    })

    // console.log('inAllowedValues',inAllowedValues)
    if (!inAllowedValues){
      // clear time
      // console.log('toRemoveTime',includeTimes,bookingTime)
      // addNotification(
      //   {
      //     title: <React.Fragment><strong>Not enough time</strong></React.Fragment>,
      //     message: `Please choose another time slot as there is not sufficient time for the services provided`,
      //     icon: `warning`,
      //   }
      //   ,5000)
      setValue('time',null)
    }
  }

  //
  const onSubmit = data => {
    console.log('submitBooking',data)

    if (newCustomerModal){
      // saving customer details first
      addNotification(
        {
          title: <React.Fragment><strong>Customer Details not saved</strong></React.Fragment>,
          message: 'Please save customer details',
          icon: `warning`,
        }
        ,5000)
      return;
    } else if (!data.customerID){
      addNotification(
        {
          title: <React.Fragment><strong>No Customer Selected</strong></React.Fragment>,
          message: 'Please Select a customer',
          icon: `warning`
        }
      ,5000)
      return;
    }

    /*
      id: ID!
      businessID: ID!
      customer: Customer # @connection(name: "customerBooking")
      customerID: ID
      teamMember: TeamMember # @connection(name: "TeamMemberBooking")
      teamMemberID: ID
      services: [TeamMemberServiceBooking]
      date: AWSDate
      startTime: AWSTime
      endTime: AWSTime
      dateCancelled: AWSDateTime #if this is set it cancelled ahead of time
      status: BookingStatus # customer did not turn up
      fee: Float
      createdAt: AWSDateTime
      updatedAt: AWSDateTime*/

    // const bookingEndTimeParts = data.time && data.time.split(':')
    // const endTime = new Date(data.time.getTime());
    const endTime = setMinutes(data.time,getMinutes(data.time) + serviceDuration);

    const formattedServices = selectedServices.map(teamMemberServiceId => ({
      teamMemberService: teamMemberServiceId,
      service: teamMemberServices.find(item => item.id == teamMemberServiceId).serviceID
    }))
    //****/
    if(isVirtualQueue){

      console.log("adding to queue")
      console.log(data.date, "test")

      const body = {

        //id: booking && booking.id || uuidv4(),
        _version: booking && booking._version,
        customerID: data.customerID,
        teamMemberID: data.teamMemberID,
        locationID: data.locationID,
        note: data.note,
        services: formattedServices.map(({service}) => service),
        date: format(new Date(), 'yyyy-MM-dd'),

        time: format(data.time, "HH:mm"),
        fee: price,
        status: "Queueing",
        source: "Receptionist"
      }

      axios({
        method: `post`,
        url: `/queue`,
        params:{
          businessID
        },
        data: body,
        baseURL: restAPI,
      }).then(response => {

      })

      // const response = axios({
      //   method: `post`,
      //   url: `queue`,
      //   params:{
      //     businessID
      //   },
      //   data: body,
      //   baseURL: restAPI,
      // })

    }else{
      //***** */
      const item = {
        id: booking && booking.id || uuidv4(),
        _version: booking && booking._version,
        customerID: data.customerID,
        locationID: data.locationID,
        teamMemberID: data.teamMemberID,
        note: data.note,
        date: format(data.date, "yyyy-MM-dd"),
        startTime: format(data.time, "HH:mm"),
        endTime: format(endTime, "HH:mm"),
        fee: price,
        services: formattedServices,
        source: "Receptionist",

        status: data.status || "Booked",
      }
      console.log('item toSubmitData', item)

      if (booking && booking.id){
        updateBooking(item)
      } else {
        delete(item._version);
        item.isNew = !customerOption.existing
        createBooking(cleanDeep(item))
      }
    }
  }

/*
type TeamMemberServiceBooking @aws_iam @aws_cognito_user_pools{
  teamMemberService: String
  service: String
}

type Booking @model
  @auth(rules: [
    { allow: groups, groups: ["Admin"], operations: [create, update, read, delete]},
    { allow: private, operations: [create, update, read, delete] }
  ])  
  @key(name: "byCustomerByDate", fields: ["customerID", "date"])
{
  id: ID!
  businessID: ID!
  customer: Customer # @connection(name: "customerBooking")
  customerID: ID
  teamMember: TeamMember # @connection(name: "TeamMemberBooking")
  teamMemberID: ID
  services: [TeamMemberServiceBooking]
  date: AWSDate
  startTime: AWSTime
  endTime: AWSTime
  dateCancelled: AWSDateTime #if this is set it cancelled ahead of time
  status: BookingStatus # customer did not turn up
  fee: Float
  createdAt: AWSDateTime
  updatedAt: AWSDateTime
}*/

{/*className={"mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"}*/}  
  
const defaultColor = "#111"

const selectDefaultStyle = {
  control: styles => ({ ...styles, backgroundColor: 'white', border: 'none' })
};


const dot = (color = '#ccc') => ({
  alignItems: 'center',
  display: 'flex',

  ':before': {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: 'block',
    marginRight: 8,
    height: 10,
    width: 10,
  },
});

const colourStyles = {
  control: styles => ({ ...styles, backgroundColor: 'white', border: 'none' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = chroma(data.color || defaultColor);
    return {
      ...styles,
      ...dot(data.backgroundColor),
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? data.color || defaultColor
        : isFocused
        ? color.alpha(0.1).css()
        : null,
      color: isDisabled
        ? '#ccc'
        : isSelected
        ? chroma.contrast(color, 'white') > 2
          ? 'white'
          : 'black'
        : data.color || defaultColor,
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled && (isSelected ? data.color || defaultColor : color.alpha(0.3).css()),
      },
    };
  },
  input: styles => ({ ...styles, ...dot() }),
  placeholder: styles => ({ ...styles, ...dot() }),
  singleValue: (styles, { data }) => ({ ...styles, ...dot(data.backgroundColor) }),
  multiValue: (styles, { data }) => {
    const color = chroma(data.color || defaultColor);
    return {
      ...styles,
      backgroundColor: color.alpha(0.1).css(),
      ...dot(data.backgroundColor),
      ':before': {
        backgroundColor: data.backgroundColor,
        borderRadius: 10,
        content: '" "',
        display: 'block',
        marginRight: 8,
        height: 10,
        width: 10,
      },
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: data.color || defaultColor,
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color || defaultColor,
    ':hover': {
      backgroundColor: data.color || defaultColor,
      color: 'white',
    },
  }),
};


  return (
    
      <div className="" style={{
          "display": "block",
      }}>
          <div className="mt-5 md:mt-0 md:col-span-2">


            { newCustomerModal &&
              <div className="col-span-6">
                <CustomerForm 
                  customerSearch={customerSearch}
                  callback={(customer) => {
                    
                    if (!customer){
                      setNewCustomerModal(false)
                      
                    } else {
                      setTimeout(
                        () => {
                          setValue('customerID',customer.id)
                          setCustomerOption({label: `${customer.givenName} ${customer.familyName} (${customer.phone})` , value: customer.id})
                          
                        },
                        500
                      )
                    }
                    
                  }}
                />
              </div>
            }

            <form onSubmit={handleSubmit(onSubmit)}>
            <div className="shadow sm:rounded-md sm:overflow-visible" style={{
                maxHeight: "90vh"
              }}>
              <div className="px-4 py-5 bg-white sm:p-6">
                <div className="grid grid-cols-6 gap-6">

                  { !newCustomerModal &&
                    <React.Fragment>
                      <div className="col-span-6 sm:col-span-6">
                        <label htmlFor="givenName" className="block text-sm font-medium leading-5 text-gray-700">Customer</label>
                        {/*<input id="givenName" name="givenName" className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />*/}
                        <AsyncCreatableSelect
                          className="mt-1 block form-select w-full p-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                          styles={selectDefaultStyle}
                          value={customerOption}
                          onChange={(selected) => {
                              setValue('customerID',selected.value)
                              setCustomerOption(selected)
                            }
                          }
                          onCreateOption={(inputValue) => {
                            setCustomerSearch(inputValue)
                            setNewCustomerModal(true)
                          }}
                          defaultOptions={[]}
                          loadOptions={(inputValue, callback) => {

                            const beginsWith = inputValue.substr(0,1) == "+" ? 
                                                      inputValue :
                                                      inputValue.substr(0,2) == "00" ? 
                                                        `+${inputValue.substr(2)}` :
                                                        `+356${inputValue}`

                            const variables = {
                              phone: {beginsWith}, 
                              businessID
                            }
                            const request = API.graphql({query: customerByBusinessByPhone, variables, authMode: "AMAZON_COGNITO_USER_POOLS"})

                            request.then( ({data}) => {

                                const customers = data.customerByBusinessByPhone.items

                                const options = customers && customers.map(customer => ({
                                  value: customer.id,
                                  label: `${customer.givenName} ${customer.familyName} (${customer.phone}) `,
                                  existing : customer.bookings.items.length > 0
                                }))

                                callback(options);
                            });
                          }}
                          onInputChange = {(inputValue, { action }) => {
                            switch (action) {
                              case 'input-change':
                                if (inputValue && inputValue.length > 1){
                                  setCustomerSearch(inputValue)
                                }
                                return;
                              default:
                                return;
                            }
                          }}
                        />
                      </div>
                    </React.Fragment>
                  }

                  <div className="col-span-3">
                    <label htmlFor="locationID" className="block text-sm font-medium leading-5 text-gray-700">Location</label>
                    <Select
                      className="mt-1 block form-select w-full p-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      styles={selectDefaultStyle}
                      value={locationOptions && locationOptions.find(item => item.value == locationID)}
                      onChange={(selected) => {
                          setValue('locationID',selected.value)
                        }
                      }
                      options={locationOptions}
                    />
                    {/*<select id="locationID" name="locationID" ref={register} className="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
                      <option/>
                      {
                        locations && locations.map(location => (
                          <option value={location.id}>{location.name} ({location.address && location.address.postCode})</option>
                        ))
                      }
                    </select>*/}
                  </div>

                  <div className="col-span-3">
                    <label htmlFor="teamMemberID" className="block text-sm font-medium leading-5 text-gray-700">Serviced By</label>
                    <Select
                      className="mt-1 block form-select w-full p-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      styles={selectDefaultStyle}
                      value={teamMemberOptions && teamMemberOptions.find(item => item.value == teamMemberID)}
                      onChange={(selected) => {
                          setValue('teamMemberID',selected.value)
                        }
                      }
                      options={teamMemberOptions}
                    />

                    {/*<select id="teamMemberID" name="teamMemberID" ref={register} className="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
                      <option/>
                      {
                        teamMembers && locationID && teamMembers
                          .filter(teamMember => (teamMember.locationID == locationID))
                          .map(teamMember => (
                            <option value={teamMember.id}>{teamMember.givenName} {teamMember.familyName}</option>
                          ))
                      }
                    </select>*/}
                  </div>

                  <div className="col-span-6">
                    <label htmlFor="serviceIDs" className="block text-sm font-medium leading-5 text-gray-700">Service(s)</label>

                    <Select
                      className="mt-1 block form-select w-full p-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      styles={colourStyles}
                      value={serviceOptions && serviceOptions.filter(item => selectedServices && selectedServices.includes(item.value))}
                      onChange={(selected) => {
                          if (Array.isArray(selected)){
                            setValue('serviceIDs',selected.map(item => item.value))
                          } else {

                            setValue('serviceIDs',[])
                          }
                      }}
                      options={serviceOptions}
                      isMulti={true}
                    />
                    { maxedOut && maxedOut.length > 0 && `The following Services have reached their daily limit ${maxedOut.map(s => s.service.name).join(',')}`}
                  </div>

                  { !isVirtualQueue &&
                  <>
                    <div className="col-span-6 sm:col-span-3">
                      <label htmlFor="familyName" className="block text-sm font-medium leading-5 text-gray-700">Date</label>
                      <DatePicker
                            dateFormat="dd/MM/yyyy"
                            selected={bookingDate}
                            onChange={(selectedDate) => {
                              setValue('date',selectedDate)
                            }}
                            minDate={new Date("2020-04-01")}
                            filterDate={isWorkingDay}
                            className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                            popperPlacement="bottom-end"
                            popperModifiers={{
                              offset: {
                                enabled: true,
                                //offset: "77px, 0px"
                              }
                            }}
                      />
                      {/*<input id="familyName" name="familyName" className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />*/}
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label htmlFor="email" className="block text-sm font-medium leading-5 text-gray-700">Time</label>

                        {/*excludeTimes={[
                          setHours(setMinutes(new Date(), 0), 17),
                          setHours(setMinutes(new Date(), 30), 18),
                          setHours(setMinutes(new Date(), 30), 19),
                          setHours(setMinutes(new Date(), 30), 17)
                        ]}*/}

  {/*                      minTime={setHours(setMinutes(new Date(), 0), 9)}
                        maxTime={setHours(setMinutes(new Date(), 0), 19)}
  */}
                      <DatePicker
                        selected={bookingTime}
                        onChange={(selectedTime) => {
                          setValue('time',selectedTime)
                        }}
                        className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"


                        includeTimes={includeTimes}

                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={timeSlotInterval}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        />
                        {/*customInput={<TimeInputFormat />*/}
                      {/*<input id="email" name="email" className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />*/}
                    </div>
                  </>
                  }

                  { !isVirtualQueue && bookingDate && bookingTime &&
                    <div className="col-span-6">
                      By saving you will create an appointment for {format(bookingDate,"d/MM/yyyy")} at {format(bookingTime,"h:mm aa")} for the duration of <strong>{serviceDuration} minutes</strong> at a price of <strong>€{price}</strong>
                    </div>
                  }

                  { isVirtualQueue && bookingDate && bookingTime && eta &&
                    <div className="col-span-6">
                      You will queue in {eta} for the duration of <strong>{serviceDuration} minutes</strong> at a price of <strong>€{price}</strong>
                    </div>
                  }

                </div>

                <div className="col-span-6">
                  <label htmlFor="note" className="block text-sm font-medium leading-5 text-gray-700">Note</label>
                  <input id="note" defaultValue={booking && booking.note} ref={register} name="note" className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />

                </div>
              </div>
              <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                <span className="inline-flex rounded-md shadow-sm">
                  
                  { !isVirtualQueue &&
                  <button 
                  
                    type="submit" 
                    className="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
                    style={maxedOut.length > 0 ? { opacity: .3, cursor: "not-allowed"} : {}}
                    disabled={maxedOut.length > 0}

                  >
                  Save
                  </button>}
                  { isVirtualQueue && format(bookingDate,"yyyy-MM-dd") == format(new Date(),"yyyy-MM-dd") &&
                  <button 
                  
                    type="submit" 
                    className="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
                    style={maxedOut.length > 0 ? { opacity: .3, cursor: "not-allowed"} : {}}
                    disabled={maxedOut.length > 0}

                  >
                  Save
                  </button>}
                  { isVirtualQueue && format(bookingDate,"yyyy-MM-dd") != format(new Date(),"yyyy-MM-dd") &&
                    <div className="col-span-6">
                      You can only queue for today.
                    </div>
                  }
                </span>
              </div>
            </div>
            </form>
          </div>

      </div>
    )
}

const mapStateToProps = (state) => {
    return {
      businessID: state.businessID,
      booking: state.booking.item,
      bookings: state.booking.list,
      customers: state.customer.list,
      newCustomerModal: state.customer.newModal,
      services: state.service.list,
      teamMembers: state.teamMember.list,
      location: state.scheduler.location,
      locations: state.location.list,
      teamMemberBookings: state.booking.byTeamMember,
      availabilityExceptions: state.booking.availabilityExceptions,
  }
}

const mapDispatchToProps = dispatch => ({
  getBooking: (id) => dispatch(ACTIONS.getItem(id)),
  clearBooking: () => dispatch(ACTIONS.clearItem()),
  setNewCustomerModal: (show) => dispatch(CUSTOMERACTIONS.newCustomerModal(show)),
  addNotification: (message,hideAfter) => dispatch(NOTIFICATIONACTIONS.addNotification(message,hideAfter)),
  createBooking: (item) => dispatch(ACTIONS.createItem(item)),
  updateBooking: (item) => dispatch(ACTIONS.updateItem(item)),
  getBookingsByTeamMember: (variables) => dispatch(ACTIONS.getBookingsByTeamMember(variables)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BookingForm)

import {Types} from "./action";
import _ from "lodash";

// import {NotificationManager} from 'react-notifications';

// import cleanDeep from 'clean-deep';


const defaultState = {
  business: {}
};

// BUSINESS_GET_ITEM
// BUSINESS_UPDATE_ITEM
// BUSINESS_CREATE_ITEM

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case Types.CHANGELOG_GET_LIST: {

      let newState = {...state};

      newState.changelog.items = action.payload.data.changelogByEntityByDate.items;

      return newState;
    }
    case Types.CHANGELOG_TOGGLE_MODAL: {

      let newState = {...state};

      newState.changelog.modal = action.status;

      return newState;
    }
    default:
      return state;
  }
};

export default reducer;
import {Types} from "./action";
import _ from "lodash";
// import {NotificationManager} from 'react-notifications';

// import cleanDeep from 'clean-deep';


const defaultState = {
  product: {}
};

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case Types.PRODUCT_GET_ITEM: {

      let newState = {...state};

      newState.product.item = action.payload.data.getProduct;

      return newState;
    }
    case Types.PRODUCT_CLEAR_ITEM: {

      let newState = {...state};

      newState.product.item = {};

      return newState;
    }
    case Types.PRODUCT_UPDATE_PHOTO: {

      let newState = {...state};

      newState.product.item = {...newState.product.item};
      newState.product.item.photo = action.photo;

      if (newState.product.list){
        const itemIndex = newState.product.list.findIndex(item => item.id == newState.product.item.id)
        newState.product.list[itemIndex] = newState.product.item;
        newState.product.list = _.sortBy(newState.product.list,['order','name'])
      }

      console.log('newproductUpdated',newState.product.item)

      return newState;
    }
    case Types.PRODUCT_GET_LIST: {

      let newState = {...state};

      const existingList = newState.product.list || []

      const newItems = action.payload.data.listProducts.items.filter(item => item._deleted != true)

      const merged = _(existingList) // start sequence
        .keyBy('id') // create a dictionary of the 1st array
        .merge(_.keyBy(newItems, 'id')) // create a dictionary of the 2nd array, and merge it to the 1st
        .values() // turn the combined dictionary to array
        .value(); // get the value (array) out of the sequence


      // TODO add sorting facility
      newState.product.list = _.sortBy(merged,['order','name']) 


      newState.product.listNextToken = action.payload.data.listProducts.nextToken;
      newState.product.listStartedAt = action.payload.data.listProducts.startedAt;
      console.log('updatedProductList',newState.product.list)

      return newState;
    }
    case Types.PRODUCT_UPDATE_ITEM: {

      let newState = {...state};

      const updatedItem = action.payload.data.updateProduct;
      newState.product.item = updatedItem;

      if (newState.product.list){
        const itemIndex = newState.product.list.findIndex(item => item.id == updatedItem.id)
        newState.product.list[itemIndex] = updatedItem;
        newState.product.list = _.sortBy(newState.product.list,['order','name'])
      }

      console.log('newProductUpdated',newState.product.item)

      return newState;
    }
    case Types.PRODUCT_DELETE_ITEM: {

      let newState = {...state};

      const deletedItem = action.payload;
      // newState.product.item = deletedItem;

      if (newState.product.list){
        newState.product.list = _.reject(newState.product.list,{id: deletedItem})
      }

      console.log('productDeleted')

      return newState;
    }
    case Types.PRODUCT_CREATE_ITEM: {

      let newState = {...state};

      const createdItem = action.payload.data.createProduct;
      newState.product.item = createdItem;
      if (newState.product.list){
        newState.product.list.push(createdItem);
        newState.product.list = _.sortBy(newState.product.list,['order','name'])
      }

      console.log('newProductUpdated',newState.product.item)

      return newState;
    }
    default:
      return state;
  }
};

export default reducer;
import React from "react";
import _ from "lodash";
import { v4 as uuidv4 } from 'uuid';
import {
          locationScheduleExceptionByLocationByDate,
       } from 'graphql/queries'

import {
  createPhoto,
  createAddress,
  updateAddress,
  createLocation,
  updateLocation,
  deletePhoto,
} from 'graphql/mutations';
       
import { format } from 'date-fns';
import {API} from '@aws-amplify/api'
import {Storage} from '@aws-amplify/storage'
import awsMobile from 'aws-exports';
import AWS from 'aws-sdk';
import {Actions as NOTIFICATIONACTIONS} from 'store/notifications/action';


const getLocation = /* GraphQL */ `
  query GetLocation($id: ID!) {
    getLocation(id: $id) {
      id
      businessID
      name
      addressID
      phone
      openingHours {
        day
        opensAt
        closesAt
      }
      opensOnPublicHolidays
      acceptsBookings
      virtualQueue
      online
      isMobile
      mobileWeekOffset
      bookingFlow
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      address {
        id
        addressLine1
        addressLine2
        addressLine3
        addressLine4
        city
        region
        postCode
        country
        attentionTo
        lat
        lng
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      team {
        items {
          id
          businessID
          username
          givenName
          familyName
          phone
          email
          acceptsBookings
          locationID
          offDays
          offDayExceptions
          seatNo
          status
          role
          startDate
          endDate
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      photos {
        items {
          id
          businessID
          description
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          photo {
            bucket
            key
          }
        }
        nextToken
        startedAt
      }
    }
  }
`;

const listLocations = /* GraphQL */ `
  query ListLocations(
    $filter: ModelLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLocations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        businessID
        name
        addressID
        phone
        openingHours {
          day
          opensAt
          closesAt
        }
        opensOnPublicHolidays
        acceptsBookings
        virtualQueue
        online
        isMobile
        mobileWeekOffset
        bookingFlow
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        address {
          id
          addressLine1
          addressLine2
          addressLine3
          addressLine4
          city
          region
          postCode
          country
          attentionTo
          lat
          lng
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        team {
          nextToken
          startedAt
        }
        photos {
          items {
            id
            businessID
            description
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;

// types of action
const Types = {
  LOCATION_GET_ITEM: "LOCATION_GET_ITEM",
  LOCATION_UPDATE_ITEM: "LOCATION_UPDATE_ITEM",
  LOCATION_CREATE_ITEM: "LOCATION_CREATE_ITEM",
  LOCATION_CLEAR_ITEM: "LOCATION_CLEAR_ITEM",
  LOCATION_LOADING: "LOCATION_LOADING",
  LOCATION_GET_LIST: "LOCATION_GET_LIST",
  LOCATION_UPLOAD_IMAGE: "LOCATION_UPLOAD_IMAGE",
  LOCATION_DELETE_IMAGE: "LOCATION_DELETE_IMAGE",
  LOCATION_GET_SCHEDULE_EXCEPTION: "LOCATION_GET_SCHEDULE_EXCEPTION",
};

const dispatchLoading =  loading => ({
  type: Types.LOCATION_LOADING,
  loading
});

const clearItem = response => ({
  type: Types.LOCATION_CLEAR_ITEM
});

const dispatchGetItem = response => ({
  type: Types.LOCATION_GET_ITEM,
  payload: response
});

const getItem = (id) => {
  console.log('getItem1',getItem)
  return (dispatch, getState) => {
    dispatch(dispatchLoading(true))

    const variables = {id}
    const request = API.graphql({query: getLocation, variables, authMode: "AMAZON_COGNITO_USER_POOLS"})
    console.log('getItem2',getItem)
    request.then((response) => {
        dispatch(dispatchGetItem(response))
        dispatch(dispatchLoading(false))
    });
    
  }
}

const dispatchUpdateItem = response => ({
  type: Types.LOCATION_UPDATE_ITEM,
  payload: response
});

const updateLocationAddress = async (address) => {
  // return (dispatch, getState) => {

    console.log('addressID',address.id, address)

    delete address._lastChangedAt;

    const variables = {input: address}
    const request = API.graphql({query: updateAddress, variables, authMode: "AMAZON_COGNITO_USER_POOLS"})

    // ensure that if new address it has the business id
    // if (variables.input.address && !variables.input.address.id){
    //   variables.input.address.businessID = businessID;
    // }

    const response = await request/*.then((response) => {
        // dispatch(dispatchUpdateAddress(response))

        //TODO Plan notification logic/errors
        dispatch(NOTIFICATIONACTIONS.addNotification({
          title: <React.Fragment>Location Address has been updated</React.Fragment>,
          // message: response.data.updateLocation.active ? `Customers can book this location` : `This location is disabled. It cannot be booked`,
          icon: `success`,
        },
          3000
        ))
    });*/

    return response.data.updateAddress.id
    
  // }
}


const createLocationAddress = async (address) => {
  // return (dispatch, getState) => {

    console.log('addressID',address.id, address)

    delete address._lastChangedAt;

    const variables = {input: address}
    const request = API.graphql({query: createAddress, variables, authMode: "AMAZON_COGNITO_USER_POOLS"})

    // ensure that if new address it has the business id
    // if (variables.input.address && !variables.input.address.id){
    //   variables.input.address.businessID = businessID;
    // }

    const response = await request/*.then((response) => {
        // dispatch(dispatchUpdateAddress(response))

        //TODO Plan notification logic/errors
        dispatch(NOTIFICATIONACTIONS.addNotification({
          title: <React.Fragment>Location Address has been updated</React.Fragment>,
          // message: response.data.updateLocation.active ? `Customers can book this location` : `This location is disabled. It cannot be booked`,
          icon: `success`,
        },
          3000
        ))
    });*/

    return response.data.createAddress.id
    
  // }
}


const updateItem = (item) => {
  return async (dispatch, getState) => {

    const state = getState()
    const businessID = state.businessID

    const address = item.address

    // console.log('item.addressID',item.addressID,item.address.id)

    if (address){
      if (item.addressID && item.address && item.address.id == item.addressID){
        updateLocationAddress({...address, businessID})
      } else {
        const addressID = uuidv4()
        address.id = addressID;
        item.addressID = addressID;

        delete address._version

        createLocationAddress({...address, businessID})
      }
    }
 

    // item.addressID = addressID;

    delete item.address;

    const variables = {input: item}
    const request = API.graphql({query: updateLocation, variables, authMode: "AMAZON_COGNITO_USER_POOLS"})

    // ensure that if new address it has the s idbusines
    
    // if (variables.input.address && !variables.input.address.id){
    //   variables.input.address.businessID = businessID;
    // }

    request.then((response) => {
        dispatch(dispatchUpdateItem(response))

        //TODO Plan notification logic/errors
        dispatch(NOTIFICATIONACTIONS.addNotification({
          title: <React.Fragment><strong>{response.data.updateLocation.name}</strong> has been updated</React.Fragment>,
          // message: response.data.updateLocation.active ? `Customers can book this location` : `This location is disabled. It cannot be booked`,
          icon: `success`,
        },
          3000
        ))
    });
    
  }
}

const dispatchCreateItem = response => ({
  type: Types.LOCATION_CREATE_ITEM,
  payload: response
});

const createItem = (item) => {
  return (dispatch, getState) => {

    console.log('businessID',getState('businessID'))
    const address = item.address

    const addressID = uuidv4()
    address.id = addressID;
    item.addressID = addressID;

    createLocationAddress(address)

    // item.addressID = addressID;

    delete item.address;


    item.addressID = addressID;

    
    const state = getState()

    const businessID = state.businessID
    const variables = {input: {...item, businessID}}

    // the address also needs the business id
    // if (variables.input.address){
    //   variables.input.address.businessID = businessID;
    // }

    const request = API.graphql({query: createLocation, variables, authMode: "AMAZON_COGNITO_USER_POOLS"})

    request.then((response) => {
        dispatch(dispatchCreateItem(response))

        //TODO Plan notification logic/errors
        dispatch(NOTIFICATIONACTIONS.addNotification({
          title: <React.Fragment><strong>{response.data.createLocation.name}</strong> has been created</React.Fragment>,
          // message: response.data.updateLocation.status == "Active" ? `This location is raring and ready to go.` : `This location is disabled and cannot be booked.`,
          icon: `success`,
        },3000))
    });
    
  }
}

const updateList = response => ({
  type: Types.LOCATION_GET_LIST,
  payload: response
});

const getList = (limit, nextToken) => {
  return (dispatch, getState) => {

    const variables = {limit, nextToken}
    const request = API.graphql({query: listLocations, variables, authMode: "AMAZON_COGNITO_USER_POOLS"})

    request.then((response) => {
        dispatch(updateList(response))
    });
    
  }
}


const handleDeletePhoto = (id) => {
  return (dispatch, getState) => {

    const state = getState()

    // get item for version
    const item = _.find(state.location.item.photos.items,{id})

    const variables = {input: { id, _version: item._version } }
    const request = API.graphql({query: deletePhoto, variables, authMode: "AMAZON_COGNITO_USER_POOLS"})

    request.then((response) => {
        // dispatch(dispatchCreateItem(response))
        return dispatch({
          type: Types.LOCATION_DELETE_IMAGE,
          payload: response
        })

        //TODO Plan notification logic/errors
        dispatch(NOTIFICATIONACTIONS.addNotification({
          title: <React.Fragment><strong>Image</strong> has been deleted</React.Fragment>,
          // message: response.data.updateLocation.status == "Active" ? `This location is raring and ready to go.` : `This location is disabled and cannot be booked.`,
          icon: `success`,
        },3000))
    });

  }
}

const handleUpload = (file, preFileName) => {
  return (dispatch, getState) => {

    const state = getState()

    const businessID = state.businessID

    console.log(file,'show me the file!')

    const {
      aws_user_files_s3_bucket_region: region,
      aws_user_files_s3_bucket: bucket
    } = awsMobile


    console.log(bucket,'this is the bucket!')

    const { name: fileName, type: mimeType } = file;

    const filePath = `location/${state.location.item.id}/${fileName}`  

    const key = preFileName ? preFileName : filePath

    const fileForUpload = {
      bucket,
      key,
      region,
      type: mimeType
    };

    dispatch(NOTIFICATIONACTIONS.addNotification({
      title: <React.Fragment><strong>Image</strong> is currently uploading. Please wait...</React.Fragment>,
      // message: response.data.updateLocation.status == "Active" ? `This location is raring and ready to go.` : `This location is disabled and cannot be booked.`,
      icon: `success`,
    },3000))


    const request = Storage.put(key, file, {
      level: 'public',
      contentType: mimeType,
      progressCallback: (e) => {
          console.log(e,'this is a callback! from storage')
      }
    });


    request.then(() => {

      // image saved add into GQL
      
      const variables = {
        input : {
          // id: uuidv4()
          businessID,
          // description: String
          photo: {
            bucket,
            key,
          },
          photoLocationId: state.location.item.id
        }
      }

        const request = API.graphql({query: createPhoto, variables, authMode: "AMAZON_COGNITO_USER_POOLS"})

        request.then((response) => {
            // dispatch(dispatchCreateItem(response))
            return dispatch({
              type: Types.LOCATION_UPLOAD_IMAGE,
              payload: response
            })

            //TODO Plan notification logic/errors
            dispatch(NOTIFICATIONACTIONS.addNotification({
              title: <React.Fragment><strong>Image</strong> has been uploaded</React.Fragment>,
              // message: response.data.updateLocation.status == "Active" ? `This location is raring and ready to go.` : `This location is disabled and cannot be booked.`,
              icon: `success`,
            },3000))
        });

    });
  }
}



const updateLocationScheduleExceptionList = response => ({
  type: Types.LOCATION_GET_SCHEDULE_EXCEPTION,
  payload: response
});

const getLocationScheduleExceptions = (id) => {
  return (dispatch, getState) => {

    const state = getState();

    const locationID = id || state.location.item.id

    const variables = {
      locationID,
      sortDirection: "ASC",
      limit: 100,
      date: {
        ge: format(new Date(),"yyyy-MM-01")
      }
      // nextToken
    }
    const request = API.graphql({query: locationScheduleExceptionByLocationByDate, variables, authMode: "AMAZON_COGNITO_USER_POOLS"})

    request.then((response) => {
        dispatch(updateLocationScheduleExceptionList(response))
    });
    
  }
}

const Actions = {
  getItem,
  createItem,
  updateItem,
  getList,
  clearItem,
  handleUpload,
  handleDeletePhoto,
  getLocationScheduleExceptions
}

// exports = {
//   Types,
//   Actions
// }

export {Types, Actions}

// exports.Types = Types
// exports.Actions = Actions
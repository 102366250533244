import React, { useState, useEffect } from "react";
import { useForm } from 'react-hook-form'
import { useParams, useHistory } from "react-router";

import _ from 'lodash';
import { connect } from 'react-redux';
import { Actions as ACTIONS } from 'store/teamMember/action';
import { Actions as LOCATIONACTIONS } from 'store/location/action';
import { v4 as uuidv4 } from 'uuid';
import { publicHolidays } from '../../lib/publicHolidays';
import { format, parse, setYear, addYears, addDays, setDate, setMonth } from 'date-fns'

const CalendarDay = ({
	teamMember,
	unavailabilities,
	availabilityExceptions,
	unavailabilitiesByDate,
	availabilityExceptionsByDate,

	createUnavailability,
	updateUnavailability,
	deleteUnavailability,
	createAvailabilityException,
	deleteAvailabilityException,
	openModal,
	isLeave,
	leave,
	leaveColor,
	...props
}) => {

	const [display, setDisplay] = useState('none')

	const offDays = teamMember.offDays;

	const calDate = props.date.moment
	const publicHolidayList = publicHolidays(parseInt(calDate.format('YYYY')))

	const dayOfWeek = calDate.format('dddd')
	const dateString = calDate.format('YYYY-MM-DD')

	const isOffDay = offDays && offDays.includes(dayOfWeek);
	const isException = availabilityExceptionsByDate?.find((e) => e.date == dateString);
	//const isLeave = unavailabilitiesByDate && unavailabilitiesByDate.includes(dateString);
	const isPublicHoliday = publicHolidayList.includes(dateString);

	const onClick = (e) => {
		e.stopPropagation()
		if (!props.isInThePast) {

			if (isException?.isCustom) {
				openModal(true, dateString)
				return;

			} else if (isPublicHoliday || isOffDay) {

				if (isException) {
					// cancel exception
					const exception = _.find(availabilityExceptions, { date: dateString })
					deleteAvailabilityException(exception.id);
				} else {
					createAvailabilityException(dateString)
				}
				return;

			}

			if (isLeave) {
				// cancel exception
				// const leave = _.find(unavailabilities,{date: dateString})

				if (leave.reason == 'Off') {
					updateUnavailability({
						id: leave.id,
						reason: 'Leave',
						_version: leave._version
					})
				} else if (leave.reason == 'Leave') {
					updateUnavailability({
						id: leave.id,
						reason: 'Sick',
						_version: leave._version
					})
				} else {
					deleteUnavailability(leave.id);
				}
			} else {
				createUnavailability(dateString)
			}
		}
	}


	const getStyle = function ({ date, isSelected }) {
		return `${isSelected ? 'o_selected-day' : ''} ${date.type}-day`

	}

	const getInline = ({ isToday, isInThePast }) => ({

	  cursor: isInThePast && !isToday ? 'not-allowed' : 'inherit',
		background:
			isException?.isCustom ? "#44A"
				: isException ? "yellow"
					: isOffDay ? "#000"
						: isPublicHoliday ? "orange"
							: isLeave ? leaveColor
	  : isToday ? '#2A52BE' //dark blue
									: isInThePast ? '#e4e4e4' : 'inherit',
		color: isInThePast ? '#555555' : 'inherit',
		position: 'relative'
	})

	return (
		<div onClick={(e) =>{

			!props.isInThePast && onClick(e)
		}}
			className={getStyle(props)}
			style={getInline(props)}
			//Calendar edit button
			onMouseEnter={e => {
				setDisplay('block');
			}}
			onMouseLeave={e => {
				setDisplay('none')
			}}>
			{props.label}
			<div
				style={{
					display,
					position: 'absolute',
					bottom: 0,
					right: 0
				}}
				onClick={(e) => {
					e.preventDefault()
					e.stopPropagation()
					if (props.isInThePast) {
						return;
					} else {
						openModal(true, dateString)
					}



				}}
			>
				{/*<span className='text-xs text-right' style={{fontSize:'.5rem'}}>8:00-12:00</span>*/}
				{!props.isInThePast &&
					<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
						<path strokeLinecap="round" strokeLinejoin="round" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
					</svg>
				}
			</div>
		</div>)
}

const mapStateToProps = (state, ownProps) => {

	const calDate = ownProps.date.moment

	const dateString = calDate.format('YYYY-MM-DD')

	const unavailabilities = state.teamMember.unavailabilities && state.teamMember.unavailabilities
	const unavailabilitiesByDate = unavailabilities?.map?.(({ date }) => date)
	const isLeave = unavailabilitiesByDate && unavailabilitiesByDate.includes(dateString);

	const leave = isLeave && _.find(unavailabilities, { date: dateString })

	return {
		teamMember: state.teamMember.item,
		unavailabilities,
		availabilityExceptions: state.teamMember.availabilityExceptions && state.teamMember.availabilityExceptions,
		unavailabilitiesByDate,
		isLeave,
		leave,
		leaveColor: leave?.reason == "Leave" ? "#add8e6" //light blue
		 : leave?.reason == "Off" ? "#000" //black
		 : leave?.reason == "Sick" ? "green"
		 : "#fff",//white
		availabilityExceptionsByDate: state?.teamMember?.availabilityExceptions?.map((exception) => {
			return {
				date: exception.date,
				isCustom: (exception.from && exception.to) && true
			}
		}),
		leaveAvailable: state.business.item.vacationLeaveDays,
		locations: state.location.list,
	}
}

const mapDispatchToProps = dispatch => ({
	createUnavailability: (date) => dispatch(ACTIONS.createUnavailability(date)),
	deleteUnavailability: (id) => dispatch(ACTIONS.deleteUnavailability(id)),
	updateUnavailability: (item) => dispatch(ACTIONS.updateUnavailability(item)),
	createAvailabilityException: (date) => dispatch(ACTIONS.createAvailabilityException(date)),
	deleteAvailabilityException: (id) => dispatch(ACTIONS.deleteAvailabilityException(id)),
	openModal: (modalStatus, dateString) => dispatch(ACTIONS.createCustomAvailability(modalStatus, dateString)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CalendarDay) 
import React, { useState, useEffect } from "react";
import { useForm } from 'react-hook-form'
import { useParams, useHistory} from "react-router";

import _ from 'lodash';
import {connect} from 'react-redux';
import {Actions as ACTIONS} from 'store/mobileLocation/action';
import {Actions as LOCATIONACTIONS} from 'store/location/action';
import { v4 as uuidv4 } from 'uuid';
import { publicHolidays } from '../../lib/publicHolidays';
import { format, parse, setYear, addYears, addDays, setDate, setMonth } from 'date-fns'

const CalendarDay = ({
	// teamMember,
	// unavailabilities,
	// availabilityExceptions,
	// unavailabilitiesByDate,
	// availabilityExceptionsByDate,

	createLocationScheduleException,
	deleteLocationScheduleException,
	locationScheduleExceptions,
	selectedMobileLocation,
	defaultMobileLocation,
	colour,
	dateString,
	 ...props
	}) => {

	// const offDays = teamMember.offDays;

	// const calDate = props.date.moment

	// const publicHolidayList = publicHolidays(calDate.format('YYYY'))
	// console.log('publicHolidayList',publicHolidayList)

	// const dayOfWeek = calDate.format('dddd')
	// const dateString = calDate.format('YYYY-MM-DD')
	// console.log('CalDayProps',dateString)

	// const isOffDay = offDays && offDays.includes(dayOfWeek);
	// const isException = availabilityExceptionsByDate && availabilityExceptionsByDate.includes(dateString);
	// const isLeave = unavailabilitiesByDate && unavailabilitiesByDate.includes(dateString);
	// const isPublicHoliday = publicHolidayList.includes(dateString);

	const isException = false

  const onClick = (e) => {
      e.stopPropagation()
    if (props.isInthePast) {
      return;
    }

    if (!selectedMobileLocation || selectedMobileLocation == defaultMobileLocation.id){
    	// disable this day
    	createLocationScheduleException({id: uuidv4(), disable: true})
    } else {
    	createLocationScheduleException({id: uuidv4(), date: dateString})
    }

	// if (isLeave){
	// 	// cancel exception
	// 	const leave = _.find(unavailabilities,{date: dateString})
	// 	deleteLocationScheduleException(leave.id);
	// } else {
	// 	createLocationScheduleException(dateString)
	// }

  }

	const getStyle = function ({date, isSelected}) {
	  return `${isSelected ? 'o_selected-day' : ''} ${date.type}-day`
	}
	 
	const getInline = ({isToday, isInThePast}) => ({
	  cursor: isInThePast ? 'not-allowed' : 'inherit',
	  background: colour,
	  color: isInThePast ? '#555555' : 'inherit',
	})

  return (
    <div onClick={onClick}
      className={getStyle(props)}
      style={getInline(props)}>
      {props.label}
    </div>)
}

const mapStateToProps = (state, ownProps) => {

	const calDate = ownProps.date.moment
	const dateString = calDate.format('YYYY-MM-DD')
	const weekNo = calDate.week()

	const locationScheduleExceptions = state.location.scheduleExceptions

	const exception = locationScheduleExceptions && locationScheduleExceptions.find(item => item.date == dateString)

	const location = state.location.item
	const mobileLocations = state.mobileLocation.list && state.mobileLocation.list.filter(item => item.isRepeating && item.locationID == location.id).sort((a, b) => a.order - b.order)

	const mobileWeekOffset = location.mobileWeekOffset || 0

	let weeksOnRecurring = 0
	mobileLocations.map(item => {
		item.previousWeeks = weeksOnRecurring;
		weeksOnRecurring += item.weeksAtLocation
	})

	const offset = (weekNo + mobileWeekOffset) % weeksOnRecurring;

	const mobileLocation = mobileLocations.find(item => {
		return item.previousWeeks <= offset && (item.previousWeeks + item.weeksAtLocation) > offset
	})

	const exceptionLocation = exception && state.mobileLocation.list.find(item => item.id == exception.mobileLocationID)

	console.log('exceptionLocation',exceptionLocation)

	const selectedMobileLocation = state.mobileLocation.selected

	return {
	  location: state.location.item,
	  locationScheduleExceptions: state.location.locationScheduleExceptions,
      // mobileLocations: state.mobileLocation.list,
      exception,
      exceptionLocation,
      colour: exceptionLocation ? exceptionLocation.colour : mobileLocation ? mobileLocation.colour : "#333",
      defaultMobileLocation: mobileLocation,
      selectedMobileLocation,
      dateString,
  }
}

const mapDispatchToProps = dispatch => ({
	createLocationScheduleException: (item) => dispatch(ACTIONS.createLocationScheduleException(item)),
	deleteLocationScheduleException: (id) => dispatch(ACTIONS.deleteLocationScheduleException(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CalendarDay)
import React, { useState, useEffect } from "react";
import { useForm } from 'react-hook-form'
import { useParams, useHistory} from "react-router";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import {connect} from 'react-redux';

import {Actions as ACTIONS} from 'store/customer/action';
import PageHeading from 'components/PageHeading';
import { v4 as uuidv4 } from 'uuid';
import { format } from 'date-fns'
import cleanDeep from 'clean-deep';
import CustomerBookings from 'components/customer/CustomerBookings';
import DatePicker from 'react-datepicker';

import "react-datepicker/dist/react-datepicker.css";
import "styles/datepicker.css";

const CustomerForm = ({customer,customerSearch,createCustomer, updateCustomer,deleteCustomer,callback, permissions}) => {

	let history = useHistory();
	const { register, setValue, handleSubmit, watch, errors } = useForm()

	register({ name: 'phone', type: 'custom'}, { required: true }) // disabled required to see what saves
	register({ name: 'dob', type: 'custom'}, { required: false })
	const phone = watch('phone') || customer && customer.phone || ( customerSearch && typeof(customerSearch) == "string" && customerSearch.length == 8 ? `+356${customerSearch}` : customerSearch && typeof(customerSearch) == "String" ? customerSearch : "" )
	const dob = watch('dob') || customer && customer.dob

	  useEffect(() => {
	    if (customerSearch){
	    	const newPhoneValue = customerSearch && typeof(customerSearch) == "string" && customerSearch.length == 8 ? `+356${customerSearch}` : customerSearch
	      	setValue('phone',newPhoneValue, { shouldDirty: true })

	        setTimeout(
	            () => {
		      		setValue('phone',newPhoneValue, { shouldDirty: true })
	            },
	            500
            )
	    }
	  },[customerSearch]);


	  useEffect(() => {
	    if (customer && customer.id){
	      setValue('phone',customer.phone)
	      setValue('dob',customer.dob)
	    } else {
	      setValue('phone',null)
	      setValue('dob',null)
	    }
	  },[customer && customer.id]);

	const today = format(new Date(),"yyyy-MM-dd")
	const futureBookings = customer && customer.bookings && customer.bookings && customer.bookings.items && customer.bookings.items.filter(i => i.date >= today && i.status != "Cancelled")

	const onSubmit = data => {
		console.log('submitCustomer',data)

		if (data.phone.substr(0,1) !== '+'){
			data.phone = "+" + data.phone
		}


		/*offDayExceptions: [AWSDate] #OffDays that will be worked anyway
		startDate: AWSDate
		endDate: AWSDate
		photo: S3Object*/

		const item = {
			id: customer && customer.id || uuidv4(),
			_version: customer && customer._version,
			givenName: data.givenName,
			familyName: data.familyName,
			phone: data.phone,
			email: data.email,
		}
		console.log('item',item)

		if (customer && customer.id){
			updateCustomer(cleanDeep(item),callback)
		} else {
			delete(item._version);
			console.log('createItem',item,callback)
			createCustomer(cleanDeep(item),callback)
		}
	}

	return (

		
		<div className="mt-5 md:mt-0 md:col-span-2 relative">
		  <div 
		  	style={{position:"absolute",right: "5px", cursor: "pointer"}} 
		  	onClick={() => callback(null) }
		  	>X</div>
		  <form onSubmit={handleSubmit(onSubmit)}>
			<div className="shadow sm:rounded-md">
			  <div className="px-4 py-5 bg-white sm:p-6">
				<div className="grid grid-cols-6 gap-6">


					<div class="col-span-6 sm:col-span-3">
						<label for="givenName" class="block text-sm font-medium leading-5 text-gray-700">First name</label>
						<input id="givenName" name="givenName" defaultValue={customer && customer.givenName} ref={register({ required: true })} class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
					</div>

					<div class="col-span-6 sm:col-span-3">
						<label for="familyName" class="block text-sm font-medium leading-5 text-gray-700">Last name</label>
						<input id="familyName" name="familyName" defaultValue={customer && customer.familyName} ref={register} class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
					</div>

					<div class="col-span-6 sm:col-span-3">
						<label for="email" class="block text-sm font-medium leading-5 text-gray-700">Email</label>
						<input id="email" name="email" defaultValue={customer && customer.email} ref={register} class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
					</div>

					<div class="col-span-6 sm:col-span-3">
						<label for="phone" class="block text-sm font-medium leading-5 text-gray-700">Phone</label>
						<PhoneInput
						  country={'mt'}
						  value={phone}
						  onChange={phone =>setValue('phone',phone, { shouldDirty: true })}
						  enableSearch={true}
						  masks={{mt: ".... ...."}}

					      inputStyle={{width: "100%"}}
						/>
						{/*<input id="phone" name="phone" defaultValue={customer && customer.phone} ref={register({ required: true })} class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />*/}
					</div>

					<div class="col-span-6 sm:col-span-3">
						<label for="phone" class="block text-sm font-medium leading-5 text-gray-700">Date of Birth</label>
						<DatePicker
                          dateFormat="dd/MM/yyyy" 
                          selected={dob} 
                          onChange={(selectedDate) => {
                            setValue('dob',selectedDate)
                          }}
                          className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                          popperPlacement="bottom-end"
                          popperModifiers={{
                            offset: {
                              enabled: true,
                              //offset: "77px, 0px"
                            }
                          }}
                    	/>
					</div>
 
					{ permissions == "Admin" && 
						<div className="col-span-6 sm:col-span-4">
						  <div className="flex items-start">
							<div className="absolute flex items-center h-5">
							  <input id="vip" name="vip" ref={register} defaultChecked={customer && customer.vip} type="checkbox" className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out" />
							</div>
							<div className="pl-7 text-sm leading-5">
							  <label htmlFor="vip" className="font-medium text-gray-700">VIP</label>
							  <p className="text-gray-500">This client is considered a VIP and entitled to special treatment.</p>
							</div>
						  </div>
						</div>
					}

				</div>
			  </div>
			  <div className="md:grid md:grid-cols-2 md:gap-6 bg-gray-50 ">
				  <div className="px-4 py-3 text-left sm:px-6">
				  	{ customer && customer.id && futureBookings && futureBookings.length == 0 &&
						<span className="inline-flex rounded-md shadow-sm">
						  <button 
						  	onClick={(e) => {
						  		e.preventDefault()
						  		e.stopPropagation()
						  		deleteCustomer(customer.id,customer._version,history)
						  	}} 
						  	className="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red active:bg-red-700 transition duration-150 ease-in-out">
							Delete
						  </button>
						</span>
					}
				  </div>
				  <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
					<span className="inline-flex rounded-md shadow-sm">
					  <button type="submit" className="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">
						Save
					  </button>
					</span>
				  </div>
			  </div>
			</div>
		  </form>
		</div>
	)
}


const mapStateToProps = (state) => {
	return {
	  customer: state.customer.item,
      permissions: state.permissions,
  }
}

const mapDispatchToProps = dispatch => ({
  createCustomer: (item,callback) => dispatch(ACTIONS.createItem(item,callback)),
  updateCustomer: (item,callback) => dispatch(ACTIONS.updateItem(item,callback)),
  deleteCustomer: (id,version,history) => dispatch(ACTIONS.deleteItem(id,version,history)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerForm) 
import React, { useState, useEffect } from "react";
import MainMenu from "./MainMenu";
import Notifications from "components/notifications/Notifications"
import ChangelogModal from "components/changelog/ChangelogModal"
import BookingChangedEmailModal from "components/booking/BookingChangedEmailModal"
import Tenants from "components/tenant/Tenants"
import {connect} from 'react-redux';
import {Auth} from '@aws-amplify/auth'

import {Actions as ACTIONS} from 'store/booking/action';
import {Actions as BUSINESSACTIONS} from 'store/business/action';
import {Actions as SERVICEACTIONS} from 'store/service/action';
import {Actions as PRODUCTACTIONS} from 'store/product/action';
import {Actions as TEAMMEMBERACTIONS} from 'store/teamMember/action';
import {Actions as LOCATIONACTIONS} from 'store/location/action';
import {Actions as MOBILELOCATIONACTIONS} from 'store/mobileLocation/action';
import {Actions as CUSTOMERACTIONS} from 'store/customer/action';
import {Actions as BOOKINGACTIONS} from 'store/booking/action';

const Wrapper = ({
	children,
	userDetails,
	bookings,
	bookingsNextToken,
	services,
	servicesNextToken,
	products,
	productsNextToken,
	teamMember,
	teamMembers,
	teamMembersNextToken,
	locations,
	locationsNextToken,
	mobileLocations,
	mobileLocationsNextToken,
	customers,
	customersNextToken,
	listBookings,
	listServices,
	listProducts,
	listTeamMembers,
	listLocations,
	listMobileLocations,
	listCustomers,
	business,
	businessID,
	getBusiness,
	subscribeToBookingUpdates,
	subscribeToCustomerUpdates,
}) => {

	const signOut = async() => {
	    try {
	        await Auth.signOut({ global: true });
	        window.location.reload(); // ensure app data is cleared
	    } catch (error) {
	        console.log('error signing out: ', error);
	    }
	}

	useEffect(() => {
		if (businessID){
			console.log('id',bookings)
			if (!(bookings)){
				listBookings(1000,bookingsNextToken)
			}
			if (!(services)){
				listServices(1000,servicesNextToken)
			}
			if (!(products)){
				listProducts(1000,productsNextToken)
			}
			if (!(teamMembers)){
				listTeamMembers(1000,teamMembersNextToken)
			}
			if (!(locations)){
				listLocations(1000,locationsNextToken)
			}
			if (!(mobileLocations)){
				listMobileLocations(1000,mobileLocationsNextToken)
			}
			// if (!(customers)){
			// 	listCustomers(1000,customersNextToken)
			// }
			if (!(business && business.id)){
				console.log('getBusiness')
				getBusiness()
			}
			subscribeToBookingUpdates()
			subscribeToCustomerUpdates()
		}
	},[businessID]);

	useEffect(() => {
		// if (customersNextToken){
		// 	listCustomers(1000,customersNextToken)
		// }
	},[customersNextToken]);

  	const [sidebarOpen, setSidebarOpen] = useState(false)

  	if (!business){
  		return <div><Tenants/> Loading...</div>
  	}

	return (
		<div className="flex bg-gray-100">
		   {/*Off-canvas menu for mobile */}
		  <div className="" style={{display: sidebarOpen ? 'block' : 'none'}}>
		    <div onClick={() => {setSidebarOpen(false)}} className="fixed inset-0 z-30 transition-opacity ease-linear duration-300" style={{display: sidebarOpen ? 'block' : 'none'}}>
		      <div className="absolute inset-0 bg-gray-600 opacity-75"></div>
		    </div>
		    <div className="fixed inset-0 flex z-40" onClick={() => {setSidebarOpen(false)}} >
		      <div className="flex-1 flex flex-col max-w-xs w-full bg-gray-800 transform ease-in-out duration-300 " style={{display: sidebarOpen ? 'block' : 'none'}}>
		        <div className="absolute top-0 right-10 -mr-14 p-1">
		          <button onClick={() => {setSidebarOpen(false)}} className="flex items-center justify-center h-12 w-12 rounded-full focus:outline-none focus:bg-gray-600" style={{display: sidebarOpen ? 'block' : 'none'}}>
		            <svg className="h-6 w-6 text-white" stroke="currentColor" fill="none" viewBox="0 0 24 24">
		              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
		            </svg>
		          </button>
		        </div>
		        <div className="flex-1 pt-5 pb-4 overflow-y-auto">
		          <div className="flex-shrink-0 flex items-center px-4">
		            <img className="h-8 w-auto" src="/img/logos/workflow-logo-on-dark.svg" alt="Workflow" />
		          </div>
		          
		            <MainMenu/>
		        </div>
		        <div className="flex-shrink-0 flex bg-gray-700 p-4">
		          <a href="#" className="flex-shrink-0 group block">
		            <div className="flex items-center">
		              <div>
		              	{
	                		teamMember && teamMember.photo ? 
	                			<img className="inline-block h-10 w-10 rounded-full" src={`https://${teamMember.photo.bucket}.s3-eu-west-1.amazonaws.com/public/${teamMember.photo.key}`}/>
	                			:
			                  <img className="inline-block h-10 w-10 rounded-full" src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="" />
	                	}
		              </div>
		              <div className="ml-3">
			              <p className="text-sm leading-5 font-medium text-white">
			                {userDetails && userDetails.attributes && userDetails.attributes.given_name ? `${userDetails.attributes.given_name} ${userDetails.attributes.family_name ? userDetails.attributes.family_name : ''}` : `${userDetails.attributes && userDetails.attributes.email}`}
			              </p>
			              <p className="text-xs leading-4 font-medium text-gray-400 group-hover:text-gray-300 group-focus:underline transition ease-in-out duration-150">
			                <a onClick={signOut}>Sign Out</a>
			              </p>
			          </div>
		            </div>
		          </a>
		        </div>
		      </div>
		      <div className="flex-shrink-0 w-14">
		         {/*Force sidebar to shrink to fit close icon*/} 
		      </div>
		    </div>
		  </div>

		   {/*Static sidebar for desktop */}
		  {/*<div className="hidden md:flex md:flex-shrink-0">
		    <div className="flex flex-col w-64 bg-gray-800">
		      <div className="h-0 flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
		        <div className="flex items-center flex-shrink-0 px-4">
		          <img className="h-8 w-auto" src="/img/logos/workflow-logo-on-dark.svg" alt="Workflow" />
		        </div>

		          	<Tenants/>

		        <nav className="mt-5 flex-1 px-2 bg-gray-800">
		          <MainMenu/>
		        </nav>
		      </div>
		      <div className="flex-shrink-0 flex bg-gray-700 p-4">
		        <div href="#" className="flex-shrink-0 group block focus:outline-none">
		          <div className="flex items-center">
		            <div>
		              <img className="inline-block h-9 w-9 rounded-full" src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="" />
		            </div>
		            <div className="ml-3">
		              <p className="text-sm leading-5 font-medium text-white">
		                {userDetails && userDetails.attributes && userDetails.attributes.given_name ? `${userDetails.attributes.given_name} ${userDetails.attributes.family_name ? userDetails.attributes.family_name : ''}` : `${userDetails.attributes && userDetails.attributes.email}`}
		              </p>
		              <p className="text-xs leading-4 font-medium text-gray-400 group-hover:text-gray-300 group-focus:underline transition ease-in-out duration-150">
		                <a onClick={signOut}>Sign Out</a>
		              </p>
		            </div>
		          </div>
		        </div>
		      </div>
		    </div>
		  </div>*/}
		  <div className="flex flex-col w-0 flex-1">
		    <div className=" pl-1 pt-1 sm:pl-3 sm:pr-3 sm:pt-3">
		      <button onClick={() => {setSidebarOpen(true)}} className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:bg-gray-200 transition ease-in-out duration-150">
		        <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
		          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"/>
		        </svg>
		      </button>
		  	  {/* Maybe tenant switcher could be here eventually - if we support multi business logins? */}
		  	  <Tenants/>
		      { business && business.logo && business.name !== "Ta Spiru" &&
	      			<div class="flex items-center" style={{float:"right"}}>
		                <span class="inline-block h-10 w-10 rounded-full overflow-hidden bg-gray-100">
                			<img src={`https://${business.logo.bucket}.s3-eu-west-1.amazonaws.com/public/${business.logo.key}`}/>
		                </span>
		            </div>
		        }
		        <a href="#" className="flex-shrink-0 group block" style={{float:"right"}}>
		            <div className="flex items-center">
		              <div>
		              	{
	                		teamMember && teamMember.photo ? 
	                			<img className="inline-block h-10 w-10 rounded-full" src={`https://${teamMember.photo.bucket}.s3-eu-west-1.amazonaws.com/public/${teamMember.photo.key}`}/>
	                			:
			                  <img className="inline-block h-10 w-10 rounded-full" src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="" />
	                	}
		              </div>
		              <div className="ml-3">
			              <p className="text-sm leading-5 font-medium">
			                {userDetails && userDetails.attributes && userDetails.attributes.given_name ? `${userDetails.attributes.given_name} ${userDetails.attributes.family_name ? userDetails.attributes.family_name : ''}` : `${userDetails.attributes && userDetails.attributes.email}`}
			              </p>
			              <p className="text-xs leading-4 font-medium text-gray-700 group-hover:text-gray-500 group-focus:underline transition ease-in-out duration-150">
			                <a style={{cursor:"pointer"}} onClick={signOut}>Sign Out</a>
			              </p>
			          </div>
		            </div>
	          	</a>
		    </div>
		    <main className="flex-1 relative z-0 pb-6 pt-0 focus:outline-none" tabindex="0"  x-init="$el.focus()">
        		<ChangelogModal/>
        		<BookingChangedEmailModal/>
		      	{businessID && children}
		      	<Notifications/>
		    </main>
		  </div>
		</div>
	)
}

const mapStateToProps = (state) => {
    return {
      businessID: state.businessID,
      userDetails: state.userDetails,
      business: state.business.item,
      bookings: state.booking.list,
      bookingsNextToken: state.booking && state.booking.listNextToken,
      services: state.service.list,
      servicesNextToken: state.service && state.service.listNextToken,
      products: state.product.list,
      productsNextToken: state.produc && state.produc.listNextToken,
      teamMembers: state.teamMember.list,
      teamMembersNextToken: state.teamMembers && state.teamMembers.listNextToken,
      locations: state.location.list,
      locationsNextToken: state.location && state.location.listNextToken,
      mobileLocations: state.mobileLocation.list,
      mobileLocationsNextToken: state.mobileLocation && state.mobileLocation.listNextToken,
      customers: state.customer.list,
      teamMember: state.userDetails && state.userDetails.username && state.teamMember.list && state.teamMember.list.find(i => i.username == state.userDetails.username),
      customersNextToken: state.customer && state.customer.listNextToken,
  }
}

const mapDispatchToProps = dispatch => ({
  listBookings: (limit,nextToken) => dispatch(ACTIONS.getList(limit,nextToken)),
  listServices: (limit,nextToken) => dispatch(SERVICEACTIONS.getList(limit,nextToken)),
  listProducts: (limit,nextToken) => dispatch(PRODUCTACTIONS.getList(limit,nextToken)),
  listTeamMembers: (limit,nextToken) => dispatch(TEAMMEMBERACTIONS.getList(limit,nextToken)),
  listLocations: (limit,nextToken) => dispatch(LOCATIONACTIONS.getList(limit,nextToken)),
  listMobileLocations: (limit,nextToken) => dispatch(MOBILELOCATIONACTIONS.getList(limit,nextToken)),
  listCustomers: (limit,nextToken) => dispatch(CUSTOMERACTIONS.getList(limit,nextToken)),
  getBusiness: (id) => dispatch(BUSINESSACTIONS.getItem(id)),
  subscribeToBookingUpdates: () => dispatch(BOOKINGACTIONS.subscribeToUpdates()),
  subscribeToCustomerUpdates: () => dispatch(CUSTOMERACTIONS.subscribeToUpdates()),
  
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Wrapper)
import {Types} from "./action";
import _ from "lodash";
// import {NotificationManager} from 'react-notifications';

// import cleanDeep from 'clean-deep';


const defaultState = {
  service: {}
};

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case Types.SCHEDULER_SET_LOCATION: {

      let newState = {...state};

      newState.scheduler.location = action.location;

      return newState;
    }
    case Types.SCHEDULER_SET_FILTER: {

      let newState = {...state};

      newState.scheduler.filter = action.filter;

      return newState;
    }
    default:
      return state;
  }
};

export default reducer;
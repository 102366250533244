import React, { useState, useEffect } from "react";
import { useForm } from 'react-hook-form'
import { useParams, useHistory} from "react-router";

import {connect} from 'react-redux';

import {Actions as ACTIONS} from 'store/paymentMachines/action';
import {Actions as LOCATIONACTIONS} from 'store/location/action';
import PageHeading from 'components/PageHeading';
import { v4 as uuidv4 } from 'uuid';
import cleanDeep from 'clean-deep'
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import { format, parse, setYear, addYears, addDays, setDate, setMonth } from 'date-fns'
import Calendar from 'react-calendar-multiday'

import "react-datepicker/dist/react-datepicker.css";
import "styles/datepicker.css";


const ExampleCustomInput = ({ value, onClick }) => (
	<button onClick={onClick} type="button" class="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150">
		{value} {/*{currentDate && format(new Date(currentDate),'dd/MM/yyyy')}*/}
	</button>
);

const customFields = {
	"Trust": [
		"siteId", // : 2245,
		"password", // : "QM3BD7FP",
		"serialNumber", // : "230027303201272128888711",
	]
}

const PaymentMachine = ({
		paymentMachine,
		createPaymentMachine,
		updatePaymentMachine,
		getPaymentMachine,
		locations,
		listLocations,
		mobileLocations,
	}) => {

  	let history = useHistory();
	let { id } = useParams();

	// initialId is used for when we are clearing as it causes an issue.
  	const [initialId, setInitialId] = useState(paymentMachine?.id)

	const { register, setValue, handleSubmit, watch, errors, reset } = useForm()

	const paymentType = watch('type') || paymentMachine?.type;

	  console.log('paymentType',paymentType)

	useEffect(() => {
		console.log('id',locations)
		if (!(locations)){
			console.log('getLocations')
			listLocations(1000,null)
		}
	},[]);

	const onSubmit = data => {
		console.log(data)

		const item = {
			id: paymentMachine?.id || uuidv4(),
			_version: paymentMachine?._version,
			name: data.name,
			locationID: data.locationID,
			status: data.status,
		}

		if (paymentType){
			item.type = paymentType
			item.settings = {}
			customFields[paymentType]?.map(field => {
				item.settings[field] = data[field]
			})
			item.settings = JSON.stringify(item.settings)
		}

		console.log('item',item)

		if (paymentMachine?.id){
			const cleanedItem = cleanDeep(item);
			updatePaymentMachine(cleanedItem)
		} else {
			delete(item._version);
			console.log('updatedItem',item)
			createPaymentMachine(cleanDeep(item))
		}
	}

	useEffect(() => {
		if (!(id !== 'new' && paymentMachine?.id && paymentMachine.id == id)){
			getPaymentMachine(id)
		} else if (id == 'new'){
			// clearPaymentMachine()
		} else {
			reset({
				...paymentMachine,
			}); 
		}
	},[paymentMachine?.id]);

	useEffect(() => {
		if (id == 'new' && paymentMachine?.id && paymentMachine.id !== initialId && paymentMachine._version == 1){
  			history.push(`/paymentMachine/${paymentMachine.id}`)
		}
	},[paymentMachine?._version == 1 && paymentMachine.id]);

	if ( (!paymentMachine && id !== 'new' ) || (paymentMachine && id !== 'new' && id !== paymentMachine.id ) || (id === 'new' && paymentMachine)){

		return (
			<div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
				Loading...
			</div>
		)
	}

	return (

		<React.Fragment>
			<PageHeading item={paymentMachine} isNew={id === 'new'} label={paymentMachine?.name}/>
			<div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
				<div className="md:grid md:grid-cols-3 md:gap-6">
					<div className="md:col-span-1">
					  <div className="px-4 sm:px-0">
						<h3 className="text-lg font-medium leading-6 text-gray-900">PaymentMachine Details</h3>
						<p className="mt-1 text-sm leading-5 text-gray-500">
						  This information will be displayed publicly.
						</p>

					  </div>
					</div>
					<div className="mt-5 md:mt-0 md:col-span-2">
					  <form onSubmit={handleSubmit(onSubmit)}>
						<div className="shadow sm:rounded-md sm:overflow-hidden">
						  <div className="px-4 py-5 bg-white sm:p-6">
							<div className="grid grid-cols-6 gap-6">


								<div class="col-span-6 sm:col-span-3">
									<label for="name" class="block text-sm font-medium leading-5 text-gray-700">Name</label>
									<input id="name" name="name" defaultValue={paymentMachine?.name} ref={register} class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
								</div>

								<div className="col-span-6 sm:col-span-3">
									<label htmlFor="type" className="block text-sm font-medium leading-5 text-gray-700">Type</label>
									<select id="type" name="type" defaultValue={paymentMachine?.type} ref={register} className="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
										<option></option>
										<option>Trust</option>
									</select>
								</div>

								{
									/* When the type is selected show fields based on the type */
									paymentType && customFields[paymentType]?.map(field => (
										<div class="col-span-6 sm:col-span-3">
											<label for={field} class="block text-sm font-medium leading-5 text-gray-700">{field}</label>
											<input id={field} name={field} defaultValue={paymentMachine?.settings[field]} ref={register} class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
										</div>
									))
								}

								<div className="col-span-6 sm:col-span-3">
									<label htmlFor="locationID" className="block text-sm font-medium leading-5 text-gray-700">Location</label>
									<select id="locationID" name="locationID" ref={register} className="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
										{
											locations && locations.map(location => (
												<option value={location.id}>{location.name} ({location.address && location.address.postCode})</option>
											))
										}
										{/* TODO PaymentMachined official location list */}
									</select>
								</div>

								<div className="col-span-6 sm:col-span-3">
									<label htmlFor="status" className="block text-sm font-medium leading-5 text-gray-700">Status</label>
									<select id="status" name="status" defaultValue={paymentMachine?.status} ref={register} className="mt-1 block form-select w-full py-2 px-3 py-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
										<option>Active</option>
										<option>Disabled</option>
									</select>
								</div>


							</div>
						  </div>
						  <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
							<span className="inline-flex rounded-md shadow-sm">
							  <button type="submit" className="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">
								Save
							  </button>
							</span>
						  </div>
						</div>
					  </form>
					</div>
				</div>
			</div>
		</React.Fragment>
		)
}

const mapStateToProps = (state) => {
	return {
	  paymentMachine: state.paymentMachine?.item,
      locations: state.location?.list,
  }
}

const mapDispatchToProps = dispatch => ({
  createPaymentMachine: (item) => dispatch(ACTIONS.createItem(item)),
  updatePaymentMachine: (item) => dispatch(ACTIONS.updateItem(item)),
  getPaymentMachine: (id) => dispatch(ACTIONS.getItem(id)),
  listLocations: (limit,nextToken) => dispatch(LOCATIONACTIONS.getList(limit,nextToken)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentMachine)
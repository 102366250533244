import React from "react";
import _ from 'lodash'

import {
          bookingStatsByBusinessByReportTypeByDate,
       } from 'graphql/queries'
       
import {API} from '@aws-amplify/api'

var { format } = require('date-fns')


// types of action
const Types = {
  DASHBOARD_GET_STATS: "DASHBOARD_GET_STATS",
};

const updateList = (reportType,data) => ({
  type: Types.DASHBOARD_GET_STATS,
  reportType,
  data
});

const getStats = ({limit, nextToken, from, to, reportType}) => {
  return (dispatch, getState) => {

    const state = getState()
    const today = format(new Date(),"yyyy-MM-dd")

    const variables = {
      limit, 
      nextToken, 
      businessID: state.businessID,
      reportTypeDate:{
        between:[
          {
            reportType: reportType,
            date: from
          },
            {
            reportType: reportType,
            date: to
          }
        ]
      },
    }
    const request = API.graphql({query: bookingStatsByBusinessByReportTypeByDate, variables, authMode: "AMAZON_COGNITO_USER_POOLS"})

    request.then((response) => {

      const groupedByDate =  _.groupBy(response.data.bookingStatsByBusinessByReportTypeByDate.items,'date');
      console.log('chartresponse', groupedByDate)

        dispatch(updateList(reportType,groupedByDate))
    });
    
  }
}


const getSales = ({limit, nextToken, from, to, reportType}) => {
  return (dispatch, getState) => {

    const state = getState()
    const today = format(new Date(),"yyyy-MM-dd")

    const variables = {
      limit, 
      nextToken, 
      businessID: state.businessID,
      reportTypeDate:{
        between:[
          {
            reportType: reportType,
            date: from
          },
            {
            reportType: reportType,
            date: to
          }
        ]
      },
    }
    const request = API.graphql({query: bookingStatsByBusinessByReportTypeByDate, variables, authMode: "AMAZON_COGNITO_USER_POOLS"})

    request.then((response) => {
        dispatch(updateList(reportType,response.data.bookingStatsByBusinessByReportTypeByDate.items))
    });
    
  }
}

const Actions = {
  getStats,
  getSales
}

// exports = {
//   Types,
//   Actions
// }

export {Types, Actions}

// exports.Types = Types
// exports.Actions = Actions
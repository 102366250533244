import {Types} from "./action";
import _ from "lodash";
// import {NotificationManager} from 'react-notifications';

// import cleanDeep from 'clean-deep';


const defaultState = {
  customer: {}
};

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case Types.CUSTOMER_NEW_MODAL: {

      let newState = {...state};

      newState.customer.newModal = action.show;
      newState.customer.item = {};

      return newState;
    }
    case Types.CUSTOMER_GET_ITEM: {

      let newState = {...state};

      newState.customer.item = action.payload.data.getCustomer;

      return newState;
    }
    case Types.CUSTOMER_CLEAR_ITEM: {

      let newState = {...state};

      newState.customer.item = {};

      return newState;
    }
    case Types.CUSTOMER_GET_SEARCH: {

      let newState = {...state};

      const newItems = action.payload.data.listCustomers.items.filter(item => item._deleted != true)

      // TODO add sorting facility
      newState.customer.search = _.sortBy(newItems,['givenName','familyName']) 

      return newState;
    }
    case Types.CUSTOMER_GET_LIST: {

      let newState = {...state};

      const existingList = newState.customer.list || []

      const newItems = action.payload.data.listCustomers.items.filter(item => item._deleted != true)

      const merged = _(existingList) // start sequence
        .keyBy('id') // create a dictionary of the 1st array
        .merge(_.keyBy(newItems, 'id')) // create a dictionary of the 2nd array, and merge it to the 1st
        .values() // turn the combined dictionary to array
        .value(); // get the value (array) out of the sequence


      // TODO add sorting facility
      newState.customer.list = _.sortBy(merged,['givenName','familyName']) 


      newState.customer.listNextToken = action.payload.data.listCustomers.nextToken;
      newState.customer.listStartedAt = action.payload.data.listCustomers.startedAt;
      console.log('updatedCustomerList',newState.customer.list)

      return newState;
    }
    case Types.CUSTOMER_UPDATE_ITEM: {

      let newState = {...state};

      const updatedItem = action.payload.data.updateCustomer || action.payload.data.onUpdateCustomer;
      newState.customer.item = updatedItem;

      if (newState.customer.list){
        const itemIndex = newState.customer.list.findIndex(item => item.id == updatedItem.id)
        newState.customer.list[itemIndex] = updatedItem;
        newState.customer.list = _.sortBy(newState.customer.list,['givenName','familyName'])
      }

      if (action.callback && typeof(action.callback == 'function')){
        action.callback(updatedItem)
      }

      console.log('newCustomerUpdated',newState.customer.item)

      return newState;
    }
    case Types.CUSTOMER_DELETE_ITEM: {

      let newState = {...state};

      const deletedItem = action.payload;
      // newState.service.item = deletedItem;

      if (newState.customer.list){
        newState.customer.list = _.reject(newState.customer.list,{id: deletedItem})
      }

      console.log('customerDeleted')

      return newState;
    }
    case Types.CUSTOMER_CREATE_ITEM: {

      let newState = {...state};

      const createdItem = action.payload.data.createCustomer || action.payload.data.onCreateCustomer;
      
      const exists = newState.customer && newState.customer.list && newState.customer.list.find(item => item.id == createdItem.id)

      if (exists){
        return state;
      }

      if (newState.customer.list){
        newState.customer.list.push(createdItem);
        newState.customer.list = _.sortBy(newState.customer.list,['givenName','familyName'])
      }

      if (newState.customer.newModal){
        newState.customer.newModal = false;
      } else {
        // do not set customer item if inside a modal
        newState.customer.item = createdItem;
      }


      if (action.callback && typeof(action.callback == 'function')){
        action.callback(createdItem)
      }

      return newState;
    }
    default:
      return state;
  }
};

export default reducer;
import React from "react";

import {
          getBooking,
          listBookings,
          bookingByTeamMemberByDate,
          bookingByLocationByDate,
          unavailablityByBusinessByDate,
          availabilityExceptionByBusinessByDate,
          bookingByCustomerByDate,
       } from 'graphql/queries'

import {
  createChangelog,
  createBooking,
  updateBooking,
} from 'graphql/mutations';

import {
  onCreateBooking,
  onUpdateBooking,
} from 'graphql/subscriptions';
       
import {API, graphqlOperation} from '@aws-amplify/api'
import {Auth} from '@aws-amplify/auth'
import awsMobile from 'aws-exports';
import AWS from 'aws-sdk';
import {Actions as NOTIFICATIONACTIONS} from 'store/notifications/action';
import { diffString, diff } from 'json-diff';

// types of action
const Types = {
  BOOKING_GET_ITEM: "BOOKING_GET_ITEM",
  BOOKING_UPDATE_ITEM: "BOOKING_UPDATE_ITEM",
  BOOKING_CREATE_ITEM: "BOOKING_CREATE_ITEM",
  BOOKING_CLEAR_ITEM: "BOOKING_CLEAR_ITEM",
  BOOKING_LOADING: "BOOKING_LOADING",
  BOOKING_GET_LIST: "BOOKING_GET_LIST",
  BOOKING_BY_TEAM_MEMBER: "BOOKING_BY_TEAM_MEMBER",
  BOOKING_CALENDAR_DATE_LOCATION: "BOOKING_CALENDAR_DATE_LOCATION",
  BOOKING_BY_LOCATION: "BOOKING_BY_LOCATION",
  BOOKING_BY_CUSTOMER: "BOOKING_BY_CUSTOMER",
  BOOKING_EMAIL_MODAL: "BOOKING_EMAIL_MODAL",
  BOOKING_SEND_EMAIL: "BOOKING_SEND_EMAIL",
  BOOKING_UNAVAILABILITY: "BOOKING_UNAVAILABILITY",
  BOOKING_AVAILABILITY_EXCEPTION: "BOOKING_AVAILABILITY_EXCEPTION",
  BOOKING_SHOW_BLOCK_MODAL: "BOOKING_SHOW_BLOCK_MODAL",
};

const showBlock =  teamMemberId => ({
  type: Types.BOOKING_SHOW_BLOCK_MODAL,
  teamMemberId
});

const toggleBookingChangedEmailModal =  status => ({
  type: Types.BOOKING_EMAIL_MODAL,
  status
});

const bookingEmailSent =  payload => ({
  type: Types.BOOKING_SEND_EMAIL,
  payload
});

const sendBookingEmail = () => {

  return async (dispatch, getState) => {

    const state = getState()

    const myInit = {
        body: { booking: state.booking.item.id}, // replace this with attributes you need
        headers: { 
          Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
        },
    };

    // lets create the user in cognito
    API
      .post('booking', '/bookings/emailUpdate', myInit)
      .then(response => {
        // Add your code here
        dispatch(toggleBookingChangedEmailModal(false));

        dispatch(NOTIFICATIONACTIONS.addNotification({
          title: <React.Fragment><strong>Booking confirmation</strong> email has been sent</React.Fragment>,
          // message: response.data.updateBooking.active ? `You team can now start to provide this booking.` : `This booking is disabled and cannot be booked.`,
          icon: `success`,
        },3000))
      })
      .catch(error => {
        console.log(error.response);

        dispatch(toggleBookingChangedEmailModal(false));

        dispatch(NOTIFICATIONACTIONS.addNotification({
          title: <React.Fragment><strong>Error</strong> sending confirmation email.</React.Fragment>,
          // message: response.data.updateBooking.active ? `You team can now start to provide this booking.` : `This booking is disabled and cannot be booked.`,
          icon: `warning`,
        },3000))
      });
  }


};



const dispatchLoading =  loading => ({
  type: Types.BOOKING_LOADING,
  loading
});

const clearItem = response => ({
  type: Types.BOOKING_CLEAR_ITEM
});

const dispatchGetItem = response => ({
  type: Types.BOOKING_GET_ITEM,
  payload: response
});

const getItem = (id) => {
  console.log('getItem1',getItem)
  return (dispatch, getState) => {
    dispatch(dispatchLoading(true))

    const variables = {id}
    const request = API.graphql({query: getBooking, variables, authMode: "AMAZON_COGNITO_USER_POOLS"})
    console.log('getItem2',getItem)
    request.then((response) => {
        dispatch(dispatchGetItem(response))
        dispatch(dispatchLoading(false))
    });
    
  }
}

const subscribeToUpdates = () => {
  return (dispatch, getState) => {

    const state = getState()

    const businessID = state.businessID

    const createSubscription = API.graphql(
        graphqlOperation(onCreateBooking,{businessID})
    ).subscribe({
        next: (data) => {

          const state = getState()

          const newEntry = data.value.data.onCreateBooking;

          let currentBooking = state.booking.list.find(({id}) => id == newEntry.id)
          // only dispatch if same as what user is viewing
          if (
            state.booking.calendarDate == newEntry.date && 
            state.booking.calendarLocation == newEntry.locationID &&
            !currentBooking
          ){
            dispatch(dispatchCreateItem(data.value))
            console.log('onCreateBooking',data.value)
          }
        },
        error: error => dispatch(subscribeToUpdates)
    });

    const updateSubscription = API.graphql(
        graphqlOperation(onUpdateBooking,{businessID})
    ).subscribe({
        next: (data) => {

          const state = getState()

          const newEntry = data.value.data.onUpdateBooking;

          console.log('onUpdateBooking',newEntry,state.booking)
          // only dispatch if same as what user is viewing
          if (
            state.booking.calendarDate == newEntry.date && 
            state.booking.calendarLocation == newEntry.locationID
          ){
            let currentBooking = state.booking.list.find(({id}) => id == newEntry.id)

            console.log('currentBooking',currentBooking)
            if (currentBooking && typeof(currentBooking) == 'object' && newEntry && currentBooking._version !== newEntry._version){
              dispatch(dispatchUpdateItem(data.value))
            }
            
          }
        },
        error: error => dispatch(subscribeToUpdates)
    });

  }
}

const dispatchUpdateItem = response => ({
  type: Types.BOOKING_UPDATE_ITEM,
  payload: response
});

const updateItem = (item) => {
  return (dispatch, getState) => {

    const state = getState();

    console.log('itemToupdate',item)
    let currentBooking = state.booking.list.find(({id}) => id == item.id)

    if (!currentBooking) {
      currentBooking = state.booking.byLocation.find(({id}) => id == item.id)
    }

    // ensure version is always correct before saving
    item._version = currentBooking._version

    // const difference = diff(currentBooking,item);
    // console.log('difference',difference)

    const variables = {input: item}
    const request = API.graphql({query: updateBooking, variables, authMode: "AMAZON_COGNITO_USER_POOLS"})

    request.then(async (response) => {
        dispatch(dispatchUpdateItem(response))

        const difference = diff(currentBooking,response.data.updateBooking);

        // remove common items
          delete difference._version;
          delete difference._lastChangedAt;
          delete difference.updatedAt;
          delete difference.customer; // this is an object ignore

        const differenceKeys = Object.keys(difference)

        const notEndTimeOnly = differenceKeys.filter(i => !["endTime"].includes(i)).length > 0
        const statusOnly = notEndTimeOnly == 1 && differenceKeys.filter(i => !["status","endTime"].includes(i)).length == 0

        // notify user if status is now cancelled or there was another tangible change
        const notifyUser = notEndTimeOnly && !(statusOnly && response.data.updateBooking.status != "Cancelled" ) 

        const bookingSource = response.data.updateBooking.source

          console.log('differenceKeys statusOnly',notEndTimeOnly,statusOnly,response.data.updateBooking.status == "Cancelled")

        if (notifyUser){ 
          // if (["App","Website"].includes(bookingSource) && response.data.updateBooking.status == "Cancelled"){
          //   console.log('differenceKeys sendBookingEmail')
          //   dispatch(sendBookingEmail())
          // } else {
            dispatch(toggleBookingChangedEmailModal(true))
          // }
        } else {
          console.log('differenceKeys DO NOT dispatchShowEmailModal')
        }

        let actionTaken = "Update";

        if (item.status == "Cancelled"){
          actionTaken = "Cancel";
        }

        const userInfo = await Auth.currentUserInfo();
        console.log('userInfo',userInfo)

        const currentTeamMember = state.teamMember.list.find(i => i.username == userInfo.username)

        const newChangeLog = {
          entityID: item.id,
          action: actionTaken,
          teamMemberID: currentTeamMember.id,
          businessID: state.businessID,
          diff: JSON.stringify(difference)
        }

        const changeLogRequest = API.graphql({query: createChangelog, variables: {input: newChangeLog}, authMode: "AMAZON_COGNITO_USER_POOLS"})

        changeLogRequest.then((response) => {
          console.log('changeLogSaved',response)
          // we don't need to do anythign after saving the changelog right now.
        })

        // save change log for this change
        // 
        // Create
        // Update
        // Cancel
        // Disable
        // Enable

        //TODO Plan notification logic/errors
        dispatch(NOTIFICATIONACTIONS.addNotification({
          title: <React.Fragment><strong>{response.data.updateBooking.givenName}</strong> has been updated</React.Fragment>,
          // message: response.data.updateBooking.active ? `Bookings can book this booking` : `This booking is disabled. It cannot be booked`,
          icon: `success`,
        },
          3000
        ))
    });
    
  }
}

const dispatchCreateItem = response => ({
  type: Types.BOOKING_CREATE_ITEM,
  payload: response
});

const createItem = (item) => {
  return (dispatch, getState) => {

    const state = getState()

    const businessID = state.businessID
    const variables = {input: {...item, businessID}}
    const request = API.graphql({query: createBooking, variables, authMode: "AMAZON_COGNITO_USER_POOLS"})

    request.then(async (response) => {
        // switched off as subscription is filling this
        dispatch(dispatchCreateItem(response))
        dispatch(toggleBookingChangedEmailModal(true))

        let actionTaken = "Create";

        const userInfo = await Auth.currentUserInfo();
        console.log('userInfo',userInfo)

        const currentTeamMember = state.teamMember.list.find(i => i.username == userInfo.username)

        const newChangeLog = {
          entityID: item.id,
          action: actionTaken,
          businessID: state.businessID,
          teamMemberID: currentTeamMember.id
        }

        const changeLogRequest = API.graphql({query: createChangelog, variables: {input: newChangeLog}, authMode: "AMAZON_COGNITO_USER_POOLS"})

        changeLogRequest.then((response) => {
          console.log('changeLogSaved',response)
          // we don't need to do anythign after saving the changelog right now.
        })

        //TODO Plan notification logic/errors
        dispatch(NOTIFICATIONACTIONS.addNotification({
          title: <React.Fragment><strong>{response.data.createBooking.givenName}</strong> has been created</React.Fragment>,
          // message: response.data.updateBooking.active ? `You team can now start to provide this booking.` : `This booking is disabled and cannot be booked.`,
          icon: `success`,
        },3000))
    });
    
  }
}

const dispatchBookingsByTeamMember = response => ({
  type: Types.BOOKING_BY_TEAM_MEMBER,
  payload: response
});

const getBookingsByTeamMember = (variables) => {
  return (dispatch, getState) => {

    /*const variables = {
      teamMemberID:"23b46d6b-166e-44a6-a112-7b1944156cb9",
      date:{
        ge:"2020-04-01"
      },
      sortDirection:"ASC",
      limit, 
      nextToken
    }*/
    const request = API.graphql({query: bookingByTeamMemberByDate, variables, authMode: "AMAZON_COGNITO_USER_POOLS"})

    request.then((response) => {
        dispatch(dispatchBookingsByTeamMember(response))
    });
    
  }
}

const dispatchBookingsCalendarDateLocation = (date,location) => ({
  type: Types.BOOKING_CALENDAR_DATE_LOCATION,
  date,
  location
});

const dispatchBookingsByLocation = response => ({
  type: Types.BOOKING_BY_LOCATION,
  payload: response
});

const getBookingsByLocation = (variables) => {
  return (dispatch, getState) => {

    dispatch(dispatchBookingsCalendarDateLocation(variables.date.eq,variables.locationID))

    /*const variables = {
      locationID:"23b46d6b-166e-44a6-a112-7b1944156cb9",
      date:{
        ge:"2020-04-01"
      },
      sortDirection:"ASC",
      limit, 
      nextToken
    }*/
    const request = API.graphql({query: bookingByLocationByDate, variables, authMode: "AMAZON_COGNITO_USER_POOLS"})

    request.then((response) => {
        dispatch(dispatchBookingsByLocation(response))
    });
    
  }
}

const dispatchBookingUnavailability = response => ({
  type: Types.BOOKING_UNAVAILABILITY,
  payload: response
});

const getUnavailability = (variables) => {
  return (dispatch, getState) => {

    /*const variables = {
      locationID:"23b46d6b-166e-44a6-a112-7b1944156cb9",
      date:{
        ge:"2020-04-01"
      },
      sortDirection:"ASC",
      limit, 
      nextToken
    }*/
    const request = API.graphql({query: unavailablityByBusinessByDate, variables, authMode: "AMAZON_COGNITO_USER_POOLS"})

    request.then((response) => {
        dispatch(dispatchBookingUnavailability(response))
    });
    
  }
}


const dispatchBookingAvailabilityException = response => ({
  type: Types.BOOKING_AVAILABILITY_EXCEPTION,
  payload: response
});

const getAvailabilityException = (variables) => {
  return (dispatch, getState) => {

    /*const variables = {
      locationID:"23b46d6b-166e-44a6-a112-7b1944156cb9",
      date:{
        ge:"2020-04-01"
      },
      sortDirection:"ASC",
      limit, 
      nextToken
    }*/
    const request = API.graphql({query: availabilityExceptionByBusinessByDate, variables, authMode: "AMAZON_COGNITO_USER_POOLS"})

    request.then((response) => {
        dispatch(dispatchBookingAvailabilityException(response))
    });
    
  }
}


const dispatchBookingsByCustomer = response => ({
  type: Types.BOOKING_BY_CUSTOMER,
  payload: response
});

const getBookingsByCustomer = (variables) => {
  return (dispatch, getState) => {

    /*const variables = {
      customerID:"23b46d6b-166e-44a6-a112-7b1944156cb9",
      date:{
        ge:"2020-04-01"
      },
      sortDirection:"ASC",
      limit, 
      nextToken
    }*/
    const request = API.graphql({query: bookingByCustomerByDate, variables, authMode: "AMAZON_COGNITO_USER_POOLS"})

    request.then((response) => {
        dispatch(dispatchBookingsByCustomer(response))
    });
    
  }
}

const updateList = response => ({
  type: Types.BOOKING_GET_LIST,
  payload: response
});

const getList = (limit, nextToken) => {
  return (dispatch, getState) => {

    const variables = {limit, nextToken}
    const request = API.graphql({query: listBookings, variables, authMode: "AMAZON_COGNITO_USER_POOLS"})

    request.then((response) => {
        dispatch(updateList(response))
    });
    
  }
}

const Actions = {
  getItem,
  createItem,
  updateItem,
  getList,
  clearItem,
  getBookingsByTeamMember,
  getBookingsByLocation,
  getBookingsByCustomer,
  toggleBookingChangedEmailModal,
  sendBookingEmail,
  getUnavailability,
  getAvailabilityException,
  subscribeToUpdates,
  showBlock
}

// exports = {
//   Types,
//   Actions
// }

export {Types, Actions}

// exports.Types = Types
// exports.Actions = Actions
import Rollbar from 'rollbar';
import React from "react";


const RollbarObj = new Rollbar({
  accessToken: "fb1a659177b74d01bbe2b742fdf0b17e",
  captureUncaught: true,
  captureUnhandledRejections: true, 
});

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    RollbarObj.error(error,errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children; 
  }
}

export default ErrorBoundary
import React, { useState, useEffect } from "react";
import * as PropTypes from 'prop-types';
import classNames from 'clsx';
import ToolbarMUI from '@material-ui/core/Toolbar';
import { withStyles } from '@material-ui/core/styles';
import Select from 'react-select';
import {connect} from 'react-redux';
import {Actions as ACTIONS} from 'store/scheduler/action';
import {Actions as BOOKINGACTIONS} from 'store/booking/action';
import {
  Plugin,
  Template,
  TemplatePlaceholder,
  TemplateConnector
} from "@devexpress/dx-react-core";
import { getMessagesFormatter } from '@devexpress/dx-core';
import { addDays, subDays, format } from 'date-fns'
import DatePicker from 'react-datepicker';
import { useHistory } from "react-router-dom";
import {API} from '@aws-amplify/api'

import {
          customerByBusinessByPhone,
       } from 'graphql/queries'

import AsyncSelect from 'react-select/async';

import "react-datepicker/dist/react-datepicker.css";
import "styles/datepicker.css";

const IndicatorsContainer = () => (
	<div style={{ display: "flex", padding:"6.5px"}}>
		<svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
		  <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"/>
		</svg>
	</div>
)
	
const selectDefaultStyle = {
	// radius 0.375rem
  control: styles => ({ 
  	...styles, 
  	backgroundColor: 'white', 
  	border: 'none',
  	padding: 0,
  	minHeight: 'auto',
  	height: '29px',
  }),
  indicatorSeparator:  (provided, state) => ({
  	display: "none",
  }),
  indicatorsContainer:  (provided, state) => ({
  	// icon: ``,
  }),
  valueContainer:  (provided, state) => ({
    // ...provided,
    padding: "0",
    display: "block",
  }),
  placeholder:  (provided, state) => ({
    // ...provided,
    padding: "0",
    display: "block",
    padding: "6.5px 13px",
    fontSize: "0.875rem",
  }),
  singleValue:  (provided, state) => ({
    // ...provided,
    padding: "6.5px 13px",
    fontSize: "0.875rem",
  }),
  input:  (provided, state) => ({
    ...provided,
    lineHeight: "0",
    display: "block",
    padding: "6.5px 13px",
    fontSize: "0.875rem",
    top: 0,
    position: "absolute",
  }),
  option:  (provided, state) => ({
    ...provided,
    lineHeight: "1.25rem",
    fontSize: "0.875rem",
  }),
  menu: (provided, state) => ({
    ...provided,
    zIndex: 999999,
    // lineHeight: "14px",
  }),
};

const pluginDependencies = [
  { name: 'Toolbar' },
  { name: 'ViewState' },
];

const defaultMessages = {
  today: 'Today',
};



const BaseCustomHeader = ({
	business,
	locations,
	setSchedulerLocation,
	setSchedulerFilter,
	filter,
	getBookingsByLocation,
	getUnavailability,
	getAvailabilityException,
	location,
	messages,
	customers,
	thisUser,
	permissions,
	businessID,
	...restProps
}) => {
	// console.log('Custombusiness ',business.allowBookingsDayAdvance)
  	let history = useHistory();

	const updateBookings = (currentDateOption) => { 
		// console.log('update',updateBookings)

		const variables = {
	        locationID: location.value,
	        date: {
	          eq: currentDateOption
	        },
	        limit: 1000,
	    }

		getBookingsByLocation(variables)

		const unavailabilityVariables = {
	        businessID: business.id,
	        date: {
	          eq: currentDateOption
	        },
	        limit: 1000,
	    }

		getUnavailability(unavailabilityVariables)
		getAvailabilityException(unavailabilityVariables)
	}

	let currentDateOption = localStorage.getItem('calendarBookingDate') ? localStorage.getItem('calendarBookingDate') : format(new Date(),'yyyy-MM-dd')



	useEffect(() => {
		if (locations && locations.length > 0 && !location){

			console.log('locationToUse User',thisUser)
			const locationToUse = thisUser ? locations.find(item => item.id == thisUser.locationID) : locations[0]

			const locationDeets = localStorage.getItem('location') ? JSON.parse(localStorage.getItem('location'))  : {value: locationToUse.id, label: locationToUse.name}
			console.log('locationToUse',locationToUse,locationDeets)

			setSchedulerLocation(locationDeets)
		}
	},[locations && locations.length]);

	useEffect(() => {
		if (location){
			updateBookings(currentDateOption)
		}
	},[location && location.value]);

	const locationOptions = locations && 
							locations
								.filter(location => (permissions !== "OwnBookings" || location.id == thisUser.locationID))
								.map(location => ({value: location.id, label: location.name}))


    const getMessage = getMessagesFormatter({ ...defaultMessages, ...messages });

    const maxDate = addDays(new Date(), business && business.allowBookingsDayAdvance || 90)

	return (
	  <Plugin>
	    <Template name="toolbarContent">
	      <TemplateConnector>
	      	{({
					currentDate,
					startViewDate,
					endViewDate,
					firstDayOfWeek,
					currentView,
					intervalCount,
					formatDate,
	      			...getters
	      		}, {
	              changeCurrentDate,
	              ...setters
	            }) => {

	      			{/*console.log('setters',setters)*/}

    				const maxDateReached = new Date(currentDate).getTime() >= maxDate

	      			// unclean??
	      			currentDateOption = currentDate;

	              const setCurrentDate = nextDate => {

	              	localStorage.setItem('calendarBookingDate',nextDate)

	              	{/*console.log('nextDate',nextDate)*/}
	              	// update from DB
	              	updateBookings(nextDate);

	              	// change date in calendar
	              	changeCurrentDate({
		                nextDate,
		              })
	              };

	              const ExampleCustomInput = ({ value, onClick }) => {
	              	return (
				    <button 
				    	onClick={onClick} 
				    	type="button" 
				    	class="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150"
				    	style={{
			              	background : value != format(new Date(),'EEE dd/MM/yyyy') ? "lightblue" : "transparent"
			              }}
				    	>
		            	{value} {/*{currentDate && format(new Date(currentDate),'dd/MM/yyyy')}*/}
		          	</button>
				  )};

				  const filterOptions = [
		            	{
		            		value: "Booked,Confirmed,Present,Served,BeingServed,FinishedEarly,Queueing,NotAnswering,Paid,ReadyForPayment,PaymentFailed",
		            		label: "Show Active",
		            	},
					  	{
						    label: "Not Answering",
						    value: "NotAnswering",
						},
		            	{
		            		value: "NoShow",
		            		label: "Show No Show",
		            	},
		            	{
		            		value: "Cancelled",
		            		label: "Show Cancelled",
		            	},
		            	{
		            		value: "Confirmed",
		            		label: "Show Confirmed",
		            	},
		            	{
		            		value: "Booked",
		            		label: "Show Unconfirmed",
		            	},
		            	{
		            		value: "Present",
		            		label: "Show Arrived",
		            	}
		            ]
				  const cantGoBack = ["OwnBookings","Host"].includes(permissions) && currentDate <= format(new Date(),'yyyy-MM-dd')

	              return (
					    <div className="sm:px-12 lg:px-12" style={{width: "100%"}}>
					      {permissions !== "OwnBookings" && 
						      <div className="pt-6 grid grid-cols-12 gap-12">

						        <div className="col-span-2 col-start-1">
						          <span class="relative z-0 inline-flex cursor-pointer" onClick={() => {window.location.reload()}}>
							          <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"></path></svg>
							      	</span>
						        </div>
						        <div className="col-span-4 col-start-5">
						          <span class="relative z-0 inline-flex shadow-sm">
							            
							          <span style={{padding:"5px", fontWeight: "bold"}}>

							            Week {currentDateOption && format(new Date(currentDateOption),'I')}
							          </span>
							      	</span>
						        </div>
						        <div className="col-span-3 col-start-10">
						          <AsyncSelect
						              className="block form-select w-full p-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
						              styles={selectDefaultStyle}
			                          defaultOptions={[]}
			                          loadOptions={(inputValue, callback) => {

			                            console.log('loadOptions',inputValue)

			                            const beginsWith = inputValue.substr(0,1) == "+" ? 
			                                                      inputValue :
			                                                      inputValue.substr(0,2) == "00" ? 
			                                                        `+${inputValue.substr(2)}` :
			                                                        `+356${inputValue}`

			                            const variables = {
			                              phone: {beginsWith}, 
			                              businessID
			                            }
			                            const request = API.graphql({query: customerByBusinessByPhone, variables, authMode: "AMAZON_COGNITO_USER_POOLS"})

			                            request.then( ({data}) => {
			                                console.log('response',data)

			                                const customers = data.customerByBusinessByPhone.items

			                                const options = customers && customers.map(customer => ({
			                                  value: customer.id,
			                                  label: `${customer.givenName} ${customer.familyName} (${customer.phone})`
			                                }))

			                                callback(options);
			                            });
			                          }}
						              placeholder="Find client..."
						              onChange={(selectedCustomer) => {
						            	history.push("/customer/" + selectedCustomer.value);
						              }}
						              components={{IndicatorsContainer}}
						            />
						        </div>
						      </div>
						  }
					      <div className="py-6 grid grid-cols-12 gap-12">
					        <div className="col-span-3 sm:col-span-3">
					          <Select
					            className="block form-select w-full p-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
					            styles={selectDefaultStyle}
					            value={location}
					            onChange={(selected) => {
					            	localStorage.setItem('location',JSON.stringify(selected))
					            	setSchedulerLocation(selected)
					            }}
					            options={locationOptions}
					            components={{IndicatorsContainer}}
					          />
					        </div>
					        <div className="col-span-4 col-start-5">
					          <span class="relative z-0 inline-flex shadow-sm">
					            <button type="button" class={`relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150 ${cantGoBack ? 'cursor-not-allowed': ''}`}
					            	disabled={cantGoBack}
									onClick={() => {
					            		const newDate = subDays(new Date(currentDate),1)
					                	setCurrentDate(format(newDate,'yyyy-MM-dd'));
					            	}}>
					              <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
					                <path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd"/>
					              </svg>
					            </button>
					            <button
					              className="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150"
					              onClick={() => {
					                setCurrentDate(format(new Date(),'yyyy-MM-dd'));
					              }}
					              style={{
					              	background : currentDate == format(new Date(),'yyyy-MM-dd') ? "lightblue" : "transparent"
					              }}
					            >
					              {getMessage('today')}
					            </button>

					            <DatePicker
		                          dateFormat="EEE dd/MM/yyyy" 
		                          selected={new Date(currentDate)}
		                          onChange={(selectedDate) => {
					                setCurrentDate(format(selectedDate,'yyyy-MM-dd'));
		                          }}
		                          minDate={["OwnBookings","Host"].includes(permissions) ? new Date() : new Date("2020-04-01")}
		                          customInput={<ExampleCustomInput />}
			                      maxDate={maxDate}
		                          className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
		                          popperPlacement="bottom-end"
		                          popperModifiers={{
		                            offset: {
		                              enabled: true,
		                              //offset: "77px, 0px"
		                            }
		                          }}
		                        />
					            <button disabled={maxDateReached} style={{opacity: maxDateReached ? 0.5 : 1}} type="button" class="-ml-px relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150" onClick={() => {
				                	const newDate = addDays(new Date(currentDate),1)

				                	if (newDate.getTime() <= maxDate.getTime()){
				                		// only allow next date if not going over maximum
				                		setCurrentDate(format(newDate,'yyyy-MM-dd'));
				                	} else {
				                		// alert that max date is blocked
				                	}
				            	}}>
					              <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
					                <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"/>
					              </svg>
					            </button>
					          </span>
					        </div>

					        <div className="col-span-3 col-start-10">
					          <Select
					            className="block form-select w-full p-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
					            styles={selectDefaultStyle}
					            placeholder="Show..."
					            onChange={setSchedulerFilter}
					            value={filter}
					            options={filterOptions}
					            components={{IndicatorsContainer}}
					          />
					        </div>
					      </div>
					    </div>
	              );
	            }}

	        {/*{({ rows }) => <span>Number of rows: {rows.length}</span>}*/}
	      </TemplateConnector>
	      <TemplatePlaceholder />
	    </Template>
	  </Plugin>
	)
};


const mapStateToProps = (state) => {
    return {
      businessID: state.businessID,
      business: state.business.item,
      location: state.scheduler.location,
      filter: state.scheduler.filter,
      locations: state.location.list,
      customers: state.customer.list && state.customer.list.map(item => ({ label: `${item.givenName} ${item.familyName ? item.familyName: ''} (${item.phone})`,value: item.id})),
      username: state.userDetails.username,
      permissions: state.permissions,
      thisUser: state.teamMember.list && state.teamMember.list.find(item => item.username == state.userDetails.username)
  }
}

const mapDispatchToProps = dispatch => ({
  setSchedulerFilter: (variables) => dispatch(ACTIONS.setSchedulerFilter(variables)),
  setSchedulerLocation: (variables) => dispatch(ACTIONS.setSchedulerLocation(variables)),
  getBookingsByLocation: (variables) => dispatch(BOOKINGACTIONS.getBookingsByLocation(variables)),
  getUnavailability: (variables) => dispatch(BOOKINGACTIONS.getUnavailability(variables)),
  getAvailabilityException: (variables) => dispatch(BOOKINGACTIONS.getAvailabilityException(variables)),
});

export const CustomHeader = connect(
  mapStateToProps,
  mapDispatchToProps
)(BaseCustomHeader)

import {Types} from "./action";
import _ from "lodash";
// import {NotificationManager} from 'react-notifications';

// import cleanDeep from 'clean-deep';


const defaultState = {
  location: {}
};

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case Types.MOBILELOCATION_SELECTED: {

      let newState = {...state};

      newState.mobileLocation.selected = action.selected;

      return newState;
    }
    case Types.MOBILELOCATION_GET_ITEM: {

      let newState = {...state};

      newState.mobileLocation.item = action.payload.data.getMobileLocation;

      return newState;
    }
    case Types.MOBILELOCATION_CLEAR_ITEM: {

      let newState = {...state};

      newState.mobileLocation.item = {};

      return newState;
    }
    case Types.MOBILELOCATION_GET_LIST: {

      let newState = {...state};

      const existingList = newState.mobileLocation.list || []

      const merged = _(existingList) // start sequence
        .keyBy('id') // create a dictionary of the 1st array
        .merge(_.keyBy(action.payload.data.listMobileLocations.items, 'id')) // create a dictionary of the 2nd array, and merge it to the 1st
        .values() // turn the combined dictionary to array
        .value(); // get the value (array) out of the sequence


      // TODO add sorting facility
      newState.mobileLocation.list = _.sortBy(merged,'name') 


      newState.mobileLocation.listNextToken = action.payload.data.listMobileLocations.nextToken;
      newState.mobileLocation.listStartedAt = action.payload.data.listMobileLocations.startedAt;
      console.log('updatedLocationList',newState.mobileLocation.list)

      return newState;
    }
    case Types.MOBILELOCATION_DELETE_IMAGE: {

      let newState = {...state};

      const deletedItem = action.payload;
      const id = deletedItem.id
      
      newState.mobileLocation.item.photos.items = _.reject(newState.mobileLocation.item.photos.items,{id})
      
      // const

      if (newState.mobileLocation.list){
        const itemIndex = newState.mobileLocation.list.findIndex(item => item.id == newState.mobileLocation.item.id)
        newState.mobileLocation.list[itemIndex] = newState.mobileLocation.item;
        newState.mobileLocation.list = _.sortBy(newState.mobileLocation.list,'name')
      }

      console.log('Photo deleted',newState.mobileLocation.item)

      return newState;
    }
    case Types.MOBILELOCATION_UPLOAD_IMAGE: {

      let newState = {...state};

      const uploadedItem = action.payload;
      if (newState.mobileLocation.item && newState.mobileLocation.item.photos.items && Array.isArray(newState.mobileLocation.item.photos.items)){
        newState.mobileLocation.item.photos.items.push(uploadedItem);
      } else {
        newState.mobileLocation.item.photos.items = [uploadedItem]
      }

      if (newState.mobileLocation.list){
        const itemIndex = newState.mobileLocation.list.findIndex(item => item.id == newState.mobileLocation.item.id)
        newState.mobileLocation.list[itemIndex] = newState.mobileLocation.item;
        newState.mobileLocation.list = _.sortBy(newState.mobileLocation.list,'name')
      }

      console.log('new Photo Uploaded',newState.mobileLocation.item)

      return newState;
    }
    case Types.MOBILELOCATION_UPDATE_ITEM: {

      let newState = {...state};

      const updatedItem = action.payload.data.updateMobileLocation;
      newState.mobileLocation.item = updatedItem;

      if (newState.mobileLocation.list){
        const itemIndex = newState.mobileLocation.list.findIndex(item => item.id == updatedItem.id)
        newState.mobileLocation.list[itemIndex] = updatedItem;
        newState.mobileLocation.list = _.sortBy(newState.mobileLocation.list,'name')
      }

      console.log('newLocationUpdated',newState.mobileLocation.item)

      return newState;
    }
    case Types.MOBILELOCATION_CREATE_ITEM: {

      let newState = {...state};

      const createdItem = action.payload.data.createMobileLocation;
      newState.mobileLocation.item = createdItem;
      if (newState.mobileLocation.list){
        newState.mobileLocation.list.push(createdItem);
        newState.mobileLocation.list = _.sortBy(newState.mobileLocation.list,'name')
      }

      console.log('newLocationUpdated',newState.mobileLocation.item)

      return newState;
    }
    default:
      return state;
  }
};

export default reducer;
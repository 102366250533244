import {Types} from "./action";
import _ from "lodash";
// import {NotificationManager} from 'react-notifications';

// import cleanDeep from 'clean-deep';


const defaultState = {
  ad: {}
};

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case Types.AD_NEW_MODAL: {

      let newState = {...state};

      newState.ad.newModal = action.show;
      newState.ad.item = {};

      return newState;
    }
    case Types.AD_GET_ITEM: {

      let newState = {...state};

      newState.ad.item = action.payload.data.getAd;

      return newState;
    }
    case Types.AD_CLEAR_ITEM: {

      let newState = {...state};

      newState.ad.item = {};

      return newState;
    }
    case Types.AD_GET_SEARCH: {

      let newState = {...state};

      const newItems = action.payload.data.listAds.items.filter(item => item._deleted != true)

      // TODO add sorting facility
      newState.ad.search = _.sortBy(newItems,['givenName','familyName']) 

      return newState;
    }
    case Types.AD_GET_LIST: {

      let newState = {...state};

      const existingList = newState.ad.list || []

      const newItems = action.payload.data.listAds.items.filter(item => item._deleted != true)

      const merged = _(existingList) // start sequence
        .keyBy('id') // create a dictionary of the 1st array
        .merge(_.keyBy(newItems, 'id')) // create a dictionary of the 2nd array, and merge it to the 1st
        .values() // turn the combined dictionary to array
        .value(); // get the value (array) out of the sequence


      // TODO add sorting facility
      newState.ad.list = merged // _.sortBy(merged,['givenName','familyName']) 


      newState.ad.listNextToken = action.payload.data.listAds.nextToken;
      newState.ad.listStartedAt = action.payload.data.listAds.startedAt;
      console.log('updatedAdList',newState.ad.list)

      return newState;
    }
    case Types.AD_UPDATE_ITEM: {

      let newState = {...state};

      const updatedItem = action.payload.data.updateAd || action.payload.data.onUpdateAd;
      newState.ad.item = updatedItem;

      if (newState.ad.list){
        const itemIndex = newState.ad.list.findIndex(item => item.id == updatedItem.id)
        newState.ad.list[itemIndex] = updatedItem;
        newState.ad.list = newState.ad.list // _.sortBy(newState.ad.list,['givenName','familyName'])
      }

      if (action.callback && typeof(action.callback == 'function')){
        action.callback(updatedItem)
      }

      console.log('newAdUpdated',newState.ad.item)

      return newState;
    }
    case Types.AD_DELETE_ITEM: {

      let newState = {...state};

      const deletedItem = action.payload;
      // newState.service.item = deletedItem;

      if (newState.ad.list){
        newState.ad.list = _.reject(newState.ad.list,{id: deletedItem})
      }

      console.log('adDeleted')

      return newState;
    }
    case Types.AD_CREATE_ITEM: {

      let newState = {...state};

      const createdItem = action.payload.data.createAd || action.payload.data.onCreateAd;
      
      const exists = newState.ad && newState.ad.list && newState.ad.list.find(item => item.id == createdItem.id)

      if (exists){
        return state;
      }

      if (newState.ad.list){
        newState.ad.list.push(createdItem);
        newState.ad.list = _.sortBy(newState.ad.list,['givenName','familyName'])
      }

      if (newState.ad.newModal){
        newState.ad.newModal = false;
      } else {
        // do not set ad item if inside a modal
        newState.ad.item = createdItem;
      }


      if (action.callback && typeof(action.callback == 'function')){
        action.callback(createdItem)
      }

      return newState;
    }
    case Types.AD_UPDATE_IMAGE: {

      let newState = {...state};

      newState.ad.item = {...newState.ad.item};
      newState.ad.item.image = action.image;

      if (newState.ad.list){
        const itemIndex = newState.ad.list.findIndex(item => item.id == newState.ad.item.id)
        newState.ad.list[itemIndex] = newState.ad.item;
        newState.ad.list = _.sortBy(newState.ad.list,'name')
      }

      return newState;
    }
    default:
      return state;
  }
};

export default reducer;
import React, { useState, useEffect } from "react";
import { useForm } from 'react-hook-form'

import {connect} from 'react-redux';

import {Actions as ACTIONS} from 'store/dashboard/action';
import PageHeading from 'components/PageHeading';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useLocation,
  useParams
} from "react-router-dom";
import { useHistory } from "react-router-dom";

import Chart from './Chart';

const Dashboard = ({getStats}) => {

  	let history = useHistory();

	return (
		<React.Fragment>
			<PageHeading withCreate={true}/>

			<Chart chartTitle="Bookings by Team Member" reportType="teamMember"/>
			<Chart chartTitle="Bookings by Location" reportType="location"/>
			<Chart chartTitle="Bookings by Status" reportType="appointmentStatus"/>
			<Chart chartTitle="Bookings by Source" reportType="createdAtSource"/>
			
		</React.Fragment>
	)
}


const mapStateToProps = (state) => {
    return {
      customers: state.customer.list,
      customersNextToken: state.customer.listNextToken,
      customerOptions: state.customer.list && state.customer.list.map(item => ({ label: `${item.givenName} ${item.familyName ? item.familyName: ''} (${item.phone})`,value: item.id})),
  }
}

const mapDispatchToProps = dispatch => ({
  getStats: (input) => dispatch(ACTIONS.getStats(input)),
  // timesheetUpdateSaveStatus: (status) => dispatch(ACTIONS.timesheetUpdateSaveStatus(status))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard)
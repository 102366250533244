import {Types} from "./action";
import _ from "lodash";
// import {NotificationManager} from 'react-notifications';

// import cleanDeep from 'clean-deep';


const defaultState = {
  service: {}
};

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case Types.DELETE_MODAL: {

      let newState = {...state};

      console.log(action,'action')

      newState.delete.show = action.show;
      newState.delete.fn = action.fn;

      return newState;
    }
    default:
      return state;
  }
};

export default reducer;
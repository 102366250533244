import React, { useState, useEffect } from "react";
import { useForm } from 'react-hook-form'

import {connect} from 'react-redux';

import {Actions as ACTIONS} from 'store/teamMember/action';
import PageHeading from 'components/PageHeading';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useLocation,
  useParams
} from "react-router-dom";

import Select from 'react-select';

const IndicatorsContainer = () => (
	<div style={{ display: "flex", padding:"6.5px"}}>
		<svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
		  <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"/>
		</svg>
	</div>
)
	
const selectDefaultStyle = {
	// radius 0.375rem
  control: styles => ({ 
  	...styles, 
  	backgroundColor: 'white', 
  	border: 'none',
  	padding: 0,
  	minHeight: 'auto',
  	height: '29px',
  }),
  indicatorSeparator:  (provided, state) => ({
  	display: "none",
  }),
  indicatorsContainer:  (provided, state) => ({
  	// icon: ``,
  }),
  valueContainer:  (provided, state) => ({
    // ...provided,
    padding: "0",
    display: "block",
  }),
  placeholder:  (provided, state) => ({
    // ...provided,
    padding: "0",
    display: "block",
    padding: "6.5px 13px",
    fontSize: "0.875rem",
  }),
  singleValue:  (provided, state) => ({
    // ...provided,
    padding: "6.5px 13px",
    fontSize: "0.875rem",
  }),
  input:  (provided, state) => ({
    ...provided,
    lineHeight: "0",
    display: "block",
    padding: "6.5px 13px",
    fontSize: "0.875rem",
    top: 0,
    position: "absolute",
  }),
  option:  (provided, state) => ({
    ...provided,
    lineHeight: "1.25rem",
    fontSize: "0.875rem",
  }),
  menu: (provided, state) => ({
    ...provided,
    zIndex: 999999,
    // lineHeight: "14px",
  }),
};

const TeamMembers = ({teamMembers,teamMembersNextToken,listTeamMembers,locations}) => {

  	const [filter, setFilter] = useState("Active")

	useEffect(() => {
		console.log('id',teamMembers)
		if (!(teamMembers)){
			console.log('getBusiness')
			listTeamMembers(1000,teamMembersNextToken)
		}
	},[]);

	// if (!teamMembers){

	// 	return (
	// 		<div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
	// 			Loading...
	// 		</div>
	// 	)
	// }

	return (
		<React.Fragment>
			<PageHeading withCreate={true}/>
			<div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
				<div class="flex flex-col">

				    <div className="sm:px-12 lg:px-12" style={{width: "100%"}}>
				      
				      <div className="pt-6 grid grid-cols-12 gap-12">
				        <div className="col-span-3 col-start-10">
				          <Select
				            className="block form-select w-full p-0 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
				            styles={selectDefaultStyle}
				            placeholder="Filter"
				            onChange={(selectedValue) => {
				            	setFilter(selectedValue.value)
				            }}
				            options={[
				            		{
				            			label: "Active",
				            			value: "Active",
				            		},
				            		{
				            			label: "Disabled",
				            			value: "Disabled",
				            		}
				            	]}
				            components={{IndicatorsContainer}}
				          />
				        </div>
				      </div>
					</div>

				  <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
				    <div class="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
				      <table class="min-w-full">
				        <thead>
				          <tr>
				            <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
				              Name
				            </th>
				            <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
				              Phone
				            </th>
				            <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
				              Seat No
				            </th>
				            <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
				              Location
				            </th>
				            <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
				              Status
				            </th>
				            <th class="px-6 py-3 border-b border-gray-200 bg-gray-50"></th>
				          </tr>
				        </thead>
				        <tbody class="bg-white">
				          { teamMembers && teamMembers.filter(t => !t._deleted && t.status == filter).map( (teamMember, index) => (

					          <tr>
					            <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">
					              {teamMember.givenName} {teamMember.familyName}
					            </td>
					            <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
					              {teamMember.phone}
					            </td>
					            <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
					              {teamMember.seatNo}
					            </td>
					            <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
					              {teamMember.locationID && locations.find(item => item.id == teamMember.locationID).name}
					            </td>
					            <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
					               <div class="ml-2 flex-shrink-0 flex">
						            	{teamMember.status}
						            </div>
					            </td>
					            <td class="px-6 py-4 whitespace-no-wrap text-right border-b border-gray-200 text-sm leading-5 font-medium">
					              <Link to={`/team/${teamMember.id}`}  class="text-indigo-600 hover:text-indigo-900 focus:outline-none focus:underline">Edit</Link>
					            </td>
					          </tr>
							))}
				        </tbody>
				      </table>
				    </div>
				  </div>
				</div>
			</div>
		</React.Fragment>
		)
}


const mapStateToProps = (state) => {
    return {
      teamMembers: state.teamMember.list,
      locations: state.location.list,
      teamMembersNextToken: state.teamMember.listNextToken,
  }
}

const mapDispatchToProps = dispatch => ({
  listTeamMembers: (limit,nextToken) => dispatch(ACTIONS.getList(limit,nextToken)),
  // timesheetUpdateSaveStatus: (status) => dispatch(ACTIONS.timesheetUpdateSaveStatus(status))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TeamMembers)
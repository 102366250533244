import {Types} from "./action";
import _ from "lodash";
// import {NotificationManager} from 'react-notifications';

// import cleanDeep from 'clean-deep';


const defaultState = {
  teamMember: {}
};

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case Types.TEAMMEMBER_GET_ITEM: {

      let newState = {...state};

      newState.teamMember.item = action.payload.data.getTeamMember;

      return newState;
    }
    case Types.TEAMMEMBER_CUSTOM_AVAILABILITY_EXCEPTION: {

      let newState = {...state};

      console.log("Action Modal Status", action)
      const data = {
        status: action.modalStatus,
        date: action.dateString,
      }

      newState.teamMember.customExceptionModal = data


      return newState;
    }
    case Types.TEAMMEMBER_CLEAR_ITEM: {

      let newState = {...state};

      newState.teamMember.item = {};

      return newState;
    }
    case Types.TEAMMEMBER_GET_LIST: {

      let newState = {...state};

      const existingList = newState.teamMember.list || []

      const merged = _(existingList) // start sequence
        .keyBy('id') // create a dictionary of the 1st array
        .merge(_.keyBy(action.payload.data.listTeamMembers.items, 'id')) // create a dictionary of the 2nd array, and merge it to the 1st
        .values() // turn the combined dictionary to array
        .value(); // get the value (array) out of the sequence


      // TODO add sorting facility
      newState.teamMember.list = _.sortBy(merged,['locationID','seatNo','givenName']) 


      newState.teamMember.listNextToken = action.payload.data.listTeamMembers.nextToken;
      newState.teamMember.listStartedAt = action.payload.data.listTeamMembers.startedAt;
      console.log('updatedTeamMemberList',newState.teamMember.list)

      return newState;
    }
    case Types.TEAMMEMBER_UPDATING_ITEM: {

      let newState = {...state};

      newState.teamMember.updating = true;

      return newState;
    }
    case Types.TEAMMEMBER_UPDATE_ITEM: {

      let newState = {...state};

      newState.teamMember.updating = false;
      const updatedItem = action.payload.data.updateTeamMember;
      newState.teamMember.item = updatedItem;

      if (newState.teamMember.list){
        const itemIndex = newState.teamMember.list.findIndex(item => item.id == updatedItem.id)
        newState.teamMember.list[itemIndex] = updatedItem;
        newState.teamMember.list = _.sortBy(newState.teamMember.list,'name')
      }

      console.log('newTeamMemberUpdated',newState.teamMember.item)

      return newState;
    }
    case Types.TEAMMEMBER_UPDATE_PHOTO: {

      let newState = {...state};

      newState.teamMember.item = {...newState.teamMember.item};
      newState.teamMember.item.photo = action.photo;

      if (newState.teamMember.list){
        const itemIndex = newState.teamMember.list.findIndex(item => item.id == newState.teamMember.item.id)
        newState.teamMember.list[itemIndex] = newState.teamMember.item;
        newState.teamMember.list = _.sortBy(newState.teamMember.list,'name')
      }

      console.log('newTeamMemberUpdated',newState.teamMember.item)

      return newState;
    }
    case Types.TEAMMEMBER_DELETE_ITEM: {

      let newState = {...state};

      const deletedItem = action.payload;
      // newState.product.item = deletedItem;

      if (newState.teamMember.list){
        newState.teamMember.list = _.reject(newState.teamMember.list,{id: deletedItem})
      }

      return newState;
    }
    case Types.TEAMMEMBER_CREATE_ITEM: {

      let newState = {...state};

      const createdItem = action.payload.data.createTeamMember;
      newState.teamMember.item = createdItem;
      if (newState.teamMember.list){
        newState.teamMember.list.push(createdItem);
        newState.teamMember.list = _.sortBy(newState.teamMember.list,'name')
      }

      console.log('newTeamMemberUpdated',newState.teamMember.item)

      return newState;
    }
    case Types.TEAMMEMBER_UPDATE_TEAMMEMBER_SERVICE: {

      let newState = {...state};

      const updatedItem = action.payload.data.updateTeamMemberService;
      /*newState.teamMember.item = updatedItem;

      if (newState.teamMember.list){
        const itemIndex = newState.teamMember.list.findIndex(item => item.id == updatedItem.id)
        newState.teamMember.list[itemIndex] = updatedItem;
        newState.teamMember.list = _.sortBy(newState.teamMember.list,'name')
      }*/

      console.log('newTeamMemberUpdated',updatedItem,newState.teamMember.item.services)

      return newState;
    }
    case Types.TEAMMEMBER_CREATE_TEAMMEMBER_SERVICE: {

      let newState = {...state};

      const createdItem = action.payload.data.createTeamMemberService;
      // newState.teamMember.item.se = createdItem;

      // if (newState.teamMember.list){
      //   newState.teamMember.list.push(createdItem);
      //   newState.teamMember.list = _.sortBy(newState.teamMember.list,'name')
      // }

      console.log('newTeamMemberUpdated',createdItem,newState.teamMember.item.services)

      return newState;
    }
    case Types.TEAMMEMBER_CREATE_UNAVAILABILITY: {

      let newState = {...state};

      const createdItem = action.payload.data.createTeamMemberService;

      // maybe do something?
      
      return newState;
    }
    case Types.TEAMMEMBER_GET_UNAVAILABILITY: {

      let newState = {...state};

      const unavailability = action.payload.data.unavailablityByTeamMemberByDate;
      newState.teamMember.unavailabilities = unavailability && unavailability.items && unavailability.items.filter(item => !item._deleted);

      return newState;
    }
    case Types.TEAMMEMBER_CREATE_AVAILABILITY_EXCEPTION: {

      let newState = {...state};

      const createdItem = action.payload.data.createTeamMemberService;

      const setModalStatus = {status: false}

      newState.teamMember.customExceptionModal = setModalStatus

      // maybe do something?
      
      return newState;
    }
    case Types.TEAMMEMBER_DELETE_AVAILABILITY_EXCEPTION: {

      let newState = {...state};

      const createdItem = action.payload.data.createTeamMemberService;

      const setModalStatus = {status: false}

      newState.teamMember.customExceptionModal = setModalStatus

      // maybe do something?
      
      return newState;
    }
    case Types.TEAMMEMBER_GET_AVAILABILITY_EXCEPTION: {

      let newState = {...state};

      const availabilityExceptions = action.payload.data.availabilityExceptionByTeamMemberByDate;
      newState.teamMember.availabilityExceptions = availabilityExceptions.items && availabilityExceptions.items.filter(item => !item._deleted);

      return newState;
    }
    default:
      return state;
  }
};

export default reducer;
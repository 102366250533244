import React from 'react';
import logo from './logo.svg';
import './App.css';
import "./styles/app.css";
import Wrapper from "./components/Wrapper";
import ErrorBoundary from "./components/ErrorBoundary";
import Dashboard from "./components/dashboard/Dashboard"
import Sales from "./components/sales/Sales"
import Bookings from "./components/booking/Bookings"
import BusinessSettings from "./components/business/BusinessSettings"
import Services from "./components/services/Services"
import Service from "./components/services/Service"
import Products from "./components/products/Products"
import Product from "./components/products/Product"
import TeamMembers from "./components/teamMember/TeamMembers"
import TeamMember from "./components/teamMember/TeamMember"
import Locations from "./components/location/Locations"
import Location from "./components/location/Location"
import MobileLocation from "./components/mobileLocation/MobileLocation"
import Customers from "./components/customer/Customers"
import Customer from "./components/customer/Customer"
import DeleteModal from "./components/DeleteModal"

import Ads from "./components/ads/Ads"
import Ad from "./components/ads/Ad"
import PaymentMachines from "./components/paymentMachines/PaymentMachines"
import PaymentMachine from "./components/paymentMachines/PaymentMachine"
import {Amplify} from 'aws-amplify';

import awsconfig from './aws-exports';

import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useLocation,
  useParams
} from "react-router-dom";


String.prototype.getInitials = function(glue){
    if (typeof glue == "undefined") {
        var glue = true;
    }

    var initials = this.replace(/[^a-zA-Z- ]/g, "").match(/\b\w/g);

    if (glue) {
        return initials.join('');
    }

    return  initials;
};

Amplify.configure(awsconfig);

const usePageViews = () => {
  let location = useLocation();
  // React.useEffect(
  //   () => {
  //     ga.send(["pageview", location.pathname]);
  //   }, 
  //   [location]
  // );
}


const App = () => {
  usePageViews()

  return (
    <div className="App">
      <ErrorBoundary>
        <Wrapper>
          <Switch>
            <Route exact path="/">
              <Redirect to="/bookings" />
            </Route>
            <Route exact path="/dashboard">
              <Dashboard/>
            </Route>
            <Route exact path="/sales">
              <Sales/>
            </Route>
            <Route exact path="/bookings">
              {/*<BookingSchedule/>*/}
              <Bookings/>
            </Route>
            <Route exact path="/services">
              <Services/>
            </Route>
            <Route path="/services/:id" component={Service}/>

            <Route exact path="/products">
              <Products/>
            </Route>
            <Route path="/products/:id" component={Product}/>

            <Route exact path="/location" component={Locations}/>
            <Route path="/location/:locationID/:id" component={MobileLocation}/>
            <Route path="/location/:id" component={Location}/>

            <Route exact path="/customer" component={Customers}/>
            <Route path="/customer/:id" component={Customer}/>

            <Route exact path="/team" component={TeamMembers}/>
            <Route path="/team/:id" component={TeamMember}/>

            <Route exact path="/ad" component={Ads}/>
            <Route path="/ad/:id" component={Ad}/>
            <Route exact path="/paymentMachine" component={PaymentMachines}/>
            <Route path="/paymentMachine/:id" component={PaymentMachine}/>
            <Route exact path="/settings">
              <BusinessSettings/>
            </Route>
          </Switch>
          <DeleteModal/>
        </Wrapper>
      </ErrorBoundary>
    </div>
  );
}

export default withAuthenticator(App);

import React, { useState, useEffect } from "react";
import {connect} from 'react-redux';

import {Actions as MOBILELOCATION_ACTIONS} from 'store/mobileLocation/action';


import { useParams, useHistory} from "react-router";


const MobileLocationRow = ({
	location,
	mobileLocation,
	selectedMobileLocation,
	setSelectedMobileLocation,
}) => {

  	let history = useHistory();

	const fontWeight = selectedMobileLocation && mobileLocation.id == selectedMobileLocation ? "bold" : "400"
	console.log('fontWeight',fontWeight,selectedMobileLocation,mobileLocation.id)

	return (

		<div 
			onClick={() => setSelectedMobileLocation(mobileLocation.id)}
			style={{
				cursor: "pointer",
				fontWeight
			}}
			>
			<span style={{
				display: "inline-block",
				width: "10px",
				height: "10px",
				marginRight: "10px",
				backgroundColor: mobileLocation.colour
			}}></span>
			{mobileLocation.name}

			<span 
				className="ml-5 inline-block h-5 w-5 overflow-hidden bg-gray-100 cursor-pointer"
				onClick={() => history.push(`/location/${location.id}/${mobileLocation.id}`)}>

				<svg fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"></path></svg>
			</span>
		</div>
		)
}


const mapStateToProps = (state) => {

	const location = state.location.item
	const selectedMobileLocation = state.mobileLocation.selected

	return {
		location,
	  	selectedMobileLocation,
  }
}

const mapDispatchToProps = dispatch => ({
  setSelectedMobileLocation: (item) => dispatch(MOBILELOCATION_ACTIONS.setSelectedMobileLocation(item)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MobileLocationRow) 